import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { SelectPrettyOption, SelectPrettyValue } from "./SelectPretty";
import { Box, Button, Option, Select, Stack, Typography } from "@mui/joy";
import { useEffect, useMemo, useRef, useState } from "react";
import { LuInfo } from "react-icons/lu";
import * as R from "remeda";

import {
  agGridCellAutoPadding,
  changeAllZIndex,
  headerHeight,
} from "../../globals";
import { useUserSubscriptionTier } from "../../../context/auth";
import { productName } from "../../utils";
import {
  joinProductsWithGridPageProducts,
  knownStatusList,
  type TAffectedProducts,
  type TProductWithGridIdAndStatus,
  type TKnownStatus,
  type TStatus,
} from "../statuses/statusLogic";
import { FaArrowRight, FaChevronDown } from "react-icons/fa";
import {
  knownStatusRules,
  statusModalMaxWidth,
  StatusOperationSchema,
  type TStatusTransitionEnhanced,
  type TUpdateStatusOperation,
} from "../statuses/consts";

import {
  changeAllHoverAtom,
  useUpdateColumnStatus,
  useWriteStatusInstruments,
  checkCanBroadcast,
  useGlobalDependencyOptions,
  genSelectStatusOptions,
  genStatusTransitionActions,
  verifyKnownStatusRules,
} from "../statuses/hooks";
import { useSetAtom } from "jotai";
import {
  useActivePageId,
  useGridSettings,
  useGridSettingsStatusMap,
  usePageProducts,
  useProductsByIds,
} from "../../../data";
import { useUserHasRole } from "../../../context/auth";
import { logger } from "@artis/logger";
import { useThemeMode } from "../../../context/theme";

export function ChangeAllStatusButton() {
  const [open, setOpen] = useState(false);
  const elRef = useRef<HTMLAnchorElement>(null);
  const { disabledFeatures } = useUserSubscriptionTier();
  const setChangeAll = useSetAtom(changeAllHoverAtom);

  const statusProducts = useStatusProducts();

  if (!statusProducts?.length) {
    return (
      <Box
        sx={{
          height: headerHeight * 2,
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        height: headerHeight * 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      {open && (
        <StatusModalMany
          setClose={() => setOpen(false)}
          anchor={elRef.current}
          open={open}
        />
      )}
      <Button
        disabled={disabledFeatures?.changeSource}
        ref={elRef}
        onClick={() => setOpen(true)}
        onMouseEnter={() => {
          setChangeAll(true);
        }}
        onMouseLeave={() => {
          setChangeAll(false);
        }}
        size="sm"
        variant="soft"
        sx={{
          ml: agGridCellAutoPadding,
          fontSize: "0.7rem",
          fontWeight: "400",
          minHeight: 0,
          paddingInline: 0.5,
          paddingBlock: 0.5,
        }}
      >
        SOURCE{" "}
        <FaArrowRight
          style={{
            fontSize: "7px",
            marginLeft: "2px",
          }}
        />
      </Button>
    </Box>
  );
}

async function handleUpdateStatus({
  statusOperations,
  isDeniedBroadcast,
  isLiteUser,
  toStatus,
  affectedGridProducts,
  transitionActions,
  updateColumnStatus,
  writeStatusInstruments,
  statusMap,
}: {
  statusOperations: Record<string, TUpdateStatusOperation>;
  isDeniedBroadcast: boolean;
  isLiteUser: boolean;
  toStatus: TStatus;
  affectedGridProducts: TProductWithGridIdAndStatus[] | undefined;
  transitionActions: TStatusTransitionEnhanced[];
  updateColumnStatus: ReturnType<typeof useUpdateColumnStatus>;
  writeStatusInstruments: ReturnType<typeof useWriteStatusInstruments>;
  statusMap: Record<string, TStatus>;
}) {
  const toStatusIsGlobalProduct = !knownStatusList.includes(toStatus);

  const productIds = affectedGridProducts
    ?.map((product) => {
      const gridId = product.gridId;
      const curveId = product.id;
      const fromStatus = statusMap[curveId];
      if (toStatusIsGlobalProduct) {
        const hasGlobalProduct = product.product_global_dependencies.some(
          (globalDep) => globalDep.global_product,
        );
        if (hasGlobalProduct) {
          return product.id;
        }
      } else {
        // casting because we have already ruled out the global products
        const toStatusRules = knownStatusRules[toStatus as TKnownStatus];

        const canEod = !!product?.eod_product_dep;
        const canBroadcast = !!checkCanBroadcast(
          product?.packageByPackage.permissions,
        );

        const resolvedRules = {
          isDeniedBroadcast,
          isLiteUser,
          canEod,
          canBroadcast,
        };

        const canTransitionToStatus = verifyKnownStatusRules(
          toStatusRules,
          resolvedRules,
        );

        if (canTransitionToStatus) {
          logger.log("product:", { name: product.name });
          logger.log("to:", { toStatus });

          const requiredAction = transitionActions.find(
            (action) => action.gridId === gridId,
          );

          if (requiredAction) {
            const action = statusOperations[requiredAction.id];

            if (action === "write") {
              logger.log("write", {
                from: requiredAction.from,
                to: toStatus,
                gridId,
                curveId,
                product,
                fromStatus,
              });
              if (gridId && curveId && knownStatusList.includes(toStatus)) {
                writeStatusInstruments({
                  to: toStatus,
                  from: fromStatus,
                  gridId,
                  curveId,
                });
              } else {
                console.error("gridId or curveId is missing");
              }
            }
          }

          return product.id;
        }
      }
    })
    .filter(Boolean);

  if (productIds?.length) {
    await updateColumnStatus(toStatus, productIds);
  }
}

function SelectStatusOnePage(props: TSelectStatusChangeAll) {
  const statusProducts = useStatusProducts();
  return <SelectStatusChangeAll {...props} statusProducts={statusProducts} />;
}

function StatusModalMany({
  setClose,
  anchor,
  open,
}: {
  setClose: () => unknown;
  anchor: HTMLAnchorElement | null;
  open: boolean;
}) {
  const mode = useThemeMode();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const [toStatus, setToStatus] = useState<TStatus>("listen");
  const [affectedGridProducts, setAffectedGridProducts] =
    useState<TAffectedProducts>({});
  const [transitionActions, setTransitionActions] = useState<
    TStatusTransitionEnhanced[]
  >([]);

  const [statusOperations, setStatusOperations] = useState<
    Record<string, TUpdateStatusOperation>
  >({});

  const [pageScope, setPageScope] = useState<"current-page" | "all-pages">(
    "current-page",
  );

  const distinctTransitions = R.uniqueBy(
    transitionActions,
    (entry) => `${entry?.from}-${entry?.to}`,
  );

  const isDeniedBroadcast = useUserHasRole("no-broadcast");
  const { liteUser } = useUserSubscriptionTier();

  const updateColumnStatus = useUpdateColumnStatus();
  const writeStatusInstruments = useWriteStatusInstruments();
  const statusMap = useGridSettingsStatusMap();
  const isLiteUser = !!liteUser;
  async function onSave() {
    logger.log("-- ON SAVE --");
    logger.log("");

    setError(undefined);
    setIsLoading(true);

    try {
      await handleUpdateStatus({
        isDeniedBroadcast,
        isLiteUser,
        toStatus,
        affectedGridProducts: affectedGridProducts[pageScope],
        transitionActions,
        statusOperations,
        updateColumnStatus,
        writeStatusInstruments,
        statusMap,
      });

      setClose();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong!");
    }
  }

  return (
    <ClickAwayListener onClickAway={setClose}>
      <BasePopup
        open={open}
        offset={8}
        anchor={anchor}
        placement="right-start"
        style={{
          width: "100%",
          maxWidth: statusModalMaxWidth,
          zIndex: changeAllZIndex,
        }}
      >
        <Box
          sx={{
            borderRadius: "sm",
            overflow: "hidden",
            background: (theme) =>
              mode === "light"
                ? theme.palette.background.surface
                : theme.palette.background.level1,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: (theme) =>
              mode === "light"
                ? theme.palette.neutral[200]
                : theme.palette.neutral[700],
          }}
        >
          <Box>
            <Box sx={{ p: 2 }}>
              <Stack gap={2}>
                <Stack
                  flexDirection="row"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    sx={{
                      fontWeight: "xl",
                    }}
                    level="title-sm"
                  >
                    Source
                  </Typography>
                  <Box sx={{ width: "60%" }}>
                    {pageScope === "current-page" ? (
                      <SelectStatusOnePage
                        toStatus={toStatus}
                        pageScope={pageScope}
                        setToStatus={setToStatus}
                        affectedGridProducts={affectedGridProducts}
                        setAffectedGridProducts={setAffectedGridProducts}
                        transitionActions={transitionActions}
                        setTransitionActions={setTransitionActions}
                      />
                    ) : (
                      <SelectStatusAllPages
                        toStatus={toStatus}
                        pageScope={pageScope}
                        setToStatus={setToStatus}
                        affectedGridProducts={affectedGridProducts}
                        setAffectedGridProducts={setAffectedGridProducts}
                        transitionActions={transitionActions}
                        setTransitionActions={setTransitionActions}
                      />
                    )}
                  </Box>
                </Stack>
                <Stack
                  flexDirection="row"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    sx={{
                      fontWeight: "xl",
                    }}
                    level="title-sm"
                  >
                    Apply to
                  </Typography>
                  <Box sx={{ width: "60%" }}>
                    <Select
                      value={pageScope}
                      onChange={(_, value) => {
                        setPageScope(value as "current-page" | "all-pages");

                        setToStatus("listen");
                        setTransitionActions([]);
                        setError(undefined);
                      }}
                    >
                      <Option value={"current-page"}>This Page</Option>
                      <Option value={"all-pages"}>All Pages</Option>
                    </Select>
                  </Box>
                </Stack>
                {pageScope === "all-pages" && (
                  <Typography level="body-xs">
                    NB: Each Curve can only be in one status across all of your
                    Pages
                  </Typography>
                )}
              </Stack>
              {distinctTransitions?.length > 0 ? (
                <Box sx={{ pt: 2 }}>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    {distinctTransitions.map((action) => {
                      const productNamesPerAction = transitionActions
                        .filter((entry) => entry.id === action.id)
                        .map((entry) => entry.productName)
                        .join(", ");

                      return (
                        <Stack key={action.id}>
                          <Typography
                            endDecorator={
                              <LuInfo title={productNamesPerAction} />
                            }
                            sx={{
                              textTransform: "capitalize",
                              fontWeight: "xl",
                              mb: 1,
                            }}
                            level="title-sm"
                          >
                            {action.label}
                          </Typography>
                          <Select
                            size="sm"
                            value={
                              statusOperations[action.id]
                                ? statusOperations[action.id]
                                : "read"
                            }
                            sx={{
                              button: {
                                textTransform: "capitalize",
                              },
                            }}
                            onChange={(_, value) => {
                              setStatusOperations((prev) => ({
                                ...prev,
                                [action.id]: StatusOperationSchema.parse(value),
                              }));
                            }}
                          >
                            {action.options.map((option) => {
                              return (
                                <Option
                                  key={option.value}
                                  value={option.value}
                                  sx={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {option.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Stack>
                      );
                    })}
                  </Box>
                </Box>
              ) : (
                ""
              )}
              <Box sx={{ pt: 2 }}>
                <Stack gap={2} direction="row" justifyContent={"flex-end"}>
                  <Button
                    color="neutral"
                    onClick={setClose}
                    sx={{
                      pl: 2,
                      pr: 2,
                      minWidth: "70px",
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    loading={isLoading}
                    loadingPosition="end"
                    onClick={onSave}
                    sx={{ pl: 2, pr: 2, minWidth: "70px" }}
                  >
                    Save
                  </Button>
                </Stack>
                {error && (
                  <Typography color="danger" sx={{ pt: 2 }} level="body-sm">
                    {error}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </BasePopup>
    </ClickAwayListener>
  );
}

type TSelectStatusChangeAll = {
  toStatus: TStatus;
  pageScope: "current-page" | "all-pages";
  setToStatus: React.Dispatch<React.SetStateAction<TStatus>>;
  transitionActions: TStatusTransitionEnhanced[];
  setTransitionActions: React.Dispatch<
    React.SetStateAction<TStatusTransitionEnhanced[]>
  >;
  affectedGridProducts: TAffectedProducts;
  setAffectedGridProducts: React.Dispatch<
    React.SetStateAction<TAffectedProducts>
  >;
};

function SelectStatusChangeAll({
  statusProducts,
  toStatus,
  pageScope,
  setToStatus,
  setTransitionActions,
  affectedGridProducts,
  setAffectedGridProducts,
}: TSelectStatusChangeAll & {
  statusProducts: TProductWithGridIdAndStatus[];
}) {
  const settings = useGridSettings();
  // this needs to initialize the affected products when the user
  // changes between current page and all pages
  useEffect(() => {
    if (!affectedGridProducts[pageScope] && statusProducts.length) {
      logger.log("set affectedGridProducts in chg all modal");
      setAffectedGridProducts((prev) =>
        R.merge(prev, {
          [pageScope]: statusProducts,
        }),
      );
    }
  }, [
    pageScope,
    statusProducts,
    setAffectedGridProducts,
    affectedGridProducts,
  ]);

  const canAtLeastOneEod = statusProducts?.find(
    (product) => product?.eod_product_dep,
  );
  const canAtLeastOneBroadcast = statusProducts?.find((product) =>
    checkCanBroadcast(product?.packageByPackage.permissions),
  );
  const { liteUser } = useUserSubscriptionTier();
  const resolvedRules = {
    isDeniedBroadcast: useUserHasRole("no-broadcast"),
    isLiteUser: !!liteUser,
    canEod: !!canAtLeastOneEod,
    canBroadcast: !!canAtLeastOneBroadcast,
  };

  const logo = statusProducts?.[0]?.packageByPackage?.sourceBySource?.logo;

  const globalOptions = useGlobalDependencyOptions(statusProducts ?? []);

  const statusOptionsToRender = genSelectStatusOptions({
    resolvedRules,
    globalOptions,
    settings: settings.data,
  });

  const allStatusOptions = genSelectStatusOptions({
    resolvedRules,
    globalOptions,
    settings: settings.data,
  });

  const statusMap = useGridSettingsStatusMap();
  return (
    <Select
      size="sm"
      value={toStatus}
      indicator={false}
      endDecorator={<FaChevronDown style={{ marginTop: 2, marginRight: 2 }} />}
      sx={{
        pl: 2,
      }}
      onChange={(_e, value) => {
        if (!value) return;

        const toStatus = value;

        const transitionActions = R.pipe(
          statusProducts || [],
          R.map((product) => {
            const transitionAction = genStatusTransitionActions({
              from: statusMap?.[product.id] || "listen",
              to: toStatus,
            });

            return (
              transitionAction && {
                ...transitionAction,
                productId: product.id,
                gridId: product.gridId,
                productName: productName(product),
              }
            );
          }),
        ).filter(Boolean);

        setTransitionActions(transitionActions);
        setToStatus(value);
      }}
      renderValue={(renderValueParams) => {
        return (
          <SelectPrettyValue
            options={statusOptionsToRender}
            renderValueParams={renderValueParams}
            logo={logo}
          />
        );
      }}
    >
      {allStatusOptions.map((option) => {
        return (
          <SelectPrettyOption
            key={option.value}
            {...option}
            statusOptionsToRender={statusOptionsToRender}
            artisLogo={logo}
          />
        );
      })}
    </Select>
  );
}

function SelectStatusAllPages(props: TSelectStatusChangeAll) {
  const statusProducts = useStatusProducts();
  return <SelectStatusChangeAll {...props} statusProducts={statusProducts} />;
}

const useStatusProducts = () => {
  const pageProducts = usePageProducts(useActivePageId());
  const ids = pageProducts.data?.map((p) => p.product_id).filter(Boolean) || [];
  const { data: products } = useProductsByIds(ids);
  const statusMap = useGridSettingsStatusMap();
  return useMemo(
    () =>
      joinProductsWithGridPageProducts(
        products ?? [],
        pageProducts.data ?? [],
        statusMap,
      ),
    [pageProducts.data, products, statusMap],
  );
};
