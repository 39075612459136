import type { SxProps, Theme } from "@mui/joy/styles/types";
import type { GridApi, IRowNode } from "ag-grid-community";
import { nanoid } from "nanoid";
import { z } from "zod";
import { useCallback } from "react";
import type { TData } from "../../../calculations-worker";
import { useGrid } from "../../stores";
import { v4 as uuidv4 } from "uuid";

export const ruleValidation = z.object({
  _id: z.string(),
  id: z.string(),
  rule: z.union([
    z.literal("equals"),
    z.literal("isNotEqual"),
    z.literal("lessThan"),
    z.literal("moreThan"),
    z.literal("lessThanOrEqual"),
    z.literal("moreThanOrEqual"),
  ]),
  limit: z.string(),
  limitRef: z
    .object({
      columnId: z.string(),
      rowId: z.string(),
      name: z.string(),
      period: z.string(),
    })
    .optional(),
  formatting: z.object({
    bgColor: z.optional(z.string().nullable()),
    boldText: z.boolean(),
    invertTextColor: z.boolean(),
  }),
  note: z.string(),
  stopIfTrue: z.boolean(),
  columnId: z.string(),
  rowId: z.string(),
});

export type TConditionalRule = z.infer<typeof ruleValidation> & {
  ids?: string[];
};

export function emptyRule(): TConditionalRule {
  return {
    id: nanoid(),
    _id: uuidv4(),
    rule: "isNotEqual",
    limit: "",
    formatting: {
      bgColor: null,
      boldText: false,
      invertTextColor: false,
    },
    note: "",
    stopIfTrue: false,
    columnId: "",
    rowId: "",
  };
}

export function headerStyle(theme: Theme) {
  return {
    p: { background: theme.palette.neutral, overflow: "hidden" },
    textAlign: "center",
    verticalAlign: "middle !important",
    borderRadius: "4px !important",
  } satisfies SxProps;
}

export function useResetLimitSelection() {
  const [api] = useGrid();
  return useCallback(() => {
    api?.setGridOption("onCellClicked", undefined);
    document.querySelectorAll(".ag-cell-selection").forEach((cell) => {
      cell.classList.remove("ag-cell-selection");
    });
  }, [api]);
}

export function getPeriodByRowId(api: GridApi<TData> | null, rowId: string) {
  const rowNode = api?.getRowNode(rowId);

  if (rowNode?.data?.rowType === "adhoc") {
    return rowNode.data.id;
  }

  return rowNode?.data?.period?.toString();
}

export function getNodePeriod(node?: IRowNode<TData>) {
  if (node?.data?.rowType === "adhoc") {
    return node?.data?.id ?? "";
  }

  return node?.data?.period?.toString() ?? "";
}
