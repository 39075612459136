import type { IHeaderParams } from "ag-grid-community";
import { For } from "million/react";
import { memo } from "react";

import { useThemeMode } from "../../../context/theme";

import type {
  PageProductsFragmentFragment,
  Product_Maturity_Enum,
  Product_Uom_Enum,
} from "../../../__generated__/gql/graphql";
import {
  defaultFieldNameSelector,
  fieldNameSelectorToLabel,
  isListOnlyPermissions,
  uomToLabel,
} from "../../utils";
import type { CurveColumnStackName, validColumnSettings } from "../column-defs";
import {
  useActivePageId,
  usePageProduct,
  useProductById,
  useGridSettingsSelect,
  type TProduct,
} from "../../../data";
import { useUserSubscriptionTier } from "../../../context/auth";
import { useVisibleColumns } from "../useVisibleColumns";
import type { TStatus } from "../statuses/statusLogic";
import { brightColor } from "../../../utils/color";

import { ColumnHeaderSelectMemo } from "./ColumnHeaderSelect";
import { ColumnHeaderStatus } from "./ColumnHeaderStatus";
import { getColorFromVarName } from "../cell-style";

export type TColumnHeaderProps = {
  gridId: string;
  status: TStatus;
  productId?: string;
  artisType?: string;
  exchange?: number;
  settings?: ReturnType<typeof validColumnSettings>;
  maturity?: Product_Maturity_Enum;
  hasSharedCell?: boolean;
  permissions: TProduct["packageByPackage"]["permissions"] | null | undefined;
  sharedCellOffsets: string[];
  columnLabels?: {
    commodityParentGroup?: string | null;
    commodityGroup?: number | null;
    geographicalRegion?: number | null;
    package?: string | null;
    uom?: Product_Uom_Enum;
  };
  hasFormula?: boolean;
};

export function ColumnHeaderProduct(
  params: TColumnHeaderProps & IHeaderParams,
) {
  const { productId, gridId } = params;

  const { data: product } = useProductById(productId || "");
  const visibleColumns = useVisibleColumns();
  const logo = product?.packageByPackage?.sourceBySource?.logo;
  const hasSharedCell = product?.has_shared_cell;

  const mode = useThemeMode();
  const columnSettings = usePageProduct(useActivePageId(), gridId);

  const headerColumnColour = useGridSettingsSelect(
    (x) => x?.header_column_colour,
  );
  const subheaderColumnColour = useGridSettingsSelect(
    (x) => x?.subheader_column_colour,
  );
  const hideStatusRow = useGridSettingsSelect((x) => x?.hide_status_row);

  const { disabledFeatures } = useUserSubscriptionTier();

  const permissions = product?.packageByPackage.permissions;
  const isListOnly = isListOnlyPermissions(
    product?.packageByPackage.permissions,
  );
  const eodCurve = product?.artis_type === "eod";

  return (
    <div className="w-full flex flex-col items-center self-start [font-size:10px]">
      {eodCurve || (hasSharedCell && permissions && !isListOnly) ? (
        <>
          {!hideStatusRow.data && !eodCurve && !disabledFeatures?.statusRow && (
            <ColumnHeaderSelectMemo {...params} logo={logo} />
          )}
          {eodCurve && <BlankHeader />}
          <ColumnHeaderStatus
            {...params}
            headerColor={headerColumnColour.data ?? ""}
          />
        </>
      ) : (
        <>
          {!hideStatusRow.data && <BlankHeader />}
          <BlankHeader />
        </>
      )}
      <For each={visibleColumns.data ?? []}>
        {({ name }) => (
          <StaticColumnHeaderMemo
            key={name}
            fetching={false}
            columnName={name}
            product={product}
            columnSettings={columnSettings.data}
            gridSettingsColors={{
              headerColumnColour: headerColumnColour.data ?? "",
              subheaderColumnColour: subheaderColumnColour.data ?? "",
            }}
            mode={mode}
          />
        )}
      </For>
    </div>
  );
}

function StaticColumnHeader({
  columnName,
  product,
  columnSettings,
  fetching,
  gridSettingsColors,
  mode,
}: {
  columnName: CurveColumnStackName;
  product: TProduct | undefined;
  columnSettings?: PageProductsFragmentFragment | null;
  fetching: boolean;
  gridSettingsColors: {
    headerColumnColour: string;
    subheaderColumnColour: string;
  };
  mode: "dark" | "light";
}) {
  const perms = product?.packageByPackage.permissions;
  const isListOnly = isListOnlyPermissions(perms);
  const noPermissions = !perms;

  function genLabelAndStyle() {
    const data = { label: "", style: {} };

    if (fetching) {
      data.label = "Loading...";
      return data;
    }

    const retrievedLabel = retrieveLabel(columnName, product, columnSettings);

    if (isListOnly) {
      data.label = columnName === "name" ? retrievedLabel : "";
      return data;
    }

    if (noPermissions) {
      data.label = columnName === "name" ? "No Permission" : "";
      return data;
    }

    const retrievedStyle = retrieveStyle({
      columnName,
      gridSettingsColors,
      mode,
    });
    data.label = retrievedLabel;
    data.style = retrievedStyle;

    return data;
  }

  const { label, style } = genLabelAndStyle();

  return (
    <div
      style={style}
      className={`column-header column-header-product-${columnName}`}
    >
      {label}
    </div>
  );
}

const StaticColumnHeaderMemo = memo(StaticColumnHeader);

function BlankHeader() {
  return <div className="column-header" />;
}

function retrieveLabel(
  displayName: CurveColumnStackName,
  product: TProduct | null | undefined,
  columnSettings?: PageProductsFragmentFragment | null,
) {
  if (!product) return "loading...";

  const parseLabel = () => {
    switch (displayName) {
      case "commodity_parent_group":
        return product.commodityGroupByCommodityGroup?.commodity_parent_group
          .commodity_parent_group;
      case "geographical_region":
        return product.geographicalRegionByGeographicalRegion
          ?.geographical_region;
      case "commodity_group":
        return product.commodityGroupByCommodityGroup?.commodity_group;
      case "package":
        return product.packageByPackage?.name;
      case "name":
        return product.name;
      case "description":
        return product.description;
      case "source":
        return product.packageByPackage?.sourceBySource?.name;
      case "field_name":
        return fieldNameSelectorToLabel(
          columnSettings?.column_field_selector ??
            defaultFieldNameSelector(product.artis_type),
        );
      case "uom":
        return uomToLabel({
          artisType: product.artis_type,
          source: product.packageByPackage?.sourceBySource?.name ?? "",
          productId: product.id,
          selector:
            columnSettings?.column_field_selector ??
            defaultFieldNameSelector(product.artis_type),
          cellLabel: product.uom,
        });
      default:
        throw new Error(
          `header name could not be parsed for value: ${displayName}`,
        );
    }
  };

  const label = parseLabel();

  return label || "N/A";
}

function retrieveStyle({
  columnName,
  gridSettingsColors,
  mode,
}: {
  columnName: CurveColumnStackName;
  gridSettingsColors: {
    headerColumnColour: string;
    subheaderColumnColour: string;
  };
  mode: "dark" | "light";
}) {
  // Determine the background color based on column name
  const backgroundColor = ["name", "uom"].includes(columnName)
    ? gridSettingsColors.headerColumnColour
    : gridSettingsColors.subheaderColumnColour;

  const backgroundIsVar = backgroundColor.startsWith("var(");
  const colorValue = backgroundIsVar
    ? getColorFromVarName(backgroundColor) === ""
      ? "inherit"
      : getColorFromVarName(backgroundColor)
    : backgroundColor;

  // Override the color based on mode when backgroundColor is 'inherit'
  let color: string;
  if (colorValue === "inherit") {
    color = mode === "dark" ? "white" : "black";
  } else {
    // Determine text color based on the brightness of the background color
    color = brightColor(colorValue) ? "black" : "white";
  }

  return {
    backgroundColor: colorValue,
    fontWeight: columnName === "name" ? "bold" : "normal",
    color: color,
  };
}
