import { useState } from "react";

/**
 * Resolve an async function using react suspense
 * The key must be unique for each function call
 */
export const useSuspenseFn = <T>(key: string, fn: () => Promise<T>): T => {
  const [state] = useState(() => {
    const existing = suspends.get(key);
    if (existing) return existing;
    const promise = wrapSuspense(fn());
    suspends.set(key, promise);
    return promise;
  });
  return state.read();
};

// Global cache of suspense promises
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const suspends = new Map<string, any>();

const wrapSuspense = <T>(promise: Promise<T>) => {
  let status = "pending";
  let error: unknown = null;
  let result: T;
  const suspender = promise.then(
    (data) => {
      status = "success";
      result = data;
    },
    (err) => {
      status = "error";
      error = err;
    },
  );
  return {
    read() {
      if (status === "pending") throw suspender;
      if (status === "error") throw error;
      return result;
    },
  };
};
