import {
  Button,
  DialogTitle,
  Divider,
  Drawer,
  ModalClose,
  Option,
  Select,
  Typography,
  Checkbox,
  Sheet,
} from "@mui/joy";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import type React from "react";
import { Fragment, useState } from "react";
import { useThemeMode } from "../../context/theme";
import {
  gridSettingsResetConfirmationZIndex,
  gridSettingsWidth,
  gridSettingsZIndex,
  sideBarWidth,
} from "../globals";
import {
  initGridSettings,
  type TGridSettings,
  useGridSettings,
  useGridSettingsSelect,
  useUpdateGridSettings,
} from "../../data";
import { useUserHasRole } from "../../context/auth";
import { SettingsDateColumn } from "./SettingsDateColumn";
import { useUserSubscriptionTier } from "../../context/auth";
import { useSetThemeMode } from "../../context/theme";
import { SimpleColorPicker } from "./SimpleColorPicker";
import { getColorFromVarName } from "../market-grid/cell-style";
import { useGridSettingsVisible } from "./hooks";

export function CollapsibleSettings() {
  const [isGridSettingsVisible, setIsGridSettingsVisible] =
    useGridSettingsVisible();

  const scratchpadEnabled = useUserHasRole("scratchpad-beta");

  return (
    <Drawer
      open={isGridSettingsVisible}
      onClose={() => setIsGridSettingsVisible(false)}
      slotProps={{
        root: {
          sx: {
            zIndex: gridSettingsZIndex,
            width: {
              xs: "100%",
              md: `${gridSettingsWidth}px`,
            },
          },
        },
        content: {
          sx: {
            left: {
              xs: 0,
              md: sideBarWidth,
            },
            width: {
              xs: "100%",
              md: `${gridSettingsWidth}px`,
            },
            background: "transparent",
          },
        },
        backdrop: {
          background: "transparent",
          sx: {
            backdropFilter: "blur(0px)",
            WebkitBackdropFilter: "blur(0px)",
          },
        },
      }}
      sx={{
        "--Drawer-horizontalSize": {
          sm: `${gridSettingsWidth}px`,
          xs: "100%",
        },
        zIndex: gridSettingsZIndex,
      }}
    >
      <div className="bg-settings p-5 w-full h-full rounded-r-2xl flex flex-col gap-4">
        <DialogTitle className="!text-3xl !font-extrabold">
          Settings
        </DialogTitle>
        <ModalClose sx={{ position: "absolute", top: 10, right: 10 }} />
        <Divider />
        <div className="flex justify-between gap-4 pr-12">
          <div className="flex flex-col gap-6">
            <div className="space-y-4">
              <ColumnTitle>Grid appearance</ColumnTitle>
              <SettingsDateColumn />
            </div>
            <SettingsAppearance />
            <RestoreDefaults />
          </div>
          <div className="flex flex-col gap-4 h-max">
            <SettingsTimeSpreads />
            <SettingsFullMonthRow />
            <SettingsAlerts />
            {scratchpadEnabled && <SettingsScratchpad />}
          </div>
          <div className="flex flex-col gap-4">
            <SettingsColorOptions />
            <SettingsColumnColorOptions />
          </div>
        </div>
      </div>
    </Drawer>
  );
}

function SettingsAppearance() {
  const setMode = useSetThemeMode();
  const mode = useThemeMode();
  const update = useUpdateGridSettings();
  const settings = useGridSettings();
  const { liteUser } = useUserSubscriptionTier();

  return (
    <div className="grid [grid-template-columns:17.8rem_3rem] gap-y-4">
      <label htmlFor="flash_cell_updates">Flash cell updates</label>
      <Checkbox
        id="flash_cell_updates"
        checked={!!settings.data?.flash_cell_updates}
        disabled={liteUser}
        color="neutral"
        onChange={() =>
          update.optimistic({
            flash_cell_updates: !settings.data?.flash_cell_updates,
          })
        }
      />

      <label htmlFor="alternating_row_colours">Alternating row shading</label>
      <Checkbox
        id="alternating_row_colours"
        checked={!!settings.data?.alternating_row_colours}
        disabled={liteUser}
        color="neutral"
        onChange={() =>
          update.optimistic({
            alternating_row_colours: !settings.data?.alternating_row_colours,
          })
        }
      />

      <label htmlFor="hide_status_row">Hide status row</label>
      <Checkbox
        id="hide_status_row"
        checked={Boolean(settings.data?.hide_status_row)}
        disabled={liteUser}
        color="neutral"
        onChange={() =>
          update.optimistic({
            hide_status_row: !settings.data?.hide_status_row,
          })
        }
      />

      <label htmlFor="dark_mode">Dark mode</label>
      <Checkbox
        id="dark_mode"
        checked={mode === "dark"}
        disabled={liteUser}
        color="neutral"
        onChange={() => {
          const newMode = mode === "dark" ? "light" : "dark";
          setMode(newMode);
        }}
      />

      <label htmlFor="disable_eod">Disable EOD</label>
      <Checkbox
        id="disable_eod"
        checked={Boolean(settings.data?.hide_eod)}
        disabled={liteUser}
        color="neutral"
        onChange={() =>
          update.optimistic({ hide_eod: !settings.data?.hide_eod })
        }
      />

      <label htmlFor="disable_broadcast">Disable Broadcast</label>
      <Checkbox
        id="disable_broadcast"
        checked={Boolean(settings.data?.hide_broadcast)}
        disabled={liteUser}
        color="neutral"
        onChange={() =>
          update.optimistic({
            hide_broadcast: !settings.data?.hide_broadcast,
          })
        }
      />

      <label htmlFor="disable_private">Disable Private</label>
      <Checkbox
        id="disable_private"
        checked={Boolean(settings.data?.hide_private)}
        disabled={liteUser}
        color="neutral"
        onChange={() =>
          update.optimistic({ hide_private: !settings.data?.hide_private })
        }
      />

      <label htmlFor="disable_global">Disable Global</label>
      <Checkbox
        id="disable_global"
        checked={Boolean(settings.data?.hide_global)}
        disabled={liteUser}
        color="neutral"
        onChange={() =>
          update.optimistic({ hide_global: !settings.data?.hide_global })
        }
      />
    </div>
  );
}

function SettingsAlerts() {
  const update = useUpdateGridSettings();
  const settings = useGridSettings();
  const { liteUser } = useUserSubscriptionTier();

  return (
    <div>
      <ColumnTitle>Alert settings</ColumnTitle>
      <SettingGrid>
        <label htmlFor="enable_alerts">Enable</label>
        <Checkbox
          id="enable_alerts"
          checked={!!settings.data?.sound}
          disabled={liteUser}
          color="neutral"
          onChange={() =>
            update.optimistic({
              sound: !settings.data?.sound,
            })
          }
        />
      </SettingGrid>
    </div>
  );
}

function SettingsFullMonthRow() {
  const update = useUpdateGridSettings();
  const fullMonthRow = useGridSettingsSelect(
    (x) => x?.enable_full_month_row_feature,
  );
  const { liteUser } = useUserSubscriptionTier();

  return (
    <div>
      <ColumnTitle>Full Month Row</ColumnTitle>
      <SettingGrid>
        <label htmlFor="enable_full_month_row_feature">Enable</label>
        <Checkbox
          id="enable_full_month_row_feature"
          checked={!!fullMonthRow.data}
          disabled={liteUser}
          color="neutral"
          onChange={() =>
            update.optimistic({
              enable_full_month_row_feature: !fullMonthRow.data,
            })
          }
        />
      </SettingGrid>
    </div>
  );
}

function SettingsTimeSpreads() {
  const update = useUpdateGridSettings();
  const adhocSpreadsSwitch = useGridSettingsSelect(
    (x) => x?.adhoc_spreads_switch,
  );
  const adhocSpreadsRows = useGridSettingsSelect((x) => x?.adhoc_spreads_rows);
  return (
    <div>
      <ColumnTitle>Time Spreads</ColumnTitle>
      <SettingGrid>
        <label htmlFor="adhoc_spreads_switch" className="text-base">
          Show
        </label>
        <Checkbox
          checked={!!adhocSpreadsSwitch.data}
          color="neutral"
          onChange={() =>
            update.optimistic({
              adhoc_spreads_switch: !adhocSpreadsSwitch.data,
            })
          }
        />
        <label htmlFor="adhoc_spreads_rows" className="text-base">
          Rows to Show
        </label>
        <Select
          className="min-w-[4.5rem]!"
          value={adhocSpreadsRows.data ?? 0}
          onChange={async (_e, newValue) =>
            newValue !== null &&
            update.optimistic({ adhoc_spreads_rows: newValue })
          }
        >
          {Array.from({ length: 20 }, (_, i) => {
            const value = i + 1;
            return (
              <Option key={value} value={value}>
                {value}
              </Option>
            );
          })}
        </Select>
      </SettingGrid>
    </div>
  );
}

function SettingsScratchpad() {
  const hasScratchpadRole = useUserHasRole("scratchpad-beta");
  const { liteUser } = useUserSubscriptionTier();

  const update = useUpdateGridSettings();
  const settings = useGridSettings();

  if (!hasScratchpadRole) {
    return null;
  }

  const NumberOptions = () => {
    return (
      <>
        {Array.from({ length: 20 }, (_, i) => {
          const value = i + 1;
          return (
            <Option key={value} value={value}>
              {value}
            </Option>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <ColumnTitle>Scratchpad</ColumnTitle>
      <SettingGrid>
        <label htmlFor="grid_scratchpad_switch">Enable (beta)</label>
        <Checkbox
          id="grid_scratchpad_switch"
          color="neutral"
          disabled={liteUser}
          checked={!!settings.data?.grid_scratchpad_switch}
          onChange={() =>
            update.optimistic({
              grid_scratchpad_switch: !settings.data?.grid_scratchpad_switch,
            })
          }
        />
        <label htmlFor="scratchpad_max_rows">Rows</label>
        <Select
          value={settings.data?.scratchpad_max_rows ?? 0}
          onChange={async (_e, newValue) =>
            newValue !== null &&
            update.optimistic({ scratchpad_max_rows: newValue })
          }
          aria-label="Scratchpad max rows"
        >
          <NumberOptions />
        </Select>
        <label htmlFor="scratchpad_max_cols">Columns</label>
        <Select
          value={settings.data?.scratchpad_max_cols ?? 0}
          onChange={async (_e, newValue) =>
            newValue !== null &&
            update.optimistic({ scratchpad_max_cols: newValue })
          }
          aria-label="Max Scratchpad columns"
        >
          <NumberOptions />
        </Select>
      </SettingGrid>
    </div>
  );
}

export type GridColorSetting = {
  title: string;
  key: Extract<
    keyof TGridSettings,
    | "indicator_colour"
    | "header_column_colour"
    | "subheader_column_colour"
    | "broadcast_colour"
    | "hybrid_colour"
    | "listen_colour"
    | "eod_colour"
    | "local_colour"
    | "global_colour"
  >;
  value: string | null;
};

function SettingsColorOptions() {
  const update = useUpdateGridSettings();
  const settings = useGridSettings();
  const { liteUser } = useUserSubscriptionTier();

  const colorSettings = [
    {
      title: "Header",
      key: "header_column_colour",
      value:
        settings.data?.header_column_colour ??
        initGridSettings.header_column_colour,
    },
    {
      title: "Subheader",
      key: "subheader_column_colour",
      value:
        settings.data?.subheader_column_colour ??
        initGridSettings.subheader_column_colour,
    },
    {
      title: "Row Indicator",
      key: "indicator_colour",
      value: settings.data?.indicator_colour ?? null,
    },
  ] satisfies GridColorSetting[];

  return (
    <div>
      <ColumnTitle>Grid colours</ColumnTitle>
      <SettingGrid>
        {colorSettings.map(({ title, key, value }) => {
          const color = value
            ? value.startsWith("var(")
              ? getColorFromVarName(value) === ""
                ? "inherit"
                : getColorFromVarName(value)
              : value
            : "";

          return (
            <Fragment key={key}>
              <p>{title}</p>
              <SimpleColorPicker
                position="bottom"
                value={color}
                onChange={(v) => update.optimistic({ [key]: v })}
                disabled={liteUser}
                additionalSettings={
                  key === "subheader_column_colour" && (
                    <div className="w-full flex justify-between items-center p-6 pt-0">
                      <label htmlFor={"matchColor"} className="text-sm">
                        Match Header Colour
                      </label>
                      <input
                        id={"matchColor"}
                        type="checkbox"
                        className="m-0 mr-2"
                        checked={value === "var(--headerColumnColour)"}
                        onChange={(e) =>
                          update.optimistic({
                            [key]: e.target.checked
                              ? "var(--headerColumnColour)"
                              : color,
                          })
                        }
                      />
                    </div>
                  )
                }
              />
            </Fragment>
          );
        })}
      </SettingGrid>
    </div>
  );
}

function SettingsColumnColorOptions() {
  const settings = useGridSettings();
  const update = useUpdateGridSettings();
  const { liteUser } = useUserSubscriptionTier();

  const colorSettings = [
    {
      title: "Hybrid",
      key: "hybrid_colour",
      value: settings.data?.hybrid_colour ?? null,
    },
    {
      title: "Listen",
      key: "listen_colour",
      value: settings.data?.listen_colour ?? null,
    },

    {
      title: "Broadcast",
      key: "broadcast_colour",
      value: settings.data?.broadcast_colour ?? null,
    },
    {
      title: "Private",
      key: "local_colour",
      value: settings.data?.local_colour ?? null,
    },

    {
      title: "EOD",
      key: "eod_colour",
      value: settings.data?.eod_colour ?? null,
    },
    {
      title: "Global",
      key: "global_colour",
      value: settings.data?.global_colour ?? null,
    },
  ] satisfies GridColorSetting[];

  return (
    <div>
      <ColumnTitle>Status colours</ColumnTitle>
      <SettingGrid>
        {colorSettings.map(({ title, key, value }) => (
          <Fragment key={key}>
            <Typography
              sx={{ flexShrink: 0 }}
              {...(liteUser ? { textColor: "text.disabled" } : {})}
            >
              {title}
            </Typography>
            <SimpleColorPicker
              position="bottom"
              value={value ?? ""}
              onChange={(v) => update.optimistic({ [key]: v })}
              disabled={liteUser}
            />
          </Fragment>
        ))}
      </SettingGrid>
    </div>
  );
}

const RestoreDefaults = () => {
  const update = useUpdateGridSettings();
  const { liteUser } = useUserSubscriptionTier();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    update.reset();
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={liteUser}
        color="neutral"
        onClick={handleOpen}
        className="w-64!"
      >
        Restore Default Settings
      </Button>
      <ClickAwayListener
        disableReactTree
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClose}
      >
        <BasePopup
          open={open}
          style={{
            zIndex: gridSettingsResetConfirmationZIndex,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Sheet
            variant="outlined"
            sx={(theme) => ({
              background: theme.palette.background.body,
              padding: 2,
              borderRadius: theme.radius.md,
              zIndex: gridSettingsResetConfirmationZIndex + 1,
            })}
          >
            <Typography level="h4" fontWeight="bold">
              Restore Settings
            </Typography>
            <p className="my-2">Restore all grid settings back to default?</p>
            <div className="mt-6 flex justify-end gap-4">
              <Button color="neutral" onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary">
                Restore
              </Button>
            </div>
          </Sheet>
        </BasePopup>
      </ClickAwayListener>
    </>
  );
};

const ColumnTitle = (p: { children: React.ReactNode }) => (
  <h4 className="text-xl font-extrabold w-max col-span-full">{p.children}</h4>
);

const SettingGrid = (p: React.JSX.IntrinsicElements["div"]) => (
  <div
    className="grid [grid-template:auto/8rem_1fr] gap-3 items-center py-3"
    {...p}
  />
);
