import type { GridApi, RangeSelectionChangedEvent } from "ag-grid-community";
import { useCallback, useRef } from "react";
import { atom, useAtomValue } from "jotai";

import { selectedCellsByRange, type TRangeCell } from "../../tableUtils";
import { store } from "../modal";
import type { TData } from "../calculations-worker";
import { gridApiAtom } from "./stores";

export type TRangeSelection = TRangeCell[][];
const rangeSelectionAtom = atom<TRangeSelection>([]);
rangeSelectionAtom.debugLabel = "rangeSelectionAtom";

export const getCurrentRangeSelection = () => store.get(rangeSelectionAtom);
export const useCurrentRangeSelection = () => useAtomValue(rangeSelectionAtom);

export function useRangeSelectionChanged() {
  const groupRanges = useRef<Set<number>>(new Set());

  return useCallback((_: RangeSelectionChangedEvent) => {
    const api = store.get(gridApiAtom);
    if (!api) return;
    const selectedRanges = selectedCellsByRange(
      api,
      [] as TRangeSelection,
      ({ column, rowNode, store, rangeIdx }) => {
        if (
          !column ||
          !rowNode.id ||
          rowNode?.rowIndex === undefined ||
          rowNode?.rowIndex === null
        )
          return;
        store[rangeIdx] = store[rangeIdx] ?? [];
        store[rangeIdx].push({
          columnId: column.getId(),
          rowId: rowNode.id,
          rowIndex: rowNode.rowIndex,
          headerName: column?.getColDef()?.headerName || column.getId(),
        });
      },
    );

    const oldRowIdsToUpdate = store
      .get(rangeSelectionAtom)
      .flat()
      ?.map((x) => x.rowId);
    const newSelectedRange =
      selectedRanges[selectedRanges.length - 1]?.map((x) => x.rowId) || [];

    if (selectedRanges.length === 1) {
      const newIdsToUpdate = selectedRanges[0]?.map((x) => x.rowId);

      store.set(rangeSelectionAtom, selectedRanges);
      groupRanges.current = new Set();
      refreshTargetCells([...oldRowIdsToUpdate, ...newIdsToUpdate], api);
      return;
    }

    const groupedRanges: TRangeSelection = [];

    selectedRanges?.map((range, idx) => {
      const isPartOfCurrentGroup = groupRanges.current.has(idx);

      // we push an empty array to be populated with the current range
      // so length - 1 works as new group
      if (!isPartOfCurrentGroup) {
        groupedRanges.push([]);
      }

      // Add the current range to the last group in the groupedRanges
      groupedRanges[groupedRanges.length - 1].push(...range);
    });
    store.set(rangeSelectionAtom, groupedRanges);
    refreshTargetCells([...oldRowIdsToUpdate, ...newSelectedRange], api);
  }, []);
}

function refreshTargetCells(rowIds: string[], api?: GridApi<TData>) {
  if (!api || api.isDestroyed()) {
    return;
  }
  const rowNodes = rowIds.map((id) => api.getRowNode(id)).filter(Boolean);
  api.refreshCells({ force: true, rowNodes });
}
