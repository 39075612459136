import { unique } from "remeda";
import type { TRangeSelection } from "../../useRangeSelectionChange";

export const SelectionInfo = (p: {
  selection: string;
}) => {
  return (
    <div className="w-full">
      <p className="py-1">Selection</p>
      <p className="border px-4 py-3 rounded-md overflow-x-auto whitespace-nowrap">
        {p.selection}
      </p>
    </div>
  );
};

export const selectionColumnIds = (selection: TRangeSelection) => [
  ...selection.reduce(
    (a, b) => b.reduce((c, d) => (d.productId ? c.add(d.columnId) : c), a),
    new Set<string>(),
  ),
];

export const columnSelectionInfo = (selection: TRangeSelection) => {
  const k = [
    ...selection.reduce(
      (a, b) => b.reduce((c, d) => (d.productId ? c.add(d.headerName) : c), a),
      new Set<string>(),
    ),
  ];
  if (k.length <= 1) return k.join("");
  return `${k[0]} - ${k[k.length - 1]}`;
};

export const rangeSelectionInfo = (selection: TRangeSelection) =>
  selection
    .map((range) => {
      const first = range[0];
      const last = range[range.length - 1];

      const firstPeriod = first?.rowId;
      const lastPeriod = last?.rowId;

      const packageNames = unique(range.map((cell) => cell.headerName));

      if (firstPeriod === lastPeriod)
        return `${packageNames.join(", ")}: ${firstPeriod}`;

      if (packageNames.length > 1) {
        return `${packageNames[0]}: ${firstPeriod} - ${
          packageNames[packageNames.length - 1]
        }: ${lastPeriod}`;
      }

      return `${packageNames[0]}: ${firstPeriod} - ${lastPeriod}`;
    })
    .join(", ");
