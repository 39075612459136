import { useEffect } from "react";
import { useGridSettings } from "../../data";
import { calculateTextColor } from "../../utils/color";

export function useDynamicCssClassRules() {
  const s = useGridSettings().data;

  useEffect(() => {
    // https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
    // 60% opacity
    const opacityInHex = "99";

    setVar(
      "--rowOddColour",
      s?.alternating_row_colours ? "var(--rowOddColourOn)" : "transparent",
    );

    setVar("--indicatorColour", `${s?.indicator_colour}${opacityInHex}`);

    setVar("--headerColumnColour", s?.header_column_colour || "transparent");
    setVar(
      "--header-text-colour",
      calculateTextColor(s?.header_column_colour ?? "inherit", false),
    );
    setVar(
      "--subheaderColumnColour",
      s?.subheader_column_colour || "transparent",
    );

    setColorPair("listen", s?.listen_colour);
    setColorPair("hybrid_broadcast", s?.hybrid_colour);
    setColorPair("private", s?.local_colour);
    setColorPair("eod", s?.eod_colour);
    setColorPair("broadcast", s?.broadcast_colour);
    setColorPair("global", s?.global_colour);
  }, [
    s?.indicator_colour,
    s?.header_column_colour,
    s?.listen_colour,
    s?.subheader_column_colour,
    s?.alternating_row_colours,
    s?.local_colour,
    s?.hybrid_colour,
    s?.broadcast_colour,
    s?.eod_colour,
    s?.global_colour,
  ]);
}

const setColorPair = (name: string, color?: string | null) => {
  document.documentElement.style.setProperty(
    `--${name}-color-bg`,
    color ?? "transparent",
  );
  document.documentElement.style.setProperty(
    `--${name}-color-fg`,
    calculateTextColor(color ?? "inherit", false),
  );
};

const setVar = (name: string, value: string) =>
  document.documentElement.style.setProperty(name, value);
