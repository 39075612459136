import dayjs from "dayjs";

import {
  currentMonth,
  EodEntriesSub,
  GridSettingsQuerySub,
  LatestEodDateQuery,
} from "../queries";
import type { GraphQLClient } from "../../../utils/graphql";
import { eod } from "./eod";

export const settings = {
  statusMap: {},
  priorEodDate: "2024-08-01",
  eodDate: null as null | string,
  getEodDate() {
    if (this?.eodDate === "prior") return this.priorEodDate;
    return this?.eodDate || this.priorEodDate;
  },

  start: async (graphql: GraphQLClient, userId: string) => {
    let eodSubscriptionCleanup: () => void = () => undefined;

    await graphql.execute(LatestEodDateQuery).then((res) => {
      const eodEntries = res?.data?.eod_entry || [];
      if (eodEntries.length === 0) return;

      const nowDate = dayjs().tz("Europe/London");

      const sortedDates = [...eodEntries]
        .map((entry) => entry.evaluation_date)
        .filter(Boolean)
        .sort((a, b) => dayjs(b).unix() - dayjs(a).unix());

      const priorDate = sortedDates.find((date) =>
        dayjs(date).isBefore(nowDate, "day"),
      );

      if (priorDate) {
        settings.priorEodDate = priorDate;
      }
    });

    return graphql.subscribe(
      GridSettingsQuerySub,
      { userId },
      {
        next: (res) => {
          settings.eodDate = res.data?.grid_settings?.[0].eod_date ?? null;
          settings.statusMap =
            (res.data?.grid_settings?.[0].status_map as Record<
              string,
              string
            >) || {};

          const eodDate = settings.getEodDate();

          if (eodDate) {
            eodSubscriptionCleanup();
            eodSubscriptionCleanup = graphql.subscribe(
              EodEntriesSub,
              {
                eval_date: eodDate,
                date: currentMonth,
              },
              {
                next: (x) => eod.resetCells(x.data?.eod_entry ?? []),
              },
            );
          }
        },
      },
    );
  },
};
