import type { INTERNAL_PrdStore } from "jotai/vanilla/store";
import { atom, getDefaultStore, useAtom } from "jotai";

export const store: INTERNAL_PrdStore = getDefaultStore();

type TAddProductsModal = {
  modalId: "add-products";
  pageId: string;
  curveWidth?: number;
};

export const modalAtom = atom<TAddProductsModal | null>(null);

export function useModal() {
  return useAtom(modalAtom);
}
