import { Box, Typography } from "@mui/joy";
import { useKeyboardShortcuts } from "../market-grid/keyboard";
import { InfoModal } from "./InfoModal";

export function KeyboardShortcutsModal({
  open,
  setClose,
}: {
  open: boolean;
  setClose: () => void;
}) {
  const gridKeyboardShortcuts = useKeyboardShortcuts();
  return (
    <InfoModal
      open={open}
      onClose={setClose}
      title={"Keyboard Shortcuts"}
      labelBy="keyboard-shortcuts-title"
      describedBy="keyboard-shortcuts-description"
    >
      <Box
        id="shortcut-help"
        sx={{
          display: "grid",
          gridTemplateColumns: "3.5fr 3fr",
        }}
      >
        {gridKeyboardShortcuts.map((shortcut) => (
          <Box
            key={shortcut.name}
            className="item"
            sx={{
              cursor: "pointer",
              display: "contents",
              marginBottom: 2,
              "&:hover .key__button": {
                color: (theme) => theme.palette.primary[500],
              },
            }}
          >
            <Typography
              component="p"
              sx={{
                alignSelf: "center",
              }}
            >
              {shortcut.name}
            </Typography>
            <Box
              className="shortcut-column"
              sx={{
                display: "flex",
                gap: 0.5,
              }}
            >
              {shortcut.shortcuts
                .filter((keyCombo) => !keyCombo.includes("cmd"))
                .slice(0, 1)
                .map((keyCombo) => (
                  <Box
                    key={keyCombo}
                    className="shortcut"
                    sx={{ display: "flex", gap: 0.5 }}
                  >
                    {keyCombo
                      .split("+")
                      .map((key) =>
                        key === "ArrowUp"
                          ? "↑"
                          : key === "ArrowDown"
                            ? "↓"
                            : key,
                      )
                      .map((key) => (
                        <Box
                          key={key}
                          className="key__button"
                          sx={{
                            display: "inline-block",
                            height: "30px",
                            minWidth: "30px",
                            padding: "8px",
                            backgroundColor: "rgba(233, 234, 237, 0.24)",
                            boxShadow:
                              "-3px -3px 7px #ffffff73, 2px 2px 5px rgba(94, 104, 121, 0.288)",
                            borderRadius: "5px",
                            margin: "5px",
                            color: "grey",
                            fontSize: "14px",
                            textAlign: "center",
                            transition: "color 0.2s ease-in-out",
                          }}
                        >
                          {key}
                        </Box>
                      ))}
                  </Box>
                ))}
            </Box>
          </Box>
        ))}
      </Box>
    </InfoModal>
  );
}
