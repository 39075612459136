import type {
  ColDef,
  EditableCallbackParams,
  ITooltipParams,
  ICellRendererParams,
  CellClassParams,
} from "ag-grid-community";
import { isNullish } from "remeda";
import type { TCell, TData } from "../../calculations-worker";

import { columnWidth } from "../../globals";
import { formatGridNumber, parseNumber } from "../../numbers";
import { compareIdx, isListOnlyPermissions } from "../../utils";
import type { TColumnHeaderProps } from "../components";
import type { ProcessCellStyleFn } from "../cell-style";
import {
  formatAdhocText,
  formatMonthColumnText,
  monthTimespreadValueGetter,
  timespreadData,
} from "../periodHelpers";
import type { TStatusMap } from "../../../triplit/schema";
import { throttle } from "../../../utils";

import { suppressKeyboardEvent } from "../keyboard";
import { isDev } from "../../../globals";
import type { TAdhocSpreadFormatted, TProduct } from "../../../data";
import type { TTooltipCell } from "../components";
import type { TProductConfigs } from "../../../data/cache";
import { getCurrentRangeSelection } from "../useRangeSelectionChange";
import type { PageProductsFragmentFragment } from "../../../__generated__/gql/graphql";
import { NumberCellEditor } from "../components/NumberCellEditor";

export const curveColumnStack = [
  {
    label: "Parent",
    name: "commodity_parent_group",
    defaultVisible: false,
    groupable: true,
    required: false,
    hoverPosition: null,
    showOnMobile: false,
  },
  {
    label: "Region",
    name: "geographical_region",
    hoverPosition: 4,
    defaultVisible: false,
    groupable: true,
    required: false,
    showOnMobile: false,
  },
  {
    label: "Commodity",
    name: "commodity_group",
    hoverPosition: 3,
    defaultVisible: false,
    groupable: true,
    required: false,
    showOnMobile: false,
  },
  {
    label: "Package",
    hoverPosition: 2,
    name: "package",
    required: false,
    defaultVisible: false,
    showOnMobile: false,
    groupable: false,
  },
  {
    name: "name",
    label: "Display Name",
    showOnMobile: true,
    required: true,
    groupable: false,
    defaultVisible: false,
    hoverPosition: null,
  },
  {
    label: "Curve Name",
    name: "description",
    hoverPosition: 1,
    required: false,
    defaultVisible: false,
    showOnMobile: false,
    groupable: false,
  },
  {
    label: "Source",
    name: "source",
    hoverPosition: 5,
    required: false,
    defaultVisible: false,
    showOnMobile: false,
    groupable: false,
  },
  {
    label: "Field Name",
    name: "field_name",
    required: false,
    defaultVisible: false,
    hoverPosition: null,
    showOnMobile: false,
    groupable: false,
  },
  {
    label: "Price UOM",
    showOnMobile: true,
    name: "uom",
    defaultVisible: false,
    required: true,
    hoverPosition: null,
    groupable: false,
  },
] as const;

export type CurveColumnStackName = (typeof curveColumnStack)[number]["name"];

type RequiredCurveColumnStack = Exclude<
  (typeof curveColumnStack)[number],
  { required: false }
>;

type OptionalCurveColumnStack = Exclude<
  (typeof curveColumnStack)[number],
  { required: true }
>;

export const requiredColumnStack = curveColumnStack.filter(
  (column): column is RequiredCurveColumnStack => {
    return column.required;
  },
);

export const potentiallyInvisibleColumns = curveColumnStack.filter(
  (column): column is OptionalCurveColumnStack => {
    return !column.required;
  },
);

export const mainMonthColumnId = "period";

// If a field is provided, it's a shadow column.
// width only is used for the main column period
function monthColumnDefs({
  field,
  width,
  spreads,
  mainMonthColumn,
  processCellStyle,
}: {
  field?: string;
  width?: number;
  spreads: TAdhocSpreadFormatted;
  mainMonthColumn: boolean;
  processCellStyle: ProcessCellStyleFn;
}) {
  return {
    colId: field ?? "1",
    field: mainMonthColumnId,
    headerComponentParams: {
      mainMonth: mainMonthColumn,
      gridId: field,
    },
    headerName: "Month",
    width: width ? width : 70,
    minWidth: 70,
    lockPinned: !field,
    pinned: field ? undefined : "left",
    lockPosition: !field,
    headerComponent: "agColumnMonths",
    resizable: true,
    suppressNavigable: true,
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
    valueFormatter: (params) => {
      if (params.data?.rowType === "adhoc") {
        const spread = spreads[params.data.id];
        return formatAdhocText({
          from: spread?.from?.periodValue,
          to: spread?.to?.periodValue,
        });
      }

      const str = params.value?.toString();
      return formatMonthColumnText(str);
    },
    tooltipValueGetter: (params) => {
      const code = params.node?.data?.code;
      const isTspd = params.node?.data?.rowType === "adhoc";
      if (isTspd) {
        if (params.valueFormatted?.toString().trim() === "-/-") {
          return "Click here to set a custom Time Spread";
        }
        return params.valueFormatted?.toString() || "Time Spread";
      }
      if (isDev) {
        return `${code} / M${params.node?.rowIndex}`;
      }
      if (code) {
        return code;
      }
    },
    cellStyle: field
      ? processCellStyle
      : (params) => {
          const api = params?.api;
          if (!api) return null;

          const columns = api.getColumns();
          if (!columns) return null;

          const cellIndex = params.node.rowIndex;

          const rangeSelection = api.getCellRanges();

          const inRange = rangeSelection?.some((range) => {
            const start = range?.startRow?.rowIndex;
            const end = range?.endRow?.rowIndex;
            if (!start || !end || !cellIndex) return false;
            return cellIndex >= start && cellIndex <= end;
          });

          const borderRight = inRange
            ? "4px solid var(--indicatorColour)"
            : "var(--ag-borders-critical) var(--ag-border-color)";

          return {
            borderRight,
          };
        },
  } satisfies ColDef<TData, TCell>;
}

function monthTimespreadColumnDefs({
  field,
  width,
  processCellStyle,
}: {
  field?: string;
  width?: number;
  processCellStyle: ProcessCellStyleFn;
}) {
  return {
    colId: field,
    field: "month-timespread",
    headerComponentParams: {
      gridId: field,
      shadowType: "month-timespread",
    },
    headerName: "Spread",
    width: field && width ? width : 70,
    cellStyle: processCellStyle,
    lockPinned: false,
    lockPosition: false,
    resizable: true,
    headerComponent: "agColumnMonthTimespread",
    valueGetter: (params) => {
      if (timespreadData) {
        return monthTimespreadValueGetter(params, timespreadData);
      }
    },
    suppressNavigable: true,
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
  } satisfies ColDef;
}

function blankColumnDefs({
  field,
  width,
}: { field?: string; width?: number } = {}) {
  return {
    colId: field,
    field: "blank",
    headerName: "",
    headerComponentParams: {
      gridId: field,
      shadowType: "blank",
    },
    cellClass: "blank-cell",
    valueGetter: () => null,
    width: field && width ? width : 70,
    lockPinned: false,
    lockPosition: false,
    resizable: true,
    headerComponent: null,
    enableValue: false,
    suppressNavigable: true,
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
  } satisfies ColDef;
}

export const defaultColumnSettings = {
  decimalPlaces: 2,
  thousandsSeparator: false,
  columnWidth,
  increment: 0.25,
};

export function validColumnSettings({
  decimalPlaces,
  thousandsSeparator,
  columnWidth,
  increment,
}: {
  decimalPlaces?: number | null;
  thousandsSeparator?: boolean | null;
  columnWidth?: number | null;
  increment?: number | null;
}) {
  return {
    decimalPlaces: isNullish(decimalPlaces)
      ? defaultColumnSettings.decimalPlaces
      : decimalPlaces,
    thousandsSeparator: isNullish(thousandsSeparator)
      ? defaultColumnSettings.thousandsSeparator
      : thousandsSeparator,
    columnWidth: isNullish(columnWidth)
      ? defaultColumnSettings.columnWidth
      : columnWidth,
    increment: isNullish(increment)
      ? defaultColumnSettings.increment
      : increment,
  };
}

export const sharedCellOffsetsArr: Record<string, string[]> = {};

export function genColumnDefs({
  currentPageProducts,
  products,
  mainMonthColumnWidth,
  statusMap,
  flashCellUpdates,
  spreads,
  processCellStyle,
  configs,
}: {
  currentPageProducts: PageProductsFragmentFragment[] & TProduct[];
  products: TProduct[];
  mainMonthColumnWidth: number | undefined;
  statusMap: TStatusMap;
  flashCellUpdates: boolean;
  configs: TProductConfigs;
  spreads: TAdhocSpreadFormatted;
  processCellStyle: ProcessCellStyleFn;
}): ColDef<TData, TCell>[] {
  const orderedProducts = currentPageProducts?.sort(compareIdx);
  const productColDefs: ColDef<TData, TCell>[] = orderedProducts
    ?.map((pageProduct) => {
      const {
        id,
        column_field_selector: columnFieldSelector,
        product_id: productId,
        column_type: columnType,
        column_width: columnWidth,
        thousands_separator: thousandsSeparatorSetting,
        decimal_places: decimalPlacesSetting,
      } = pageProduct;

      // Shadow columns.
      if (columnType !== "product") {
        switch (columnType) {
          case "month":
            return monthColumnDefs({
              field: id,
              width: columnWidth,
              spreads,
              processCellStyle,
              mainMonthColumn: false,
            });
          case "month-timespread":
            return monthTimespreadColumnDefs({
              field: id,
              width: columnWidth,
              processCellStyle,
            });
          case "blank":
            return blankColumnDefs({ field: id, width: columnWidth });
        }
      }

      const product = products?.find((p) => p.id === productId);
      const permissions = product?.packageByPackage.permissions;
      const productConfigs = configs[productId ?? ""];

      const sharedCellOffsets = product?.has_shared_cell
        ? productConfigs
            ?.map((config) =>
              config.formula === null ? config.offset.toString() : null,
            )
            .filter(Boolean)
        : [];

      if (product?.id) sharedCellOffsetsArr[product.id] = sharedCellOffsets;

      const isListOnly = isListOnlyPermissions(permissions);

      const status = productId ? statusMap?.[productId] || "listen" : "listen";

      const isEditable = (
        params:
          | EditableCallbackParams<TData, TCell>
          | ITooltipParams<TData, TCell, unknown>,
        customStatusCheck = false,
      ) => {
        const rowIndex = params?.node?.rowIndex?.toString();

        const editable =
          params?.data?.rowType === "mth" &&
          sharedCellOffsets &&
          rowIndex &&
          (status === "broadcast" ||
            status === "hybrid_broadcast" ||
            status === "private" ||
            customStatusCheck)
            ? sharedCellOffsets.includes(rowIndex)
            : false;
        return editable;
      };

      // Define the tooltip value getter function
      const tooltipValueGetter = (params: ITooltipParams) =>
        ({
          status,
          productId: productId ?? undefined,
          isEditable: Boolean(
            params.data?.rowType === "mth" && product?.has_shared_cell,
          ),
        }) satisfies TTooltipCell;

      // Use the throttled version of the tooltip value getter function
      const throttledTooltipValueGetter = throttle(tooltipValueGetter, 1000);

      return {
        colId: id,
        field: id,
        headerName: product?.name,
        suppressFillHandle: !product?.has_shared_cell,
        ...(!isListOnly &&
          permissions && {
            tooltipComponent: "agTooltipProduct",
            // params for header tooltip
            headerTooltip: productId ?? undefined,
            // params for row cell tooltip
            tooltipValueGetter: throttledTooltipValueGetter,
            editable: isEditable,
          }),
        type: "numericColumn",
        cellEditor: NumberCellEditor,
        headerComponent: "agColumnHeaderProducts",
        cellRendererSelector: (params: ICellRendererParams) => {
          const productId = params.colDef?.headerComponentParams.productId;
          const sharedCells = sharedCellOffsetsArr[productId];
          const rowIndex = params.node.rowIndex?.toString();
          if (
            flashCellUpdates &&
            rowIndex &&
            productId &&
            sharedCells?.includes(rowIndex)
          ) {
            return {
              component: "agAnimateShowChangeCellRenderer",
            };
          }
          return undefined;
        },
        // anything changing in header params (local or remote changes),
        // will lead to the column headers being re-rendered.
        headerComponentParams: {
          gridId: id,
          productId: productId ?? undefined,
          settings: validColumnSettings({
            thousandsSeparator: thousandsSeparatorSetting,
            decimalPlaces: decimalPlacesSetting,
          }),

          status: status || "listen",
          artisType: product?.artis_type,
          sharedCellOffsets,
          hasSharedCell: Boolean(product?.has_shared_cell),
          permissions,
          exchange: product?.packageByPackage.sourceBySource?.exchange?.code,
          maturity: product?.maturity,
          columnLabels: {
            commodityParentGroup:
              product?.commodityGroupByCommodityGroup?.commodity_parent_group
                ?.commodity_parent_group,
            commodityGroup: product?.commodity_group,
            geographicalRegion: product?.geographical_region,
            package: product?.packageByPackage?.name,
            uom: product?.uom,
          },
          hasFormula:
            (
              productConfigs
                ?.map((config) => config?.formula)
                .filter(Boolean) || []
            )?.length > 0,
        } satisfies TColumnHeaderProps,
        suppressHeaderMenuButton: true,
        suppressKeyboardEvent,
        filter: false,
        sortable: false,
        cellClass: "ag-right-aligned-cell",
        cellStyle: (params) => processCellStyle(params, product),
        cellClassRules: {
          rangeSelectionGroupTwo: (params) => findRangeSelectionCell(params, 1),
        },
        valueFormatter: (params) => {
          const { thousandsSeparator, decimalPlaces } = validColumnSettings({
            thousandsSeparator: thousandsSeparatorSetting,
            decimalPlaces: decimalPlacesSetting,
          });

          const cellValue = params.value;

          if (cellValue === null || cellValue === undefined) {
            return "";
          }
          if (columnFieldSelector === "lasttime") {
            const date = new Date(cellValue);
            const formattedTime = date.toTimeString().split(" ")[0];
            return formattedTime;
          }

          if (typeof cellValue === "string") return cellValue;

          const number = parseNumber(cellValue);
          if (number === null) return "";

          const formatted = formatGridNumber(
            number,
            decimalPlaces,
            thousandsSeparator,
          );

          return formatted;
        },

        width: columnWidth || 150,
      } satisfies ColDef<TData, TCell>;
    })
    .filter(Boolean) as ColDef<TData, TCell>[];

  return currentPageProducts
    ? [
        monthColumnDefs({
          width: mainMonthColumnWidth,
          spreads,
          processCellStyle,
          mainMonthColumn: true,
        }),
        ...productColDefs,
      ]
    : [];
}

export function findRangeSelectionCell(params: CellClassParams, group: number) {
  const rangeSelection = getCurrentRangeSelection();
  // only check for match in array gorup
  if (rangeSelection.length <= group) return false;
  if (rangeSelection.length > 2) return false;
  for (let i = 0; i < rangeSelection[group].length; i++) {
    if (
      rangeSelection[group][i].rowId === params.data.id &&
      rangeSelection[group][i].columnId === params.colDef.field
    ) {
      return true;
    }
  }

  return false;
}
