import type { GridApi } from "ag-grid-community";
import { registerExtension } from "msgpack-es";
import type { TStatusMap } from "../../../../triplit/schema";
import type { TData, TGridDataRowId } from "../../../calculations-worker";
import {
  colParams,
  defaultFieldNameSelector,
  isListOnlyPermissions,
} from "../../../utils";
import type {
  TAdhocSpreadFormatted,
  TPageProductWithInfo,
} from "../../../../data";
import { getCalcWorker } from "../../../calculations-worker";

registerExtension(
  // id used for keywords in live-prices
  3,
  String,
  // we don't encode anything so don't bother implementing
  () => new Uint8Array(),
  // treat keywords as strings but strip :
  (keyword) => new TextDecoder().decode(keyword).substring(1),
);

let apiIssueCount = 0;

// Exported functions
export function getAllRowIds(api: GridApi<TData>): TGridDataRowId[] {
  const rows: TGridDataRowId[] = [];
  if (!api || api.isDestroyed()) return rows;
  api.forEachNode((node) => {
    if (!node?.data) throw new Error("Node data is missing");
    rows.push([node.data.rowType, node.data.id]);
  });

  return rows;
}

export function addInfoToPageProduct(
  product: TPageProductWithInfo,
  statusMap: TStatusMap,
) {
  if (!product || !product.product_id || !product.artis_type) return;

  const artisType = product?.artis_type;
  const fieldSelector =
    product?.column_field_selector ??
    defaultFieldNameSelector(artisType || "customer_curve");

  const permissions = product?.packageByPackage?.permissions;
  const isListOnlyPermission = isListOnlyPermissions(permissions);

  const isPermissioned = permissions?.length && !isListOnlyPermission;

  const eodId =
    artisType === "eod"
      ? product.eod_product_dep || product.product_id
      : product.eod_product_dep;

  return {
    ...product,
    columnId: product.id,
    eodId,
    productId: product.product_id,
    selector: fieldSelector,
    isPermissioned: !!isPermissioned,
    hasSharedCell: !!product.has_shared_cell,
    artisType,
    status: statusMap?.[product.product_id] || "listen",
  };
}

export async function updateGrid(
  adhocSpreads: TAdhocSpreadFormatted,
  api: GridApi<TData>,
  products: TPageProductWithInfo[],
  statusMap: Record<string, string>,
) {
  try {
    if (!api || api.isDestroyed()) {
      console.warn("api not ready", api);
      apiIssueCount++;
      if (apiIssueCount > 20) {
        console.error("reloading cause of api issue");
        window.location.reload();
        return;
      }
      return;
    }

    apiIssueCount = 0;
    const transactions: TData[] = [];
    const productsWithKey = products
      .map((product) => addInfoToPageProduct(product, statusMap))
      .filter(Boolean);

    const allRowIds = getAllRowIds(api);

    const resolvedGridData = await getCalcWorker().getGridData({
      rowIds: allRowIds,
      columns: productsWithKey,
      adhocSpreads,
    });

    if (!resolvedGridData || !api || api.isDestroyed()) return;

    api.forEachNode((node, idx) => {
      if (node.data?.blank || !node.data) return;
      const rowUpdates = node.data;
      const monthCode = node.id;
      if (typeof monthCode !== "string") return;

      const columns = api.getColumns();
      const resolvedCell = resolvedGridData[idx];

      if (!columns) return;

      const columnsWithoutPermissions = columns.filter((c) => {
        const params = colParams(c);
        if (
          (!params?.permissions ||
            (params?.permissions && !params?.permissions?.length)) &&
          params?.productId
        ) {
          return c;
        }
      });

      for (const column of productsWithKey) {
        const colId = column.id;
        const productId = column.productId;

        if (!productId || productId === "period" || !resolvedCell) return;

        const resolvedValue = resolvedCell[colId];

        if (resolvedValue && !resolvedValue?.Err && "Ok" in resolvedValue) {
          const newValue = resolvedValue.Ok;
          if (rowUpdates[colId] !== newValue) {
            rowUpdates[colId] = newValue;
          }
        } else if (resolvedValue?.Err) {
          // in the future we can add error visualisations here like excel
          rowUpdates[colId] = undefined;
        } else {
          rowUpdates[colId] = undefined;
        }
      }

      for (const column of columnsWithoutPermissions) {
        rowUpdates[column.getColId()] = undefined;
      }

      transactions.push(rowUpdates);
    });

    api.applyTransactionAsync({
      update: transactions,
    });
  } catch (error) {
    console.error("updateGrid error", error);
  }
}
