import { Option, Select, Checkbox } from "@mui/joy";

import { capitalizeFirstLetter } from "../../utils";
import { londonDate } from "../utils";
import {
  defaultMonthsRangeSettings,
  genMonthList,
  nthNextCals,
  nthNextHlvs,
  nthNextMonths,
  nthNextQtrs,
  nthNextSeasons,
} from "../market-grid";
import {
  initGridSettings,
  useGridSettings,
  useUpdateGridSettings,
} from "../../data";
import { Fragment } from "react/jsx-runtime";

export function SettingsDateColumn() {
  const orderedDateColumnSettings = useDateColumnSettings();
  const update = useUpdateGridSettings();

  const keyMappings = {
    qtrs: "Quarters",
    hlvs: "Halves",
    months: "Months",
    cals: "Calendars",
    seasons: "Seasons",
  } satisfies Record<
    (typeof orderedDateColumnSettings)[number]["valueKey"],
    string
  >;

  return (
    <div className="grid [grid-template-columns:7rem_8rem_3rem_3rem] items-center gap-2 gap-x-4">
      <p className="">Period</p>
      <p className="">To</p>
      <p className="">Enable</p>
      <p className="">Current</p>
      {orderedDateColumnSettings.map((x) => (
        <Fragment key={x.currentKey}>
          <p>{keyMappings[x.valueKey] || capitalizeFirstLetter(x.valueKey)}</p>
          <Select
            value={x.value.toString()}
            renderValue={(newValue) => newValue?.label}
            onChange={(_e, newValue) => {
              newValue !== null &&
                update.optimistic({
                  [x.valueKey]: Number.parseInt(newValue, 10),
                });
            }}
            className="w-full!"
          >
            {x.options.map(([value, label]) => {
              return (
                <Option key={value} value={value.toString()}>
                  {label}
                </Option>
              );
            })}
          </Select>
          {x.show !== null ? (
            <Checkbox
              color="neutral"
              className="w-6 h-6 self-center place-self-center"
              checked={!!x.show}
              onChange={(event) =>
                update.optimistic({ [x.showKey]: !!event.target.checked })
              }
            />
          ) : (
            <span />
          )}
          {x.current !== null ? (
            <Checkbox
              color="neutral"
              className="w-6 h-6 self-center place-self-center"
              checked={!!x.current}
              onChange={(event) =>
                update.optimistic({ [x.currentKey]: !!event.target.checked })
              }
            />
          ) : (
            <span />
          )}
        </Fragment>
      ))}
    </div>
  );
}

const useDateColumnSettings = () => {
  const settings = useGridSettings();

  const date = londonDate();
  const monthList = genMonthList(defaultMonthsRangeSettings, date);

  const monthOptions = nthNextMonths(monthList || settings.data?.months);
  const monthValue = settings.data?.months ?? monthOptions[0][0];

  const qtrsOptions = nthNextQtrs(
    monthList,
    settings.data?.qtr_current ?? initGridSettings.qtr_current,
  );
  const qtrsValue = settings.data?.qtrs ?? qtrsOptions[0][0];

  const hlvsOptions = nthNextHlvs(
    monthList,
    settings.data?.hlv_current ?? initGridSettings.hlv_current,
  );
  const hlvsValue = settings.data?.hlvs ?? hlvsOptions[0][0];

  const seasonsOptions = nthNextSeasons(
    monthList,
    settings.data?.season_current ?? initGridSettings.season_current,
  );
  const seasonsValue = settings.data?.seasons ?? seasonsOptions[0][0];

  const calsOptions = nthNextCals(
    monthList,
    settings.data?.cal_current ?? initGridSettings.cal_current,
  );
  const calsValue = settings.data?.cals ?? calsOptions[0][0];

  const orderedDateColumnSettings = [
    {
      options: monthOptions,
      value: monthValue,
      valueKey: "months",
      current: null,
      show: null,
      showKey: null,
      currentKey: null,
    },
    {
      options: qtrsOptions,
      value: qtrsValue,
      current: settings.data?.qtr_current,
      show: settings.data?.qtr_switch,
      valueKey: "qtrs",
      showKey: "qtr_switch",
      currentKey: "qtr_current",
    },
    {
      options: hlvsOptions,
      value: hlvsValue,
      current: settings.data?.hlv_current,
      show: settings.data?.hlv_switch,
      valueKey: "hlvs",
      showKey: "hlv_switch",
      currentKey: "hlv_current",
    },
    {
      options: seasonsOptions,
      value: seasonsValue,
      current: settings.data?.season_current,
      show: settings.data?.season_switch,
      valueKey: "seasons",
      showKey: "season_switch",
      currentKey: "season_current",
    },
    {
      options: calsOptions,
      value: calsValue,
      current: settings.data?.cal_current,
      show: settings.data?.cal_switch,
      valueKey: "cals",
      showKey: "cal_switch",
      currentKey: "cal_current",
    },
  ] as const;

  return orderedDateColumnSettings;
};
