import {
  type TPageFormatting,
  usePageFormattingOptions,
  useUpdatePageFormatting,
} from "../../../../data";
import { getCellIds, modalTypeToHighlightType } from "../../cell-style";
import type { TRangeSelection } from "../../useRangeSelectionChange";
import { modalCancelActions } from "../modalAtoms";
import type { TFormatTypes } from "../FormatCell";
import { useGrid } from "../../stores";

export const useUpdateFormatting = (p: {
  pageId: string;
  type: TFormatTypes;
  selection: TRangeSelection;
}) => {
  const formatting = usePageFormattingOptions(p.pageId);
  const updateFormatting = useUpdatePageFormatting();
  const [api] = useGrid();

  const highlightType = modalTypeToHighlightType(p.type);
  const highlights = { ...(formatting.data?.[highlightType] ?? {}) };
  const { cellIds } = getCellIds({ type: p.type, ranges: p.selection, api });

  const onChange = (value: Partial<TPageFormatting[string]>) => {
    if (!modalCancelActions.hasCancel("formatting")) {
      const current = structuredClone({ ...formatting.data });
      modalCancelActions.addCancel("formatting", () =>
        updateFormatting({ id: p.pageId, ...current }),
      );
    }
    cellIds.map((cellId) => {
      highlights[cellId] = {
        color: highlights[cellId]?.color,
        boldText: highlights[cellId]?.boldText || false,
        invertTextColor: highlights[cellId]?.invertTextColor || false,
        ...value,
      };
    });
    console.log(value, highlights);
    updateFormatting({ id: p.pageId, [highlightType]: highlights });
  };

  const value = highlights[cellIds[0]] ?? {};

  return [value, onChange] as const;
};
