import {
  useDebouncedCallback,
  useLocalStorageValue,
  useIntervalEffect,
} from "@react-hookz/web";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useStore } from "jotai";

import { useOptionalActivePageId, usePageProducts } from "../../data";
import {
  getCalcWorker,
  exposeMainThreadApi,
  type TData,
} from "../calculations-worker";
import { gridApiAtom } from "../market-grid/stores";
import { alertProductChanged } from "../alerts";
import { colParams } from "../utils";
import { useLogout, useUserId } from "../../context/auth";
import { logger } from "@artis/logger";
import { sendNotification } from "../market-grid/modals/Alerts/AlertToast";
import { useApi } from "../../admin/hooks/useApi";
import { useGraphQLClient } from "../../utils/graphql";
import { triggeredAlertsQueueAtom } from "../../data/alerts/alerts";

export const useBottomPanelOpen = () =>
  useLocalStorageValue("bottomPanelOpen", {
    defaultValue: false,
    initializeWithValue: true,
  });

export const useConnectCalcWorkerClientHandlers = () => {
  const { getAccessTokenSilently } = useAuth0();
  const pageId = useOptionalActivePageId() ?? "";
  const userId = useUserId();
  const pageProducts = usePageProducts(pageId);
  const queryClient = useQueryClient();
  const handleLogout = useLogout();
  const store = useStore();

  const makeApiRequest = useApi();
  const hasuraClient = useGraphQLClient();

  useIntervalEffect(() => {
    const queue = store.get(triggeredAlertsQueueAtom);
    if (queue.length > 0) {
      const alert = queue[0];
      store.set(triggeredAlertsQueueAtom, queue.slice(1));
      sendNotification(
        alert,
        alert.initial,
        hasuraClient,
        queryClient,
        makeApiRequest,
      );
    }
  }, 500);

  const refetchAlerts = useDebouncedCallback(
    () => {
      queryClient.invalidateQueries({ queryKey: ["alerts", userId, pageId] });
    },
    [userId, pageId],
    300,
  );

  useEffect(() => {
    return exposeMainThreadApi({
      invalidateQueries: (queryKey) =>
        queryClient.invalidateQueries({ queryKey }),
      alertProductChanged: alertProductChanged,
      logoutRequest: () => handleLogout(),
      renewToken: async () => {
        // Wait 5 seconds. This is because when we update the permission in Hasura, the Auth0 token is not updated immediately, so fetching it right away will return the old token.
        await new Promise((resolve) => setTimeout(resolve, 5000));

        const token = await getAccessTokenSilently({
          cacheMode: "off",
        });

        logger.debug("Renewing token", { token });
        getCalcWorker().updateJWT(token);

        await new Promise((resolve) => setTimeout(resolve, 1000));

        const gridApi = store.get(gridApiAtom);
        if (!gridApi) return;

        const productsWithKey = pageProducts.data
          ?.filter(
            (product): product is typeof product & { productId: string } =>
              !!product.product_id,
          )
          .filter(Boolean);

        const transactions: TData[] = [];
        gridApi.forEachNode((node) => {
          if (node.data?.blank || !node.data) return;
          const rowUpdates = node.data;

          const columns = gridApi.getColumns();
          columns?.map((column) => {
            const params = colParams(column);
            if (
              params?.productId &&
              !productsWithKey?.find((p) => p.id === params.gridId)
            ) {
              rowUpdates[params.gridId] = undefined;
            }
          });

          transactions.push(rowUpdates);
        });

        gridApi.applyTransaction({ update: transactions });
      },
      getToken: async () => {
        const token = await getAccessTokenSilently({ cacheMode: "off" });
        return token;
      },
      refetchAlerts: () => {
        refetchAlerts();
      },
      triggerAlert: async (alert, initial) => {
        const queue = store.get(triggeredAlertsQueueAtom);
        if (!queue.find((a) => a.id === alert.id)) {
          store.set(triggeredAlertsQueueAtom, [
            ...queue,
            {
              ...alert,
              initial,
            },
          ]);
        }
        refetchAlerts();
      },
    });
  }, [
    getAccessTokenSilently,
    pageProducts.data,
    store,
    queryClient,
    handleLogout,
    refetchAlerts,
  ]);
};

const SLOW_THRESHOLD = 5000;

export function useConnectionStatus() {
  const [isSlowConnection, setIsSlowConnection] = useState(false);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    let lastUpdatedAt: null | number = null;
    const handler = () => {
      if (lastUpdatedAt !== null) {
        setIsSlowConnection(Date.now() - lastUpdatedAt > SLOW_THRESHOLD);
      }
    };

    const interval = setInterval(() => {
      Promise.race([
        getCalcWorker().lastLivePriceUpdate(),
        new Promise<never>((_, rej) =>
          setTimeout(() => rej("calcworker timeout"), 1000),
        ),
      ])
        .then((x) => {
          lastUpdatedAt = x;
        })
        .catch(console.error)
        .finally(handler);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleOffline = () => {
      setIsOffline(true);
      logger.log("Connection went offline");
    };

    const handleOnline = () => {
      setIsOffline(false);
      logger.log("Connection came online");
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  return { isOffline, isSlowConnection };
}
