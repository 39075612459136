import {
  faInputPipe,
  faTrash,
  faCog,
  faBell,
  faDiagramCells,
  faColumns3,
  faPalette,
  faFunction,
  faCopy,
  faFileExport,
  faMemoCircleInfo,
  faLineHeight,
  faCheck,
  faKeyboard,
  faChevronRight,
  faChartCandlestick,
  faChartSimple,
  faLineChart,
} from "@fortawesome/pro-solid-svg-icons";
import { faLineColumns } from "@fortawesome/pro-duotone-svg-icons";
import { library, icon } from "@fortawesome/fontawesome-svg-core";

library.add([
  faInputPipe,
  faLineColumns,
  faDiagramCells,
  faTrash,
  faBell,
  faCog,
  faColumns3,
  faPalette,
  faCopy,
  faFileExport,
  faMemoCircleInfo,
  faLineHeight,
  faCheck,
  faFunction,
  faKeyboard,
  faChevronRight,
  faChartCandlestick,
  faChartSimple,
  faLineChart,
]);

const icons = {
  field: icon({ prefix: "fas", iconName: "input-pipe" }),
  column: icon({ prefix: "fad", iconName: "line-columns" }),
  type: icon({ prefix: "fas", iconName: "diagram-cells" }),
  remove: icon({ prefix: "fas", iconName: "trash" }),
  settings: icon({ prefix: "fas", iconName: "cog" }),
  width: icon({ prefix: "fas", iconName: "columns-3" }),
  format: icon({ prefix: "fas", iconName: "palette" }),
  alerts: icon({ prefix: "fas", iconName: "bell" }),
  copy: icon({ prefix: "fas", iconName: "copy" }),
  export: icon({ prefix: "fas", iconName: "file-export" }),
  details: icon({ prefix: "fas", iconName: "memo-circle-info" }),
  header: icon({ prefix: "fas", iconName: "line-height" }),
  check: icon({ prefix: "fas", iconName: "check" }),
  function: icon({ prefix: "fas", iconName: "function" }),
  keyboard: icon({ prefix: "fas", iconName: "keyboard" }),
  chevron: icon({ prefix: "fas", iconName: "chevron-right" }),
  chartCandlestick: icon({ prefix: "fas", iconName: "chart-candlestick" }),
  barChart: icon({ prefix: "fas", iconName: "chart-simple" }),
  lineChart: icon({ prefix: "fas", iconName: "line-chart" }),
};

export const getIcon = (iconName: keyof typeof icons, color?: string) => {
  if (color) {
    return icons[iconName].html.join().replace("currentColor", color);
  }

  return icons[iconName].html.join();
};
