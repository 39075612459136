import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
  hashKey,
  useQuery,
  useQueryClient,
  type QueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

import { useGraphQLClient } from "./context";

export const useSubscription = <
  TData,
  TVariables extends Record<string, unknown>,
  TError = Error,
>(
  document: TypedDocumentNode<TData, TVariables>,
  options: Omit<QueryOptions<TData, TVariables, TError>, "queryFn"> & {
    variables: TVariables;
  },
): UseQueryResult<TData, TError> => {
  const key = options.queryKey ? hashKey(options.queryKey) : null;
  const queryClient = useQueryClient();
  const graphql = useGraphQLClient();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const resolve = useMemo(() => Promise.withResolvers<TData | null>(), [key]);

  const qry = useQuery<TData, TError>({
    ...options,
    queryFn: () => resolve.promise,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  } as any);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    return graphql.subscribe<TData, Record<string, unknown>>(
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      document as any,
      options.variables,
      {
        error: (error: Error) => resolve.reject(error),
        next: (d) => {
          resolve.resolve(d.data ?? null);
          if (options.queryKey)
            queryClient.setQueryData(options.queryKey, d.data);
        },
      },
    );
  }, [key]);

  return qry;
};
