import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { rootRoute } from "../baseRoutes";

const rootAdminRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "admin",
  component: lazyRouteComponent(() => import("./nav/Dashboard"), "Dashboard"),
});

const orgsRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "orgs",
  component: lazyRouteComponent(() => import("./orgs/Table"), "AllOrgs"),
});

const createOrganisationRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "orgs/new/$id",
  component: lazyRouteComponent(
    () => import("./orgs/Create"),
    "CreateOrganisation",
  ),
});

const editOrganisationRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "orgs/edit/$id",
  component: lazyRouteComponent(
    () => import("./orgs/Edit"),
    "EditOrganisation",
  ),
});

const singleOrgRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "orgs/$org",
  component: lazyRouteComponent(
    () => import("./components/OrgWrapper"),
    "SingleOrgWrapper",
  ),
});

const singleOrgPermissionsRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "permissions",
  component: lazyRouteComponent(
    () => import("./permissions/Table"),
    "Permissions",
  ),
});

const singleOrgGlobalPermissionsRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "global-permissions",
  component: lazyRouteComponent(
    () => import("./globalPermissions/Table"),
    "GlobalPermissions",
  ),
});

const singleOrgPackagesRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "packages",
  component: lazyRouteComponent(() => import("./packages/Table"), "Packages"),
});

const singleOrgProductsRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "products",
  component: lazyRouteComponent(() => import("./products/Table"), "Products"),
});

const singleOrgAgreementsRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "agreements",
  component: lazyRouteComponent(() => import("./agreements"), "Agreements"),
});

const singleOrgUsersRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "users",
  component: lazyRouteComponent(() => import("./users"), "Users"),
});

const singleOrgCreateUserRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "users/new/$id",
  component: lazyRouteComponent(() => import("./users"), "CreateUser"),
});

const singleOrgEditUserRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "users/$userId",
  component: lazyRouteComponent(() => import("./users"), "UserEdit"),
});

const singleOrgSingleUserPackagesRoute = createRoute({
  getParentRoute: () => singleOrgEditUserRoute,
  path: "packages",
  component: lazyRouteComponent(() => import("./packages/Table"), "Packages"),
});

const singleOrgCreatePackageRoute = createRoute({
  getParentRoute: () => singleOrgRoute,
  path: "packages/new/$id",
  component: lazyRouteComponent(() => import("./packages"), "CreatePackage"),
});

const singleOrgSingleUserProductsRoute = createRoute({
  getParentRoute: () => singleOrgEditUserRoute,
  path: "products",
  component: lazyRouteComponent(() => import("./products/Table"), "Products"),
});

/*  if the provider is directly in the Reports component the call in 
      useStore in useReportColDefs will return undefined. It's not actually a hook 
      so it doesn't get called again when the provider is added. */
const reportsRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "reports",
  component: lazyRouteComponent(
    () => import("./reports/Wrapper"),
    "ReportsWrapper",
  ),
});

const reportsExchangesRoute = createRoute({
  getParentRoute: () => reportsRoute,
  path: "exchanges",
  component: lazyRouteComponent(() => import("./reports"), "Reports"),
});

const reportsEditRoute = createRoute({
  getParentRoute: () => reportsRoute,
  path: "exchanges/$userId",
  component: lazyRouteComponent(() => import("./reports"), "UsersReports"),
});

const reportsGlobalPermissionsRoute = createRoute({
  getParentRoute: () => reportsRoute,
  path: "global-permissions",
  component: lazyRouteComponent(
    () => import("./reports/GlobalPermissions"),
    "GlobalPermissions",
  ),
});

const consoleRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "console",
  component: lazyRouteComponent(
    () => import("./components/Console"),
    "Console",
  ),
});

const auditLogRoute = createRoute({
  getParentRoute: () => rootAdminRoute,
  path: "audit",
  component: lazyRouteComponent(() => import("./audit"), "AuditLog"),
});

export const adminRoutes = rootAdminRoute.addChildren([
  orgsRoute,
  createOrganisationRoute,
  editOrganisationRoute,
  singleOrgRoute.addChildren([
    singleOrgPermissionsRoute,
    singleOrgGlobalPermissionsRoute,
    singleOrgUsersRoute,
    singleOrgCreateUserRoute,
    singleOrgEditUserRoute,
    singleOrgPackagesRoute,
    singleOrgCreatePackageRoute,
    singleOrgSingleUserPackagesRoute,
    singleOrgProductsRoute,
    singleOrgSingleUserProductsRoute,
    singleOrgAgreementsRoute,
  ]),
  consoleRoute,
  auditLogRoute,
  reportsRoute.addChildren([
    reportsExchangesRoute,
    reportsEditRoute,
    reportsGlobalPermissionsRoute,
  ]),
]);
