import type { AlertFragmentFragment } from "../../../__generated__/gql/graphql";

export interface MainThreadApi {
  logoutRequest: () => void;
  renewToken: () => Promise<void>;
  getToken: () => Promise<string>;
  alertProductChanged: (products: { name: string }[]) => void;
  refetchAlerts: () => void;
  triggerAlert: (alert: AlertFragmentFragment, initial: boolean) => void;
  invalidateQueries: (queryKey: string[]) => void;
}

export const createChannel = () => new BroadcastChannel("worker->clients");
