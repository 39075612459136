import type { GridOptions, CellClickedEvent } from "ag-grid-community";
import { useMemo } from "react";

import { processDataFromClipboard } from "./copyRangeSelectionHelpers";
import { rowHeight, separatorHeight } from "../globals";
import { LoadingOverlay } from "../components/Loading";
import { handleRedo, handleUndo } from "./undo-redo";
import { useOnCellKeyDown } from "./keyboard";
import { mainMonthColumnId } from "./column-defs";
import { useMemoCallback } from "../../utils/useMemoCallback";

type GridOptionsProps = {
  onTimeSpreadClick: (params: CellClickedEvent) => void;
};

export const useGridOptions = (opts: GridOptionsProps) => {
  const onCellKeyDown = useOnCellKeyDown();
  const onTimeSpreadClick = useMemoCallback(opts.onTimeSpreadClick);

  return useMemo(() => {
    return {
      getRowHeight: (params) => {
        if (params.data?.period) {
          const firstRow = params.data?.firstOfType;
          const isNotMonth = params.data?.rowType !== "mth";

          return firstRow && isNotMonth
            ? rowHeight + separatorHeight
            : rowHeight;
        }
      },
      loadingOverlayComponent: LoadingOverlay,
      noRowsOverlayComponent: LoadingOverlay,
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      onUndoStarted: handleUndo,
      onRedoStarted: handleRedo,
      onCellKeyDown,
      rowClassRules: {
        borderRow: (params) => {
          const isHlvAndH2Period =
            params.data?.rowType === "hlv" &&
            params.data?.period?.includes("H2");
          const isQtrAndQ4Period =
            params.data?.rowType === "qtr" &&
            params.data?.period?.includes("Q4");
          return !!(
            params.data?.year ||
            params.data?.quarter ||
            isHlvAndH2Period ||
            isQtrAndQ4Period
          );
        },
        separator: (params) => {
          const notMonth = params.data?.rowType !== "mth";
          const firstRow = params.data?.firstOfType;
          return firstRow && notMonth;
        },
      },
      processDataFromClipboard,
      postProcessPopup: (params) => {
        if (params.type === "columnMenu" && !params.column) {
          params.ePopup.style.display = "none";
        }
      },
      onCellClicked: (params) => {
        const isMonthColumn = params.column.getColId() === "1";
        const isAdhoc = params.data?.rowType === "adhoc";
        if (isMonthColumn && isAdhoc) {
          onTimeSpreadClick(params);
        }
      },
      onCellValueChanged: (params) => {
        if (params.data?.rowType === "adhoc") {
          params.api.refreshCells({
            force: true,
            rowNodes: [params.node],
            columns: [mainMonthColumnId],
          });
        }
      },
    } satisfies GridOptions;
  }, [onTimeSpreadClick, onCellKeyDown]);
};
