import { useQuery } from "@tanstack/react-query";

import { useGraphQLClient } from "../utils/graphql";
import { graphql } from "../graphql";

export const useSources = () => {
  const graphql = useGraphQLClient();
  return useQuery({
    queryKey: ["sources"],
    queryFn: async () =>
      graphql.execute(sourcesQuery).then((res) => res.data?.source),
  });
};

const sourcesQuery = graphql(`
  query Sources {
    source {
      id
      name
    }
  }
`);
