import { Box, Typography, AspectRatio, Skeleton } from "@mui/joy";
import { PortableText, type PortableTextComponents } from "@portabletext/react";
import { assetRefToSanityUrl } from "../infoSection/utils";
import type { NewFeature } from "../../../src/__generated__/sanity/graphql-request";
import { useState } from "react";
import { InfoModal } from "./InfoModal";

type ImageWithSkeletonProps = {
  value: {
    asset: {
      _ref: string;
    };
  };
};

function ImageWithSkeleton({ value }: ImageWithSkeletonProps) {
  const [loading, setLoading] = useState(true);
  const imageUrl = assetRefToSanityUrl(value.asset._ref);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <AspectRatio
      variant="plain"
      sx={{ width: "100%", maxWidth: 800, my: 2, mx: "auto" }}
    >
      <Skeleton loading={loading}>
        <img
          src={imageUrl}
          alt=""
          onLoad={handleImageLoad}
          style={{
            maxWidth: "100%",
            width: "auto",
            height: "auto",
            maxHeight: 800,
            objectFit: "contain",
          }}
        />
      </Skeleton>
    </AspectRatio>
  );
}

export function AnnouncementModal({
  open,
  setClose,
  announcement,
}: {
  open: boolean;
  setClose: () => void;
  announcement: NewFeature | null;
}) {
  const components: PortableTextComponents = {
    block: {
      normal: ({ children }) => (
        <Typography level="body-lg">{children}</Typography>
      ),
      h1: ({ children }) => <Typography level="h1">{children}</Typography>,
      h2: ({ children }) => <Typography level="h2">{children}</Typography>,
    },
    marks: {
      strong: ({ children }) => <strong>{children}</strong>,
      link: ({ value, children }) => (
        <a href={value.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
    types: {
      breakline: () => <br />,
      image: ImageWithSkeleton,
    },
  };

  return (
    <InfoModal
      open={open}
      onClose={setClose}
      title={announcement?.title || ""}
      labelBy="announcement-title"
      describedBy="announcement-description"
    >
      <Box
        sx={{
          overflowY: "auto",
          maxHeight: "70vh",
          pt: 0,
        }}
      >
        {announcement?.bodyRaw && (
          <PortableText value={announcement.bodyRaw} components={components} />
        )}
      </Box>
    </InfoModal>
  );
}
