import { CssBaseline } from "@mui/joy";
import { Box } from "@mui/system";
import { Outlet, useRouterState } from "@tanstack/react-router";
import { memo, Suspense } from "react";
import { webappTheme } from "../../styles/extendTheme";
import { CollapsibleSettings, SyncGridSettings } from "../grid-settings";
import { sideBarWidth, topBarHeight, triplitStatusBarZIndex } from "../globals";
import { ToasterContainer } from "../components/Toaster";
import { isMobile } from "../../shared/hooks";
import { useRefreshTimeout } from "../market-pages";
import { ModalWrapper } from "../components/Modal";
import { client } from "../../triplit/triplit";
import { isDev } from "../../globals";
import { useConnectionStatus } from "@triplit/react";
import { useDynamicCssClassRules } from "../market-grid/useDynamicCssClassRules";
import { GridModalWrapper } from "../market-grid/modals/GridModal";
import { AppThemeProvider } from "../../context/theme";
import { useShortcuts } from "../market-grid/keyboard";
import { useConnectCalcWorkerClientHandlers } from "./hooks";
import { LicenceAgreement } from "../licensing";
import { SessionProvider } from "../../context/sessions";
import { useConnectCalcWorker } from "../calculations-worker";
import {
  useOptionalActivePageId,
  useSyncGridSettings,
  useSyncPageProducts,
  useSyncPages,
} from "../../data";
import { DialogProvider } from "../dialog";
import { Loader } from "./Loader";
import { Sidebar } from "./Sidebar";

export { Sidebar } from "./Sidebar";

export function Layout() {
  return (
    <SessionProvider>
      <AppThemeProvider theme={webappTheme}>
        <Loader>
          <RegisterListeners />
          <SyncGridSettings />
          <CssBaseline />
          <ToasterContainer />

          <TriplitStatusBar />

          <Sidebar />

          {isMobile && <GridModalWrapper />}
          <DialogProvider>
            <LicenceAgreement>
              <div className="webapp w-full relative md:pl-side-bar">
                <CollapsibleSettings />
                <Suspense>
                  <Outlet />
                </Suspense>
              </div>
            </LicenceAgreement>
          </DialogProvider>

          <ModalWrapper />
        </Loader>
      </AppThemeProvider>
    </SessionProvider>
  );
}

const RegisterListeners = memo(() => {
  useSyncPageProducts(useOptionalActivePageId());
  useConnectCalcWorkerClientHandlers();
  useDynamicCssClassRules();
  useConnectCalcWorker();
  useRefreshTimeout({});
  useSyncGridSettings();
  useShortcuts();
  useSyncPages();

  return null;
});

function TriplitStatusBar() {
  const currentRoute = useRouterState();
  const isChartRoute =
    currentRoute.resolvedLocation?.pathname === "/app/trading-chart";

  const connectionStatus = useConnectionStatus(client);
  if (!isDev || connectionStatus === "OPEN") return null;
  return (
    <Box
      sx={(theme) => ({
        position: "fixed",
        top: 0,
        right: isChartRoute ? "auto" : 0,
        left: isChartRoute ? sideBarWidth : "auto",
        height: topBarHeight,
        zIndex: triplitStatusBarZIndex,
        backgroundColor:
          connectionStatus === "CLOSED"
            ? theme.palette.danger[500]
            : theme.palette.warning[500],
        color: "white",
        padding: 1,
        textAlign: "center",
      })}
    >
      {connectionStatus === "CLOSED" && "Server connection offline"}
      {connectionStatus === "CONNECTING" && "Connecting to server..."}
    </Box>
  );
}
