import {
  RouterProvider,
  createRoute,
  createRouter,
  redirect,
} from "@tanstack/react-router";
import { ErrorBoundary } from "react-error-boundary";

import { adminRoutes } from "./admin/adminRoutes";
import { rootRoute } from "./baseRoutes";
import { webappRoute } from "./webapp/Routes";
import Loading from "./webapp/components/Loading";

import { ApolloClientProvider } from "./context/apollo";
import { AnalyticsProvider } from "./context/ph";
import { TriplitProvider } from "./triplit/provider";
import { RefreshAccessToken, AuthProvider } from "./context/auth";
import { AppQueryProvider } from "./context/query";
import { ErrorPage } from "./webapp/components/ErrorPage";
import { HasuraProvider } from "./context/hasura/provider";

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  beforeLoad: () => {
    throw redirect({
      to: "/app/market",
    });
  },
});

const catchAllRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/*",
  beforeLoad: () => {
    throw redirect({
      to: "/app/market",
    });
  },
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  webappRoute,
  adminRoutes,
  catchAllRoute,
]);

const router = createRouter({
  routeTree,
  defaultPendingComponent: () => <Loading showLogo syncing={false} />,
  defaultErrorComponent: (e) => {
    console.error(e);
    return <ErrorPage {...e} />;
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export function App() {
  return (
    <ErrorBoundary
      fallbackRender={(e) => (
        <ErrorPage error={e.error} reset={e.resetErrorBoundary} />
      )}
    >
      <AppQueryProvider>
        <AuthProvider>
          <ApolloClientProvider>
            <HasuraProvider>
              <TriplitProvider>
                <AnalyticsProvider>
                  <RefreshAccessToken />
                  <RouterProvider router={router} />
                </AnalyticsProvider>
              </TriplitProvider>
            </HasuraProvider>
          </ApolloClientProvider>
        </AuthProvider>
      </AppQueryProvider>
    </ErrorBoundary>
  );
}
