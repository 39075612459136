import type React from "react";
import { useEffect, useRef, useState } from "react";
import { Option, Select, Switch } from "@mui/joy";

import {
  type TPageProduct,
  useSelectedPageProducts,
  useUpdatePageProducts,
} from "../../../../data";

import { columnWidth, maxColumnWidth, minColumnWidth } from "../../../globals";
import type { TRangeSelection } from "../../useRangeSelectionChange";
import {
  columnSelectionInfo,
  selectionColumnIds,
  SelectionInfo,
} from "./SelectionInfo";
import { useUpdateFormatting } from "./useUpdateFormatting";
import { modalCancelActions } from "../modalAtoms";

export const FormatSettings = (p: {
  selection: TRangeSelection;
  pageId: string;
}) => {
  const [current, onChange] = useUpdateFormatting({ ...p, type: "column" });
  const updatePageProducts = useUpdatePageProducts();
  const productIds = selectionColumnIds(p.selection);
  const product = useSelectedPageProducts(p.pageId, productIds);

  const updatePage = (arg: Partial<TPageProduct>) => {
    if (!modalCancelActions.hasCancel("formatting-page")) {
      const initial = (product.data ?? []).map((p) => ({
        id: p.id,
        column_width: p.column_width,
        decimal_places: p.decimal_places,
        thousands_separator: p.thousands_separator,
      }));
      modalCancelActions.addCancel("formatting-page", () =>
        Promise.all(initial.map(updatePageProducts.optimistic)),
      );
    }
    productIds.forEach((id) => updatePageProducts.optimistic({ id, ...arg }));
  };

  return (
    <div className="flex flex-col gap-2">
      <SelectionInfo selection={columnSelectionInfo(p.selection)} />
      <div className="py-6 px-2 grid [grid-template-columns:1fr_auto] space-y-4">
        <div className="grid grid-cols-subgrid col-span-full items-center">
          <label htmlFor="ndp" className="text-base">
            Number of Decimal Places
          </label>
          <Select
            variant="outlined"
            defaultValue={product?.data?.[0]?.decimal_places ?? 2}
            onChange={(_, value) =>
              updatePage({ decimal_places: Number(value) })
            }
          >
            {Array.from({ length: 10 }, (_, i) => (
              <Option key={i.toString()} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </div>
        <div className="grid grid-cols-subgrid col-span-full items-center">
          <label htmlFor="cw" className="text-base">
            Set Column Width
          </label>
          <NumberInput
            id="cw"
            className="border w-24 px-3 py-2 rounded-md"
            value={product?.data?.[0]?.column_width ?? columnWidth}
            onChange={(x) =>
              updatePage({
                column_width: Math.min(
                  maxColumnWidth,
                  Math.max(minColumnWidth, x),
                ),
              })
            }
          />
        </div>
        <div className="grid grid-cols-subgrid col-span-full items-center">
          <label htmlFor="ts" className="text-base">
            Thousands Separator
          </label>
          <Switch
            id="ts"
            size="lg"
            checked={!!product.data?.[0]?.thousands_separator}
            onChange={() =>
              updatePage({
                thousands_separator: !product.data?.[0]?.thousands_separator,
              })
            }
          />
        </div>
      </div>
      <p className="text-lg font-semibold px-2">Text</p>
      <div className="flex px-2 gap-6 py-2">
        <div className="flex items-center gap-4">
          <label htmlFor="ti" className="text-base">
            Invert Text Colour
          </label>
          <Switch
            id="ti"
            size="lg"
            checked={!!current.invertTextColor}
            onChange={() =>
              onChange({ invertTextColor: !current.invertTextColor })
            }
          />
        </div>
        <div className="flex items-center gap-4">
          <label htmlFor="bt" className="text-base">
            Bold Text
          </label>
          <Switch
            id="bt"
            size="lg"
            checked={!!current.boldText}
            onChange={() => onChange({ boldText: !current.boldText })}
          />
        </div>
      </div>
    </div>
  );
};

const NumberInput = (
  p: Omit<React.JSX.IntrinsicElements["input"], "value" | "onChange"> & {
    value: number;
    onChange: (value: number) => void;
  },
) => {
  const [value, setValue] = useState(p.value.toString());
  const initial = useRef(true);

  useEffect(() => setValue(p.value.toString()), [p.value]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: inconvinient to add onChange to dependencies
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }
    if (Number.isNaN(Number(value)) || !value || Number(value) === p.value)
      return;
    p.onChange(Number(value));
  }, [value, p.value]);

  return (
    <input
      type="number"
      {...p}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
