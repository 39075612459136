import type { RedoStartedEvent, UndoStartedEvent } from "ag-grid-community";
import { atom } from "jotai";

import { getCalcWorker, type TOptimisticCell } from "../../calculations-worker";
import { store } from "../../modal";

export type TUndoRedo = TOptimisticCell & {
  pasted: boolean;
};

export const undoRedoAtom = atom<{
  undo: TUndoRedo[];
  redo: TUndoRedo[];
  time: number;
}>({
  undo: [],
  redo: [],

  // Handles an edge case where the user might paste multiple times in a row. This way we can differentiate between modifying multiple cells in 1 paste vs. multiple pastes.
  time: new Date().getTime(),
});

function undoRedo(action: "undo" | "redo") {
  const undoRedoValue = store.get(undoRedoAtom);
  if (action === "undo") {
    getCalcWorker().optimisticCellEdit(undoRedoValue.undo);
  } else {
    getCalcWorker().optimisticCellEdit(undoRedoValue.redo);
  }
}

export function handleUndo(_: UndoStartedEvent) {
  undoRedo("undo");
}

export function handleRedo(_: RedoStartedEvent) {
  undoRedo("redo");
}
