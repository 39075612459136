import cn from "mcn";
import type React from "react";

const variants = {
  neutral: "bg-neutral text-white",
  primary: "bg-primary text-white",
};

export const Button = (
  p: React.ComponentProps<"button"> & { variant: keyof typeof variants },
) => {
  return (
    <button
      type="button"
      {...p}
      className={cn(
        "px-5 py-1.5 rounded-md cursor-pointer",
        variants[p.variant],
        p.className,
      )}
    />
  );
};
