import { atom, useAtom, useAtomValue, useStore } from "jotai";
import { useEffect } from "react";

import { getCalcWorker } from "../../webapp/calculations-worker";
import { useGridSettingsSelect } from "../../data";
import { gridSettingsWidth } from "../globals";

export const eodDateAtom = atom<string | null>(null);
eodDateAtom.debugLabel = "eodDateAtom";
export const useGridSettingsEodDate = () => useAtomValue(eodDateAtom);

export const gridSettingsVisibleAtom = atom<boolean>(false);
gridSettingsVisibleAtom.debugLabel = "gridSettingsVisibleAtom";
export const useGridSettingsVisible = () => useAtom(gridSettingsVisibleAtom);

export const gridSettingsWidthAtom = atom((get) =>
  get(gridSettingsVisibleAtom) ? gridSettingsWidth : 0,
);
gridSettingsWidthAtom.debugLabel = "gridSettingsWidthAtom";
export const useGridSettingsWidth = () => useAtomValue(gridSettingsWidthAtom);

export const SyncGridSettings = () => {
  const eodDate = useGridSettingsSelect((x) => x?.eod_date);
  const store = useStore();

  useEffect(() => {
    if (eodDate.data !== "prior") {
      store.set(eodDateAtom, eodDate.data ?? null);
      return;
    }
    getCalcWorker()
      .getPriorEodDate()
      .then((date) => {
        return store.set(eodDateAtom, date);
      });
  }, [store, eodDate.data]);

  return null;
};
