import { useCallback, useEffect, useState } from "react";
import { useIntervalEffect } from "@react-hookz/web";
import { Typography } from "@mui/joy";

import type { TManualStoreKey } from "../../calculations-worker";
import { getManualInstrumentType } from "../statuses/statusLogic";
import { getCalcWorker } from "../../calculations-worker";
import type { colParams } from "../../utils";

export function LastEditedInfoHeader({
  colInfo,
  status,
}: {
  colInfo: ReturnType<typeof colParams>;
  status: string;
}) {
  const storageType = status.includes("-")
    ? "global"
    : getManualInstrumentType(status);
  const productId = storageType === "global" ? status : colInfo?.productId;
  const lastEditedInfo = useLatestEditInfo({
    storageType,
    productId,
  });
  return (
    <Typography
      sx={{
        color: "inherit",
      }}
      fontSize={10}
      fontWeight={700}
    >
      {lastEditedInfo}
    </Typography>
  );
}

function useLatestEditInfo({
  productId,
  storageType,
}: {
  storageType?: TManualStoreKey["storageType"] | null;
  productId?: TManualStoreKey["productId"];
}) {
  const worker = getCalcWorker();
  const [editInfo, setEditInfo] = useState<string | null | undefined>(null);

  const fetchManualProductInfo = useCallback(async () => {
    if (worker && productId && storageType) {
      try {
        const info = await worker.getManualProductInfo({
          productId,
          storageType,
        });
        setEditInfo(info?.latestEditInfo);
      } catch (error) {
        console.error(error);
      }
    }
  }, [worker, productId, storageType]);

  // Fetch immediately on mount
  useEffect(() => {
    fetchManualProductInfo();
  }, [fetchManualProductInfo]);

  // Continue fetching every second
  useIntervalEffect(() => {
    fetchManualProductInfo();
  }, 1000);

  return editInfo || "";
}
