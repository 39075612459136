import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsChartRange = (): GetContextMenuSection => {
  return (ctx) => {
    if (ctx.isHeaderMenu) return [];

    const ranges = ctx.api.getCellRanges();
    const createChart = ctx.api.createRangeChart;

    if (!ranges?.length) return [];

    const selection = ranges?.reduce(
      (acc, range) => {
        const c = range.columns.map((col) => col.getColId());
        c.map((col) => {
          if (!acc.colIds.includes(col)) {
            acc.colIds.push(col);
          }
          if (!acc.startIndexes.includes(range.startRow?.rowIndex ?? 0)) {
            acc.startIndexes.push(range.startRow?.rowIndex ?? 0);
          }
          if (!acc.endIndexes.includes(range.endRow?.rowIndex ?? 0)) {
            acc.endIndexes.push(range.endRow?.rowIndex ?? 0);
          }
        });
        return acc;
      },
      {
        colIds: [],
        startIndexes: [],
        endIndexes: [],
      } as {
        colIds: string[];
        startIndexes: number[];
        endIndexes: number[];
      },
    ) || { colIds: [], startIndexes: [], endIndexes: [] };

    const cellRange = {
      columns: selection.colIds
        .map((c) => ctx.api.getColumn(c))
        .filter(Boolean),
      rowStartIndex: Math.min(...selection.startIndexes),
      rowEndIndex: Math.max(...selection.endIndexes),
    };

    return [
      {
        name: "View Forward Curve",
        icon: getIcon("lineChart"),
        action: () => {
          createChart({ chartType: "line", cellRange });
        },
      },
    ];
  };
};
