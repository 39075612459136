import { logger as BaseLogger } from "@artis/logger";
import { wrap, type Remote } from "comlink";
import { useEffect, useState } from "react";

import { useAccessToken, useCurrentUser, useUserId } from "../../context/auth";
export { clearIdbCache } from "../../data/cache";
import { isMobile } from "../../shared/hooks";
import { isDev } from "../../globals";

export type { TOptimisticCell } from "./optimisticLogic";
export { exposeMainThreadApi } from "./messaging/client";
import type { CalculationsWorker } from "./worker";
import { showLoader } from "../layout/Loader";
export type * from "./stores";

const logger = BaseLogger.child({ group: "calc-worker" });

export type CalcWorker = Remote<typeof CalculationsWorker>;

let CALC_WORKER: null | CalcWorker = null;
const isSharedWorker =
  !isDev && !isMobile && typeof SharedWorker !== "undefined";

export const getCalcWorker = (): CalcWorker => {
  if (CALC_WORKER) return CALC_WORKER;
  if (isSharedWorker) {
    const worker = new SharedWorker(new URL("./worker", import.meta.url), {
      type: "module",
      name: "calcs",
    });
    CALC_WORKER = wrap<typeof CalculationsWorker>(worker.port);
  } else {
    const worker = new Worker(new URL("./worker", import.meta.url), {
      type: "module",
      name: "calcs",
    });
    CALC_WORKER = wrap<typeof CalculationsWorker>(worker);
  }
  return CALC_WORKER;
};

let initialized = false;
let connecting = false;
const done = showLoader("calc-worker");
const reconnect = async (userId: string, token: string) => {
  logger.debug("reconnect", { initialized, connecting });
  if (connecting) return;
  if (!initialized) {
    connecting = true;
    initialized = true;
    const ready = await getCalcWorker().initWorker(
      import.meta.env,
      userId,
      token,
    );
    const start = getCalcWorker().start(token);
    if (!ready.ready) await start;
    await new Promise((resolve) => setTimeout(resolve, 500));
    connecting = false;
    return done();
  }

  const isConnected = await Promise.race([
    getCalcWorker().active(),
    new Promise((resolve) => setTimeout(() => resolve(false), 500)),
  ] as const).then((connected) => connected as boolean);

  logger.debug("reconnect", { isConnected });

  if (isConnected) return;
  CALC_WORKER = null;
  await getCalcWorker()
    .initWorker(import.meta.env, userId, token)
    .then(() => getCalcWorker().start(token));
};

export const useConnectCalcWorker = () => {
  const token = useAccessToken();
  const userId = useUserId();

  const user = useCurrentUser();

  useEffect(() => {
    if (!user.data) return;
    getCalcWorker().setUser(user.data);
  }, [user.data]);

  useEffect(() => {
    const handler = () => {
      if (document.visibilityState === "hidden" || !token.data?.access_token)
        return;
      reconnect(userId, token.data?.access_token);
    };

    if (token.data?.access_token) reconnect(userId, token.data?.access_token);

    document.addEventListener("visibilitychange", handler);
    return () => document.removeEventListener("visibilitychange", handler);
  }, [token.data?.access_token, userId]);
};

export const usePauseWorker = () => {
  const [paused, setPaused] = useState(false);

  const toggle = () => {
    if (paused) getCalcWorker().resume();
    else getCalcWorker().pause();
    setPaused(!paused);
  };

  return [paused, toggle] as const;
};
