import type { Radio } from "@mui/joy";
import type { ComponentProps } from "react";
import { LuArrowUpDown, LuDownload, LuUpload } from "react-icons/lu";
import { z } from "zod";
import type { TKnownStatus, TStatus } from "./statusLogic";

export const statusModalMaxWidth = 200;

export type TKnownStatusRules = Partial<{
  isDeniedBroadcast: boolean;
  isLiteUser: boolean;
  canEod: boolean;
  canBroadcast: boolean;
}>;

export type TStatusOption = {
  value: TStatus;
  label: string | undefined;
  icon: React.ComponentType | undefined;
  image: boolean;
  style: React.CSSProperties | undefined;
  logo?: string | null | undefined;
};

export const knownStatusRules = {
  hybrid_broadcast: {
    isDeniedBroadcast: false,
    isLiteUser: false,
    canBroadcast: true,
  },
  listen: undefined,
  broadcast: {
    isDeniedBroadcast: false,
    isLiteUser: false,
    canBroadcast: true,
  },
  private: undefined,
  eod: {
    canEod: true,
  },
} satisfies Record<TKnownStatus, TKnownStatusRules | undefined>;

export const knownStatusOptions = [
  {
    value: "hybrid_broadcast",
    icon: LuArrowUpDown,
    label: undefined,
    image: true,
    style: { display: "flex", gap: 1 },
  },
  {
    value: "listen",
    label: undefined,
    icon: LuDownload,
    image: true,
    style: { display: "flex", gap: 1 },
  },
  {
    value: "broadcast",
    label: undefined,
    icon: LuUpload,
    image: true,
    style: { display: "flex", gap: 1 },
  },
  {
    value: "private",
    label: "Private",
    icon: undefined,
    image: false,
    style: undefined,
  },
  {
    value: "eod",
    label: "EOD",
    image: false,
    icon: undefined,
    style: undefined,
  },
] satisfies TStatusOption[];

export const StatusOperationSchema = z.enum(["read", "write"]);

export type TUpdateStatusOperation = z.infer<typeof StatusOperationSchema>;
export type TUpdateStatusOperationOption = {
  label: string;
  value: TUpdateStatusOperation;
};

export type TStatusTransition = {
  id: string;
  from: TStatus;
  to: TStatus;
  label: string;
  options: TUpdateStatusOperationOption[];
};

export type TStatusTransitionEnhanced = TStatusTransition & {
  productId: string;
  gridId: string | undefined;
  productName: string;
};

export const rejoinLiveStreamOption = {
  label: "re-join live stream",
  value: "read",
} satisfies TUpdateStatusOperationOption;

export const statusTransitions = [
  {
    id: "eod-hybrid_broadcast",
    from: "eod",
    to: "hybrid_broadcast",
    label: "from eod to hybrid broadcast",
    options: [
      rejoinLiveStreamOption,
      {
        label: "broadcast EOD Data",
        value: "write",
      },
    ],
  },
  {
    id: "private-hybrid_broadcast",
    from: "private",
    to: "hybrid_broadcast",
    label: "from private to hybrid broadcast",
    options: [
      rejoinLiveStreamOption,
      {
        label: "broadcast private data",
        value: "write",
      },
    ],
  },
  {
    id: "eod-broadcast",
    from: "eod",
    to: "broadcast",
    label: "from eod to broadcast",
    options: [
      rejoinLiveStreamOption,
      {
        label: "broadcast EOD data",
        value: "write",
      },
    ],
  },
  {
    id: "private-broadcast",
    from: "private",
    to: "broadcast",
    label: "from private to broadcast",
    options: [
      rejoinLiveStreamOption,
      {
        label: "broadcast private data",
        value: "write",
      },
    ],
  },
  {
    id: "*-private",
    from: "*",
    to: "private",
    label: "status change to private",
    options: [
      {
        label: "use current data",
        value: "write",
      },
      {
        label: "reload last private data",
        value: "read",
      },
    ],
  },
] satisfies TStatusTransition[];

export const radioSlotProps = {
  label: ({ checked }) => ({
    sx: {
      p: 0.5,
      fontWeight: "lg",
      fontSize: "sm",
      color: checked ? "text.primary" : "text.secondary",
    },
  }),
  action: ({ checked }) => ({
    sx: (theme) => ({
      ...(checked && {
        "--variant-borderWidth": "2px",
        "&&": {
          // && to increase the specificity to win the base :hover styles
          borderColor: theme.vars.palette.primary[500],
        },
      }),
    }),
  }),
} satisfies ComponentProps<typeof Radio>["slotProps"];
