import type { ColumnResizedEvent } from "ag-grid-community";

import {
  useActivePageId,
  usePageProducts,
  useUpdateGridSettings,
  useUpdatePageProducts,
} from "../../data";
import { mainMonthColumnId } from "./column-defs";
import { colParams } from "../utils";
import { useMemoCallback } from "../../utils/useMemoCallback";

export const useOnColumnResized = () => {
  const updatePage = useUpdatePageProducts();
  const update = useUpdateGridSettings();
  const pageId = useActivePageId();
  const products = usePageProducts(pageId);

  const onColumnResized = (params: ColumnResizedEvent) => {
    const width = params?.column?.getActualWidth();

    if (params.source === "api" || !width) return;

    const columnFieldId = params?.column?.getColId();
    if (columnFieldId === mainMonthColumnId) {
      update.optimistic({ main_month_column_width: width });
    }

    // resizing any other column
    const columnId = colParams(params.column)?.gridId;
    const product = products?.data?.find((p) => p.id === columnId);

    if (!columnId || !product) throw new Error("columnId is undefined");
    updatePage.optimistic({ id: columnId, column_width: width });
  };

  return useMemoCallback(onColumnResized);
};
