import { Box, Button, FormControl, FormLabel, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useUserId } from "../../../context/auth";
import { getCalcWorker } from "../../calculations-worker";
import { useUpdateGridSettings } from "../../../data";

export default function EodDateSelectPopup({
  selectedDate,
  handleClose,
}: {
  selectedDate: string | null;
  handleClose: () => void;
}) {
  const userId = useUserId();
  const [eodDate, setEodDate] = useState(selectedDate);
  const [priorEodDate, setPriorEodDate] = useState<string | null | undefined>(
    null,
  );
  const updateSettings = useUpdateGridSettings();

  useEffect(() => {
    getCalcWorker()
      .getPriorEodDate()
      .then((priorEodDate) => {
        if (!priorEodDate) {
          return;
        }
        setPriorEodDate(priorEodDate);
      })
      .catch((e) => {
        console.error("Error getting prior EOD date", e);
      });
  }, []);

  const handleSave = async (newDate: string) => {
    const eodDate = priorEodDate === newDate ? "prior" : newDate;
    await updateSettings.optimistic({ eod_date: eodDate });
    handleClose();
  };

  const isPrior = selectedDate === priorEodDate;

  return (
    <Box
      sx={(theme) => ({
        padding: 2,
        background: theme.palette.background.popup,
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      })}
    >
      <FormControl
        sx={{
          width: "min-content",
        }}
      >
        <FormLabel>Set Date</FormLabel>
        <input
          type="date"
          max={priorEodDate || undefined}
          onChange={(e) => {
            setEodDate(e.target.value);
          }}
          value={eodDate || selectedDate || ""}
        />
      </FormControl>
      <Typography color="danger" level="body-sm">
        Applies to all of your EODs
      </Typography>
      <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
        <Button
          disabled={eodDate === selectedDate || !eodDate}
          onClick={() => eodDate && handleSave(eodDate)}
        >
          Save
        </Button>
        {priorEodDate && (
          <Button
            sx={{
              alignSelf: "flex-end",
            }}
            variant={isPrior ? "plain" : "outlined"}
            onClick={() => {
              handleSave(priorEodDate);
            }}
            disabled={isPrior}
            title="Set the EOD date to the prior EOD date"
          >
            {isPrior ? "Using Prior Date" : "Set to Prior"}
          </Button>
        )}
      </Box>
    </Box>
  );
}
