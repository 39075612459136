// import { useSelectGridSettings } from "../grid-settings";
import { useGridSettingsSelect } from "../../data/grid-settings";
import { curveColumnStack } from "./column-defs";

export const useVisibleColumns = () => {
  const columns = useGridSettingsSelect((settings) =>
    curveColumnStack.filter(({ name, defaultVisible, required }) => {
      return required || (settings?.[name] ?? defaultVisible);
    }),
  );
  return columns;
};
