import { Box, Modal, Stack, Typography } from "@mui/joy";
import type { ReactElement } from "react";
import { CloseButton } from "../components/CloseButton";

export function InfoModal({
  children,
  open,
  onClose,
  title,
  disableMinWidth,
  labelBy,
  describedBy,
  showCloseButton = true,
}: {
  children: ReactElement;
  open: boolean;
  onClose: () => void;
  title: string;
  disableMinWidth?: boolean;
  labelBy?: string;
  describedBy?: string;
  showCloseButton?: boolean;
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby={labelBy}
      aria-describedby={describedBy}
      sx={{
        "&:focus-visible": {
          outline: "none",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: disableMinWidth ? "auto" : "90%",
          maxWidth: "500px",
          bgcolor: "white",
          borderRadius: "sm",
          background: (theme) => theme.palette.background.surface,
          maxHeight: "90vh",
          outline: "none",
          position: "relative",
        }}
      >
        <Typography
          level="h3"
          component="h2"
          sx={{
            padding: 2,
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
        <Box pl={2} pr={2}>
          {children}
        </Box>
        {showCloseButton && (
          <Stack p={2} alignItems={"flex-end"}>
            <CloseButton onClick={() => onClose()} />
          </Stack>
        )}
      </Box>
    </Modal>
  );
}
