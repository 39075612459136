export const AlertsIcon = (p: React.ComponentProps<"svg">) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Bell ringing</title>
    <g clip-path="url(#clip0_158_1153)">
      <path
        d="M11 3C10.3777 3 9.875 3.50273 9.875 4.125V4.8C7.30859 5.32031 5.375 7.59141 5.375 10.3125V10.9734C5.375 12.6258 4.7668 14.2219 3.66992 15.4594L3.40977 15.7512C3.11445 16.0816 3.04414 16.5563 3.22344 16.9605C3.40273 17.3648 3.80703 17.625 4.25 17.625H17.75C18.193 17.625 18.5938 17.3648 18.7766 16.9605C18.9594 16.5563 18.8855 16.0816 18.5902 15.7512L18.3301 15.4594C17.2332 14.2219 16.625 12.6293 16.625 10.9734V10.3125C16.625 7.59141 14.6914 5.32031 12.125 4.8V4.125C12.125 3.50273 11.6223 3 11 3ZM12.5926 20.3426C13.0145 19.9207 13.25 19.3477 13.25 18.75H11H8.75C8.75 19.3477 8.98555 19.9207 9.40742 20.3426C9.8293 20.7645 10.4023 21 11 21C11.5977 21 12.1707 20.7645 12.5926 20.3426ZM5.98672 3.54141C5.6668 3.20391 5.13242 3.18984 4.79492 3.51328C3.07227 5.15156 2 7.46836 2 10.0312C2 10.4988 2.37617 10.875 2.84375 10.875C3.31133 10.875 3.6875 10.4988 3.6875 10.0312C3.6875 7.94648 4.55938 6.06914 5.95859 4.73672C6.29609 4.4168 6.31016 3.88242 5.98672 3.54492V3.54141ZM16.0414 4.73672C17.4406 6.06914 18.3125 7.94648 18.3125 10.0312C18.3125 10.4988 18.6887 10.875 19.1562 10.875C19.6238 10.875 20 10.4988 20 10.0312C20 7.46836 18.9277 5.15156 17.2086 3.51328C16.8711 3.19336 16.3367 3.20391 16.0168 3.54141C15.6969 3.87891 15.7074 4.41328 16.0449 4.7332L16.0414 4.73672Z"
        fill="#FF4306"
      />
    </g>
    <defs>
      <clipPath id="clip0_158_1153">
        <rect width="18" height="18" fill="white" transform="translate(2 3)" />
      </clipPath>
    </defs>
  </svg>
);
