import {
  createClient,
  type Client,
  type FormattedExecutionResult,
  type Sink,
} from "graphql-ws";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { jwtDecode } from "jwt-decode";
import { logger } from "@artis/logger";
import { print } from "graphql";

import { getHasuraUrl, type JWT, orgIdToEnv } from "../../globals";
import type { GraphQLClient } from "../../utils/graphql";

export const createHasuraClient = (token: string): GraphQLClient => {
  const claims = token ? jwtDecode<JWT>(token) : undefined;
  if (!claims) {
    throw new Error("No claims found in token");
  }

  const hasuraClaims = claims?.["https://hasura.io/jwt/claims"];
  const roles = hasuraClaims?.["x-hasura-allowed-roles"];
  const adminRoles = ["umi-internal-write"];

  const roleHeader = {};
  for (const role of adminRoles) {
    if (roles?.includes(role)) {
      Object.assign(roleHeader, { "x-hasura-role": role });
      break;
    }
  }
  const env = orgIdToEnv(claims.org_id);
  const hasuraUrl = getHasuraUrl(env);

  const isAdminUrl =
    typeof window !== "undefined" && window.location.pathname.includes("admin");

  const headers = {
    ...(isAdminUrl ? roleHeader : {}),
    authorization: `Bearer ${token}`,
  };

  let wsClient: Client | null = null;

  return {
    execute: createQuery(hasuraUrl, headers),
    subscribe: (query, ...args) => {
      if (!wsClient) wsClient = createGraphqlWsClient(hasuraUrl, headers);
      const cb = args[1] ? args[1] : (args[0] as Sink<unknown>);
      const v = args[1] ? (args[0] as Record<string, unknown>) : {};
      return subscribe(wsClient, query, cb, v);
    },
    dispose: () => wsClient?.terminate(),
  };
};

function subscribe<TResult, TVariables extends Record<string, unknown>>(
  client: Client,
  document: TypedDocumentNode<TResult, TVariables>,
  callbacks: Partial<Sink<FormattedExecutionResult<TResult>>>,
  variables?: Record<string, unknown>,
) {
  const queryStr = print(document).replace(
    /^(\s*)query\s+/m,
    "$1subscription ",
  );

  // biome-ignore lint/suspicious/noExplicitAny: CBA to find the correct type
  return client.subscribe<TResult, any>(
    {
      query: queryStr,
      variables,
    },
    {
      next: (x) => callbacks.next?.(x),
      error:
        callbacks.error ??
        ((err) => logger.error("Subscription error", { err })),
      complete:
        callbacks.complete ?? (() => logger.debug("Subscription completed")),
    },
  );
}

const createGraphqlWsClient = (
  url: string,
  headers: Record<string, string>,
) => {
  return createClient({
    url: url.replace("https", "wss").replace("http", "ws"),
    connectionParams: {
      headers: headers,
    },
    shouldRetry: (errOrCloseEvent) => {
      if (
        typeof errOrCloseEvent === "object" &&
        errOrCloseEvent &&
        "message" in errOrCloseEvent &&
        typeof errOrCloseEvent.message === "string" &&
        errOrCloseEvent.message.includes("JWT")
      ) {
        return false;
      }
      return true;
    },
    retryAttempts: 5,
    retryWait: async (retries) => {
      const delay = Math.min(1000 * 2 ** retries, 16000);
      await new Promise((resolve) => setTimeout(resolve, delay));
    },
    keepAlive: 10000,
    on: {
      connected: () => {
        logger.debug("WebSocket connected");
      },
      closed: (event) => {
        const closeEvent = event as { code: number; reason: string };
        logger.debug("WebSocket closed", {
          code: closeEvent.code,
          reason: closeEvent.reason,
        });
      },
      error: (error) => {
        const err = error as Error;
        logger.error("WebSocket error", { error: err });
      },
    },
  });
};

const createQuery =
  (url: string, headers: Record<string, unknown>): GraphQLClient["execute"] =>
  async (query, ...variables) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/graphql-response+json",
        ...headers,
      },
      body: JSON.stringify({
        query: print(query).replace(/^(\s*)subscription\s+/m, "$1query "),
        variables: variables[0],
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // biome-ignore lint/suspicious/noExplicitAny: Its fine
    return response.json() as any;
  };
