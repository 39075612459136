import { graphql } from "../../graphql";

export const ActiveSessionSub = graphql(`
  subscription UserSession($user: String!, $mobile: Boolean!) {
    session_by_pk(folio_user: $user, is_mobile: $mobile) {
      id
      last_seen
      session_start
      is_mobile
    }
  }
`);

export const StartSessionMut = graphql(`
  mutation StartSession(
    $user: String!
    $mobile: Boolean!
    $session_id: String!
    $last_seen: timestamptz!
    $session_start: timestamptz!
  ) {
    insert_session_one(
      object: {
        folio_user: $user
        is_mobile: $mobile
        id: $session_id
        last_seen: $last_seen
        session_start: $session_start
      }
      on_conflict: { constraint: session_pkey, update_columns: [id, last_seen, session_start] }
    ) {
      id
      last_seen
    }
  }
`);

export const LivenessProbeMutation = graphql(`
  mutation LivenessProbeMutation($user: String!, $mobile: Boolean!) {
    update_session_by_pk(pk_columns: { folio_user: $user, is_mobile: $mobile }, _set: { last_seen: "now()" }) {
      id
    }
		update_folio_user_by_pk(pk_columns: { id: $user }, _set: { last_seen: "now()" }) {
      id
    }
  }
`);

export const DeleteSession = graphql(`
  mutation DeleteSession($user: String!, $mobile: Boolean!) {
    update_folio_user_by_pk(pk_columns: { id: $user } _set: { logout_requested_at: "now()", last_seen: "now()" }) {
      id
    }
    delete_session(where: { folio_user: { _eq: $user }, is_mobile: { _eq: $mobile } }) {
      affected_rows
    }
  }
`);
