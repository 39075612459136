import type { IMenuActionParams } from "ag-grid-community";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
} from "@mui/joy";

import { useUserSubscriptionTier } from "../../../context/auth";
import { useDeletePageProducts } from "../../../data";
import type { GetContextMenuSection } from "./helpers";
import { colParams } from "../../utils";
import { useDialog } from "../../dialog";
import { getIcon } from "./icons";

export const useGetMenuItemsRemoveColumn = (): GetContextMenuSection => {
  const { disabledFeatures } = useUserSubscriptionTier();
  const remove = useDeletePageProducts();

  const openConfirm = useDialog<string>((p) => (
    <ConfirmDialog
      open={p.open}
      title="Remove Curve"
      message="Are you sure you want to remove this Curve from your Page?"
      onCancel={p.close}
      onConfirm={() => {
        p.close();
        if (p.arg) remove.mutate(p.arg);
      }}
    />
  ));

  const handleRemove = (e: IMenuActionParams) => {
    const id = colParams(e.column)?.gridId;
    if (id) remove.mutateAsync(id);
  };

  return (ctx) => {
    if (ctx.isProduct) {
      const isMultiColumn =
        new Set(ctx.rangeSelection.map((x) => x.columnId)).size > 1;
      return [
        {
          name: "Remove Curve",
          disabled: disabledFeatures?.removeCurve || isMultiColumn,
          tooltip: disabledFeatures?.removeCurve
            ? "Please upgrade your subscription to access this feature"
            : undefined,
          icon: getIcon("remove"),
          action: (e) => {
            const id = colParams(e.column)?.gridId;
            if (id) openConfirm(id);
          },
        },
      ];
    }
    if (ctx.isShadowCurve) {
      return [
        {
          name: "Remove Column",
          icon: getIcon("remove"),
          action: handleRemove,
        },
      ];
    }
    return [];
  };
};

const ConfirmDialog = (p: {
  open: boolean;
  title: string;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Modal open={p.open} onClose={p.onCancel}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>{p.title}</DialogTitle>
        <DialogContent>{p.message}</DialogContent>
        <DialogActions>
          <Button
            size="sm"
            variant="solid"
            color="primary"
            onClick={() => p.onConfirm()}
            className="!rounded-md !px-4"
          >
            Remove
          </Button>
          <Button
            size="sm"
            color="neutral"
            variant="solid"
            onClick={() => p.onCancel()}
            className="!rounded-md !px-4"
          >
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
