import type { GridApi } from "ag-grid-community";
import { useEffect } from "react";
import * as R from "remeda";

import {
  adhocLookups,
  allPeriodsCount,
  parseAndFormatDate,
  rowStringToCode,
} from "../periodHelpers";
import type {
  TMonthColumn,
  TQrtColumn,
  THlvColumn,
  TSeasonColumn,
  TCalColumn,
  TAdhocColumn,
} from "../periodHelpers";
import { useGrid } from "../stores";
import { logger } from "@artis/logger";
import {
  initGridSettings,
  type TGridSettings,
  useGridSettings,
  useAdhocSpreads,
  useActivePageId,
  type TAdhocSpreadFormatted,
} from "../../../data";
/*
 * Updates the grid (e.g col defs and number of months to show) via the API when settings change
 */
export const useGridConfigureMonthColumn = () => {
  const spreads = useAdhocSpreads(useActivePageId());
  const settings = useGridSettings();
  const [api] = useGrid();

  useEffect(() => {
    if (!api || !settings.data) return;
    const monthColumn = generateMonthColumnData({
      gridSettingsForRowData: settings.data,
      adhocSpreadsData: spreads.data ?? {},
    });
    logger.log("update grid month column", { monthColumn });
    updateGridMonthColumn(api, monthColumn);
  }, [settings, api, spreads.data]);
};

function generateMonthColumnData({
  gridSettingsForRowData,
  adhocSpreadsData,
}: {
  adhocSpreadsData: TAdhocSpreadFormatted;
  gridSettingsForRowData: TGridSettings;
}) {
  const { months, qtrs, cals, hlvs, seasons } = allPeriodsCount(
    gridSettingsForRowData,
  );
  const {
    qtr_switch,
    hlv_switch,
    season_switch,
    cal_switch,
    adhoc_spreads_switch,
  } = gridSettingsForRowData;

  const generateMonths = months?.map(([_, month], idx) => {
    const stringCode = rowStringToCode(month);
    const quarter = ["MAR", "JUN", "SEP", "DEC"].includes(
      month.substring(0, 3),
    );
    const firstOfType = idx === 0;
    return {
      id: stringCode,
      period: month,
      monthString: parseAndFormatDate(month),
      rowType: "mth",
      code: stringCode,
      ...(quarter && { quarter: true }),
      ...(firstOfType && { firstOfType: true }),
    } satisfies TMonthColumn;
  });

  const generateQtrs =
    qtr_switch &&
    qtrs?.map(([_, qtr], idx) => {
      const isCurrentQtr = idx === 0 && !!gridSettingsForRowData.qtr_current;
      const firstOfType = idx === 0;
      return {
        id: rowStringToCode(qtr),
        period: qtr,
        rowType: "qtr",
        current: isCurrentQtr,
        code: rowStringToCode(qtr),
        ...(firstOfType && { firstOfType: true }),
      } satisfies TQrtColumn;
    });

  const generateHlvs =
    hlv_switch &&
    hlvs?.map(([_, hlv], idx) => {
      const isCurrentHlv = idx === 0 && !!gridSettingsForRowData.hlv_current;
      const firstOfType = idx === 0;
      return {
        id: rowStringToCode(hlv),
        period: hlv,
        rowType: "hlv",
        current: isCurrentHlv,
        code: rowStringToCode(hlv),
        ...(firstOfType && { firstOfType: true }),
      } satisfies THlvColumn;
    });

  const generateSeasons =
    season_switch &&
    seasons?.map(([_, season], idx) => {
      const isCurrentSeasons =
        idx === 0 && !!gridSettingsForRowData.season_current;
      const firstOfType = idx === 0;

      return {
        id: rowStringToCode(season),
        period: season,
        rowType: "season",
        current: isCurrentSeasons,
        code: rowStringToCode(season),
        ...(firstOfType && { firstOfType: true }),
      } satisfies TSeasonColumn;
    });

  const generateCals =
    cal_switch &&
    cals?.map(([_, cal], idx) => {
      const isCurrentCal = idx === 0 && !!gridSettingsForRowData.cal_current;
      const firstOfType = idx === 0;

      return {
        id: rowStringToCode(cal),
        period: cal,
        rowType: "cal",
        current: isCurrentCal,
        code: rowStringToCode(cal),
        ...(firstOfType && { firstOfType: true }),
      } satisfies TCalColumn;
    });

  const adhocSpreadsRows = gridSettingsForRowData.adhoc_spreads_rows;
  const adhocSpreads = adhoc_spreads_switch
    ? Object.values(adhocLookups)
        .slice(0, adhocSpreadsRows ?? initGridSettings.adhoc_spreads_rows)
        .reduce((acc: Array<TAdhocColumn>, id, idx) => {
          const spread = adhocSpreadsData[id];
          acc.push({
            id,
            period: spread?.from?.periodValue || " ",
            code: spread?.to?.periodValue || " ",
            rowType: "adhoc",
            formula: "period - code",
            ...(idx === 0 && { firstOfType: true }),
          } satisfies TAdhocColumn);

          return acc;
        }, [])
    : [];

  const mergedData = [
    ...(generateMonths || []),
    ...(generateQtrs || []),
    ...(generateHlvs || []),
    ...(generateSeasons || []),
    ...(generateCals || []),
    ...(adhocSpreads || []),
  ].filter(Boolean);

  return mergedData;
}

// this drives the total number of rows in the grid
function updateGridMonthColumn(
  api: GridApi,
  monthColumn: ReturnType<typeof generateMonthColumnData>,
) {
  if (!api || api.isDestroyed()) return;
  const currentRowIds = new Set<string>();
  const currentGridRows: typeof monthColumn = [];

  api.forEachNode((node) => {
    currentRowIds.add(node.data.id);
    currentGridRows.push(node.data);
  });

  const firstOfTypeUpdatedRows = genUpdatedRows(monthColumn, currentGridRows);

  // if true, we know it's a deletion.
  // So, we simply remove the rows from the grid
  if (monthColumn.length < currentRowIds.size) {
    const rowsToRemove = currentGridRows.filter(
      (row) => !monthColumn.some((mRow) => mRow.id === row.id),
    );
    api.applyTransaction({
      remove: rowsToRemove,
      update: firstOfTypeUpdatedRows,
    });
    api.resetRowHeights();
    return;
  }

  // We know we are adding rows here.
  // Figure out what has been added
  const rowsToAdd = monthColumn.filter((row) => !currentRowIds.has(row.id));
  // we only support adding one rowType at a time
  const rowType = rowsToAdd?.[0]?.rowType;

  const groupedGridRows = R.groupBy(currentGridRows, (row) => row?.rowType);

  // rowType lengths
  const mthLength = groupedGridRows?.mth?.length || 0;
  const qtrLength = mthLength + (groupedGridRows?.qtr?.length || 0);
  const hlvLength = qtrLength + (groupedGridRows?.hlv?.length || 0);
  const seasonLength = hlvLength + (groupedGridRows?.season?.length || 0);
  const calLength = seasonLength + (groupedGridRows?.cal?.length || 0);
  const adhocSpreadsLength = calLength + (groupedGridRows?.adhoc?.length || 0);

  if (
    rowsToAdd.length === 1 &&
    "current" in rowsToAdd[0] &&
    rowsToAdd[0].current
  ) {
    const currentRowToAdd = rowsToAdd[0];

    const gridIndexesForCurrent = {
      qtr: mthLength,
      hlv: qtrLength,
      season: hlvLength,
      cal: seasonLength,
      adhoc: calLength,
    };

    if (!api.getRowNode(currentRowToAdd.id)) {
      api.applyTransaction({
        add: [currentRowToAdd],
        addIndex: gridIndexesForCurrent[currentRowToAdd.rowType],
        update: firstOfTypeUpdatedRows,
      });
      api.resetRowHeights();
      return;
    }
  }

  const gridIndexes = {
    mth: mthLength,
    qtr: qtrLength,
    hlv: hlvLength,
    season: seasonLength,
    cal: calLength,
    adhoc: adhocSpreadsLength,
  };

  // given our new grid settings, we can only add one rowType at a time,
  // as we don't have a save button but rather a save on change
  if (!api.getRowNode(rowsToAdd[0]?.id)) {
    api.applyTransaction({
      add: rowsToAdd,
      addIndex: gridIndexes[rowType],
      update: firstOfTypeUpdatedRows,
    });
    api.resetRowHeights();
  }
}

function genUpdatedRows(
  monthColumn: ReturnType<typeof generateMonthColumnData>,
  currentGridRows: ReturnType<typeof generateMonthColumnData>,
) {
  return currentGridRows
    .filter((row) => monthColumn.some((mRow) => mRow.id === row.id))
    .map((row) => {
      const { id } = row;
      const matchingMonthColumn = monthColumn.find((mRow) => mRow.id === id);

      row.firstOfType = matchingMonthColumn?.firstOfType;

      return row;
    });
}
