import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Stack,
  Typography,
} from "@mui/joy";
import type { ErrorComponentProps } from "@tanstack/react-router";
import { FaExclamationTriangle } from "react-icons/fa";
import { Sidebar } from "../layout";

export function ErrorPage(p: ErrorComponentProps) {
  const regex = /netlify|localhost/gi;
  const showDetails = regex.test(window.location.href);

  return (
    <Stack
      width={"100%"}
      height={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Sidebar />
      <Alert
        color="danger"
        sx={{
          fontSize: "lg",
          p: 2,
          maxWidth: 400,
        }}
      >
        <Stack gap={2}>
          <Stack flexDirection={"column"} gap={1} alignItems={"center"}>
            <FaExclamationTriangle size={32} />
            <Typography level="h2">Error. Something went wrong.</Typography>
          </Stack>
          {showDetails && (
            <Accordion
              variant="soft"
              color="neutral"
              sx={{
                p: 1,
                borderRadius: "md",
              }}
            >
              <AccordionSummary
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  "button:hover": {
                    backgroundColor: "transparent !important",
                    opacity: 0.8,
                  },
                }}
              >
                Details
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  sx={{
                    maxHeight: 200,
                    overflow: "auto",
                  }}
                >
                  <Typography>Name: {p.error.name}</Typography>
                  <Typography>Message: {p.error.message}</Typography>
                  {p.info?.componentStack && (
                    <Typography>
                      Component Stack: {p.info?.componentStack}
                    </Typography>
                  )}
                  <Typography>Stack: {p.error.stack}</Typography>
                </Stack>
              </AccordionDetails>
            </Accordion>
          )}
        </Stack>
      </Alert>
    </Stack>
  );
}
