import { datadogLogs, type LogsInitConfiguration } from "@datadog/browser-logs";
import { datadogRum, type RumInitConfiguration } from "@datadog/browser-rum";
import posthog, { type ToolbarParams } from "posthog-js";
import * as Sentry from "@sentry/react";

import { GIT_SHA } from "../../globals";
import { measureMemory } from "./measureMemory";

const toolbarJSON = new URLSearchParams(window.location.hash.substring(1)).get(
  "__posthog",
);

if (toolbarJSON) {
  posthog.loadToolbar(JSON.parse(toolbarJSON) as ToolbarParams);
}

const env = import.meta.env.VITE_CONTEXT || "local";

const devDatadogClientToken = "pub35e54dd1a81ef0b11eff8104736aa1e7";
const prodDatadogClientToken = "pub57e14c6263f92f7c98d347982c233ddb";

const clientToken =
  env === "production" ? prodDatadogClientToken : devDatadogClientToken;

const datadogBaseConfig = {
  applicationId: "34d168e9-fd17-4c97-904f-31b4a16cc6e9",
  clientToken,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.eu",
  service: "webapp-2.0",
  env,
  version: GIT_SHA,
  sessionSampleRate: 100,
} as const satisfies RumInitConfiguration;

const datadogConfig = {
  ...datadogBaseConfig,
  compressIntakeRequests: true,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  trackViewsManually: true,
  storeContextsAcrossPages: true,
  silentMultipleInit: true,
  allowFallbackToLocalStorage: true,
} satisfies RumInitConfiguration;

const datadogLogsConfig = {
  ...datadogBaseConfig,
  // debug logs are not forwarded to datadog
  forwardConsoleLogs: ["warn", "error", "info"],
  telemetrySampleRate: 100,
  telemetryConfigurationSampleRate: 100,
  telemetryUsageSampleRate: 100,
} satisfies LogsInitConfiguration;

datadogRum.init(datadogConfig);
datadogLogs.init(datadogLogsConfig);

posthog.init("phc_5VQrxO6QX946p5WIhQcFj6QJK5fsVbXn8iOsVsbhCh2", {
  capture_pageview: false,
  api_host: `${window.location.origin}/ingest`,
  ui_host: "https://eu.posthog.com",
  person_profiles: "identified_only",
});

Sentry.init({
  dsn: "https://fe87329662ff690016184c6cb335abb1@o4505007010414592.ingest.us.sentry.io/4506944634224640",
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  profilesSampleRate: 1.0,

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
});

if (crossOriginIsolated) {
  setTimeout(measureMemory, 30000);
}
