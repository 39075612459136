import { HexColorPicker } from "react-colorful";
import "./react-colorful.css";

import type { TRangeSelection } from "../../useRangeSelectionChange";
import { rangeSelectionInfo, SelectionInfo } from "./SelectionInfo";
import { useUpdateFormatting } from "./useUpdateFormatting";
import type { TFormatTypes } from "../../cell-style";

export const FormattingColor = (p: {
  selection: TRangeSelection;
  type: TFormatTypes;
  pageId: string;
}) => {
  const [current, onChange] = useUpdateFormatting(p);
  return (
    <div className="flex flex-col gap-2">
      <SelectionInfo selection={rangeSelectionInfo(p.selection)} />
      <div className="px-6 py-8">
        <ColorPicker
          value={current.color ?? "#ffffff"}
          onChange={(value) => onChange({ color: value })}
        />
      </div>
    </div>
  );
};

const ColorPicker = (p: {
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <>
      <div className="grid [grid-template-columns:minmax(0,1fr)_14.2rem] w-full gap-3">
        <HexColorPicker
          className="w-full! custom-color"
          color={p.value}
          onChange={(e) => p.onChange(e)}
        />
        <div className="grid w-full gap-3 [grid-template-columns:repeat(4,minmax(0,1fr))] [grid-auto-rows:1fr] h-min px-4">
          {swatches.map((x) => (
            <button
              key={x}
              type="button"
              style={swatchStyle(x)}
              onClick={() => p.onChange(x)}
              className="aspect-square w-full rounded-sm border"
            />
          ))}
        </div>
      </div>
      <div className="flex justify-end w-full">
        <div className="flex items-end gap-2">
          <div
            className="w-9 h-9 bg-blue-200 rounded-sm border"
            style={swatchStyle(p.value)}
          />
          <input
            value={p.value}
            onChange={(e) => p.onChange(e.target.value.slice(0, 7))}
            className="border w-24 px-3 py-2 rounded-sm h-7"
          />
        </div>
      </div>
    </>
  );
};

const swatchStyle = (color: string) => {
  if (color === "#ffffff00") {
    const size = 12;
    return {
      backgroundColor: "#fff",
      backgroundImage: `
        linear-gradient(45deg, #ccc 25%, transparent 25%),
        linear-gradient(-45deg, #ccc 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #ccc 75%),
        linear-gradient(-45deg, transparent 75%, #ccc 75%)
      `,
      backgroundSize: `${size}px ${size}px`,
      backgroundPosition: `0 0, 0 ${size / 2}px, ${size / 2}px -${size / 2}px, -${size / 2}px 0`,
    };
  }
  return {
    backgroundColor: color,
  };
};

const swatches = [
  "#c6d9f0",
  "#ebf1dd",
  "#fdeada",
  "#fcdad9",
  "#ffffff",
  "#0039bb",
  "#00ff00",
  "#4f81bd",
  "#9bbb59",
  "#f79646",
  "#c0504d",
  "#c8c8c8",
  "#41eefa",
  "#ff0000",
  "#ffffff00",
];
