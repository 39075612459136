import type { FetchResult } from "@apollo/client";
import * as idb from "idb-keyval";
import * as R from "remeda";

import type {
  ConfigsQueryQuery,
  ConfigsSubSubscription,
  ProductInfoFragmentFragment,
} from "../__generated__/gql/graphql";

export const clearIdbCache = () => idb.clear();

// Product info
export type ProductInfo = Record<string, ProductInfoFragmentFragment>;
const productInfoKey = "product_info";

export const storeProductInfo = async (info: ProductInfo) =>
  idb.set(productInfoKey, info);
export const getProductInfo = () => idb.get<ProductInfo>(productInfoKey);

// Product configs
export type TProductConfig = {
  formula?: string | null;
  product: string;
  offset: number;
};

export type TProductConfigs = Record<string, TProductConfig[]>;

const configsKey = "product_config";
export const storeProductConfigs = async (configs: TProductConfigs) =>
  idb.set(configsKey, configs);
export const getProductConfigs = () => idb.get<TProductConfigs>(configsKey);

export const transformProductConfigsQuery = (
  res: FetchResult<ConfigsSubSubscription | ConfigsQueryQuery>,
): TProductConfigs =>
  R.pipe(
    res?.data?.product_config ?? [],
    R.map((config) => ({
      formula: config?.formula,
      product: config.product,
      offset: config.offset,
    })),
    R.groupBy((config) => config.product),
  );

// Manual Cells store
const manualCellsKey = "manual_cells";
export type TManualCellValue = Record<string, number | string | undefined>;
export const setManualCells = (x: Map<string, TManualCellValue>) =>
  idb.set(manualCellsKey, JSON.stringify([...x]));
export const getManualCells = () =>
  idb
    .get<string>(manualCellsKey)
    .then((x) =>
      x ? new Map<string, TManualCellValue>(JSON.parse(x) as []) : undefined,
    );
