import { memo } from "react";

import { useGridConfigureMonthColumn } from "./useGridConfigureMonthColumn";
import { useDynamicCssExtraHeightRow } from "./useDynamicCssExtraHeightRow";
import { useGridUpdateInterval } from "./update";

export { useToggleGridUpdate, addInfoToPageProduct } from "./update";

export const RegisterGridPlugins = memo(() => {
  useDynamicCssExtraHeightRow();
  useGridConfigureMonthColumn();
  useGridUpdateInterval();
  return null;
});
