import { useStore } from "jotai";
import { nanoid } from "nanoid";
import { generateNKeysBetween } from "fractional-indexing";
import {
  useActivePageId,
  useInsertPageProducts,
  usePageProducts,
} from "../../../data";
import { useUserId, useUserSubscriptionTier } from "../../../context/auth";
import type { GetContextMenuSection } from "./helpers";
import { modalAtom } from "../../modal";
import { compareIdx } from "../../utils";
import { columnWidth } from "../../globals";
import { getIcon } from "./icons";
import type { IMenuActionParams } from "ag-grid-community";
import type { PageProductsFragmentFragment } from "../../../__generated__/gql/graphql";

export const useGetMenuItemsColumnInsert = (): GetContextMenuSection => {
  const { disabledFeatures } = useUserSubscriptionTier();
  const insertCurveDuplicate = useInsertCurveDuplicate();
  const insertShadowColumn = useInsertShadowColumn();
  const pageId = useActivePageId();
  const store = useStore();

  return (ctx) => {
    return [
      {
        name: "Insert Column",
        icon: getIcon("column"),
        subMenu: [
          {
            name: "Curve",
            disabled: disabledFeatures?.insertCurve,
            tooltip: disabledFeatures?.insertCurve
              ? "Please upgrade your subscription to access this feature"
              : undefined,
            action: () => {
              store.set(modalAtom, {
                modalId: "add-products",
                pageId,
                curveWidth: ctx.columnWidth,
              });
            },
          },
          !ctx.isShadowCurve
            ? {
                name: "Duplicate",
                disabled:
                  disabledFeatures?.insertCurve ||
                  !isRangeWithinSingleColumn(ctx.rangeSelection) ||
                  !ctx.cannedOrSourced,
                tooltip: disabledFeatures?.insertCurve
                  ? "Please upgrade your subscription to access this feature"
                  : undefined,
                action: (params: IMenuActionParams) =>
                  insertCurveDuplicate(
                    ctx.columnId,
                    params.column?.getActualWidth() ?? columnWidth,
                  ),
              }
            : null,
          "separator",
          {
            name: "Month",
            action: () => insertShadowColumn("month", ctx.columnId),
          },
          {
            name: "Time Spread",
            action: () => insertShadowColumn("month-timespread", ctx.columnId),
          },
          {
            name: "Blank",
            action: () => insertShadowColumn("blank", ctx.columnId),
          },
        ].filter(Boolean),
      },
    ];
  };
};

const useInsertShadowColumn = () => {
  const insert = useInsertPageProducts();
  const userId = useUserId();
  const pageId = useActivePageId();
  const products = usePageProducts(pageId);

  return async (type: string, id: string) => {
    const sorted = products.data?.sort(compareIdx);
    const current = sorted?.findIndex((x) => x.id === id);

    if (typeof current === "undefined" || current === -1 || !sorted)
      return console.error("Missing column");

    // Get sorting index between the current column and the next column
    const idx = generateNKeysBetween(
      sorted[current].idx,
      sorted[current + 1]?.idx,
      1,
    )[0];

    const newColId = generateShadowColumnId();

    const newColumn = {
      id: newColId,
      idx,
      product_id: undefined,
      user_id: userId,
      page_id: pageId,
      column_type: type,
      column_width: columnWidth,
      column_field_selector: undefined,
      decimal_places: undefined,
      thousands_separator: undefined,
    } satisfies PageProductsFragmentFragment;

    return insert.mutate([newColumn]);
  };
};

export const useInsertCurveDuplicate = () => {
  const insert = useInsertPageProducts();
  const userId = useUserId();
  const pageId = useActivePageId();
  const products = usePageProducts(pageId);

  return async (id: string, columnWidth: number) => {
    const sorted = products.data?.sort(compareIdx);
    const current = sorted?.findIndex((x) => x.id === id);

    if (typeof current === "undefined" || current === -1 || !sorted)
      return console.error("Missing column");

    const product = sorted[current];

    // Get sorting index between the current column and the next column
    const idx = generateNKeysBetween(
      sorted[current].idx,
      sorted[current + 1]?.idx,
      1,
    )[0];

    const newColId = nanoid();

    const newColumn = {
      id: newColId,
      idx,
      product_id: product?.product_id,
      user_id: userId,
      page_id: pageId,
      column_type: "product",
      column_width: columnWidth,
      column_field_selector: product?.column_field_selector,
      decimal_places: product?.decimal_places,
      thousands_separator: product?.thousands_separator,
    } satisfies PageProductsFragmentFragment;

    return insert.mutateAsync([newColumn]);
  };
};

function isRangeWithinSingleColumn(data: Array<{ columnId: string }>): boolean {
  if (data.length === 0) return false;
  const firstColumnId = data[0].columnId;
  return data.every((item) => item.columnId === firstColumnId);
}

function generateShadowColumnId() {
  return nanoid();
}
