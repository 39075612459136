import type { BrotliWasmType } from "brotli-wasm";

const encoder = new TextEncoder();
const decoder = new TextDecoder();
const prefix = "BROTLI:";
const quality = 11;

const B: Promise<BrotliWasmType> = (async () => {
  const { default: init, ...brotliWasm } = await import(
    "../../../../node_modules/brotli-wasm/pkg.web/brotli_wasm"
  );
  await init("/assets/brotli_wasm_bg.wasm");
  return brotliWasm;
})();

export const getSyncBrotli = async () => {
  const brotli = await B;
  return {
    compress: <T>(data: T) => compress(brotli, data),
    decompress: <T>(str: string | null | undefined, def: T) =>
      decompress(brotli, str, def),
  };
};

export const brotliDecompress = async <T = unknown>(
  str: string | null | undefined,
  def: T,
) => B.then((b) => decompress(b, str, def));

export const decompress = <T = unknown>(
  brotli: BrotliWasmType,
  str: string | null | undefined,
  def: T,
) => {
  if (!str) return def;
  try {
    const decoded = atob(str.slice(prefix.length));

    const bytes = new Uint8Array(decoded.length);
    for (let i = 0; i < decoded.length; i++) {
      bytes[i] = decoded.charCodeAt(i);
    }

    const jsonStr = decoder.decode(brotli.decompress(bytes));

    return tryParse(jsonStr, def);
  } catch (e) {
    console.error(e);
    return def;
  }
};

export const brotliCompress = async <T>(data: T) =>
  B.then((b) => compress(b, data));

export const compress = <T>(brotli: BrotliWasmType, data: T) => {
  // Encode JSON string to Uint8Array
  const uncompressedData = encoder.encode(JSON.stringify(data));

  // Compress data
  const compressedDataBytes = brotli.compress(uncompressedData, { quality });

  // Convert compressed data to base64 string without using spread operator to save memory
  let binaryString = "";
  for (let i = 0; i < compressedDataBytes.length; i++) {
    binaryString += String.fromCharCode(compressedDataBytes[i]);
  }

  return `${prefix}${btoa(binaryString)}`;
};

export const tryParse = <T>(value: string | null | undefined, def: T): T => {
  if (!value) return def;
  try {
    return JSON.parse(value) as T;
  } catch {
    return def;
  }
};
