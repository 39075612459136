import cn from "mcn";

export function EditCurvesIcon({
  selected = false,
  disabled = false,
}: { selected?: boolean; disabled?: boolean }) {
  const title = selected ? "Edit Curves Selected" : "Edit Curves";
  const cls = cn("nav-icon fill-white", [
    disabled,
    "opacity-50 pointer-events-none",
  ]);
  return (
    <div
      // biome-ignore lint/security/noDangerouslySetInnerHtml: This is not dangerous html
      dangerouslySetInnerHTML={{
        __html: getEditCurvesIconHtml({ class: cls, title }),
      }}
    />
  );
}

export const getEditCurvesIconHtml = (p?: { class?: string; title?: string }) =>
  `<svg
    width="42"
    height="42"
    viewBox="0 0 38 38"
    class="${p?.class ?? ""}"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.3875 21.539C31.642 22.0292 31.451 22.6328 30.9609 22.8874C30.4708 23.1419 29.8671 22.9509 29.6125 22.4608L31.3875 21.539ZM19.5 21.9999L18.5889 21.5876L18.5966 21.5707L18.6049 21.5541L19.5 21.9999ZM7.10688 22.4497L6.65706 21.5566L8.44329 20.657L8.89312 21.5501L7.10688 22.4497ZM29.6125 22.4608C28.5342 20.3844 27.6134 18.8797 26.7883 17.9005C25.9422 16.8965 25.3539 16.6321 24.9631 16.6242C24.5877 16.6167 24.0168 16.8413 23.1861 17.8255C22.3722 18.7897 21.4615 20.3045 20.3951 22.4457L18.6049 21.5541C19.6887 19.3777 20.6849 17.688 21.6578 16.5354C22.6139 15.4028 23.7095 14.5985 25.0034 14.6246C26.282 14.6504 27.3659 15.4823 28.3177 16.6118C29.2905 17.7662 30.2934 19.4324 31.3875 21.539L29.6125 22.4608ZM20.4111 22.4122C19.4137 24.6162 18.4234 26.3335 17.4011 27.5106C16.3836 28.6821 15.2123 29.4534 13.8641 29.4348C12.5316 29.4164 11.3519 28.6286 10.3036 27.4676C9.24417 26.2944 8.1899 24.6 7.10688 22.4497L8.89312 21.5501C9.94507 23.6387 10.9047 25.1491 11.7879 26.1272C12.6823 27.1176 13.3741 27.4278 13.8917 27.435C14.3936 27.4419 15.0511 27.1662 15.8911 26.1991C16.7262 25.2376 17.6219 23.7247 18.5889 21.5876L20.4111 22.4122Z"
    ></path>
    <path
      d="M15.8182 9.92349C16.471 9.41346 17.4137 9.52921 17.9237 10.182C18.4338 10.8348 18.318 11.7775 17.6652 12.2875L10.1791 18.1363L6.89159 18.8013L8.33213 15.7723L15.8182 9.92349Z"
      class="orange"
    ></path>
  </svg>`;
