import { logger } from "@artis/logger";
import type {
  TGridDataColumn,
  TGridDataEntry,
  TGridDataRowId,
} from "../../webapp/calculations-worker/stores/types";
import {
  relativeRowToRowId,
  relativeRowToRowType,
  rowStringToCode,
} from "../../webapp/market-grid/periodHelpers";
import { parseNumber } from "../../webapp/numbers";
import { defaultFieldNameSelector, parseLimitRef } from "../../webapp/utils";
import type { AlertFragmentFragment } from "../../__generated__/gql/graphql";
import type { ProductInfo, TProductConfigs } from "../cache";
import { parseArtisType } from "../../webapp/calculations-worker/stores";
import type { TStatusMap } from "../../triplit";

export function parseRowId(rowId: string) {
  try {
    const rowType = relativeRowToRowType(rowId);
    const parsedRowId = rowStringToCode(relativeRowToRowId[rowId]);
    if (rowType && parsedRowId) {
      return [rowType, parsedRowId] satisfies TGridDataRowId;
    }
  } catch (e) {
    logger.error("Failed to parse rowId for alerts in worker", { error: e });
  }
}

export function alertConditionMet(
  cellValue: number | null,
  limit: number | null,
  valueBelowLimit: boolean,
) {
  if (cellValue === null || limit === null) return false;
  // The valueBelowLimit is set when the alert is created. If the cell value at the time is below the limit, then it means that the user wants to be alerted when the value goes above the limit.
  if (valueBelowLimit) {
    return cellValue >= limit;
  }

  return cellValue <= limit;
}

export function rowIdToString(rowId: TGridDataRowId) {
  return `${rowId[0]}:${rowId[1]}`;
}

export function getAlertLimit(
  data: TGridDataEntry[],
  limit: string,
  limitRowIds: TGridDataRowId[],
) {
  const limitIsRef = limit.includes(":");
  if (limitIsRef) {
    const { columnId, rowId, rowType } = parseLimitRef(limit);
    if (!columnId || !rowId) return null;
    // because js is dumb and can't compare tuples
    const limitRowKeys = limitRowIds.map((id) => rowIdToString(id));
    const limitRowKey = rowIdToString([rowType, rowId]);
    const limitDataIndex = limitRowKeys.indexOf(limitRowKey);
    return parseNumber(data[limitDataIndex]?.[columnId]?.Ok);
  }

  return parseNumber(limit);
}

export function getAlertColumns(
  alerts: AlertFragmentFragment[],
  productInfo: ProductInfo | undefined,
  configs: TProductConfigs | undefined,
  statusMap: TStatusMap,
) {
  const { columns, limitColumns } = alerts?.reduce(
    (acc, alert) => {
      try {
        const productId = alert.product_id;
        if (productId) {
          const info = productInfo?.[productId];
          const config = configs?.[productId];
          const eodId = info?.eod_product_dep;
          const artisType = parseArtisType(info?.artis_type);

          if (artisType) {
            const status = statusMap?.[productId] || "listen";

            if (!acc.columns.some((c) => c.columnId === alert.column_id)) {
              acc.columns.push({
                productId,
                columnId: alert.column_id,
                eodId,
                status,
                artisType,
                selector:
                  alert.field_selector ?? defaultFieldNameSelector(artisType),
                isPermissioned: true,
                hasSharedCell: Boolean(config),
              } satisfies TGridDataColumn);
            }
          }
        }
      } catch (e) {
        logger.error(
          "Failed to parse pageProduct into grid data column (columns) for alerts in worker",
          { error: e },
        );
      }

      try {
        if (alert.limit.includes(":")) {
          const parsedLimit = parseLimitRef(alert.limit);
          const limitProductId = parsedLimit.productId;
          if (limitProductId) {
            const info = productInfo?.[limitProductId];
            const config = configs?.[limitProductId];
            const eodId = info?.eod_product_dep;
            const artisType = parseArtisType(info?.artis_type);

            if (artisType) {
              const status = statusMap?.[limitProductId] || "listen";
              const limitColumnId = parsedLimit.columnId ?? "";
              const limitSelector =
                parsedLimit.fieldSelector ??
                defaultFieldNameSelector(artisType);

              if (!acc.limitColumns.some((c) => c.columnId === limitColumnId)) {
                acc.limitColumns.push({
                  productId: limitProductId,
                  columnId: limitColumnId,
                  eodId,
                  status,
                  artisType,
                  selector: limitSelector,
                  isPermissioned: true,
                  hasSharedCell: Boolean(config),
                } satisfies TGridDataColumn);
              }
            }
          }
        }
      } catch (e) {
        logger.error(
          "Failed to parse pageProduct into grid data column (limitColumns) for alerts in worker",
          { error: e },
        );
      }

      return acc;
    },
    {
      columns: [] as TGridDataColumn[],
      limitColumns: [] as TGridDataColumn[],
    },
  ) ?? { columns: [], limitColumns: [] };

  return {
    columns: columns.filter(Boolean),
    limitColumns: limitColumns.filter(Boolean),
  };
}
