import type { GridApi } from "ag-grid-community";
import { unique } from "remeda";

import type { TRangeSelection } from "../useRangeSelectionChange";
import { openFormattingDockview } from "./useDockviewModal";
import { getCellIds } from "../cell-style";
import {
  useActivePageId,
  usePageProducts,
  useDeleteConditionalFormattingRules,
  useQueryConditionalFormattingRulesGrouped,
  useUpdatePageFormatting,
  usePageFormattingOptions,
} from "../../../data";
import { getIcon } from "./icons";
import type { GetContextMenuSection } from "./helpers";
import {
  monthCodeToShortMonthAndYear,
  rowIdToRelativeRow,
} from "../periodHelpers";
import { useUserId } from "../../../context/auth";

export const useGetMenuItemsFormatting = (): GetContextMenuSection => {
  const pageId = useActivePageId();
  const clear = useClearFormatting();
  const products = usePageProducts(pageId);

  return (ctx) => {
    const { selectedRange: ranges, api } = ctx;

    const createFormatMenuItem = (
      item: (typeof formatSubMenu)[number]["item"],
      type: (typeof formatSubMenu)[number]["type"],
    ) => ({
      name: getFormatMenuItemName({ api, selection: ranges, item }),
      action: () =>
        clear({
          type,
          cellIds: getCellIds({ type: "range", ranges, api }).cellIds,
          colIds: getCellIds({ type: "column", ranges, api }).cellIds,
          periods: getCellIds({ type: "period", ranges, api }).cellIds,
        }),
    });

    if (
      ctx.isHeaderMenu ||
      !(ctx.isProduct || (ctx.isShadowCurve && !ctx.isBlank))
    ) {
      return [];
    }
    return [
      {
        name: "Format",
        // requirements: [!isHeaderMenu, isProduct || (isShadowCurve && !isBlank)],
        icon: getIcon("format"),
        subMenu: [
          ...formatSubMenu.map(({ item, type }) => ({
            name: getFormatMenuItemName({
              api: ctx.api,
              selection: ranges,
              item,
            }),
            action: () => {
              openFormattingDockview({
                pageId,
                type,
                columnId: ctx.columnId,
                currentTab: "color",
                selectedRange: ranges,
              });
            },
          })),
          {
            name: "Clear Formatting",
            subMenu: [
              createFormatMenuItem("cell", "range"),
              createFormatMenuItem("column", "column"),
              createFormatMenuItem("period", "period"),
              {
                name: "All",
                action: () => {
                  clear({
                    type: "all",
                    cellIds: getCellIds({ type: "range", ranges, api }).cellIds,
                    colIds: getCellIds({ type: "column", ranges, api }).cellIds,
                    periods: getCellIds({ type: "period", ranges, api })
                      .cellIds,
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
};

const formatSubMenu = [
  { item: "cell", type: "range" },
  { item: "column", type: "column" },
  { item: "period", type: "period" },
] satisfies Array<{
  item: "cell" | "column" | "period";
  type: "range" | "column" | "period";
}>;

function getFormatMenuItemName({
  api,
  selection,
  item,
}: {
  api: GridApi;
  selection: TRangeSelection;
  item: "cell" | "column" | "period";
}) {
  try {
    if (!api) return "";

    // Sort in ascending order so the largest row index is last.
    const sortedRangeSelection = selection?.flat()?.sort((a, b) => {
      return a?.rowIndex ?? 0 - (b?.rowIndex ?? 0);
    });

    if (!sortedRangeSelection?.length) return "";

    const firstCell = sortedRangeSelection[0];
    const lastCell = sortedRangeSelection[sortedRangeSelection.length - 1];

    const firstPeriod = firstCell?.rowId;
    const lastPeriod = lastCell?.rowId;

    switch (item) {
      case "cell":
        return sortedRangeSelection.length > 1 ? "Range" : "Cell";
      case "column":
        return unique(sortedRangeSelection.map((cell) => cell.columnId))
          .length > 1
          ? "Columns"
          : "Column";
      case "period":
        return firstPeriod === lastPeriod
          ? `Period: ${firstPeriod}`
          : `Periods: ${firstPeriod} - ${lastPeriod}`;
    }
  } catch (e) {
    console.error("Error getting format menu item name", e);
    return "";
  }
}

type TClearCellConditionalFormatting = {
  type: "range" | "column" | "period" | "all";
  periods?: string[];
  cellIds?: string[];
  colIds?: string[];
};

export const useClearFormatting = () => {
  const clearCellConditionalFormatting = useClearConditionalFormatting();
  const update = useUpdatePageFormatting();
  const pageId = useActivePageId();
  const formatting = usePageFormattingOptions(pageId);

  return async (params: TClearCellConditionalFormatting) => {
    clearCellConditionalFormatting(params);
    const current = formatting.data;

    if (params.type === "range") {
      const cell_highlights = { ...current?.cell_highlights };
      params.cellIds?.forEach((cellId) => delete cell_highlights[cellId]);
      return update({ id: pageId, cell_highlights });
    }

    if (params.type === "column") {
      const column_highlights = { ...current?.column_highlights };
      params.colIds?.forEach((colId) => delete column_highlights[colId]);
      return update({ id: pageId, column_highlights });
    }

    if (params.type === "period") {
      const period_highlights = { ...current?.period_highlights };
      params.periods?.forEach((period) => delete period_highlights[period]);
      return update({ id: pageId, period_highlights });
    }

    if (params.type === "all") {
      return update({
        id: pageId,
        cell_highlights: {},
        column_highlights: {},
        period_highlights: {},
      });
    }

    // gridApi?.refreshCells({ force: true });
  };
};

const useClearConditionalFormatting = () => {
  const pageId = useActivePageId();
  const userId = useUserId();
  const rules = useQueryConditionalFormattingRulesGrouped(pageId, userId);
  const remove = useDeleteConditionalFormattingRules();

  return ({
    type,
    periods,
    cellIds,
    colIds,
  }: TClearCellConditionalFormatting) => {
    async function clearCell() {
      const toRemove =
        cellIds
          ?.flatMap((cellId) => rules.data?.[cellId]?.map((r) => r.id))
          ?.filter(Boolean) || [];
      return toRemove.map((id) => remove.mutateAsync(id));
    }

    function clearColumn() {
      (
        colIds
          ?.flatMap((colId) => {
            return Object.values(rules.data || {}).flatMap((rules) => {
              return rules?.map((r) => {
                if (r.column_id === colId) {
                  return r.id;
                }
              });
            });
          })
          ?.filter(Boolean) || []
      ).map((id) => remove.mutateAsync(id));
    }

    function clearPeriod() {
      (
        periods
          ?.flatMap((code) => {
            const period = monthCodeToShortMonthAndYear(code);
            const rowId = rowIdToRelativeRow?.[period];

            return Object.values(rules.data || {}).flatMap((rules) => {
              return rules?.map((r) => {
                if (r.row_id === rowId) {
                  return r.id;
                }
              });
            });
          })
          ?.filter(Boolean) || []
      ).map((id) => remove.mutateAsync(id));
    }

    if (type === "range") {
      clearCell();
    }

    if (type === "column") {
      clearColumn();
    }

    if (type === "period") {
      clearPeriod();
    }

    if (type === "all") {
      clearCell();
      clearColumn();
      clearPeriod();
    }
  };
};
