import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import cn from "mcn";

import { Button } from "./button";
import { AlertsIcon } from "../icons/AlertsIcon";
import { MdClose } from "react-icons/md";

export const Alert = (p: {
  title: string;
  message: React.ReactNode;
  variant: "warning" | "danger";
  action?: string;
  onAction?: () => void;
  className?: string;
}) => {
  return (
    <div
      role="alert"
      className={cn(
        "bg-card shadow-xl rounded-md p-5 border space-y-4 text-foreground",
        p.className,
      )}
    >
      <h4 className="font-semibold flex gap-3 text-2xl">
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          size="lg"
          className={cn("w-8 h-8", {
            "text-danger": p.variant === "danger",
            "text-warning": p.variant === "warning",
          })}
        />
        {p.title}
      </h4>
      <div className="space-y-2 text-base">{p.message}</div>
      {(p.action || p.onAction) && (
        <div className="w-full justify-end flex">
          <Button onClick={p.onAction} variant="neutral">
            {p.action}
          </Button>
        </div>
      )}
    </div>
  );
};

export const AlertToast = (p: {
  id: string;
  title?: string | null;
  hidden?: boolean;
  message?: React.ReactNode | null;
  onDismiss: () => void;
}) => {
  return (
    <div
      id={`alert-toast-${p.id}`}
      className={cn(
        "bg-card/90 w-96 p-6 rounded-lg shadow-sm transition-all border",
        [p.hidden, "opacity-0 translate-y-10", "opacity-100 translate-y-0"],
      )}
    >
      <div className="flex gap-3 items-center text-foreground">
        <AlertsIcon className="w-4 h-4 text-[var(--artis-brand)]" />
        <h3 className="text-xl flex-1 font-semibold">{p.title}</h3>
        <button
          type="button"
          className="text-foreground-muted p-1 rounded-sm hover:bg-foreground-muted/10"
          onClick={p.onDismiss}
        >
          <MdClose className="w-6 h-6" />
        </button>
      </div>
      <div className="py-2 max-h-44 overflow-y-auto dark:text-white">
        {p.message}
      </div>
    </div>
  );
};
