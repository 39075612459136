import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { logger } from "@artis/logger";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number.POSITIVE_INFINITY,
      retry: 3,
    },
    mutations: {
      onError: (error) => logger.error(error),
    },
  },
});

broadcastQueryClient({
  queryClient,
  broadcastChannel: "artis-query",
});

export const AppQueryProvider = (p: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {p.children}
    </QueryClientProvider>
  );
};
