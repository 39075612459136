import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { logger } from "@artis/logger";

import {
  GridSettings,
  GridSettingsInsert,
  GridSettingsQuery,
  GridSettingsSubscription,
  GridSettingsUpdate,
} from "./queries";
import type { GridSettingsFragment } from "../../__generated__/gql/graphql";
import { type GraphQLClient, useGraphQLClient } from "../../utils/graphql";
import { useUserId } from "../../context/auth";
import { readFragment } from "../../graphql";
import { headerHeight } from "../../globals";
import { months } from "../../webapp/globals";

export type TGridSettings = GridSettingsFragment;
const key = (userId: string) => ["grid_settings", userId];

const fetchOrCreateGridSettings = async (
  graphql: GraphQLClient,
  userId: string,
) => {
  const settings = await graphql
    .execute(GridSettingsQuery, { userId })
    .then((x) => readFragment(GridSettings, x.data?.grid_settings)?.[0]);

  if (!settings) {
    return graphql
      .execute(GridSettingsInsert, { object: { user_id: userId } })
      .then((x) =>
        readFragment(GridSettings, x.data?.insert_grid_settings_one),
      );
  }
  return settings;
};

const gridSettingsQueryOptions = (graphql: GraphQLClient, userId: string) =>
  queryOptions({
    queryKey: key(userId),
    queryFn: () => fetchOrCreateGridSettings(graphql, userId),
  });

export const useGridSettings = () => {
  const graphql = useGraphQLClient();
  const user_id = useUserId();
  return useQuery(gridSettingsQueryOptions(graphql, user_id));
};

let PENDING_MUTATIONS = false;
export const useSyncGridSettings = () => {
  const graphql = useGraphQLClient();
  const qry = useQueryClient();
  const userId = useUserId();

  useEffect(() => {
    return graphql.subscribe(
      GridSettingsSubscription,
      { userId },
      {
        next: (data) => {
          if (PENDING_MUTATIONS || !data.data?.grid_settings?.[0]) return;
          qry.setQueryData(key(userId), data.data?.grid_settings?.[0]);
        },
      },
    );
  }, [graphql, qry, userId]);
};

export const useGridSettingsSelect = <T>(
  select: (s: TGridSettings | null | undefined) => T,
) => {
  const graphql = useGraphQLClient();
  const user_id = useUserId();
  return useQuery({
    ...gridSettingsQueryOptions(graphql, user_id),
    select,
  });
};

export const useGridSettingsStatusMap = () => {
  const statusMapStr = useGridSettingsSelect((x) => x?.status_map);
  return useMemo(
    () => (statusMapStr.data as Record<string, string> | null) ?? {},
    [statusMapStr.data],
  );
};

export const useGridSettingsIncrementMap = () => {
  const incrementMapStr = useGridSettingsSelect((x) => x?.increment_map);
  return useMemo(
    () => (incrementMapStr.data as Record<string, number> | null) ?? {},
    [incrementMapStr],
  );
};

export const useUpdateGridSettings = () => {
  const graphql = useGraphQLClient();
  const qry = useQueryClient();
  const userId = useUserId();

  const updates = useRef<Partial<TGridSettings>>({});

  const mut = useMutation({
    networkMode: "always",
    mutationFn: () => {
      PENDING_MUTATIONS = false;
      const values = { ...updates.current };
      updates.current = {};
      return graphql.execute(GridSettingsUpdate, {
        userId,
        values: { ...values, updated_at: new Date().toISOString() },
      });
    },
    onError: (err, _) => logger.error(err),
    onSettled: () => {
      if (!PENDING_MUTATIONS) qry.invalidateQueries({ queryKey: key(userId) });
    },
  });

  const timeout = useRef(setTimeout(() => null, 0));
  const optimistic = useCallback(
    async (values: Partial<TGridSettings>) => {
      qry.cancelQueries({ queryKey: key(userId) });
      PENDING_MUTATIONS = true;
      updates.current = { ...updates.current, ...values };
      qry.setQueryData<TGridSettings>(
        key(userId),
        (old) => ({ ...old, ...values }) as TGridSettings,
      );
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => mut.mutate(), 300);
    },
    [qry, userId, mut.mutate],
  );

  const reset = useCallback(() => optimistic(initGridSettings), [optimistic]);

  return Object.assign(mut, { optimistic, reset });
};

export const initGridSettings = {
  updated_at: new Date().toISOString(),
  extra_header_height: headerHeight,
  status_map: "{}",
  increment_map: "{}",

  commodity_parent_group: false,
  geographical_region: false,
  commodity_group: false,
  package: false,
  description: false,
  source: false,
  field_name: false,
  main_month_column_width: 70,

  adhoc_spreads_rows: 5,
  flash_cell_updates: false,
  enable_full_month_row_feature: false,
  qtr_switch: true,
  hlv_switch: true,
  season_switch: true,
  cal_switch: true,
  adhoc_spreads_switch: true,
  months: months,
  hlvs: 7,
  seasons: 6,
  season_current: false,
  hlv_current: false,
  cals: 4,
  qtr_current: true,
  cal_current: false,
  qtrs: 12,
  alternating_row_colours: true,
  hide_status_row: false,
  hide_eod: true,
  hide_broadcast: true,
  hide_private: false,
  hide_global: false,
  grid_scratchpad_switch: false,
  scratchpad_max_cols: 1,
  scratchpad_max_rows: 1,
  quarter_borders: true,
  sound: false,
  indicator_colour: "#ff0000",
  header_column_colour: "#b5c7e8",
  subheader_column_colour: "var(--headerColumnColour)",
  broadcast_colour: "#ffffcc",
  hybrid_colour: "#fce3e3",
  listen_colour: "#b7d7f5",
  eod_colour: "#c6e0b4",
  local_colour: "#ededed",
  global_colour: "#33AA44",
} satisfies Partial<TGridSettings>;
