import type { getProcessedSelectedCellsByRange } from "../../../tableUtils";
import { modalCancelActions } from "../modals/modalAtoms";
import {
  type TFormattingTabs,
  requestDockviewModal,
} from "../modals/modalComponents";

export function openFormattingDockview({
  pageId,
  type,
  columnId,
  currentTab,
  selectedRange,
}: {
  pageId: string;
  type: "range" | "column" | "period";
  columnId: string;
  currentTab: TFormattingTabs;
  selectedRange: ReturnType<typeof getProcessedSelectedCellsByRange>;
}) {
  modalCancelActions.reset();
  requestDockviewModal({
    parentPanel: "formatting",
    currentTab,
    params: {
      selectedRange,
      columnId,
      pageId,
      tab: "colors",
      type,
      width: 430,
      height: 430,
    },
  });
}
