import { useDeepCompareMemo } from "@react-hookz/web";
import type { ColDef } from "ag-grid-community";
import {
  type TProduct,
  useActivePageId,
  usePageProducts,
  useProductConfigs,
  useProductsByIds,
  useGridSettingsSelect,
  initGridSettings,
  useGridSettingsStatusMap,
  useAdhocSpreads,
} from "../../../data";
import { useGetProductsContextMenue } from "../context-menu";
import type { TCell, TData } from "../../calculations-worker";
import { genColumnDefs } from "./columnDefs";
import { useProcessCellStyle } from "../cell-style";
import type { PageProductsFragmentFragment } from "../../../__generated__/gql/graphql";

export {
  genColumnDefs,
  curveColumnStack,
  mainMonthColumnId,
  validColumnSettings,
  defaultColumnSettings,
  potentiallyInvisibleColumns,
  type CurveColumnStackName,
} from "./columnDefs";

export const useProductColumnDefs = (): ColDef<TData, TCell>[] => {
  const pageId = useActivePageId();
  const pageProducts = usePageProducts(pageId);
  const spreads = useAdhocSpreads(pageId);
  const configs = useProductConfigs();
  const statusMap = useGridSettingsStatusMap();
  const productIds = (pageProducts.data ?? [])
    .map((p) => p.product_id)
    .filter(Boolean);
  const products = useProductsByIds(productIds);
  const mainMonthColumnWidth = useGridSettingsSelect(
    (x) => x?.main_month_column_width,
  );
  const flashCellUpdates = useGridSettingsSelect(
    (x) => !!x?.flash_cell_updates,
  );
  const mainMenuItems = useGetProductsContextMenue({ isHeaderMenu: true });

  const currentPageProducts = (pageProducts.data ?? []).map((p) => {
    const info = products.data?.find((x) => x.id === p.product_id);
    return { ...info, ...p };
  }) as PageProductsFragmentFragment[] & TProduct[];

  const processCellStyle = useProcessCellStyle();

  return useDeepCompareMemo(() => {
    const colDefs = genColumnDefs({
      currentPageProducts,
      statusMap,
      products: products.data ?? [],
      spreads: spreads.data ?? {},
      mainMonthColumnWidth:
        mainMonthColumnWidth.data ?? initGridSettings.main_month_column_width,
      flashCellUpdates: !!flashCellUpdates.data,
      configs: configs.data ?? {},
      processCellStyle,
    });

    return colDefs.map((col) => {
      const divCol = ["month", "month-timespread", "blank"].includes(
        col.field ?? "",
      );
      if (divCol) return col;
      return { ...col, mainMenuItems };
    });
  }, [
    spreads,
    currentPageProducts,
    statusMap,
    products.data,
    mainMonthColumnWidth.data,
    flashCellUpdates.data,
    configs.data,
  ]);
};
