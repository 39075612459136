import {
  Box,
  Stack,
  Table,
  Tooltip,
  Typography,
  Button,
  Input,
  Checkbox,
  Option,
  Select,
} from "@mui/joy";
import { ModalButtons } from "../Buttons";
import { GridModalContainer } from "../GridModal";
import type { GridApi } from "ag-grid-community";
import { FaTimes, FaTrash } from "react-icons/fa";
import { PiArrowFatUpFill, PiArrowFatDownFill } from "react-icons/pi";
import {
  type TAlertForm,
  alertValidation,
  requestNotificationPermission,
  useAlerts,
} from "./hooks";
import {
  relativeRowToRowId,
  rowIdToRelativeRow,
  rowStringToCode,
} from "../../periodHelpers";
import { useAtomValue } from "jotai";
import { useState } from "react";
import {
  useFieldArray,
  useForm,
  useWatch,
  Controller,
  type UseFormReturn,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getNodePeriod,
  useResetLimitSelection,
} from "../ConditionalFormatting/helpers";
import { parseNumber } from "../../../numbers";
import toast from "react-hot-toast";
import { gridModalDockviewAtom } from "../modalAtoms";
import { GridModalSelectionInfo } from "../modalComponents";
import { useGrid } from "../../stores";
import {
  useActivePageId,
  usePageProducts,
  useProductName,
} from "../../../../data";
import { useThemeMode } from "../../../../context/theme";
import { PointerIcon } from "../../../icons";
import { alertTableStyle, AlertHeader } from "./components";
import { alertSounds } from "../../../globals";
import { v4 as uuidv4 } from "uuid";
import type { AlertFragmentFragment } from "../../../../__generated__/gql/graphql";
import {
  useAlertsByPageId,
  useDeleteAlert,
  useUpdateAlert,
} from "../../../../data/alerts/hooks";

function alertChanged(
  existing: AlertFragmentFragment,
  alert: AlertFragmentFragment,
) {
  return (
    existing.limit !== alert.limit ||
    existing.note !== alert.note ||
    existing.sound !== alert.sound ||
    existing.recurring !== alert.recurring
  );
}

type TAlertCell = {
  value: ReturnType<GridApi["getCellValue"]>;
  period: string | number | undefined;
  rowId: string | undefined;
  productId: string;
  name: string;
};

function formatAlerts(
  gridApi: GridApi | null,
  alerts: ReturnType<typeof useAlerts>["alerts"] | null,
  filter: "page" | "range",
  cells: TAlertCell[],
) {
  if (!gridApi || !alerts) return [];

  const alertValues = Object.values(alerts).flat();

  const formatted = alertValues
    ?.map((alert) => {
      if (filter === "range") {
        if (
          !cells.find(
            (cell) =>
              cell.productId === alert.product_id &&
              cell.rowId === alert.row_id,
          )
        ) {
          return;
        }
      }

      const isLimitRef = alert.limit.includes(":");
      const parts = isLimitRef ? alert.limit.split(":") : undefined;

      const limitRef = parts
        ? {
            columnId: parts[0],
            productId: parts[1],
            fieldSelector: parts[2],
            rowId: parts[3],
            name: gridApi.getColumn(parts[0])?.getColDef()?.headerName || "",
            period: getNodePeriod(
              gridApi?.getRowNode(
                rowStringToCode(relativeRowToRowId[parts[3]]),
              ),
            ),
          }
        : undefined;

      return {
        id: alert.id,
        _id: alert._id ?? uuidv4(),
        limit: alert.limit.includes(":") ? "" : alert.limit,
        limitRef,
        note: alert.note || "",
        valueBelowLimit: alert.value_below_limit,
        recurring: alert.recurring ?? false,
        sound: alert?.sound ?? false,
        status: alert.status,
        productId: alert.product_id,
        columnId: alert.column_id,
        rowId: alert.row_id,
      } satisfies TAlertForm;
    })
    .filter(Boolean);

  return formatted;
}

function ManageAlertRow({
  form,
  alert,
  index,
  selectedAlerts,
  handleSelect,
}: {
  form: UseFormReturn<{ alerts: TAlertForm[] }>;
  alert: TAlertForm;
  index: number;
  selectedAlerts: Set<string>;
  handleSelect: (_id: string, checked: boolean) => void;
}) {
  const [gridApi] = useGrid();
  const resetLimitSelection = useResetLimitSelection();

  const { register, control } = form;

  const output = useWatch({
    control,
    name: "alerts",
  });

  const mode = useThemeMode();
  const period = relativeRowToRowId[alert.rowId];

  const productName = useProductName(alert.productId);

  const affectedString = `${productName.data} [${period}]`;
  const disabled =
    output[index]?.status !== "Active" && output[index]?.status !== "Paused";

  const items = {
    select: (
      <td key="select">
        <Checkbox
          checked={alert._id ? selectedAlerts.has(alert._id) : false}
          onChange={(e) =>
            alert._id && handleSelect(alert._id, e.target.checked)
          }
          size="lg"
          sx={{
            "& .MuiCheckbox-checkbox": {
              width: "22px",
              height: "22px",
              borderRadius: "50%",
            },
            "& svg": {
              fontSize: "22px",
            },
          }}
        />
      </td>
    ),

    limit: (
      <td
        key="limit"
        style={{
          width: "88px",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Stack flexDirection={"row"} gap={1}>
          {output[index]?.limitRef?.name ? (
            <Stack
              flexDirection={"row"}
              gap={1}
              overflow="hidden"
              width="100%"
              justifyContent={"space-between"}
            >
              <Controller
                name={`alerts.${index}.limitRef`}
                control={control}
                render={({ field }) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30px",
                      border: (theme) =>
                        `1px solid ${theme.palette.neutral.outlinedBorder}`,
                      padding: "4px 8px",
                      paddingRight: "0",
                      borderRadius: "sm",
                      width: "fit-content",
                      gap: "8px",
                      backgroundColor: (theme) =>
                        theme.palette.background.surface,
                      maxWidth: "106px",
                    }}
                  >
                    <Tooltip
                      title={`${output[index]?.limitRef?.name}[${output[index]?.limitRef?.period}]`}
                      placement="top-start"
                      arrow
                    >
                      <Typography
                        fontSize="xs"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {output[index]?.limitRef?.name}[
                        {output[index]?.limitRef?.period}]
                      </Typography>
                    </Tooltip>

                    <Button
                      size="sm"
                      variant="plain"
                      color="neutral"
                      onClick={() => {
                        field.onChange(undefined);
                      }}
                      sx={{
                        minWidth: "auto",
                      }}
                      disabled={alert?.status === "Triggered"}
                    >
                      <FaTimes />
                    </Button>
                  </Box>
                )}
              />
            </Stack>
          ) : (
            <>
              <Input
                {...register(`alerts.${index}.limit`)}
                type="number"
                size="sm"
                disabled={alert?.status === "Triggered"}
                sx={{
                  height: "30px",
                  gap: "10px",
                  width: "49px",
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                }}
              />
              <Controller
                name={`alerts.${index}.limitRef`}
                control={control}
                render={({ field }) => (
                  <Button
                    size="sm"
                    variant="soft"
                    color="neutral"
                    sx={{
                      display: "flex",
                      minWidth: "49px",
                      height: "30px",
                      padding: "8px 16px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    disabled={alert?.status === "Triggered"}
                    onClick={() => {
                      if (
                        gridApi?.getGridOption("rowClass") ===
                        "ag-cell-selection"
                      ) {
                        resetLimitSelection();
                        return;
                      }

                      gridApi?.setGridOption("onCellClicked", (e) => {
                        resetLimitSelection();

                        const columnId = e.column.getColId();
                        const name = e.colDef.headerName;
                        const period = getNodePeriod(e.node);
                        const rowId = period
                          ? rowIdToRelativeRow[period]
                          : e.node.id;

                        field.onChange({
                          columnId,
                          rowId,
                          name,
                          period,
                        });
                      });

                      document.querySelectorAll(".ag-row").forEach((cell) => {
                        cell.classList.add("ag-cell-selection");
                      });
                    }}
                  >
                    <PointerIcon mode={mode} />
                  </Button>
                )}
              />
            </>
          )}
        </Stack>
      </td>
    ),
    note: (
      <td key="note" style={{ paddingRight: "20px" }}>
        <Input
          {...register(`alerts.${index}.note`)}
          size="sm"
          disabled={disabled}
        />
      </td>
    ),
    affected: (
      <td key="affected" width="90px">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "30px",
            border: (theme) =>
              `1px solid ${theme.palette.neutral.outlinedBorder}`,
            padding: "4px 8px",
            paddingRight: "0px",
            borderRadius: "sm",
            gap: "8px",
            backgroundColor: (theme) => theme.palette.background.surface,
          }}
        >
          <Tooltip title={affectedString} placement="top-start" arrow>
            <Typography
              fontSize="xs"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                paddingRight: "5px",
              }}
            >
              {affectedString}
            </Typography>
          </Tooltip>
        </Box>
      </td>
    ),
    status: (
      <td key="status">
        <Typography
          fontSize="xs"
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          overflow="hidden"
          textAlign="left"
          title={output[index]?.status}
        >
          {output[index]?.status}
        </Typography>
      </td>
    ),
    recurring: (
      <td key="recurring" style={{ width: "60px" }}>
        <Controller
          name={`alerts.${index}.recurring`}
          control={control}
          disabled={disabled}
          render={({ field }) => (
            <Checkbox
              disabled={disabled}
              checked={output[index].recurring}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
              size="lg"
              sx={{
                float: "left",
                paddingBottom: "3.5px",
                "& .MuiCheckbox-checkbox": {
                  width: "25px",
                  height: "25px",
                },
                "& svg": {
                  fontSize: "25px",
                },
              }}
            />
          )}
        />
      </td>
    ),

    sound: (
      <td key="sound" style={{ width: "60px" }}>
        <Controller
          name={`alerts.${index}.sound`}
          control={control}
          disabled={disabled}
          render={({ field }) => (
            <Checkbox
              disabled={disabled}
              checked={output[index].sound}
              onChange={(e) => {
                field.onChange(e.target.checked);

                if (e.target.checked) {
                  if (sessionStorage.getItem("soundPlayed") === "true") return;
                  new Audio(alertSounds.huthut).play();
                  sessionStorage.setItem("soundPlayed", "true");
                }
              }}
              size="lg"
              sx={{
                float: "left",
                paddingBottom: "3.5px",
                "& .MuiCheckbox-checkbox": {
                  width: "25px",
                  height: "25px",
                },
                "& svg": {
                  fontSize: "25px",
                },
              }}
            />
          )}
        />
      </td>
    ),
  };

  const tableItems = [
    items.select,
    items.affected,
    items.limit,
    items.note,
    items.status,
    items.recurring,
    items.sound,
  ];

  return <tr key={alert._id}>{tableItems}</tr>;
}

export function AlertsManager({
  alerts,
}: {
  alerts: ReturnType<typeof useAlerts>["alerts"];
}) {
  const pageId = useActivePageId();
  const pageAlerts = useAlertsByPageId();
  const updateAlert = useUpdateAlert();
  const deleteAlert = useDeleteAlert();
  const [gridApi] = useGrid();
  const resetLimitSelection = useResetLimitSelection();
  const [selectedAlerts, setSelectedAlerts] = useState<Set<string>>(new Set());
  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const rangeSelection = dockviewValue?.alerts?.selectedRange || [];
  const [alertsToShow, setAlertsToShow] = useState<"page" | "range">("range");
  const handleSelect = (_id: string, selected: boolean) => {
    setSelectedAlerts((prevSelected) => {
      const newSelected = new Set(prevSelected);
      selected ? newSelected.add(_id) : newSelected.delete(_id);
      return newSelected;
    });
  };
  const cells =
    rangeSelection
      .flatMap((range) => {
        return range.map((cell) => {
          const rowNode = gridApi?.getRowNode(cell.rowId);

          if (!rowNode) return;

          const value = gridApi?.getCellValue({
            rowNode,
            colKey: cell.columnId,
          });
          const period = getNodePeriod(rowNode);
          const rowId = period ? rowIdToRelativeRow[period] : rowNode?.id;

          if (rowId && cell.productId) {
            return {
              productId: cell.productId,
              columnId: cell.columnId,
              name:
                gridApi?.getColumn(cell.columnId)?.getColDef()?.headerName ||
                "",
              rowId,
              period,
              value,
            };
          }
        });
      })
      .filter(Boolean) || [];

  const form = useForm<{
    alerts: TAlertForm[];
  }>({
    defaultValues: {
      alerts: formatAlerts(gridApi, alerts, alertsToShow, cells),
    },
    resolver: zodResolver(alertValidation),
  });

  const { fields, replace, remove } = useFieldArray({
    control: form.control,
    name: "alerts",
    keyName: "_fieldId",
  });

  const formData = useWatch({
    control: form.control,
    name: "alerts",
  });

  const auth = useAuth0();
  const userId = auth.user?.sub;

  function filterAlertsBy(filter: "page" | "range") {
    setAlertsToShow(filter);
    form.reset({
      alerts: formatAlerts(gridApi, alerts, filter, cells),
    });
  }

  const currentPageProducts = usePageProducts(useActivePageId());

  const handleDeleteSelected = () => {
    const indicesToRemove = fields
      .map((field, index) => ({ id: field._id, index }))
      .filter(({ id }) => id && selectedAlerts.has(id))
      .map(({ index }) => index);

    indicesToRemove.sort((a, b) => b - a).forEach((index) => remove(index));

    setSelectedAlerts(new Set());
  };

  const handleGoToSelected = () => {
    if (selectedAlerts.size !== 1) return;

    const selectedId = Array.from(selectedAlerts)[0];
    const selectedAlert = fields.find((field) => field._id === selectedId);
    if (selectedAlert?.rowId && selectedAlert?.columnId && gridApi) {
      const row = rowStringToCode(relativeRowToRowId[selectedAlert.rowId]);
      const rowNode = gridApi.getRowNode(row);
      if (rowNode && rowNode.rowIndex !== null) {
        rowNode.setSelected(false);
        gridApi.ensureIndexVisible(rowNode.rowIndex);
        gridApi.ensureColumnVisible(selectedAlert.columnId);
        gridApi.setFocusedCell(rowNode.rowIndex, selectedAlert.columnId);
        gridApi.addCellRange({
          rowStartIndex: rowNode.rowIndex,
          rowEndIndex: rowNode.rowIndex,
          columnStart: selectedAlert.columnId,
          columnEnd: selectedAlert.columnId,
        });
      }
    }
  };

  const handleMoveUp = () => {
    if (selectedAlerts.size === 0) return;

    const selectedIndices = fields
      .map((field, index) =>
        field._id && selectedAlerts.has(field._id) ? index : -1,
      )
      .filter((index) => index !== -1);

    selectedIndices.sort((a, b) => a - b);

    const newFields = fields.map((field) => ({ ...field }));

    selectedIndices.forEach((index) => {
      const prevFieldId = newFields[index - 1]._id;
      if (index > 0 && prevFieldId && !selectedAlerts.has(prevFieldId)) {
        [newFields[index - 1], newFields[index]] = [
          newFields[index],
          newFields[index - 1],
        ];
      }
    });

    replace(newFields);
  };

  const handleMoveDown = () => {
    if (selectedAlerts.size === 0) return;

    const selectedIndices = fields
      .map((field, index) =>
        field._id && selectedAlerts.has(field._id) ? index : -1,
      )
      .filter((index) => index !== -1);

    selectedIndices.sort((a, b) => b - a);

    const newFields = fields.map((field) => ({ ...field }));

    selectedIndices.forEach((index) => {
      const nextFieldId = newFields[index + 1]._id;
      if (
        index < newFields.length - 1 &&
        nextFieldId &&
        !selectedAlerts.has(nextFieldId)
      ) {
        [newFields[index], newFields[index + 1]] = [
          newFields[index + 1],
          newFields[index],
        ];
      }
    });

    replace(newFields);
  };

  async function handleSave() {
    try {
      if (!pageId) throw new Error("pageId is not defined");
      if (!userId) throw new Error("userId is not defined");
      if (!gridApi) throw new Error("gridApi is not defined");
      if (!cells?.length) throw new Error("cells is not defined");

      const allExistingAlerts = pageAlerts.data || [];

      const cellIds = cells.map((cell) => `${cell.productId}-${cell.rowId}`);

      // Filter existing rules to only include ones in cellIds.
      const existingAlerts =
        alertsToShow === "page"
          ? allExistingAlerts
          : allExistingAlerts.filter((alert) =>
              cellIds.includes(`${alert.product_id}-${alert.row_id}`),
            );

      // Delete all alerts that are not in formData.
      if (!formData?.length) {
        const deletes = existingAlerts.map((alert) => {
          return deleteAlert.mutate(alert.id);
        });

        await Promise.all(deletes);
      }

      const formDataIds = formData.map((data) => data.id);

      // Check if any alerts have been removed (not in "formDataIds" but in existingAlerts).
      const alertsToDelete = existingAlerts
        .filter((alert) => {
          const alertId = alert.id;
          return !formDataIds.includes(alertId);
        })
        .map((alert) => alert.id);

      // Sort the existing alerts according to the order of "fields", and filter out the ones that are to be deleted.
      const sortedExistingAlerts = formDataIds
        .map((id) => existingAlerts.find((alert) => alert.id === id))
        .filter(Boolean)
        .filter((alert) => !alertsToDelete.includes(alert.id));

      // Update sortedExistingAlerts with data in "formData".
      const updatedAlerts = sortedExistingAlerts
        .map((existing) => {
          if (
            (existing.status !== "Active" && existing.status !== "Paused") ||
            (alertsToShow === "range" &&
              !cellIds.includes(`${existing.product_id}-${existing.row_id}`))
          )
            return;

          const alert = formData.find((data) => data.id === existing.id);

          if (alert) {
            const cell = cells.find(
              (cell) =>
                cell.productId === alert.productId &&
                cell.rowId === alert.rowId,
            );

            const productRefDetails = currentPageProducts.data?.find(
              (item) => item.id === alert?.limitRef?.columnId,
            );

            const limit = alert?.limitRef
              ? `${alert.limitRef.columnId}:${productRefDetails?.product_id}:${productRefDetails?.column_field_selector}:${alert.limitRef.rowId}`
              : parseNumber(alert.limit);

            if (!limit || !cell) return;

            const limitRefRowNode = alert?.limitRef
              ? gridApi.getRowNode(rowStringToCode(alert.limitRef?.period))
              : undefined;

            const limitRefCellValue =
              limitRefRowNode && alert.limitRef?.productId
                ? gridApi.getCellValue({
                    rowNode: limitRefRowNode,
                    colKey: alert.limitRef?.productId,
                  })
                : undefined;

            const product = currentPageProducts.data?.find(
              (product) => product.product_id === alert.productId,
            );

            if (!product) return;

            const fieldSelector =
              (alert.limitRef?.fieldSelector ||
                product.column_field_selector) ??
              undefined;

            const updated = {
              _id: existing._id,
              id: existing.id,
              limit: limit.toString(),
              note: alert.note,
              value_below_limit: limitRefCellValue
                ? cell.value < limitRefCellValue
                : cell.value < limit,
              status: alert.status,
              recurring: alert.recurring,
              sound: alert.sound,
              triggered_at: existing.triggered_at,
              product_id: existing.product_id,
              column_id: alert.columnId,
              row_id: existing.row_id || "",
              user_id: userId,
              page_id: pageId,
              field_selector: fieldSelector,
            } satisfies AlertFragmentFragment;

            if (alertChanged(existing, updated)) {
              return updated;
            }
          }
        })
        .filter(Boolean);

      const deletes = alertsToDelete.map((id) => {
        return deleteAlert.mutate(id);
      });

      const updates = updatedAlerts
        .map((alert) => {
          return updateAlert.mutate(alert);
        })
        .filter(Boolean);

      toast.promise(Promise.all([...deletes, ...updates]), {
        loading: "Saving alert(s)...",
        success: "Alert(s) saved",
        error: (error) => {
          if (error instanceof Error) {
            return error.message;
          }

          return "Failed to save alert(s)";
        },
      });
    } catch (e) {
      console.error("Failed to save alerts", e);
    }
  }

  requestNotificationPermission();

  return (
    <GridModalContainer
      panel="alerts"
      padding={0}
      body={
        <>
          <Box
            sx={{
              padding: "15px 10px",
            }}
          >
            <Typography textColor="text.primary" sx={{ paddingBottom: "2px" }}>
              Selected Column and Cell
            </Typography>
            <GridModalSelectionInfo selectedRange={rangeSelection} />
          </Box>

          <form onSubmit={form.handleSubmit(handleSave)}>
            <Box>
              <Select
                value={alertsToShow}
                onChange={(event, value) => {
                  if (value) setAlertsToShow(value as "page" | "range");
                  filterAlertsBy(value as "page" | "range");
                }}
                size="sm"
                sx={{
                  width: "100px",
                  marginLeft: "15px",
                }}
              >
                <Option value="page">Page</Option>
                <Option value="range">Range</Option>
              </Select>
            </Box>

            <Box pt={2}>
              <Stack gap={2} mb={4}>
                <Box
                  sx={(theme) => ({
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    borderTop: `1px solid ${theme.palette.divider}`,
                    maxHeight: "170px",
                    overflowY: "auto",
                  })}
                >
                  <Table sx={alertTableStyle} borderAxis="x">
                    <AlertHeader type="manage" />
                    <tbody>
                      {fields.map((alert, index) => (
                        <ManageAlertRow
                          key={alert._id}
                          form={form}
                          alert={alert}
                          index={index}
                          selectedAlerts={selectedAlerts}
                          handleSelect={handleSelect}
                        />
                      ))}
                    </tbody>
                  </Table>
                </Box>
                <Box sx={{ marginLeft: "15px" }}>
                  <Typography>Actions</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Tooltip title="Delete the selected rule(s)">
                      <Button
                        sx={{
                          display: "flex",
                          height: "30px",
                          minWidth: "49px",
                          padding: "4px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        size="sm"
                        variant="soft"
                        color="neutral"
                        onClick={handleDeleteSelected}
                        disabled={selectedAlerts.size === 0}
                      >
                        <FaTrash />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Go to the referenced grid cell">
                      <Button
                        sx={{
                          display: "flex",
                          height: "30px",
                          minWidth: "49px",
                          padding: "4px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        size="sm"
                        variant="soft"
                        color="neutral"
                        onClick={handleGoToSelected}
                        disabled={selectedAlerts.size !== 1}
                      >
                        Go To
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move the selected rule(s) higher in priority">
                      <Button
                        sx={{
                          display: "flex",
                          height: "30px",
                          minWidth: "49px",
                          padding: "4px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        size="sm"
                        variant="soft"
                        color="neutral"
                        onClick={handleMoveUp}
                        disabled={selectedAlerts.size === 0}
                      >
                        <PiArrowFatUpFill style={{ fontSize: "12px" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move the selected rule(s) lower in priority">
                      <Button
                        sx={{
                          display: "flex",
                          height: "30px",
                          minWidth: "49px",
                          padding: "4px 8px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        size="sm"
                        variant="soft"
                        color="neutral"
                        onClick={handleMoveDown}
                        disabled={selectedAlerts.size === 0}
                      >
                        <PiArrowFatDownFill style={{ fontSize: "12px" }} />
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </form>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel={"alerts"}
          onCancel={() => {
            resetLimitSelection();
          }}
          onSave={() => handleSave()}
        />
      }
    />
  );
}

export function ManageAlerts() {
  const { alerts, fetching } = useAlerts();

  if (fetching) return "Loading...";

  return <AlertsManager alerts={alerts} />;
}
