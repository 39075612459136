// backspace starts the editor on Windows
const KEY_BACKSPACE = "Backspace";

interface NumberCellEditorParams {
  decimalSeparator?: string;
  eventKey?: string;
  value?: number | null;
  onValueChange?: (value: number | null) => void;
  stopEditing?: (suppressNavigateAfterEdit?: boolean) => void;
}

// a numeric editor that supports comma or dot as decimal separator
export class NumberCellEditor {
  private eInput!: HTMLInputElement;
  private decimalSeparator?: string;
  private cancelBeforeStart = false;
  private params: NumberCellEditorParams = {};
  private value: number | null = null;

  init(params: NumberCellEditorParams): void {
    this.params = params;
    // get separator, only used for initial input
    this.decimalSeparator = params.decimalSeparator;

    // create the cell
    this.eInput = document.createElement("input");
    this.eInput.classList.add("numeric-input");

    // Initialize value based on event key or existing value
    if (params.eventKey === KEY_BACKSPACE) {
      this.eInput.value = "";
    } else if (this.isCharNumeric(params.eventKey)) {
      this.eInput.value = params.eventKey ?? "";
    } else if (params.eventKey === "." || params.eventKey === ",") {
      // Allow starting with decimal separator
      this.eInput.value = params.eventKey;
    } else if (params.value !== undefined && params.value !== null) {
      this.eInput.value = params.value.toString();
      if (this.decimalSeparator && this.decimalSeparator !== ".") {
        this.eInput.value = this.eInput.value.replace(
          ".",
          this.decimalSeparator,
        );
      }
    } else {
      this.eInput.value = "";
    }

    // Update value immediately to prevent quitting on decimal input
    this.updateValue(this.eInput.value);

    // Register input handlers
    this.eInput.addEventListener("input", (event) => {
      const input = event.target as HTMLInputElement;
      this.updateValue(input.value);
    });

    this.eInput.addEventListener("keydown", (event: KeyboardEvent) => {
      if (!event.key || event.key.length !== 1) return;

      // Allow navigation keys and backspace
      if (this.isNavigationKey(event) || this.isBackspace(event)) {
        event.stopPropagation();
        return;
      }

      // Check if the key is valid for a numeric input
      if (!this.isNumericKey(event) && event.key !== "," && event.key !== ".") {
        event.preventDefault();
        return;
      }

      // Check if the resulting value would be valid
      const selectionStart =
        (event.target as HTMLInputElement).selectionStart ?? 0;
      const selectionEnd = (event.target as HTMLInputElement).selectionEnd ?? 0;
      const currentValue = this.eInput.value;

      // Replace selected text with the key or insert at cursor position
      const newValue =
        currentValue.substring(0, selectionStart) +
        event.key +
        currentValue.substring(selectionEnd);

      // Validate new value without updating yet (we let input event handle the actual update)
      const normalizedValue = this.normalizeValue(newValue);
      if (normalizedValue === null) {
        event.preventDefault();
      }
    });

    // Prevent default behavior on these keys to allow editing in the cell
    this.eInput.addEventListener("keydown", (event: KeyboardEvent) => {
      const key = event.key;
      if (
        key === "ArrowLeft" ||
        key === "ArrowRight" ||
        key === "ArrowUp" ||
        key === "ArrowDown" ||
        key === "Home" ||
        key === "End" ||
        key === "PageUp" ||
        key === "PageDown"
      ) {
        event.stopPropagation();
      }
    });

    // only start edit if key pressed is a number, not a letter
    // we'll make exception for decimal separator
    const isNotANumber =
      params.eventKey &&
      params.eventKey.length === 1 &&
      "1234567890.,".indexOf(params.eventKey) < 0;

    this.cancelBeforeStart = !!isNotANumber;
  }

  private updateValue(inputValue: string): void {
    const normalizedValue = this.normalizeValue(inputValue);
    this.value = normalizedValue;

    if (this.params.onValueChange) {
      this.params.onValueChange(normalizedValue);
    }
  }

  isBackspace(event: KeyboardEvent): boolean {
    return event.key === KEY_BACKSPACE;
  }

  isNavigationKey(event: KeyboardEvent): boolean {
    return event.key === "ArrowLeft" || event.key === "ArrowRight";
  }

  getGui(): HTMLElement {
    return this.eInput;
  }

  afterGuiAttached(): void {
    this.eInput.focus();
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd(): boolean {
    return false; // Always accept the edit
  }

  getValue(): number | null {
    return this.value;
  }

  isPopup(): boolean {
    return false;
  }

  isCharNumeric(charStr?: string): boolean {
    return charStr !== undefined && !!/\d/.test(charStr);
  }

  isNumericKey(event: KeyboardEvent): boolean {
    const charStr = event.key;
    return this.isCharNumeric(charStr);
  }

  // transform a text with comma or dot into a numeric value
  normalizeValue(value: string): number | null {
    if (!value) return null;

    // Special case for just the decimal separator
    if (value === "." || value === ",") {
      return 0;
    }

    let processedValue = value;
    if (value.startsWith(".") || value.startsWith(",")) {
      processedValue = `0${value}`;
    }

    const commas = (processedValue.match(/,/g) || []).length;
    const dots = (processedValue.match(/\./g) || []).length;

    // Can't mix both separators
    if (commas > 0 && dots > 0) {
      return null;
    }

    // Too many separators
    if (commas > 1 || dots > 1) {
      return null;
    }

    if (!commas) {
      const number = Number.parseFloat(processedValue);
      if (!Number.isFinite(number)) return null;
      return number;
    }

    const number = Number.parseFloat(processedValue.replace(",", "."));
    if (!Number.isFinite(number)) return null;
    return number;
  }

  // replace back commas if there were any in original text
  normalizeValueToString(value: string): string {
    const number = this.normalizeValue(value);
    if (number === null) return value;

    const hasComma = value.indexOf(",") >= 0;
    if (!hasComma) return number.toString();

    return number.toString().replace(".", ",");
  }
}
