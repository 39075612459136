import {
  type QueryClient,
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef } from "react";

import type { PageProductsFragmentFragment } from "../../__generated__/gql/graphql";
import { useProductsByIds, type TProduct } from "../products";
import { useGraphQLClient } from "../../utils/graphql";
import { captureEvent } from "../../context/ph";
import { readFragment } from "../../graphql";
import { useActivePageId } from "./page";

import {
  PageProductsFragment,
  PageProductsInsert,
  PageProductUpdateMany,
  PageProductDelete,
  PageProductsSubscription,
  PageProductsQuery,
} from "./queries";

// Avoid updating the current page product data from the server if there are uncommited optimistic updates.
// Since the subscription exists separately from the update hook, we need to track this at a higher scope.
const PENDING_MUTATIONS: Set<string> = new Set();

export type TPageProduct = PageProductsFragmentFragment;

export const usePageProductsQueryOptions = (pageId?: string) => {
  const hasura = useGraphQLClient();
  return queryOptions({
    queryKey: ["page_products", pageId],
    enabled: !!pageId,
    queryFn: async () => {
      if (!pageId) return [];
      const res = await hasura.execute(PageProductsQuery, { pageId });
      return readFragment(PageProductsFragment, res.data?.page_products);
    },
  });
};

export const useSyncPageProducts = (pageId?: string) => {
  const qry = useQueryClient();
  const hasura = useGraphQLClient();
  useEffect(() => {
    if (!pageId) return;
    return hasura.subscribe(
      PageProductsSubscription,
      { pageId },
      {
        next: (res) => {
          if (PENDING_MUTATIONS.has(pageId)) return;
          const data = readFragment(
            PageProductsFragment,
            res.data?.page_products,
          );
          qry.setQueryData(["page_products", pageId], data);
        },
      },
    );
  }, [qry, hasura, pageId]);
};

export const usePageProducts = (pageId?: string) =>
  useQuery(usePageProductsQueryOptions(pageId));

export type TPageProductWithInfo = TProduct & TPageProduct;

export const getCachedPageProducts = (qry: QueryClient, pageId: string) =>
  qry.getQueryData<TPageProduct[]>(["page_products", pageId]);

export const usePageProductsWithInfo = (
  pageId?: string,
): TPageProductWithInfo[] => {
  const pageProducts = usePageProducts(pageId);
  const ids = (pageProducts.data ?? [])
    .map((p) => p.product_id)
    .filter(Boolean);
  const allProducts = useProductsByIds(ids);
  return useMemo(() => {
    return (
      (pageProducts.data ?? [])
        .map((x) => {
          const info = allProducts.data?.find((p) => p?.id === x.product_id);
          if (!info) return null;
          return { ...info, ...x };
        })
        .filter(Boolean) ?? []
    );
  }, [pageProducts.data, allProducts.data]);
};

export const usePageProduct = (pageId: string, productId: string) =>
  useQuery({
    ...usePageProductsQueryOptions(pageId),
    select: (data) => data?.find((p) => p.id === productId),
  });

export const useSelectedPageProducts = (pageId: string, ids: string[]) =>
  useQuery({
    ...usePageProductsQueryOptions(pageId),
    select: (data) => data?.filter((p) => ids.includes(p.id)),
  });

export const useUpdatePageProducts = () => {
  const qry = useQueryClient();
  const pageId = useActivePageId();
  const hasura = useGraphQLClient();

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const updates = useRef<Record<string, Partial<TPageProduct>>>({});

  const mutation = useMutation({
    networkMode: "always",
    mutationFn: () => {
      PENDING_MUTATIONS.delete(pageId);
      const all = Object.entries(updates.current).map(([id, v]) => ({
        where: { id: { _eq: id } },
        _set: v,
      }));
      updates.current = {};
      return hasura.execute(PageProductUpdateMany, { updates: all });
    },
    onSettled: (_, err) => {
      if (PENDING_MUTATIONS.size > 0 && !err) return;
      qry.invalidateQueries({ queryKey: ["page_products", pageId] });
    },
  });

  const optimistic = useCallback(
    async (values: Partial<TPageProduct> & { id: string }) => {
      qry.cancelQueries({ queryKey: ["page_products", pageId] });
      PENDING_MUTATIONS.add(pageId);
      updates.current[values.id] = { ...updates.current[values.id], ...values };
      qry.setQueryData<TPageProduct[]>(["page_products", pageId], (old) =>
        old?.map((x) => (x.id === values.id ? { ...x, ...values } : x)),
      );
      if (timeout.current !== null) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        if (PENDING_MUTATIONS.size === 0) return;
        mutation.mutate();
      }, 300);
    },
    [qry, pageId, mutation.mutate],
  );

  return Object.assign(mutation, { optimistic });
};

export const useInsertPageProducts = () => {
  const qry = useQueryClient();
  const pageId = useActivePageId();
  const hasura = useGraphQLClient();

  return useMutation({
    networkMode: "always",
    mutationFn: (objects: TPageProduct[]) => {
      return hasura.execute(PageProductsInsert, { objects });
    },
    onMutate: async (objects) => {
      qry.setQueryData<TPageProduct[]>(["page_products", pageId], (old) => [
        ...(old ?? []),
        ...objects,
      ]);
    },
    onSuccess: (_, products) => {
      products.forEach((p) =>
        captureEvent("inserted_column", {
          type: p.column_type,
          pageId: p.page_id,
          productId: p.product_id ?? undefined,
        }),
      );
    },
    onSettled: () =>
      qry.invalidateQueries({ queryKey: ["page_products", pageId] }),
  });
};

export const useDeletePageProducts = () => {
  const qry = useQueryClient();
  const pageId = useActivePageId();
  const hasura = useGraphQLClient();

  return useMutation({
    mutationFn: (id: string) => {
      return hasura.execute(PageProductDelete, { id });
    },
    onMutate: async (id) => {
      qry.setQueryData<TPageProduct[]>(["page_products", pageId], (old) => [
        ...(old ?? []).filter((x) => x.id !== id),
      ]);
    },
    onSettled: () =>
      qry.invalidateQueries({ queryKey: ["page_products", pageId] }),
  });
};
