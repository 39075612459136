import type {
  GetRowIdFunc,
  GridReadyEvent,
  CellClickedEvent,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { memo, useCallback, useRef } from "react";
import { AgGridReact, type AgGridReactProps } from "ag-grid-react";
import type { TData } from "../calculations-worker";
import { Box } from "@mui/joy";
import { useTimeSpreadsModal } from "./modals/TimeSpreads";
import {
  bottomBarHeight,
  bottomPanelHeight,
  gridSettingsTransitionMatch,
  rowHeight,
  topBarHeight,
} from "../globals";
import { useGridSettingsWidth } from "../grid-settings";

import {
  ColumnHeaderProduct,
  ColumnHeaderSelectMemo,
  ColumnHeaderStatus,
  ColumnMonthTimespread,
  ColumnMonths,
  TooltipProduct,
} from "./components";
import AddCurvesToPage from "./modals/AddCurvesToPage";
import { GridModalWrapper } from "./modals/GridModal";
import { isMobile } from "../../shared/hooks";
import { NumberCellEditor } from "./AgNumberCellEditor";
import { useProductColumnDefs } from "./column-defs";
import { useThemeMode } from "../../context/theme";
import { RegisterGridPlugins } from "./plugins";
import { useSetGrid } from "./stores";
import { useGridOptions } from "./useGridOptions";
import { useRangeSelectionChanged } from "./useRangeSelectionChange";
import { useOnCellEditRequest } from "./useOnCellEditRequest";
import { useOnColumnResized } from "./useOnColumnResized";
import { useOnColumnMoved } from "./useOnColumnMoved";
import { logger } from "@artis/logger";
import { useActivePageId, usePageProducts } from "../../data";
import { useGetProductsContextMenue } from "./context-menu";

export const MarketAgGrid = memo(
  ({ isBottomPanelOpen }: { isBottomPanelOpen: boolean }) => {
    const [modal, toggle] = useTimeSpreadsModal();
    const getContextMenuItems = useGetProductsContextMenue({
      isHeaderMenu: false,
    });
    const onRangeSelectionChanged = useRangeSelectionChanged();
    const onCellEditRequest = useOnCellEditRequest();
    const onColumnResized = useOnColumnResized();
    const products = usePageProducts(useActivePageId());
    const columnDefs = useProductColumnDefs();
    const onColumnMoved = useOnColumnMoved();
    const gridOptions = useGridOptions({
      onTimeSpreadClick: (params: CellClickedEvent) => {
        const cellElement = params.event?.target as HTMLElement;
        toggle({ anchor: cellElement, rowId: params.data?.id ?? "" });
      },
    });

    const isDark = useThemeMode() === "dark";
    const left = useGridSettingsWidth();

    const resolvedBottomPanelHeight = isBottomPanelOpen ? bottomPanelHeight : 0;
    const gridHeight = `calc(100dvh - ${topBarHeight}px - ${bottomBarHeight}px - ${resolvedBottomPanelHeight}px)`;

    if (!products.isLoading && (!products.data || products.data.length === 0))
      return <NoCurvesBox />;

    return (
      <Box>
        <div
          id="marketGrid"
          className={
            isDark
              ? "ag-theme-quartz-dark ag-theme-dark-custom"
              : "ag-theme-quartz ag-theme-light-custom"
          }
          style={{
            height: gridHeight,
            left,
            position: "relative",
            transition: gridSettingsTransitionMatch,
          }}
        >
          <Grid
            loading={products.isLoading}
            gridHeight={gridHeight}
            columnDefs={columnDefs}
            getContextMenuItems={getContextMenuItems}
            onCellEditRequest={onCellEditRequest}
            gridOptions={gridOptions}
            onColumnMoved={onColumnMoved}
            onColumnResized={onColumnResized}
            onRangeSelectionChanged={onRangeSelectionChanged}
          />
        </div>
        {modal}
      </Box>
    );
  },
);

const Grid = memo(
  ({ gridHeight, ...props }: { gridHeight: string } & AgGridReactProps) => {
    const gridRef = useRef<AgGridReact<TData> | null>(null);
    const setGrid = useSetGrid();

    const onGridPreDestroyed = useCallback(() => {
      setGrid(null);
    }, [setGrid]);

    const onGridReadyCallback = useCallback(
      (params: GridReadyEvent) => {
        logger.debug("Grid ready");
        setGrid(params.api);
      },
      [setGrid],
    );

    return (
      <>
        <RegisterGridPlugins />
        {!isMobile && <GridModalWrapper gridHeight={gridHeight} />}
        <AgGridReact<TData>
          defaultColDef={{ sortable: false }}
          ref={gridRef}
          tooltipShowDelay={500}
          getRowId={getRowId}
          scrollbarWidth={8}
          readOnlyEdit
          rowHeight={rowHeight}
          onGridReady={onGridReadyCallback}
          onGridPreDestroyed={onGridPreDestroyed}
          enableCharts
          suppressDragLeaveHidesColumns
          suppressColumnMoveAnimation
          suppressCsvExport
          suppressHeaderFocus
          suppressModelUpdateAfterUpdateTransaction
          animateRows={false}
          enableFillHandle
          enterNavigatesVertically
          enterNavigatesVerticallyAfterEdit
          cellSelection
          columnMenu={"new"}
          suppressMenuHide={true}
          suppressRowHoverHighlight
          suppressRowVirtualisation={
            import.meta.env.VITE_CONTEXT !== "production"
          }
          components={gridCustomComponents}
          {...props}
        />
      </>
    );
  },
);

const gridCustomComponents = {
  agColumnHeaderProducts: ColumnHeaderProduct,
  agColumnHeaderDropdown: ColumnHeaderSelectMemo,
  agColumnHeaderStatus: ColumnHeaderStatus,
  agColumnMonths: ColumnMonths,
  agColumnMonthTimespread: ColumnMonthTimespread,
  agTooltipProduct: TooltipProduct,
  agNumberCellEditor: NumberCellEditor,
};

function NoCurvesBox() {
  return (
    <div
      className="flex justify-center items-center"
      style={{
        height: `calc(100dvh - ${topBarHeight}px - ${bottomBarHeight}px)`,
      }}
    >
      <AddCurvesToPage allowCancel={false} />
    </div>
  );
}

const getRowId: GetRowIdFunc = (params) => params.data.id;
