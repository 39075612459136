import { useUpdatePageProducts } from "../../../data";
import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsColumnType = (): GetContextMenuSection => {
  const update = useUpdatePageProducts();

  return (ctx) => {
    if (!ctx.isShadowCurve) return [];
    return [
      {
        name: "Column Type",
        icon: getIcon("type"),
        subMenu: [
          {
            name: "Month",
            action: () =>
              update.optimistic({ id: ctx.columnId, column_type: "month" }),
          },
          {
            name: "Time Spread",
            action: () =>
              update.optimistic({
                id: ctx.columnId,
                column_type: "month-timespread",
              }),
          },
          {
            name: "Blank",
            action: () =>
              update.optimistic({ id: ctx.columnId, column_type: "blank" }),
          },
        ],
      },
    ];
  };
};
