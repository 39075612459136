import type { CellKeyDownEvent, Column } from "ag-grid-community";
import { isNullish } from "remeda";

import {
  useOptionalActivePageId,
  usePageProducts,
  useGridSettingsIncrementMap,
  useGridSettingsStatusMap,
} from "../../../data";
import { defaultSelector } from "../copyRangeSelectionHelpers";
import { store } from "../../modal";
import { monthCodeToMonthString, monthCodeToOffset } from "../periodHelpers";
import {
  getCalcWorker,
  type TOptimisticCell,
  type TGridDataColumn,
  type TGridDataRowId,
} from "../../calculations-worker";
import {
  getCurrentCellValueById,
  getProcessedSelectedCellsByRange,
  type TRangeCell,
} from "../../../tableUtils";
import { parseNumber } from "../../numbers";
import { getManualInstrumentType } from "../statuses/statusLogic";
import { defaultColumnSettings } from "../column-defs";
import { colParams } from "../../utils";
import { artisTypeSchema } from "./utils";
import { undoRedoAtom } from "../undo-redo";

export const useUpdateCellByValueAndOperation = () => {
  const incrementMap = useGridSettingsIncrementMap();
  const statusMap = useGridSettingsStatusMap();
  const pageProducts = usePageProducts(useOptionalActivePageId());

  return async function updateCellByValueAndOperation({
    params,
    operation,
    clearSelection,
    reselect,
  }: {
    params: CellKeyDownEvent;
    operation: "+" | "-";
    clearSelection?: boolean;
    reselect?: {
      index: number;
      column: Column;
    };
  }) {
    const api = params.api;

    const cells = getProcessedSelectedCellsByRange({ api }).flat();

    if (clearSelection) {
      api.clearRangeSelection();
    }

    if (reselect) {
      api.addCellRange({
        columns: [reselect.column],
        rowStartIndex: reselect.index,
        rowEndIndex: reselect.index,
      });
    }

    if (!cells?.length) return;

    const sharedCells = cells
      .reduce((acc, cell) => {
        const columnParams = colParams(api.getColumn(cell.columnId));
        const shared = columnParams?.sharedCellOffsets?.includes(
          cell.rowIndex?.toString(),
        );
        const value = getCurrentCellValueById(cell.columnId, cell.rowId, api);
        if (
          shared &&
          !isNullish(value) &&
          !acc.find(
            (c) => c.rowId === cell.rowId && c.columnId === cell.columnId,
          )
        ) {
          acc.push(cell);
        }
        return acc;
      }, [] as TRangeCell[])
      .sort((a, b) => a.rowIndex - b.rowIndex);

    const gridCells = sharedCells.reduce(
      (
        acc: {
          rowIds: TGridDataRowId[];
          columns: TGridDataColumn[];
          gridIds: string[];
        },
        selectedCell,
      ) => {
        const columnId = selectedCell.columnId;
        const productId = selectedCell.productId;
        const monthCode = selectedCell.rowId;

        if (!productId) return acc;

        const product = pageProducts.data?.find(
          (p) => p.product_id === productId,
        );

        if (!product) return acc;

        const status = statusMap?.[productId];

        const rowIdx = monthCode && monthCodeToOffset(monthCode);
        const rowId =
          !isNullish(rowIdx) &&
          rowIdx !== "" &&
          (["mth", monthCode, rowIdx] satisfies TGridDataRowId);

        if (!rowId || !status) return acc;

        const columnParams = colParams(api.getColumn(columnId));

        const artisType = artisTypeSchema.Values.customer_curve;

        const column = {
          columnId,
          productId,
          artisType,
          hasSharedCell: true,
          eodId: null,
          selector: product.column_field_selector || defaultSelector(artisType),
          status: status,
          isPermissioned: true,
        } satisfies TGridDataColumn;

        acc.rowIds.push(rowId);
        acc.columns.push(column);
        acc.gridIds.push(columnParams?.gridId || "");
        return acc;
      },
      { rowIds: [], columns: [], gridIds: [] },
    );

    const gridData = await getCalcWorker().getGridData({
      columns: gridCells.columns,
      rowIds: gridCells.rowIds,
    });

    if (!gridData?.length) return;

    const edits = gridData
      .map((data, idx) => {
        const selectedCell = sharedCells[idx];
        const columnIncrement =
          incrementMap?.[selectedCell.columnId] ||
          defaultColumnSettings.increment;
        const monthCode = selectedCell.rowId;
        const gridId = gridCells.gridIds[idx];
        const cellValue = data?.[gridId]?.Ok;

        const currentValue = typeof cellValue === "number" ? cellValue : null;
        if (currentValue === null) return;

        const newValue =
          operation === "+"
            ? currentValue + columnIncrement
            : currentValue - columnIncrement;

        const offset = selectedCell.rowIndex;
        const monthString = monthCodeToMonthString(monthCode);

        const offsetIsNotSet = offset !== 0 && !offset;

        if (offsetIsNotSet || newValue === currentValue) {
          console.warn("Invalid cell edit request", {
            offset,
            oldValue: currentValue,
          });
          return;
        }
        const value = parseNumber(newValue);

        if (!selectedCell.productId) return;

        const status = statusMap?.[selectedCell.productId];

        const storageType = getManualInstrumentType(status);

        if (!storageType) return;

        return {
          product: selectedCell.productId,
          offset,
          field: "value",
          result: value ?? undefined,
          currentValue,
          storageType,
          month: monthString,
          rowId: monthCode,
        } satisfies TOptimisticCell & { currentValue: number | undefined };
      })
      .filter(Boolean);

    store.set(undoRedoAtom, {
      undo: edits.map((edit) => ({
        ...edit,
        pasted: false,
        result: edit.currentValue,
      })),
      redo: edits.map((edit) => ({ ...edit, pasted: false })),
      time: new Date().getTime(),
    });

    await getCalcWorker().optimisticCellEdit(edits);
  };
};
