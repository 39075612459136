import type { EodEntriesSubscription } from "../../../__generated__/gql/graphql";
import { getOffset, monthStringToCode } from "../../market-grid/periodHelpers";
import type { ProductForCalcs, TCellValue } from "./types";

type TEodStoreKey = {
  productId: string;
  rowId: string;
  evaluationDate: string;
};

export const eod = {
  cells: new Map<string, TCellValue>(),
  cellsCalc: new Map<string, ProductForCalcs>(),

  getValue: (k: TEodStoreKey) => {
    return eod.cells.get(`${k.productId}:${k.rowId}:${k.evaluationDate}`);
  },
  getCalcValue: (k: TEodStoreKey) => {
    return eod.cellsCalc.get(`${k.productId}:${k.rowId}:${k.evaluationDate}`);
  },
  setValue: (k: TEodStoreKey, v: ProductForCalcs) => {
    eod.cells.set(`${k.productId}:${k.rowId}:${k.evaluationDate}`, v.result);
    eod.cellsCalc.set(`${k.productId}:${k.rowId}:${k.evaluationDate}`, v);
  },
  clear: () => {
    eod.cellsCalc.clear();
    eod.cells.clear();
  },
  resetCells: (entries: EodEntriesSubscription["eod_entry"]) => {
    for (const od of entries || []) {
      const rowId = monthStringToCode(od.month);
      try {
        const offset = getOffset(od.month);

        if (!rowId || !offset) continue;

        const key = {
          productId: od.product,
          rowId,
          evaluationDate: od.evaluation_date,
        };

        eod.setValue(key, {
          product: od.product,
          offset,
          field: "value",
          result: od.value,
        });
      } catch (e) {
        console.debug("skipping invalid eod");
      }
    }
  },
};
