import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { logger } from "@artis/logger";

import type { ConditionalFormattingRulesFragmentFragment } from "../__generated__/gql/graphql";
import { graphql, readFragment } from "../graphql";
import { useGraphQLClient } from "../utils/graphql";

export type ConditionalFormattingRules =
  ConditionalFormattingRulesFragmentFragment;

export const useDeleteConditionalFormattingRules = () => {
  const qry = useQueryClient();
  const graphql = useGraphQLClient();
  return useMutation({
    mutationKey: ["conditionalFormattingRules", "delete"],
    mutationFn: (id: string) =>
      graphql.execute(DeleteConditionalFormattingRules, { id }),
    onSettled: () => {
      qry.invalidateQueries({ queryKey: ["conditionalFormattingRules"] });
    },
    onError: (e) => {
      logger.error(e);
    },
  });
};

export const useUpsertConditionalFormattingRules = () => {
  const qry = useQueryClient();
  const graphql = useGraphQLClient();
  return useMutation({
    networkMode: "always",
    mutationKey: ["conditionalFormattingRules"],
    mutationFn: async (arg: {
      id: string;
      rules: ConditionalFormattingRules[];
    }) =>
      graphql.execute(UpsertConditionalFormattingRules, {
        objects: arg.rules.map((r) => {
          return {
            id: r.id,
            _id: r._id,
            bg_color: r.bg_color,
            bold_text: r.bold_text,
            column_id: r.column_id,
            invert_text_color: r.invert_text_color,
            limit: r.limit,
            note: r.note,
            page_id: r.page_id,
            priority_idx: r.priority_idx,
            row_id: r.row_id,
            rule: r.rule,
            stop_if_true: r.stop_if_true,
            user_id: r.user_id,
          };
        }),
      }),
    onSettled: () => {
      qry.invalidateQueries({ queryKey: ["conditionalFormattingRules"] });
    },
    onError: (e) => {
      logger.error(e);
    },
  });
};

export const useQueryConditionalFormattingRules = (
  pageId: string,
  userId: string,
) => {
  const graphql = useGraphQLClient();
  return useQuery({
    queryKey: ["conditionalFormattingRules", pageId],
    queryFn: async () => {
      const res = await graphql.execute(ConditionalFormattingRulesByPageId, {
        pageId,
        userId,
      });
      const rules = readFragment(
        ConditionalFormattingRulesFragment,
        res.data?.conditional_formatting_rules,
      );
      return rules;
    },
  });
};

export const useQueryConditionalFormattingRulesGrouped = (
  pageId: string,
  userId: string,
) => {
  const graphql = useGraphQLClient();
  return useQuery({
    queryKey: ["conditionalFormattingRules", pageId],
    queryFn: async () => {
      const res = await graphql.execute(ConditionalFormattingRulesByPageId, {
        pageId,
        userId,
      });
      const rules = readFragment(
        ConditionalFormattingRulesFragment,
        res.data?.conditional_formatting_rules,
      );
      return rules ?? [];
    },
    select: groupRules,
  });
};

// Create an object where the key is `columnId-rowId` and the value is an array of rules based on the ones that have the same columnId and rowId.
const groupRules = (rules: ConditionalFormattingRules[]) =>
  rules.reduce((acc, rule) => {
    const key = `${rule.column_id}-${rule.row_id}`;
    if (!acc[key]) return Object.assign(acc, { [key]: [rule] });
    return Object.assign(acc, { [key]: [...acc[key], rule] });
  }, {} as TConditionalFormattingRulesGrouped);

export type TConditionalFormattingRulesGrouped = Record<
  string,
  ConditionalFormattingRules[]
>;

export const ConditionalFormattingRulesFragment = graphql(`
    fragment ConditionalFormattingRulesFragment on conditional_formatting_rules {
      id
      _id
      bg_color
      bold_text
      column_id
      invert_text_color
      limit
      note
      page_id
      priority_idx
      row_id
      rule
      stop_if_true
      user_id
    }
  `);

export const ConditionalFormattingRulesByPageId = graphql(`
    query ConditionalFormattingRulesByPageId($pageId: String!, $userId: String!) {
      conditional_formatting_rules(
        where: { page_id: { _eq: $pageId }, user_id: { _eq: $userId } }
        order_by: { priority_idx: asc }
      ) {
        ...ConditionalFormattingRulesFragment
      }
    }
  `);

export const DeleteConditionalFormattingRules = graphql(`
    mutation DeleteConditionalFormattingRules($id: String!) {
      delete_conditional_formatting_rules_by_pk(id: $id) {
        id
      }
    }
  `);

export const UpsertConditionalFormattingRules = graphql(`
    mutation UpsertConditionalFormattingRules(
      $objects: [conditional_formatting_rules_insert_input!]!
    ) {
      insert_conditional_formatting_rules(
        objects: $objects
        on_conflict: {
          constraint: conditional_formatting_rules_pkey
          update_columns: [
            bg_color
            bold_text
            column_id
            invert_text_color
            limit
            note
            page_id
            priority_idx
            row_id
            rule
            stop_if_true
            user_id
          ]
        }
      ) {
        returning {
          ...ConditionalFormattingRulesFragment
        }
      }
    }
  `);
