import { useIntervalEffect } from "@react-hookz/web";
import { useAtomValue } from "jotai";

import {
  useAdhocSpreads,
  useActivePageId,
  useGridSettingsStatusMap,
  usePageProductsWithInfo,
} from "../../../../data";
export { addInfoToPageProduct } from "./helpers";
import { gridApiAtom } from "../../stores";
import { updateGrid } from "./helpers";

export const useToggleGridUpdate = () => {
  const pageId = useActivePageId();
  const pageProducts = usePageProductsWithInfo(pageId);
  const spreads = useAdhocSpreads(pageId);
  const statusMap = useGridSettingsStatusMap();
  const api = useAtomValue(gridApiAtom);
  return () => {
    if (!api) return console.warn("grid api not ready");
    updateGrid(spreads.data ?? {}, api, pageProducts, statusMap);
  };
};

export const useGridUpdateInterval = () => {
  const pageProducts = usePageProductsWithInfo(useActivePageId());
  const toggleGridUpdate = useToggleGridUpdate();
  useIntervalEffect(
    toggleGridUpdate,
    pageProducts.length > 0 ? 250 : undefined,
  );
};
