import {
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";

const DialogContext = createContext<
  React.Dispatch<
    React.SetStateAction<Record<string, React.ReactElement | undefined>>
  >
>(() => null);

export const DialogProvider = (p: { children: React.ReactNode }) => {
  const [dialogs, setDialogs] = useState<
    Record<string, React.ReactElement | undefined>
  >({});

  return (
    <DialogContext.Provider value={setDialogs}>
      {p.children}
      {Object.keys(dialogs).map((x) => {
        const node = dialogs[x];
        if (!node) return null;
        return cloneElement(node, { key: x });
      })}
    </DialogContext.Provider>
  );
};

export const useDialog = <P,>(
  elem: (p: {
    arg?: P;
    open: boolean;
    close: () => void;
  }) => React.ReactElement,
) => {
  const [open, setOpen] = useState<[P] | null>(null);
  const register = useContext(DialogContext);
  if (!register) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  const id = useId();

  const element = useMemo(() => {
    return elem({
      arg: open ? open[0] : undefined,
      open: !!open,
      close: () => setOpen(null),
    });
  }, [open, elem]);

  useEffect(() => {
    register((x) => ({ ...x, [id]: element }));
    return () => register((x) => ({ ...x, [id]: undefined }));
  }, [register, id, element]);

  return (arg: P) => setOpen([arg]);
};
