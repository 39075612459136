import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState, type ReactNode } from "react";
import type React from "react";

import {
  ActiveSessionSub,
  DeleteSession,
  LivenessProbeMutation,
  StartSessionMut,
} from "./queries";
import { LogoutProvider, useLogout, useSessionState, useUserId } from "../auth";
import { useGraphQLClient, useQuerySub } from "../../utils/graphql";
import { isMobile } from "../../shared/hooks";
import { client } from "../../triplit";
import { SessionView } from "./dialog";

export const SessionProvider = (p: { children: React.ReactNode }) => {
  useLivenessProbe();
  const isMultiSessionUser = useIsMultiSessionUser();
  if (isMultiSessionUser.isLoading || isMultiSessionUser.data) {
    return <>{p.children}</>;
  }
  return <MultiSessionGuard>{p.children}</MultiSessionGuard>;
};

const MultiSessionGuard = (p: { children: ReactNode }) => {
  const [loggingOut, setLoggingOut] = useState(false);
  const startSession = useHandlStartSession();
  const endSession = useHandleEndSession();
  const session = useSessionState();
  const userId = useUserId();
  const logout = useLogout();

  const activeSub = useQuerySub(ActiveSessionSub, {
    queryKey: ["activeSession", userId],
    variables: { user: userId, mobile: isMobile },
  });

  const active = activeSub?.data?.session_by_pk;

  const notActiveUser =
    active?.id &&
    session.data?.session_id &&
    active?.id !== session.data?.session_id &&
    active?.is_mobile === isMobile;

  // Logout the user if they are not the active session and their current
  // session is older than the active session
  // NOTE: last_seen is the time that the session token was created not actually the last time the user was active
  useEffect(() => {
    if (!notActiveUser) return;
    if (!session.data?.session_start || !active.session_start) return;
    if (session.data.session_start > active.session_start) return;
    logout();
  }, [notActiveUser, logout, session.data, active]);

  // Start the session if no active session exists
  useEffect(() => {
    if (
      loggingOut ||
      activeSub.data?.session_by_pk ||
      activeSub.isLoading ||
      !session.data?.session_id
    ) {
      return;
    }
    startSession.mutate({
      session_id: session.data.session_id,
      session_start: session.data.session_start,
    });
  }, [
    loggingOut,
    startSession.mutate,
    activeSub.data,
    activeSub.isLoading,
    session.data,
  ]);

  const onLogout = useCallback(async () => {
    setLoggingOut(true);
    await endSession.mutateAsync().catch(console.error);
    return logout();
  }, [logout, endSession.mutateAsync]);

  if (!session.data?.session_id) return null;

  if (notActiveUser) {
    return (
      <SessionView
        userId={userId}
        activeSession={active}
        currentSession={session.data}
        onLogout={logout}
        onStartSession={() =>
          startSession.mutate({
            session_id: session.data?.session_id,
            session_start: session.data.session_start,
          })
        }
      />
    );
  }

  return <LogoutProvider value={onLogout}>{p.children}</LogoutProvider>;
};

const useHandlStartSession = () => {
  const graphql = useGraphQLClient();
  const userId = useUserId();
  return useMutation({
    mutationFn: async (p: { session_id: string; session_start: string }) => {
      await graphql.execute(StartSessionMut, {
        user: userId,
        mobile: isMobile,
        last_seen: new Date().toISOString(),
        ...p,
      });
    },
  });
};

const useHandleEndSession = () => {
  const graphql = useGraphQLClient();
  const userId = useUserId();
  return useMutation({
    mutationFn: () =>
      graphql.execute(DeleteSession, { user: userId, mobile: isMobile }),
  });
};

const useIsMultiSessionUser = () => {
  const userId = useUserId();
  return useQuery({
    queryKey: ["userGroups", "multisession", userId],
    initialData: false,
    queryFn: async () =>
      client
        .fetchOne(
          client
            .query("userGroups")
            .id("multisession")
            .select(["users"])
            .where(["users", "has", userId])
            .build(),
        )
        .then((x) => !!x),
  });
};

function useLivenessProbe() {
  const userId = useUserId();
  const graphql = useGraphQLClient();
  return useQuery({
    networkMode: "online",
    queryKey: ["livenessProbe", userId],
    refetchInterval: 30_000,
    queryFn: () =>
      graphql.execute(LivenessProbeMutation, {
        user: userId,
        mobile: isMobile,
      }),
  });
}
