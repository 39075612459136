import { Box, Input, Slider, Typography } from "@mui/joy";
import { ModalButtons } from "./Buttons";
import { GridModalContainer } from "./GridModal";
import { useCallback, useState } from "react";
import {
  columnWidth as defaultColumnWidth,
  maxColumnWidth,
  minColumnWidth,
} from "../../globals";
import {
  useActivePageId,
  usePageProducts,
  useUpdatePageProducts,
} from "../../../data";

export function ColumnWidthSetting({
  columnWidth,
  setColumnWidth,
}: {
  columnWidth: number;
  setColumnWidth: (value: number) => void;
}) {
  return (
    <Box mt={1} overflow={"hidden"}>
      <Box
        mt={2}
        mb={2}
        justifyContent={"center"}
        alignItems={"center"}
        display="flex"
        gap={2}
      >
        <Slider
          min={minColumnWidth}
          max={maxColumnWidth}
          step={1}
          valueLabelDisplay="on"
          value={columnWidth}
          onChange={(_, value) => setColumnWidth(Number(value))}
          sx={{
            "--webapp-palette-background-tooltip":
              "var(--webapp-palette-neutral-900)",
            maxWidth: "80%",
            ml: 2,
            color: "black",
          }}
        />
        <Input
          type="number"
          value={columnWidth}
          onChange={(e) => setColumnWidth(Number(e.target.value))}
          sx={{
            width: 120,
            mr: 2,
          }}
        />
      </Box>
    </Box>
  );
}

export function SetAllColumnWidths() {
  const update = useUpdatePageProducts();
  const products = usePageProducts(useActivePageId());
  const [columnWidth, setColumnWidth] = useState(
    products.data?.[0].column_width ?? defaultColumnWidth,
  );

  const handleSave = useCallback(async () => {
    const width = Math.min(
      maxColumnWidth,
      Math.max(minColumnWidth, columnWidth),
    );
    const changes = (products.data ?? [])
      .map((product) => {
        if (product.column_type !== "product") return null;
        return update.optimistic({
          id: product.id,
          column_width: width,
        });
      })
      .filter(Boolean);

    await Promise.all(changes);
  }, [columnWidth, products, update.optimistic]);

  return (
    <GridModalContainer
      panel="set-all-column-widths"
      body={
        <>
          <Typography>Set the width of every column on this page</Typography>
          <ColumnWidthSetting
            columnWidth={columnWidth}
            setColumnWidth={setColumnWidth}
          />
        </>
      }
      buttons={
        <ModalButtons onSave={handleSave} parentPanel="set-all-column-widths" />
      }
    />
  );
}
