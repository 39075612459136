import type { IHeaderParams } from "ag-grid-community";

import { getBackgroundForStatus, getForegroundForStatus } from "../cell-style";
import { LastEditedInfoHeader } from "./LastEditedInfoHeader";
import { colParams } from "../../utils";
import { EodHeader } from "./EodHeader";

export function ColumnHeaderStatus(
  params: IHeaderParams & { headerColor: string; status: string },
) {
  const colInfo = colParams(params.column);
  const status = params.status;
  const eodCurve = colInfo?.artisType === "eod" || status === "eod";

  const isGlobal = status.includes("-");

  const statusName = eodCurve ? "eod" : (params.status ?? (status || "listen"));
  const backgroundColor = getBackgroundForStatus(statusName);
  const color = getForegroundForStatus(statusName);

  return (
    <div
      className="column-header flex w-full items-center justify-center text-center"
      style={{ backgroundColor, color }}
    >
      {eodCurve ? (
        <EodHeader />
      ) : status === "listen" || status === "hybrid_broadcast" || isGlobal ? (
        <LastEditedInfoHeader colInfo={colInfo} status={status} />
      ) : null}
    </div>
  );
}
