import type { GetContextMenuSection } from "./helpers";
import type { GridApi, IMenuActionParams } from "ag-grid-community";
import { requestDockviewModal } from "../modals/modalComponents";
import {
  useActivePageId,
  usePageProducts,
  useUpdatePageProducts,
} from "../../../data";
import { columnWidth } from "../../globals";
import { colParams } from "../../utils";
import { getIcon } from "./icons";
import { openFormattingDockview } from "./useDockviewModal";

export const useGetMenuItemsColumn = (): GetContextMenuSection => {
  const update = useUpdatePageProducts();
  const pageId = useActivePageId();
  const products = usePageProducts(pageId);

  const setColumnWidthsToCurrent = async (
    api: GridApi,
    actualWidth?: number,
  ) => {
    const currentWidth = actualWidth || columnWidth;

    const changes =
      api
        ?.getColumns()
        ?.map((column) => {
          const col = colParams(column);
          const gridId = col?.gridId;
          if (!gridId || !col?.artisType) return null;
          const current = products.data?.find((x) => x.id === gridId);
          if (!current) return null;
          return update.optimistic({
            id: gridId,
            column_width: currentWidth,
          });
        })
        .filter(Boolean) || [];

    await Promise.all(changes);
  };

  return (ctx) => {
    const items = [
      {
        name: "Column",
        icon: getIcon("width"),
        subMenu: [
          {
            name: "Set All Column Widths",
            action: () => {
              requestDockviewModal({
                parentPanel: "set-all-column-widths",
                params: {
                  width: 400,
                },
              });
            },
          },
          {
            name: "Set Column Widths to Current",
            action: (e: IMenuActionParams) =>
              setColumnWidthsToCurrent(e.api, ctx.columnWidth),
          },
        ],
      },
    ];

    if (ctx.isProduct) {
      const product = products.data?.find((x) => x.id === ctx.columnId);
      if (product) {
        items[0].subMenu.push({
          name: "Column Settings",
          action: () => {
            openFormattingDockview({
              pageId,
              type: "column",
              currentTab: "settings",
              columnId: ctx.columnId,
              selectedRange: ctx.selectedRange,
            });
          },
        });
      }
    }

    return items;
  };
};
