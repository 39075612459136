import { queryOptions, useQuery } from "@tanstack/react-query";

import type { ProductInfoFragmentFragment } from "../../__generated__/gql/graphql";
import { useUserId } from "../../context/auth";

import {
  globalPackagesByProductsIds,
  globalProductsByProductsIds,
  globalProductsForLiveCharts,
} from "./queries";
import { getProductConfigs, getProductInfo } from "../cache";
import { useGraphQLClient } from "../../utils/graphql";

export type TProduct = ProductInfoFragmentFragment;

// PRODUCT INFO
export const productsInfoQueryOptions = () =>
  queryOptions({
    queryKey: ["products"],
    queryFn: () => getProductInfo().then((x) => x ?? null),
  });

export const useAllProducts = () =>
  useQuery({
    ...productsInfoQueryOptions(),
    select: (data) => Object.values(data ?? {}),
  });

export const useProductById = (id: string) =>
  useQuery({
    ...productsInfoQueryOptions(),
    select: (data) => data?.[id],
  });

export const useProductsByIds = (ids: string[]) =>
  useQuery({
    ...productsInfoQueryOptions(),
    select: (data) => ids.map((x) => data?.[x]).filter(Boolean),
  });

export const useProductName = (id: string) =>
  useQuery({
    ...productsInfoQueryOptions(),
    select: (data) => data?.[id]?.name,
  });

export const useProductArtisType = (id: string) =>
  useQuery({
    ...productsInfoQueryOptions(),
    select: (data) => data?.[id]?.artis_type,
  });

// PRODUCT CONFIGS
export const productsConfigQueryOptions = () =>
  queryOptions({
    queryKey: ["configs"],
    queryFn: async () => getProductConfigs().then((x) => x ?? null),
  });

export const useProductConfigs = () => useQuery(productsConfigQueryOptions());
export const useProductConfigById = (id: string) =>
  useQuery({ ...productsConfigQueryOptions(), select: (data) => data?.[id] });

// GLOBAL PRODUCTS
export const useGlobalProducts = () => {
  const graphql = useGraphQLClient();
  const userId = useUserId();
  return useQuery({
    queryKey: ["global_product", "by-user", userId],
    queryFn: async () => {
      const res = await graphql.execute(globalProductsForLiveCharts, {
        folioUser: userId,
      });
      return res.data?.global_product;
    },
  });
};

export function useGlobalPackagesByProductIds(productIds: string[]) {
  const graphql = useGraphQLClient();
  return useQuery({
    queryKey: ["global_package", productIds],
    queryFn: async () => {
      const res = await graphql.execute(globalPackagesByProductsIds, {
        productIds: productIds,
      });
      return res.data?.global_package;
    },
  });
}

export function useGlobalProductsByProductIds(productIds: string[]) {
  const graphql = useGraphQLClient();
  return useQuery({
    queryKey: ["global_product", productIds],
    queryFn: async () => {
      const res = await graphql.execute(globalProductsByProductsIds, {
        productIds: productIds,
      });
      return res.data?.global_product;
    },
  });
}
