import { atom } from "jotai";
import type { TGridModalDockview } from "./modalComponents";

export const settingsModalAtom = atom(false);

export const emptyDockview = {} as TGridModalDockview;
export const gridModalDockviewAtom = atom<TGridModalDockview>(emptyDockview);
gridModalDockviewAtom.debugLabel = "gridModalDockviewAtom";

/** Register cancel handlers that get called when the model is closed with a cancel action */
export const modalCancelActions = {
  cancels: new Map<string, () => void>(),
  reset() {
    this.cancels.clear();
  },
  hasCancel(key: string) {
    return this.cancels.has(key);
  },
  addCancel(key: string, cancel: () => void) {
    this.cancels.set(key, cancel);
  },
  cancel() {
    for (const [_, cancel] of this.cancels) {
      cancel();
    }
    this.reset();
  },
};
