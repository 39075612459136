import { SideNavIconContainer } from "./SideNavIconContainer";

function Icon({
  selected,
}: {
  selected: boolean;
}) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: We have a custom tooltip
    <svg
      className={selected ? "nav-icon selected group" : "nav-icon group"}
      width="42"
      height="42"
      viewBox="0 -5 30 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5144 6.77588H3.5144V27.7759H5.5144V6.77588Z" fill="white" />
      <path
        d="M15.3376 0.224365H13.3376V19.2244H15.3376V0.224365Z"
        fill="white"
      />
      <path
        d="M25.3171 6.23706H23.3171V25.2371H25.3171V6.23706Z"
        fill="white"
      />
      <path
        d="M8.5144 12.7759H0.514404V22.7759H8.5144V12.7759Z"
        className="fill-white group-hover:fill-chart-pos"
      />
      <path
        d="M18.5144 3.77588H10.5144V12.7759H18.5144V3.77588Z"
        className="fill-white group-hover:fill-chart-pos"
      />
      <path
        d="M28.4856 12.5544H20.4856V20.5544H28.4856V12.5544Z"
        className="fill-white group-hover:fill-chart-neg"
      />
    </svg>
  );
}

export function LiveChartsIcon({
  selected = false,
}: {
  selected?: boolean;
}) {
  return (
    <SideNavIconContainer>
      <Icon selected={selected} />
    </SideNavIconContainer>
  );
}
