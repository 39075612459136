import { graphql } from "../../graphql";

export const PageFragment = graphql(`
  fragment PageFragment on pages {
    id
    idx
    name
    period_highlights
    updated_at
    user_id
    extra_header_height
    created_at
    column_highlights
    cell_highlights
    adhoc_spreads
  }
`);

export const PagesQuery = graphql(`
  query PagesQuery($userId: String!) {
    pages(where: { user_id: { _eq: $userId } }, order_by: { idx: asc }) {
      ...PageFragment
    }
  }
`);

export const PagesSubscription = graphql(`
  subscription PagesSubscription($userId: String!) {
    pages(where: { user_id: { _eq: $userId } }, order_by: { idx: asc }) {
      ...PageFragment
    }
  }
`);

export const PageUpdateMutation = graphql(`
  mutation Update_pages_by_pk($pageId: String!, $values: pages_set_input) {
    update_pages_by_pk(pk_columns: { id: $pageId }, _set: $values) {
      id
    }
  }
`);

export const PageInsertMutation = graphql(`
  mutation PageInsert($page: pages_insert_input!) {
    insert_pages_one(object: $page) {
      name
      idx
    }
  }
`);

export const PageDeleteMutation = graphql(`
  mutation PageDelete($id: String!) {
    delete_pages_by_pk(id: $id) {
      id
    }
  }
`);

export const PageProductsFragment = graphql(`
  fragment PageProductsFragment on page_products {
    id
    idx
    decimal_places
    column_width
    column_type
    column_field_selector
    page_id
    product_id
    thousands_separator
    user_id
  }
`);

export const PageProductsQuery = graphql(`
  query PageProductsQuery($pageId: String!) {
    page_products(
      where: { page_id: { _eq: $pageId } }
      order_by: { idx: asc }
    ) {
      ...PageProductsFragment
    }
  }
`);

export const PageProductsSubscription = graphql(`
  subscription PageProductsSubscription($pageId: String!) {
    page_products(
      where: { page_id: { _eq: $pageId } }
      order_by: { idx: asc }
    ) {
      ...PageProductsFragment
    }
  }
`);

export const PageProductUpdateMany = graphql(`
    mutation PageProductUpdateMany($updates: [page_products_updates!]!) {
      update_page_products_many(updates: $updates) {
        affected_rows
      }
    }
`);

export const PageProductsInsert = graphql(`
  mutation PageProductsInsert($objects: [page_products_insert_input!]!) {
    insert_page_products(objects: $objects) {
      affected_rows
    }
  }
`);

export const PageProductDelete = graphql(`
  mutation PageProductDelete($id: String!) {
    delete_page_products_by_pk(id: $id) {
      id
    }
  }
`);
