import { useEffect, useRef } from "react";
import {
  useQuery,
  useQueryClient,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query";
import type { TypedDocumentNode } from "@apollo/client";
import { useGraphQLClient } from "./context";

type QuerySubOptions<
  TData,
  TVariables extends Record<string, unknown>,
  TError = Error,
> = Omit<UseQueryOptions<TData, TError, TData>, "queryKey" | "queryFn"> & {
  // Required options
  queryKey: unknown[];
  variables: TVariables;
  // Optional subscription-specific options
  subscriptionOptions?: {
    enabled?: boolean;
    onSubscriptionData?: (data: TData) => void;
    onSubscriptionError?: (error: Error) => void;
  };
};

/**
 * A hook that combines React Query's useQuery with Hasura subscriptions.
 * It will:
 * 1. Set up a regular query for initial data fetch
 * 2. Set up a subscription that updates the query cache
 * 3. Handle all cleanup and error cases
 *
 * @example
 * ```ts
 * const { data, isLoading } = useQuerySub({
 *   queryKey: ['users', userId],
 *   variables: { userId },
 *   subscriptionOptions: {
 *     onSubscriptionData: (data) => console.log('New data:', data)
 *   }
 * });
 * ```
 */
export function useQuerySub<
  TData,
  TVariables extends Record<string, unknown>,
  TError = Error,
>(
  document: TypedDocumentNode<TData, TVariables>,
  options: QuerySubOptions<TData, TVariables, TError>,
): UseQueryResult<TData, TError> {
  const queryClient = useQueryClient();
  const graphql = useGraphQLClient();
  const opts = useRef(options);
  opts.current = options;

  const { queryKey, variables, subscriptionOptions, ...queryOptions } = options;

  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to run the effect when queryKey changes
  useEffect(() => {
    if (options.subscriptionOptions?.enabled === false) return;
    return graphql.subscribe<TData, Record<string, unknown>>(
      // biome-ignore lint/suspicious/noExplicitAny: CBA to find the correct type
      document as any,
      // biome-ignore lint/suspicious/noExplicitAny: CBA to find the correct type
      opts.current.variables as any,
      {
        next: (d) => {
          queryClient.setQueryData(opts.current.queryKey, d.data);
          if (d.data)
            opts.current.subscriptionOptions?.onSubscriptionData?.(d.data);
        },
        error: (error: Error) => {
          console.error("Subscription error:", error);
          opts.current.subscriptionOptions?.onSubscriptionError?.(error);
          queryClient.invalidateQueries({ queryKey: opts.current.queryKey });
        },
      },
    );
  }, [
    graphql,
    document,
    queryClient,
    options.subscriptionOptions?.enabled,
    queryKey.join("-"),
  ]);

  // Set up the regular query
  return useQuery({
    queryKey,
    queryFn: () =>
      graphql
        .execute<TData, TVariables>(
          document,
          ...([variables] as TVariables extends Record<string, never>
            ? []
            : [TVariables]),
        )
        // biome-ignore lint/suspicious/noExplicitAny: CBA to find the correct type
        .then((x) => x.data as any),
    ...queryOptions,
  });
}
