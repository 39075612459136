import { useEffect } from "react";

import { useActivePageId, usePage, useGridSettingsSelect } from "../../../data";
import { useUserSubscriptionTier } from "../../../context/auth";
import { useVisibleColumns } from "../useVisibleColumns";
import { headerHeight } from "../../globals";
import { useGrid } from "../stores";

export function useDynamicCssExtraHeightRow() {
  const { disabledFeatures } = useUserSubscriptionTier();
  const pageId = useActivePageId();
  const [api] = useGrid();
  const visibleColumns = useVisibleColumns();
  const hideStatusRow = useGridSettingsSelect((x) => x?.hide_status_row);
  const page = usePage(pageId);
  const extraHeaderHeight = page.data?.extra_header_height
    ? 75
    : headerHeight + 0.25 * headerHeight;

  useEffect(() => {
    if (!api || api.isDestroyed()) return;

    setVar("--extraHeaderHeight", `${extraHeaderHeight}px`);
    setVar("--headerHeight", `${headerHeight}px`);

    const statusColumnHeight =
      !hideStatusRow.data && !disabledFeatures?.statusRow ? headerHeight : 0;
    const lastUpdatedColumnHeight = headerHeight;
    const allHeadersMinusNameColumnHeight =
      ((visibleColumns.data?.length ?? 1) - 1) * headerHeight;
    const nameColumnHeight = extraHeaderHeight;

    const heightOfAllHeaders =
      statusColumnHeight +
      lastUpdatedColumnHeight +
      allHeadersMinusNameColumnHeight +
      nameColumnHeight;

    api.setGridOption("headerHeight", heightOfAllHeaders);
  }, [
    api,
    extraHeaderHeight,
    visibleColumns,
    hideStatusRow,
    disabledFeatures?.statusRow,
  ]);
}

const setVar = (name: string, value: string) =>
  document.documentElement.style.setProperty(name, value);
