import { nanoid } from "nanoid";
import { z } from "zod";
import { client } from "../../../../triplit/triplit";
import {
  monthCodeToShortMonthAndYear,
  relativeRowToRowId,
} from "../../periodHelpers";
import { parseLimitRef } from "../../../utils";
import { useCallback } from "react";
import { useUserDetails } from "../../../../context/auth";
import { atom, useAtom } from "jotai";
import { store } from "../../../modal";
import { vapidKeys } from "../../../../globals";
import { parsePushSubscriptions } from "../../../../utils";
import { unique } from "remeda";
import { logger } from "@artis/logger";
import type { AlertFragmentFragment } from "../../../../__generated__/gql/graphql";
import { useAlertsByPageId } from "../../../../data/alerts/hooks";

const pushSubscriptionAtom = atom<{
  subscription: PushSubscription | null;
  subscribed: boolean;
}>({
  subscription: null,
  subscribed: false,
});
pushSubscriptionAtom.debugLabel = "pushSubscriptionAtom";

navigator?.serviceWorker?.register("/service-worker.js");

navigator?.serviceWorker?.ready.then(async (registration) => {
  const subscription = await registration.pushManager.getSubscription();
  logger.info("Alerts service worker ready", {
    subscription: subscription?.toJSON(),
  });

  store.set(pushSubscriptionAtom, {
    subscription,
    subscribed: false,
  });
});

export function usePushSubscription() {
  const user = useUserDetails();
  const [subscription, setSubscription] = useAtom(pushSubscriptionAtom);

  const subscribe = useCallback(async () => {
    logger.info("Subscribing to push notifications");

    if (!subscription || !user?.id) return;
    const pushSubscriptions = await client.fetchById(
      "pushSubscriptions",
      user.id,
    );

    logger.info("User push subscriptions", { pushSubscriptions });

    if (!pushSubscriptions) {
      logger.info("Creating push subscription record for user");

      await client.insert("pushSubscriptions", {
        id: user.id,
        subscriptions: "[]",
      });
    }

    const publicKeyEncoded = vapidKeys?.publicKey;
    if (!publicKeyEncoded) {
      logger.error("VAPID public key not found.");
      return;
    }

    const publicKey = urlBase64ToUint8Array(publicKeyEncoded);

    const registration = await navigator.serviceWorker.ready;
    const pushSubscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: publicKey,
    });

    logger.info("Push subscription", {
      subscription: pushSubscription.toJSON(),
    });

    const currentSubscriptions = pushSubscriptions?.subscriptions
      ? parsePushSubscriptions(pushSubscriptions?.subscriptions)
      : [];

    if (
      !currentSubscriptions.some(
        (sub) => sub.endpoint === pushSubscription.endpoint,
      )
    ) {
      currentSubscriptions.push(pushSubscription);

      const updatedSubscriptions = unique(currentSubscriptions.slice(-2));

      await client.update("pushSubscriptions", user.id, (row) => {
        row.subscriptions = JSON.stringify(updatedSubscriptions);
      });
    }

    setSubscription({
      subscription: pushSubscription,
      subscribed: true,
    });
  }, [subscription, user, setSubscription]);

  return {
    subscribe,
    subscription,
  };
}

export const alertValidation = z.object({
  id: z.string(),
  _id: z.string().optional(),
  limit: z.string(),
  limitRef: z
    .object({
      columnId: z.string(),
      productId: z.string(),
      fieldSelector: z.string(),
      rowId: z.string(),
      name: z.string(),
      period: z.string(),
    })
    .optional(),
  note: z.string(),
  status: z.string(),
  recurring: z.boolean().optional(),
  sound: z.boolean().optional(),
  valueBelowLimit: z.boolean().optional(),
  productId: z.string(),
  columnId: z.string(),
  rowId: z.string(),
});

export type TAlertForm = z.infer<typeof alertValidation>;

export function emptyAlert(sound?: boolean): TAlertForm {
  return {
    id: nanoid(),
    limit: "",
    note: "",
    status: "Active",
    sound: sound ?? false,
    productId: "",
    recurring: false,
    columnId: "",
    rowId: "",
  };
}

// Create an object where the key is `columnId-rowId` and the value is an array of rules based on the ones that have the same columnId and rowId.
function formatAlerts(alerts: AlertFragmentFragment[]) {
  const formatted: Record<string, AlertFragmentFragment[]> = {};

  for (const alert of alerts) {
    const key = `${alert.product_id}-${alert.row_id}`;
    if (!formatted[key]) {
      formatted[key] = [];
    }

    formatted[key].push(alert);
  }

  return formatted;
}

export function useAlerts() {
  const alerts = useAlertsByPageId();

  const formatted = formatAlerts(alerts.data || []);

  return {
    alerts: formatted,
    fetching: alerts.isFetching,
  };
}

export function getAlertDetails(
  alert: AlertFragmentFragment,
  colName: string,
  limitName?: string | null,
) {
  const period = relativeRowToRowId[alert.row_id];
  const operator = alert.value_below_limit ? " >= " : " <= ";

  const limitIsRef = alert.limit.includes(":");
  const parsedLimitRef = limitIsRef ? parseLimitRef(alert.limit) : null;

  const limitDetails =
    parsedLimitRef?.columnId && parsedLimitRef?.rowId
      ? {
          period: monthCodeToShortMonthAndYear(parsedLimitRef.rowId),
        }
      : null;

  return {
    name: colName,
    period,
    operator,
    limitDetails,
    formattedMessage: `${colName} [${period}] ${operator} ${
      limitDetails ? `${limitName} [${limitDetails.period}]` : alert.limit
    }`,
  };
}

let permissionsRequested = false;

export async function requestNotificationPermission() {
  if (permissionsRequested) return false;
  permissionsRequested = true;
  if (typeof Notification === "undefined") {
    logger.warn("Notifications are not supported in this browser.");
    return false;
  }

  if (Notification.permission !== "granted") {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        logger.info("Notification permission granted");
        return true;
      }
      logger.info("Notification permission denied");
      return false;
    } catch (error) {
      logger.error("Error requesting notification permission", { error });
      return false;
    }
  } else {
    logger.info("Notification permission already granted");
    return true;
  }
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
