import type { ITooltipParams } from "ag-grid-community";
import duration from "dayjs/plugin/duration";
import { Box, Typography } from "@mui/joy";
import { memo, useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";

import type { TData, TLatestEditInfoValue } from "../../calculations-worker";
import {
  getInstrumentType,
  isGlobalStorageType,
  type TStatus,
} from "../statuses/statusLogic";
import { useProductById } from "../../../data";
import { isDev } from "../../../globals";
import { getCalcWorker } from "../../calculations-worker";

dayjs.extend(duration);

// As this is a custom tooltip, it is called for both headers and rows.
export function TooltipProduct(params: ITooltipParams) {
  return params.location === "header" ? (
    <TooltipHeaderColumn params={params} />
  ) : (
    <TooltipCell params={params} />
  );
}

export type TTooltipCell = {
  productId: string | undefined;
  status: TStatus | undefined;
  isEditable: boolean;
};

type TInfo = TLatestEditInfoValue | undefined;

function TooltipCell({
  params,
}: {
  params: ITooltipParams<TData, TTooltipCell, unknown>;
}) {
  const [storageType, setStorageType] = useState<string | null>(null);
  const [info, setInfo] = useState<TInfo>();

  const getInfo = useCallback(async () => {
    if (!params.value) return;
    // coming from tooltip params in column defs
    const { productId, status, isEditable } = params.value;

    if (!isEditable) return;

    const monthCode = params.data?.id;

    if (!monthCode) return;

    const storageType = isGlobalStorageType(status)
      ? "global"
      : getInstrumentType(status);
    setStorageType(storageType);

    // EOD curves won't have edited by/at info
    if (storageType === "eod") return;

    if (productId && status) {
      const entry = await getCalcWorker().getCellInfo({
        productId: storageType === "global" ? status : productId,
        storageType,
        rowId: monthCode,
      });

      if (!entry) {
        setInfo(undefined);
        return;
      }

      const infoBox = {
        firstname: entry.firstname,
        lastname: entry.lastname,
        username: entry.username,
        editedAt: entry.editedAt,
      } satisfies TInfo;

      setInfo((prevInfo) => {
        // Only update state if the new info is different from the previous info
        if (
          prevInfo?.firstname !== infoBox.firstname ||
          prevInfo?.lastname !== infoBox.lastname ||
          prevInfo?.username !== infoBox.username ||
          prevInfo?.editedAt !== infoBox.editedAt
        ) {
          return infoBox;
        }
        return prevInfo;
      });
    }
  }, [params.value, params.data]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const user = `${info?.firstname} ${info?.lastname}`;

  const now = dayjs();
  const editedAt = dayjs(info?.editedAt);
  const passedTime =
    info?.editedAt &&
    dayjs.duration(editedAt.diff(now), "milliseconds").humanize(true);

  return (
    <div>
      {info ? (
        <Box
          sx={{
            padding: 0.5,
            background: (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.neutral[900]
                : theme.palette.neutral[100],
          }}
        >
          <Typography level="body-xs">
            {user}
            {storageType !== "global" && ":"}
            {passedTime && ` ${passedTime}`}
          </Typography>
        </Box>
      ) : (
        ""
      )}
    </div>
  );
}

function TooltipHeaderColumn({ params }: { params: ITooltipParams }) {
  const { value: productId } = params;

  const { data: product } = useProductById(productId || "");

  if (!product) return <TooltipLoader />;

  const curveName = product.description;
  const packageName = product.packageByPackage.name;
  const commodity = product.commodityGroupByCommodityGroup?.commodity_group;
  const region =
    product.geographicalRegionByGeographicalRegion?.geographical_region;
  const source = product.packageByPackage.sourceBySource?.name;

  return (
    <>
      <ProductHeaderTooltip
        curveName={curveName}
        packageName={packageName}
        commodity={commodity}
        region={region}
        source={source}
      />
      {isDev && (
        <Box bgcolor="#ffefff">
          will only show in dev
          <TooltipHeaderProductText title="Product ID" content={productId} />
          <TooltipHeaderProductText
            title="Grid ID"
            content={params.column?.getUniqueId() || "hmm"}
          />
          <TooltipHeaderProductText
            title="Artis Type"
            content={product.artis_type}
          />
        </Box>
      )}
    </>
  );
}

function ProductHeaderTooltipRaw({
  curveName,
  packageName,
  commodity,
  region,
  source,
}: {
  curveName?: string | null;
  packageName?: string | null;
  commodity?: string | null;
  region?: string | null;
  source?: string | null;
}) {
  return (
    <Box
      sx={{
        background: "var(--ag-tooltip-background-color)",
        padding: "var(--ag-widget-container-horizontal-padding)",
        boxShadow: "var(--ag-popup-shadow)",
        borderRadius: "var(--ag-border-radius)",
        gap: 1.5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {curveName && (
        <TooltipHeaderProductText title="Curve Name" content={curveName} />
      )}
      {packageName && (
        <TooltipHeaderProductText title="Package" content={packageName} />
      )}
      {(commodity || region || source) && (
        <>
          {commodity && (
            <TooltipHeaderProductText title="Commodity" content={commodity} />
          )}
          {region && (
            <TooltipHeaderProductText title="Region" content={region} />
          )}
          {source && (
            <TooltipHeaderProductText title="Source" content={source} />
          )}
        </>
      )}
    </Box>
  );
}

const ProductHeaderTooltip = memo(ProductHeaderTooltipRaw);

function TooltipLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      Loading...
    </Box>
  );
}

function TooltipHeaderProductText({
  title,
  content,
}: {
  title: string;
  content: string;
}) {
  return (
    <Box>
      <Typography level="title-sm" fontWeight={700}>
        {title}
      </Typography>
      <Typography level="body-sm">{content}</Typography>
    </Box>
  );
}
