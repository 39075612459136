import { type ReactNode, useEffect, useMemo } from "react";
import { GraphQLProvider } from "../../utils/graphql";
import { createHasuraClient } from "./client";
import { useAccessToken } from "../auth";

export const HasuraProvider = (p: { children: ReactNode }) => {
  const token = useAccessToken();
  const client = useMemo(() => {
    if (!token.data?.access_token) return null;
    return createHasuraClient(token.data.access_token);
  }, [token.data]);
  useEffect(() => () => client?.dispose(), [client]);
  if (!client) return null;
  return <GraphQLProvider value={client}>{p.children}</GraphQLProvider>;
};
