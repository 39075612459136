import { CssVarsProvider, useColorScheme } from "@mui/joy";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { atomWithBroadcast } from "../stateAtoms";

const getTheme = (key: string) => {
  // Get theme preference
  let theme =
    typeof localStorage !== "undefined" ? localStorage.getItem(key) : undefined;
  // Or use system preference
  if (!theme || theme === "system")
    theme = window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  return theme === "dark" ? "dark" : "light";
};

export type Theme = "light" | "dark";
const storageKey = "artist-theme";
const themeAtom = atomWithBroadcast<Theme>("theme", getTheme(storageKey));
themeAtom.onMount = (setTheme) => setTheme(getTheme(storageKey));

export const AppThemeProvider = ({
  children,
  ...p
}: Parameters<typeof CssVarsProvider>[0]) => {
  const setTheme = useSetAtom(themeAtom);

  useEffect(() => {
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    const handleTheme = () => setTheme(getTheme(storageKey));
    prefersDark.addEventListener("change", handleTheme);
    return () => prefersDark.removeEventListener("change", handleTheme);
  }, [setTheme]);

  return (
    <CssVarsProvider {...p}>
      <SyncJoyTheme />
      {children}
    </CssVarsProvider>
  );
};

const SyncJoyTheme = () => {
  const theme = useAtomValue(themeAtom);
  const joy = useColorScheme();
  useEffect(() => joy.setMode(theme), [theme, joy.setMode]);
  return null;
};

export const useThemeMode = () => useAtomValue(themeAtom);

export const useSetThemeMode = () => {
  const setTheme = useSetAtom(themeAtom);
  return (theme: "dark" | "light" | "system") => {
    localStorage.setItem(storageKey, theme);
    setTheme(getTheme(storageKey));
  };
};
