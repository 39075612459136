import type { CellKeyDownEvent } from "ag-grid-community";

import { useUpdateCellByValueAndOperation } from "./useUpdateCellByValueAndOperation";
import { initGridSettings, useGridSettingsSelect } from "../../../data";

export const useUpdateColumnFromCellByValueAndOperation = () => {
  const updateCellsByValueAndOperation = useUpdateCellByValueAndOperation();
  const months = useGridSettingsSelect((x) => x?.months);
  return ({
    params,
    operation,
  }: {
    params: CellKeyDownEvent;
    operation: "+" | "-";
  }) => {
    const api = params.api;

    if (!api) return;

    const selectedColumn = api.getColumn(params.column.getColId());

    if (!selectedColumn) return;

    const selectedIndex = api.getCellRanges()?.sort((a, b) => {
      if (!a?.startRow || !b?.startRow) return 0;
      return a?.startRow?.rowIndex - b?.startRow?.rowIndex;
    })[0]?.startRow?.rowIndex;

    api.clearRangeSelection();

    api.addCellRange({
      columns: [selectedColumn],
      rowStartIndex: selectedIndex ?? 0,
      rowEndIndex: (months.data ?? initGridSettings.months) - 1,
    });

    updateCellsByValueAndOperation({
      params,
      operation,
      clearSelection: true,
      reselect: {
        index: selectedIndex ?? 0,
        column: selectedColumn,
      },
    });
  };
};
