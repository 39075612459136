import type { ValueFormatterParams } from "ag-grid-community";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import utc from "dayjs/plugin/utc.js";
import * as marky from "marky";
import { z } from "zod";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const zDateString = z
  .string()
  .refine((value) => value === null || !Number.isNaN(Date.parse(value)), {
    message: "Invalid date string",
  });

export function formatDateForTable(date: string | null | undefined) {
  if (date) {
    const dateObj = new Date(date);
    const formattedDate = new Intl.DateTimeFormat("en-UK", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(dateObj);
    const timeAgo = dayjs(date).fromNow();

    return `${formattedDate} (${timeAgo})`;
  }
  return "-";
}

export function yesterdayDate() {
  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const formattedYesterday = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(yesterday);

  return formattedYesterday;
}

export function timeAgoShort(date: Date, now: Date = new Date()) {
  const diff = now.getTime() - date.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) return "<1m";
  if (minutes < 60) return `${minutes}m`;
  if (hours < 24) return `${hours}h`;
  if (days < 30) return `${days}d`;
  if (months < 12) return `${months}M`;
  return `${years}Y`;
}

export function mergeData<T extends RequiredTableFields>(
  serverRowData: Readonly<T[]>,
  localEditedData: Record<string, Partial<T>>,
) {
  return serverRowData.map((serverRow) => {
    const localEdited = localEditedData[serverRow.id];
    if (localEdited) {
      return {
        ...serverRow,
        ...localEdited,
      };
    }
    return serverRow;
  });
}

export function editableKeys<
  T extends Record<
    string,
    Partial<{ type: string; editable?: boolean | null }>
  >,
>(config: T) {
  return objectKeys(config)
    .filter(
      (
        key,
      ): key is {
        [K in keyof T]: T[K] extends { editable: true } ? K : never;
      }[keyof T] => "editable" in config[key],
    )
    .map((key) => key);
}

export function editableColDefs<
  T extends Array<Partial<{ field: string; editable?: boolean | null }>>,
>(config: T) {
  return config.filter(
    (obj): obj is Extract<T[number], { editable: true }> =>
      "editable" in obj && !!obj?.editable,
  );
}

export type RequiredTableFields = {
  id: string | number;
};

export const ukDateFormatter = new Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

export function setDateToMidnightUTC(inputDate: string | number | Date) {
  try {
    if (!inputDate) {
      throw new Error("Input date is required.");
    }

    const newDate = new Date(inputDate);

    if (Number.isNaN(newDate.getTime())) {
      throw new Error("Invalid date.");
    }

    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const isoDateString = `${formattedDate}T00:00:00.000Z`;
    return isoDateString;
  } catch (error) {
    console.error("Error in setDateToMidnightUTC:", error);
    return null;
  }
}

const enableProfiling = false;

export function profileStart(tag: string) {
  if (enableProfiling) marky.mark(tag);
}

export function profileEnd(tag: string) {
  if (enableProfiling) marky.stop(tag);
}

type WithProperty<T, P extends PropertyKey> = {
  [K in P]: T;
};

export function memoize0<
  TResult,
  TObject extends object,
  TTag extends keyof TObject,
>(
  obj: TObject & WithProperty<TResult, TTag>,
  tag: TTag,
  func: () => TResult,
): TResult {
  if (!Object.prototype.hasOwnProperty.call(obj, tag)) {
    Object.defineProperty(obj, tag, {
      enumerable: false,
      writable: false,
      value: func(),
    });
  }

  return obj[tag];
}

/**
 * Capitalizes the first letter of a string. Will only work for roman characters.
 * @param string - The string to capitalize.
 * @returns The string with the first letter capitalized.
 **/
export function capitalizeFirstLetter(string: string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function formatKebabCase(string: string) {
  return string
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function throttle<T extends (...args: Parameters<T>) => ReturnType<T>>(
  fn: T,
  delay: number,
): T {
  let lastCall = 0;
  let lastResult: ReturnType<T>;

  return ((...args: Parameters<T>): ReturnType<T> => {
    const now = Date.now();

    if (now - lastCall >= delay) {
      lastCall = now;
      lastResult = fn(...args);
    }

    return lastResult;
  }) as T;
}

export function parsePushSubscriptions(
  subscriptions: string,
): PushSubscription[] {
  try {
    return JSON.parse(subscriptions) as PushSubscription[];
  } catch (error) {
    console.error("Error parsing push subscriptions:", error);
    return [];
  }
}

export function formatDate(date: string | Date, time = true) {
  if (!time) {
    return dayjs(date).format("DD-MMM-YYYY");
  }

  return dayjs(date).format("DD-MMM-YYYY HH:mm:ss");
}

export function agDateFormatterNoTime(params: ValueFormatterParams) {
  if (!params.value) {
    return "";
  }

  return formatDate(params.value, false);
}

export function sortAlphabeticallyEarlyMatch(
  word1: string,
  word2: string,
  query: string,
) {
  const indexA = word1.toLowerCase().indexOf(query.toLowerCase());
  const indexB = word2.toLowerCase().indexOf(query.toLowerCase());

  if (indexA !== indexB) {
    return indexA - indexB;
  }

  return word1.localeCompare(word2);
}

export function arraysHaveSameItems(
  arr1: (string | number)[] | undefined,
  arr2: (string | number)[] | undefined,
) {
  if (arr1?.length !== arr2?.length) return false;

  if (!arr1?.length && !arr2?.length) return true;

  const sortedArr1 = arr1?.slice().sort();
  const sortedArr2 = arr2?.slice().sort();

  return sortedArr1?.every((item, index) => item === sortedArr2?.[index]);
}

export function humanizeRole(role: unknown) {
  if (typeof role !== "string") return "";
  return role
    .replace(/-/g, " ")
    .replace(/\b\w/g, (match) => match.toUpperCase());
}

export function diffedData<T>(
  dirtyFields: Partial<Record<keyof T, unknown>>,
  data: T,
): Partial<T> {
  return (Object.keys(dirtyFields) as Array<keyof T>).reduce(
    (acc: Partial<T>, key: keyof T) => {
      if (dirtyFields[key]) {
        acc[key] = data[key];
      }
      return acc;
    },
    {},
  );
}

/**
 * Like Object.keys, but unsound in exchange for more convenience.
 *
 * Casts the result of Object.keys to the known keys of an object type,
 * even though JavaScript objects may contain additional keys.
 *
 * Only use this function when you know/control the provenance of the object
 * you're iterating, and can verify it contains exactly the keys declared
 * to the type system.
 *
 * Example:
 * ```
 * const o = {x: "ok", y: 10}
 * o["z"] = "UNTRACKED_KEY"
 * const safeKeys = Object.keys(o)
 * const unsafeKeys = objectKeys(o)
 * ```
 * => const safeKeys: string[]
 * => const unsafeKeys: ("x" | "y")[] // Missing "z"
 */
export const objectKeys = Object.keys as <T>(obj: T) => Array<keyof T>;

/**
 * The type of a single item in `Object.entries<T>(value: T)`.
 *
 * Example:
 * ```
 * interface T {x: string; y: number}
 * type T2 = ObjectEntry<T>
 * ```
 * => type T2 = ["x", string] | ["y", number]
 */
export type ObjectEntry<T> = {
  // Without Exclude<keyof T, undefined>, this type produces `ExpectedEntries | undefined`
  // if T has any optional keys.
  [K in Exclude<keyof T, undefined>]: [K, T[K]];
}[Exclude<keyof T, undefined>];

/**
 * Like Object.entries, but returns a more specific type which can be less safe.
 *
 * Example:
 * ```
 * const o = {x: "ok", y: 10}
 * const unsafeEntries = Object.entries(o)
 * const safeEntries = objectEntries(o)
 * ```
 * => const unsafeEntries: [string, string | number][]
 * => const safeEntries: ObjectEntry<{
 *   x: string;
 *   y: number;
 * }>[]
 *
 * See `ObjectEntry` above.
 *
 * Note that Object.entries collapses all possible values into a single union
 * while objectEntries results in a union of 2-tuples.
 */
export const objectEntries = Object.entries as <T>(
  o: T,
) => Array<ObjectEntry<T>>;

export const objectFromEntries = <T>(
  entries: Array<[keyof T, T[keyof T]]>,
): T => {
  return Object.fromEntries(entries) as T;
};

export function structuredCloneWithIgnore(obj: unknown) {
  return JSON.parse(
    JSON.stringify(obj, function replacer(_key, value) {
      if (
        typeof value === "function" ||
        value instanceof Element ||
        value instanceof Window
      ) {
        return undefined;
      }
      return value;
    }),
  );
}

export function sanitizeString(str: string) {
  return str
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase()
    .trim();
}

export function setDifference<T>(setA: Set<T>, setB: Set<T>): T[] {
  return Array.from(setA).filter((x) => !setB.has(x));
}

export function formatUnknownToString(v: unknown) {
  if (v instanceof Set) {
    return Array.from(v).join(", ");
  }
  if (v instanceof Date) {
    return Intl.DateTimeFormat("en-GB").format(v);
  }
  if (v instanceof Map) {
    return Array.from(v).join(", ");
  }
  if (Array.isArray(v) && v.every((i) => typeof i === "string")) {
    return v.join(", ");
  }
  if (v instanceof Object) {
    return JSON.stringify(v);
  }
  if (v === null || v === undefined) {
    return "";
  }
  return (v as string).toString() || "";
}

export function includes<T extends U, U>(
  arr: ReadonlyArray<T>,
  searchElement: U,
): searchElement is T {
  return arr.includes(searchElement as T);
}
