export function ArtisLogo() {
  return (
    <svg viewBox="0 0 350.09 97.1" xmlns="http://www.w3.org/2000/svg">
      <title>Artis Logo</title>
      <g>
        <path
          className="fill-foreground"
          d="M251.65,87.98c1.39,2.75,3.55,4.74,6.43,5.89,2.93,1.18,6.76,1.77,11.38,1.77h5.71v-10.06h-6.36c-2.6,0-4.64-.72-6.06-2.14-1.42-1.42-2.14-3.46-2.14-6.06l.04-21.61h14.45v-10.23h-14.43l.03-13.53h-11.17v13.53h-8.06v10.23h8.06v20.77c0,4.79.72,8.64,2.13,11.43Z"
        />
        <path
          className="fill-foreground"
          d="M283.05,45.57v10.21h7.06v39.87h11.91v-50.08h-18.97ZM294.34,39.19c2.38,0,4.13-.6,5.2-1.79,1.09-1.2,1.64-2.81,1.64-4.77s-.55-3.48-1.64-4.68c-1.07-1.19-2.82-1.79-5.2-1.79s-4.15.6-5.25,1.79c-1.11,1.2-1.68,2.77-1.68,4.68s.56,3.57,1.68,4.77c1.1,1.19,2.87,1.79,5.25,1.79Z"
        />
        <path
          className="fill-foreground"
          d="M314.98,92.61c3.59,2.82,8.61,4.26,14.95,4.26,4.07,0,7.65-.65,10.65-1.93,2.99-1.28,5.35-3.13,7.01-5.5,1.66-2.37,2.5-5.2,2.5-8.4,0-4.33-1.55-7.81-4.62-10.36-3.08-2.56-7.39-4.15-12.82-4.75l-4.11-.45c-2.53-.3-4.38-.89-5.51-1.74-1.16-.88-1.72-2.09-1.72-3.71s.69-3.01,2.11-4.07c1.39-1.03,3.37-1.56,5.9-1.56,2.82,0,4.99.62,6.46,1.83,1.46,1.21,2.28,2.65,2.47,4.39h10.45c-.2-5.04-2.05-8.9-5.51-11.49-3.5-2.62-8.14-3.94-13.79-3.94-3.68,0-6.96.59-9.75,1.75-2.79,1.16-5,2.89-6.58,5.14-1.58,2.25-2.37,5.08-2.37,8.4,0,4.15,1.4,7.53,4.15,10.05,2.76,2.52,6.8,4.07,12,4.61l4.03.45c3.15.36,5.4,1.04,6.7,2.01,1.33,1,1.98,2.27,1.98,3.89,0,1.93-.86,3.41-2.63,4.53-1.73,1.09-4.03,1.64-6.83,1.64-3.45,0-6.01-.71-7.62-2.11-1.58-1.37-2.48-2.9-2.69-4.57h-10.54c.25,4.92,2.18,8.84,5.72,11.63Z"
        />
        <path
          className="fill-foreground"
          d="M195.52,95.65v-32.97c0-9.43-7.73-17.11-17.22-17.11h-20.5v10.33h20.5c3.92,0,7.1,3.17,7.1,7.06v1.89h-11.73c-5.64.08-12.69.8-17.09,5.63-4.54,4.97-5.24,13.4-1.6,19.19,2.91,4.64,8.78,7.42,15.69,7.44,2.51-.01,5.01-.35,7.44-1.06,2.18-.64,4.65-1.52,6.45-3.21l.84-.78v3.61h10.12ZM181.52,85.72l-.04.03c-1.86,1.67-4.69,2.66-7.69,2.66-6.94,0-10.1-3.92-10.1-7.56,0-4.02,4.07-7.25,9.36-7.53v-.02s.55,0,.55,0h11.71l.03.46c.02.35.51,8.65-3.82,11.96Z"
        />
        <path
          className="fill-foreground"
          d="M233.82,55.77v-10.2c-1.69.03-3.39.13-5.07.34-1.45.18-2.89.43-4.29.81-1.21.33-2.4.74-3.53,1.29-.99.48-1.94,1.07-2.79,1.77-.64.54-1.23,1.14-1.73,1.81-.44.58-.78,1.21-1.12,1.85-.04.08-.08.15-.12.23l-.03-1.88v-2.4c0-1.05,0-2.75,0-3.8h-9.22v50.09h11.92c.04-3.58.01-7.21-.01-10.73-.05-5.82-.1-11.84.19-17.76.16-1.21.41-2.26.77-3.25,2.61-7.33,9.72-8.22,15.06-8.15Z"
        />
        {/* Grid Logo (Orange parts) */}
        <path
          className="fill-brand"
          d="M66.45,16.15c0,3.32-.06,6.65.02,9.97.08,3.54-2.61,5.64-5.21,6.04-.46.07-.94.1-1.4.11-2.85,0-5.71,0-8.56,0-3.36,0-6.18,2.3-6.84,5.59-.09.44-.12.9-.12,1.35,0,6.46,0,12.92,0,19.38,0,.76-.05,1.5-.28,2.23-.27.84-.72,1.57-1.36,2.16-1.22,1.12-2.65,1.76-4.32,1.84-.3.01-.6.01-.89.01-2.78,0-5.55,0-8.33,0-.95,0-1.88.1-2.77.44-2.02.78-3.45,2.14-4.01,4.28-.13.51-.17,1.05-.17,1.58-.01,6.65,0,13.3,0,19.95,0,2.04-.83,3.67-2.52,4.83-.86.58-1.81.96-2.84,1.06-.96.09-1.94.11-2.91.12-2.4.02-4.8,0-7.2,0-1.23,0-2.4-.2-3.5-.78-1.62-.85-2.68-2.15-3.07-3.94-.11-.5-.13-1.03-.13-1.54,0-6.6.02-13.2-.02-19.81-.02-3.65,2.55-5.54,4.9-6.03.55-.11,1.12-.18,1.68-.18,2.92-.02,5.83,0,8.75,0,2.48,0,4.46-.99,5.87-3.06.63-.93.93-1.97.93-3.1,0-6.12,0-12.23,0-18.35,0-.94,0-1.88.07-2.81.09-1.32.63-2.46,1.6-3.36,1.34-1.23,2.9-1.9,4.77-1.89,3.06.03,6.11,0,9.17-.03.87,0,1.72-.14,2.53-.46,1.99-.79,3.34-2.19,3.88-4.28.12-.45.14-.93.14-1.4,0-6.63,0-13.27.01-19.9,0-.57.06-1.16.18-1.72.23-1.11.83-2.02,1.69-2.76C47.51.56,49.07,0,50.83,0c3.07,0,6.15,0,9.22.02,1.33,0,2.58.32,3.7,1.05,1.74,1.13,2.69,2.72,2.69,4.82,0,.94,0,1.88,0,2.82,0,2.48,0,4.96,0,7.43Z"
        />
        <path
          className="fill-brand"
          d="M66.49,48.78c0-3.39,0-6.77,0-10.16,0-1.33.34-2.54,1.16-3.6,1.13-1.47,2.63-2.27,4.48-2.29,3.61-.03,7.21-.03,10.82,0,2.91.02,5.29,2.22,5.61,5.13.03.3.05.59.06.89,0,6.71,0,13.42,0,20.13,0,1.78-.58,3.32-1.92,4.53-1.05.96-2.3,1.47-3.72,1.48-3.62.02-7.24.03-10.86,0-3.05-.03-5.53-2.48-5.61-5.53-.05-1.88-.01-3.76-.01-5.64,0-1.65,0-3.29,0-4.94Z"
        />
        <path
          className="fill-brand"
          d="M88.32,80.95c0-3.42,0-6.84,0-10.25,0-2.01.83-3.6,2.45-4.78.98-.71,2.1-1.04,3.31-1.04,1.03,0,2.07,0,3.1,0,2.48,0,4.95,0,7.43,0,2.24.01,3.93,1.02,5.08,2.93.54.91.74,1.92.74,2.97,0,2.76,0,5.52,0,8.28,0,4.03,0,8.06,0,12.09,0,2.19-.94,3.9-2.8,5.07-.93.59-1.96.83-3.04.83-3.48,0-6.96.02-10.44,0-2.76-.01-5.02-1.76-5.65-4.36-.13-.53-.17-1.09-.17-1.63-.01-3.37,0-6.74,0-10.11Z"
        />
        <path
          className="fill-brand"
          d="M44.39,80.95c0-3.34,0-6.68,0-10.02,0-1.32.28-2.55,1.07-3.63,1.14-1.55,2.67-2.39,4.61-2.41,2.27-.02,4.55,0,6.82,0,1.32,0,2.63-.02,3.95,0,2.57.05,4.76,1.71,5.43,4.14.15.54.23,1.11.23,1.67.02,6.84.01,13.67,0,20.51,0,3.28-2.45,5.8-5.75,5.84-3.54.04-7.09.04-10.63,0-2.75-.03-5-1.84-5.58-4.45-.13-.57-.15-1.18-.16-1.77-.01-3.29,0-6.58,0-9.88Z"
        />
      </g>
    </svg>
  );
}

export function ArtisWorksBarLogo() {
  return (
    <svg viewBox="0 0 350.1 97.1" xmlns="http://www.w3.org/2000/svg">
      <title>Artis Works Bar Logo</title>
      <g>
        <g>
          <path
            fill="#9a9da7"
            d="M251.6,88c1.4,2.8,3.6,4.7,6.4,5.9,2.9,1.2,6.8,1.8,11.4,1.8h5.7v-10.1h-6.4c-2.6,0-4.6-.7-6.1-2.1-1.4-1.4-2.1-3.5-2.1-6.1v-21.6h14.5v-10.2h-14.4v-13.5s-11.1,0-11.1,0v13.5h-8.1v10.2h8.1v20.8c0,4.8.7,8.6,2.1,11.4h0Z"
          />
          <path
            fill="#9a9da7"
            d="M283,45.6v10.2h7.1v39.9h11.9v-50.1s-19,0-19,0ZM294.3,39.2c2.4,0,4.1-.6,5.2-1.8,1.1-1.2,1.6-2.8,1.6-4.8s-.5-3.5-1.6-4.7c-1.1-1.2-2.8-1.8-5.2-1.8s-4.1.6-5.2,1.8c-1.1,1.2-1.7,2.8-1.7,4.7s.6,3.6,1.7,4.8c1.1,1.2,2.9,1.8,5.2,1.8Z"
          />
          <path
            fill="#9a9da7"
            d="M315,92.6c3.6,2.8,8.6,4.3,15,4.3s7.6-.7,10.6-1.9c3-1.3,5.4-3.1,7-5.5s2.5-5.2,2.5-8.4-1.5-7.8-4.6-10.4c-3.1-2.6-7.4-4.2-12.8-4.8l-4.1-.4c-2.5-.3-4.4-.9-5.5-1.7-1.2-.9-1.7-2.1-1.7-3.7s.7-3,2.1-4.1c1.4-1,3.4-1.6,5.9-1.6s5,.6,6.5,1.8c1.5,1.2,2.3,2.7,2.5,4.4h10.5c-.2-5-2-8.9-5.5-11.5-3.5-2.6-8.1-3.9-13.8-3.9s-7,.6-9.8,1.8-5,2.9-6.6,5.1-2.4,5.1-2.4,8.4,1.4,7.5,4.1,10.1c2.8,2.5,6.8,4.1,12,4.6l4,.4c3.1.4,5.4,1,6.7,2,1.3,1,2,2.3,2,3.9s-.9,3.4-2.6,4.5c-1.7,1.1-4,1.6-6.8,1.6s-6-.7-7.6-2.1c-1.6-1.4-2.5-2.9-2.7-4.6h-10.5c.2,4.9,2.2,8.8,5.7,11.6h0Z"
          />
          <path
            fill="#9a9da7"
            d="M195.5,95.7v-33c0-9.4-7.7-17.1-17.2-17.1h-20.5v10.3h20.5c3.9,0,7.1,3.2,7.1,7.1v1.9h-11.7c-5.6,0-12.7.8-17.1,5.6-4.5,5-5.2,13.4-1.6,19.2,2.9,4.6,8.8,7.4,15.7,7.4,2.5,0,5-.3,7.4-1.1,2.2-.6,4.6-1.5,6.4-3.2l.8-.8v3.6h10.1ZM181.5,85.7h0c-1.9,1.7-4.7,2.7-7.7,2.7-6.9,0-10.1-3.9-10.1-7.6s4.1-7.2,9.4-7.5h0s.6,0,.6,0h11.7v.5c0,.3.5,8.7-3.8,12Z"
          />
          <path
            fill="#9a9da7"
            d="M233.8,55.8v-10.2c-1.7,0-3.4.1-5.1.3-1.4.2-2.9.4-4.3.8-1.2.3-2.4.7-3.5,1.3-1,.5-1.9,1.1-2.8,1.8-.6.5-1.2,1.1-1.7,1.8-.4.6-.8,1.2-1.1,1.8,0,0,0,.2-.1.2v-1.9s0-2.4,0-2.4v-3.8h-9.2v50.1h11.9c0-3.6,0-7.2,0-10.7,0-5.8-.1-11.8.2-17.8.2-1.2.4-2.3.8-3.2,2.6-7.3,9.7-8.2,15.1-8.2h0Z"
          />
          <path
            fill="#9a9da7"
            d="M66.4,16.1c0,3.3,0,6.6,0,10,0,3.5-2.6,5.6-5.2,6-.5,0-.9,0-1.4.1h-8.6c-3.4,0-6.2,2.3-6.8,5.6,0,.4-.1.9-.1,1.3v19.4c0,.8,0,1.5-.3,2.2-.3.8-.7,1.6-1.4,2.2-1.2,1.1-2.7,1.8-4.3,1.8-.3,0-.6,0-.9,0h-8.3c-1,0-1.9,0-2.8.4-2,.8-3.5,2.1-4,4.3-.1.5-.2,1.1-.2,1.6,0,6.7,0,13.3,0,20s-.8,3.7-2.5,4.8c-.9.6-1.8,1-2.8,1.1-1,0-1.9.1-2.9.1-2.4,0-4.8,0-7.2,0s-2.4-.2-3.5-.8c-1.6-.8-2.7-2.2-3.1-3.9-.1-.5-.1-1-.1-1.5,0-6.6,0-13.2,0-19.8,0-3.7,2.5-5.5,4.9-6,.6-.1,1.1-.2,1.7-.2,2.9,0,5.8,0,8.7,0s4.5-1,5.9-3.1c.6-.9.9-2,.9-3.1v-18.4c0-.9,0-1.9,0-2.8,0-1.3.6-2.5,1.6-3.4,1.3-1.2,2.9-1.9,4.8-1.9,3.1,0,6.1,0,9.2,0,.9,0,1.7-.1,2.5-.5,2-.8,3.3-2.2,3.9-4.3.1-.5.1-.9.1-1.4,0-6.6,0-13.3,0-19.9,0-.6,0-1.2.2-1.7.2-1.1.8-2,1.7-2.8C47.5.6,49.1,0,50.8,0,53.9,0,57,0,60.1,0c1.3,0,2.6.3,3.7,1,1.7,1.1,2.7,2.7,2.7,4.8v10.2h0Z"
          />
          <path
            fill="#9a9da7"
            d="M66.5,48.8v-10.2c0-1.3.3-2.5,1.2-3.6,1.1-1.5,2.6-2.3,4.5-2.3,3.6,0,7.2,0,10.8,0,2.9,0,5.3,2.2,5.6,5.1,0,.3,0,.6,0,.9v20.1c0,1.8-.6,3.3-1.9,4.5-1.1,1-2.3,1.5-3.7,1.5-3.6,0-7.2,0-10.9,0-3.1,0-5.5-2.5-5.6-5.5,0-1.9,0-3.8,0-5.6v-4.9h0Z"
          />
          <path
            fill="#9a9da7"
            d="M88.3,80.9v-10.2c0-2,.8-3.6,2.4-4.8,1-.7,2.1-1,3.3-1h10.5c2.2,0,3.9,1,5.1,2.9.5.9.7,1.9.7,3v20.4c0,2.2-.9,3.9-2.8,5.1-.9.6-2,.8-3,.8-3.5,0-7,0-10.4,0-2.8,0-5-1.8-5.7-4.4-.1-.5-.2-1.1-.2-1.6,0-3.4,0-6.7,0-10.1h0Z"
          />
          <path
            fill="#9a9da7"
            d="M44.4,80.9v-10c0-1.3.3-2.6,1.1-3.6,1.1-1.6,2.7-2.4,4.6-2.4,2.3,0,4.5,0,6.8,0s2.6,0,4,0c2.6,0,4.8,1.7,5.4,4.1.2.5.2,1.1.2,1.7,0,6.8,0,13.7,0,20.5,0,3.3-2.4,5.8-5.8,5.8-3.5,0-7.1,0-10.6,0-2.8,0-5-1.8-5.6-4.4-.1-.6-.2-1.2-.2-1.8,0-3.3,0-6.6,0-9.9h0Z"
          />
        </g>
      </g>
    </svg>
  );
}
