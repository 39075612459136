import { graphql } from "../../graphql";
import type { GraphQLClient } from "../../utils/graphql";
import type { AlertFragmentFragment } from "../../__generated__/gql/graphql";
import { atom } from "jotai";

type TAlert = AlertFragmentFragment & {
  initial: boolean;
};
export const triggeredAlertsQueueAtom = atom<TAlert[]>([]);

export const AlertFragment = graphql(`
	fragment AlertFragment on alerts {
		id
		limit
		_id
		column_id
		desktop_notification_sent
		field_selector
		note
		page_id
		product_id
		push_notification_sent
		recurring
		row_id
		sound
		status
		triggered_at
		user_id
		value_below_limit
	}
`);

export const AlertQuery = graphql(`
  query AlertsQuery($userId: String!) {
    alerts(where: { user_id: { _eq: $userId } }) {
      ...AlertFragment
    }
  }
`);

export const TriggeredAlertsQuery = graphql(`
	query TriggeredAlertsQuery($userId: String!) {
		alerts(where: { user_id: { _eq: $userId }, status: { _eq: "Triggered" }, triggered_at: { _is_null: false } }) {
			...AlertFragment
		}
	}
`);

export const AlertQueryByPageId = graphql(`
	query AlertsQueryByPageId($userId: String!, $pageId: String!) {
		alerts(where: { user_id: { _eq: $userId }, page_id: { _eq: $pageId } }) {
			...AlertFragment
		}
	}
`);

export const AlertExistsQueryById = graphql(`
	query AlertExistsQueryById($id: String!) {
		alerts_by_pk(id: $id) {
			id
			status
			triggered_at
			desktop_notification_sent
			push_notification_sent
		}
	}
`);

export const AlertQueryById = graphql(`
	query AlertQueryById($id: String!) {
		alerts_by_pk(id: $id) {
			...AlertFragment
		}
	}	
`);

export const AlertInsertMutation = graphql(`
	mutation AlertInsertMutation($alert: alerts_insert_input!) {
		insert_alerts_one(object: $alert) {
			...AlertFragment
		}
	}
`);

export const AlertsUpdateMany = graphql(`
	mutation AlertsUpdateMany($updates: [alerts_updates!]!) {
		update_alerts_many(updates: $updates) {
			affected_rows
		}
	}
`);

export const AlertDeleteMutation = graphql(`
	mutation AlertDeleteMutation($id: String!) {
		delete_alerts_by_pk(id: $id) {
			id
		}
	}
`);

export const AlertsSubscription = graphql(`
	subscription AlertsSubscription($userId: String!) {
		alerts(where: { user_id: { _eq: $userId } }) {
			...AlertFragment
		}
	}
`);

export async function alertExists(hasura: GraphQLClient, id: string) {
  return await hasura
    .execute(AlertExistsQueryById, { id })
    .then((res) => res?.data?.alerts_by_pk);
}

export async function updateAlert(
  hasura: GraphQLClient,
  alerts: Record<string, Partial<AlertFragmentFragment>>,
) {
  const all = Object.entries(alerts).map(([id, v]) => ({
    where: { id: { _eq: id } },
    _set: v,
  }));

  return await hasura.execute(AlertsUpdateMany, {
    updates: all,
  });
}
