import { useMutation } from "@tanstack/react-query";

import {
  type DocumentVars,
  useGraphQLClient,
  useQuerySub,
} from "../utils/graphql";
import { graphql } from "../graphql";
import { useSubscription } from "../utils/graphql";

export const useUserPermissions = (userId: string) =>
  useQuerySub(UserPermissionsSub, {
    variables: { userId },
    queryKey: ["user_permissions"],
  });

const UserPermissionsSub = graphql(`
  subscription UserPermissionsSub($userId: String!) {
    permission(where: { folio_user: { _eq: $userId } }) {
      id
      packageByPackage {
        id
        name
        sourceBySource {
          id
          name
        }
      }
    }
  }
`);

export const useUserExchangeUsage = (userId: string) =>
  useSubscription(LastExchangeUsageForSingleUser, {
    variables: { userId },
    queryKey: ["exchange_usage_last", userId],
  });

const LastExchangeUsageForSingleUser = graphql(`
  subscription LastExchangeUsageForSingleUser($userId: String!) {
    exchange_usage_last(
      where: { folio_user: { _eq: $userId } }
      order_by: { event_date: desc }
    ) {
      source
      event
    }
  }
`);

export const useUserAmendExchangeUsage = () => {
  const graphql = useGraphQLClient();
  return useMutation({
    mutationFn: (arg: DocumentVars<typeof amendExchangeUsage>) =>
      graphql.execute(amendExchangeUsage, arg),
  });
};

export const amendExchangeUsage = graphql(`
  mutation AmendExchangeUsage(
    $exchangeUsages: [exchange_usage_insert_input!]!
  ) {
    insert_exchange_usage(objects: $exchangeUsages) {
      affected_rows
    }
  }
`);
