import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { type ReactNode, useCallback, useMemo } from "react";
import { type ApolloClient, ApolloProvider } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { atom, useAtom } from "jotai";

import { LogoutProvider, useLogout } from "../auth";
import { createApolloClient } from "./client";
import { useGlobalEnv } from "../environment";

const isAppAtom = atom(false);
export const useIsApp = () => useAtom(isAppAtom);

export type TApolloClient = ApolloClient<object>;

if (import.meta.env.DEV) {
  loadDevMessages();
  loadErrorMessages();
}

export const ApolloClientProvider = ({ children }: { children: ReactNode }) => {
  const [isApp] = useIsApp();
  const auth = useAuth0();
  const env = useGlobalEnv();
  const logout = useLogout();

  const client = useMemo(
    () => createApolloClient(env, auth.getAccessTokenSilently, isApp),
    [auth.getAccessTokenSilently, isApp, env],
  );

  const onLogout = useCallback(async () => {
    await client.clearStore().catch(console.error);
    return logout();
  }, [client, logout]);

  return (
    <ApolloProvider client={client}>
      <LogoutProvider value={onLogout}>{children}</LogoutProvider>
    </ApolloProvider>
  );
};
