import { z } from "zod";

import {
  monthCodeToOffset,
  rowStringToCode,
} from "../../market-grid/periodHelpers";
import {
  isEodStorageType,
  isGlobalStorageType,
  isPrivateStorageType,
  isSharedStorageType,
} from "../../market-grid/statuses/statusLogic";
import type { TStatusMap } from "../../../triplit/schema";
import type { Product_Artis_Type_Enum } from "../../../__generated__/gql/graphql";
import type { TProductConfigs } from "../../../data/cache";

import type { TGetGridData, TGridDataEntry, ProductForCalcs } from "./types";
import { manualEntry } from "./manual-entry";
import { livePrices } from "./live-prices";
import { latestEdit } from "./latest-edit";
import { products } from "./products";
import { settings } from "./settings";
import { calcite } from "./calcite";
import { productAlerts } from "./product-alerts";
import { alerts } from "./alerts";
import { user } from "./user";
import { eod } from "./eod";
import { isNumber } from "remeda";

export type * from "./manual-entry";
export type * from "./live-prices";
export type * from "./latest-edit";
export type * from "./products";
export type * from "./types";
export type * from "./user";
export type * from "./eod";

export const stores = {
  manualEntry,
  latestEdit,
  livePrices,
  products,
  settings,
  calcite,
  productAlerts,
  alerts,
  user,
  eod,
};

export function retrieveCellValues({
  columns,
  rowIds,
  productConfigs,
  eodEvalDate,
}: TGetGridData & {
  productConfigs: TProductConfigs;
  eodEvalDate?: string;
}): TGridDataEntry[] {
  if (columns.length === 0) return [];
  return rowIds.map(([rowType, rowId], rowIdx) => {
    return columns.reduce((coll, column) => {
      const columnId = column.columnId;
      const isPermissioned = column.isPermissioned;

      if (!isPermissioned) {
        coll[columnId] = { Err: "no permission" };
        return coll;
      }

      const customerCurve = column.artisType === "customer_curve";
      const isMonthRow = rowType === "mth";
      // if no config for this row then skip
      if (
        customerCurve &&
        isMonthRow &&
        productConfigs?.[column.productId]?.[rowIdx] === undefined
      ) {
        return coll;
      }
      const lookupKey = {
        productId: column.productId,
        rowId,
        selector: column.selector,
      };

      if (!customerCurve && isMonthRow) {
        if (column.artisType === "eod") {
          // its a standalone eod curve
          if (!eodEvalDate || !column.eodId) {
            return coll;
          }

          const eodValue = stores.eod.getValue({
            productId: column.productId,
            rowId,
            evaluationDate: eodEvalDate,
          });

          coll[columnId] = { Ok: eodValue };
          return coll;
        }

        // it's a live market cell
        const livePricesValue = stores.livePrices.getValue(lookupKey);
        coll[columnId] = { Ok: livePricesValue };
        return coll;
      }

      // it's either a formula or a shared cell
      if (column.hasSharedCell && isMonthRow) {
        const currentOffset = monthCodeToOffset(rowId);
        const formulaProduct = productConfigs[column.productId];
        const isFormula = formulaProduct?.find(
          (f) => f.offset === currentOffset && f.formula,
        );

        // it's definitely a shared cell
        if (customerCurve && !isFormula) {
          const status = column.status;

          if (isSharedStorageType(status)) {
            const sharedCellValue = stores.manualEntry.getValue({
              productId: column.productId,
              storageType: status === "broadcast" ? "broadcast" : "shared",
            });

            coll[columnId] = { Ok: sharedCellValue[rowId] };
          } else if (isGlobalStorageType(status)) {
            const globalCellValue = stores.manualEntry.getValue({
              productId: status,
              storageType: "global",
            });
            coll[columnId] = { Ok: globalCellValue[rowId] };
          } else if (isEodStorageType(status)) {
            if (!column.eodId) {
              console.error(
                "EOD ID is missing for eod status product, this should never happen",
                {
                  column,
                  rowId,
                },
              );
              return coll;
            }
            if (!eodEvalDate) {
              console.error(
                "EOD evaluation date is missing for eod status product, this should never happen",
                {
                  column,
                  rowId,
                },
              );
              return coll;
            }
            const eodValue = stores.eod.getValue({
              productId: column.eodId,
              rowId,
              evaluationDate: eodEvalDate,
            });

            coll[columnId] = { Ok: eodValue };
          } else if (isPrivateStorageType(status)) {
            const cellValue = stores.manualEntry.getValue({
              productId: column.productId,
              storageType: "local",
            });

            coll[columnId] = { Ok: cellValue[rowId] };
          }
          return coll;
        }
      }
      // it's a formula
      if (isMonthRow) {
        const formulaValue = stores.calcite.getCalculatedResult(lookupKey);

        coll[columnId] = formulaValue;

        return coll;
      }
      // it's an aggregated cell
      const aggregatedValue = stores.calcite.getAggregatedResult(lookupKey);

      coll[columnId] = aggregatedValue;

      return coll;
    }, {} as TGridDataEntry);
  });
}

export async function getGridData({
  rowIds,
  columns,
  adhocSpreads,
}: TGetGridData) {
  const productConfigs = stores.products.getConfigs();

  if (!productConfigs) return [];

  const entries = retrieveCellValues({
    rowIds,
    columns,
    productConfigs,
    eodEvalDate: stores.settings.getEodDate(),
  });

  // Calculate adhoc spreads
  if (adhocSpreads && Object.keys(adhocSpreads || {})?.length > 0) {
    rowIds.map(([rowType, rowId], rowIdx) => {
      if (rowType === "adhoc") {
        columns.map((column) => {
          const spread = adhocSpreads[rowId];
          const [from, to] = [
            spread?.from?.periodValue,
            spread?.to?.periodValue,
          ];

          if (from && to) {
            const [fromRowId, toRowId] = [
              rowStringToCode(from),
              rowStringToCode(to),
            ];

            const [fromRowIndex, toRowIndex] = [
              rowIds.findIndex(([, rId]) => rId === fromRowId),
              rowIds.findIndex(([, rId]) => rId === toRowId),
            ];

            const [fromValue, toValue] = [
              entries?.[fromRowIndex]?.[column.columnId]?.Ok,
              entries?.[toRowIndex]?.[column.columnId]?.Ok,
            ];

            if (typeof fromValue === "number" && typeof toValue === "number") {
              const spreadValue = fromValue - toValue;
              entries[rowIdx][column.columnId] = { Ok: spreadValue };
            }
          }
        });
      }
    });
  }

  return entries;
}

export function getAllManualCellsForCalcsStore(
  statusMap: TStatusMap,
  eodEvalDate: string,
) {
  try {
    const products: ProductForCalcs[] = [];

    // looping over all shared columns
    for (const [key, value] of stores.manualEntry.cells.entries()) {
      const [productId] = key.split(":");
      // looping over each single month cell (per column i.e. vertical)
      // rowId is monthCode
      // filter out rows before the current month
      for (const rowId in value) {
        // figure out status
        const status = statusMap?.[productId];
        const offset = monthCodeToOffset(rowId);
        if (!isNumber(offset)) continue;

        // initiate a product map for calcs
        const productForCalcs: ProductForCalcs = {
          product: productId,
          field: "value",
          offset,
          result: value[rowId],
        };

        // we override the result based on the storage type
        if (isEodStorageType(status)) {
          const productEodId =
            stores.products.getProductInfo(productId)?.eod_product_dep;
          if (productEodId) {
            productForCalcs.result = stores.eod.getValue({
              productId: productEodId,
              rowId,
              evaluationDate: eodEvalDate,
            });
          }
        } else if (isGlobalStorageType(status)) {
          // defaulting to 111111 as not implemented just yet
          const productId = status || "";
          const globalEntry = stores.manualEntry.getValue({
            productId,
            storageType: "global",
          });
          productForCalcs.result = globalEntry?.[rowId];
        } else if (isPrivateStorageType(status)) {
          const localEntry = stores.manualEntry.getValue({
            productId,
            storageType: "local",
          });
          productForCalcs.result = localEntry?.[rowId];
        } else if (status === "broadcast") {
          const broadcastEntry = stores.manualEntry.getValue({
            productId,
            storageType: "broadcast",
          });
          productForCalcs.result = broadcastEntry?.[rowId];
        } else {
          const sharedEntry = stores.manualEntry.getValue({
            productId,
            storageType: "shared",
          });
          productForCalcs.result = sharedEntry?.[rowId];
        }

        products.push(productForCalcs);
      }
    }

    return products;
  } catch (e) {
    console.error("Error in getAllManualCellsForCalcsStore", e);
    return [];
  }
}

const artisType = z.enum(["canned", "customer_curve", "eod", "sourced"]);

export function parseArtisType(
  value: string | undefined,
): Product_Artis_Type_Enum | null {
  if (!value) return null;
  return artisType.safeParse(value)?.data ?? null;
}
