import toast from "react-hot-toast";
import { AlertToast } from "../ui";

export const alertProductChanged = (alertProducts: Array<{ name: string }>) => {
  const title =
    alertProducts.length > 1 ? "Products changed" : "Product changed";
  const list = alertProducts.map((p) => <li key={p.name}>{p.name}</li>);
  const id = alertProducts.map((x) => x.name).join("");
  toast.custom(
    (t) => (
      <AlertToast
        id={id}
        title={title}
        hidden={!t.visible || t.dismissed}
        onDismiss={() => toast.dismiss(t.id)}
        message={<ul>{list}</ul>}
      />
    ),
    { id, position: "bottom-right", duration: Number.POSITIVE_INFINITY },
  );
};
