import { Button } from "@mui/joy";

export function CloseButton({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <Button
      variant="solid"
      color="neutral"
      onClick={onClick}
      sx={{
        width: "100px",
        backgroundColor: "var(--artis-neutral)",
      }}
    >
      Close
    </Button>
  );
}
