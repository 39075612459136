import {
  Box,
  Typography,
  FormControl,
  Checkbox,
  Button,
  useTheme,
} from "@mui/joy";
import { type ChangeEvent, type FormEvent, useState } from "react";

import { useCurrentUserOrg, useUserId } from "../../context/auth";
import { useUserAmendExchangeUsage } from "../../data";
import { licenseAgreementZIndex } from "../globals";
import { ArtisLogo } from "../../images/ArtisLogo";
import { useAllLicenseAgreements } from "../../sanity/queries";
import type { Block, Span } from "../../__generated__/sanity/graphql-request";
import { sideBarWidth } from "../globals";

export const agreementTypes = [
  "CME_NYMEX",
  "ICE_FUTURES_EUROPE",
  "ICE_FUTURES_ABU_DHABI",
  "ICE_ENDEX",
  "IDS",
  "TP_ICAP",
  "ARTIS_XC",
] as const;

export type TAgreementType = (typeof agreementTypes)[number];

const replacePlaceholders = (
  text: string,
  username: string | undefined,
  org: string | undefined,
) => {
  const currentDate = new Date().toISOString().split("T")[0];
  return text
    .replace("[Date]", currentDate)
    .replace("[UserName]", username || "User")
    .replace("[OrganisationName]", org || "Organization");
};

const renderBlock = (
  block: Block,
  username: string | undefined,
  org: string | undefined,
) => {
  const { _key, style, children, listItem, level } = block;

  const renderChildren = () =>
    children?.map((child) => {
      if (child?._type === "span") {
        const spanChild = child as Span;
        const isStrong = child.marks?.includes("strong");
        const processedText = replacePlaceholders(
          spanChild.text || "",
          username,
          org,
        );

        return isStrong ? (
          <strong key={child._key}>{processedText}</strong>
        ) : (
          <span key={child._key}>{processedText}</span>
        );
      }
      return null;
    });
  if (listItem === "bullet") {
    return (
      <div
        key={_key}
        style={{
          display: "flex",
          alignItems: "flex-start",
          marginLeft: `${level ? level * 20 : 0}px`,
          marginBottom: "8px",
        }}
      >
        <ul style={{ marginLeft: "20px", listStyleType: "disc" }}>
          <li>{renderChildren()}</li>
        </ul>
      </div>
    );
  }
  switch (style) {
    case "h4":
      return (
        <Typography level="h4" key={_key} style={{ margin: "10px 0" }}>
          {renderChildren()}
        </Typography>
      );
    case "h3":
      return (
        <Typography level="h3" key={_key} style={{ margin: "10px 0" }}>
          {renderChildren()}
        </Typography>
      );
    default:
      return (
        <Typography key={_key} style={{ margin: "10px 0" }}>
          {renderChildren()}
        </Typography>
      );
  }
};

const AgreementRenderer = ({
  bodyRaw,
  username,
  org,
}: {
  bodyRaw: Block[];
  username: string | undefined;
  org: string | undefined;
}) => {
  if (!bodyRaw || bodyRaw.length === 0) {
    return <p>No agreement body available.</p>;
  }

  return <div>{bodyRaw.map((block) => renderBlock(block, username, org))}</div>;
};

export const LicenceAgreementForm = ({
  username,
  agreementType,
  sourceId,
}: {
  username: string | undefined;
  agreementType: TAgreementType;
  sourceId: number | undefined;
}) => {
  const { data: org } = useCurrentUserOrg();
  const { data: agreementsData } = useAllLicenseAgreements();
  const companyName = org?.data?.organisation_by_pk?.sourceBySource.name;
  const [accepted, setAccepted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const amend = useUserAmendExchangeUsage();
  const userId = useUserId();
  const agreements = agreementsData?.allLicenseAgreement ?? [];

  const filteredAgreements = agreements.filter(
    (agreement) =>
      agreement.title?.toUpperCase() === agreementType.toUpperCase(),
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccepted(event.target.checked);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!userId || !sourceId) return;
    setSubmitting(true);

    const newExchanges = [
      {
        folio_user: userId,
        source: sourceId,
        event: "start",
      },
    ];
    try {
      await amend.mutate({ exchangeUsages: newExchanges });

      // waiting for the subscription to update the reduces the likely hood of the same agreement being shown again
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setAccepted(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    }
  };
  const theme = useTheme();

  const isDark = theme.palette.mode === "dark";

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          width: {
            xs: "100%",
            md: `calc(100% - ${sideBarWidth}px)`,
          },
          position: "relative",
          left: {
            xs: 0,
            md: sideBarWidth,
          },
          zIndex: licenseAgreementZIndex,
          fontSize: "lg",
          p: 3,
        }}
      >
        <Box
          sx={{
            width: "130px",
            marginLeft: -1,
          }}
        >
          <ArtisLogo />
        </Box>

        <Box className="licence-agreements-top">
          <Box
            className="text-component"
            sx={{
              marginTop: 5,
            }}
          >
            <AgreementRenderer
              bodyRaw={filteredAgreements[0]?.bodyRaw}
              username={username}
              org={companyName}
            />
            <FormControl>
              <Checkbox
                id={"licence-agreement-checkbox"}
                label={`ACCEPT ${
                  !["ARTIS_XC", "TP_ICAP"].includes(agreementType)
                    ? agreementType.replace(/_/g, " ")
                    : ""
                } TERMS`}
                name={agreementType}
                checked={accepted}
                onChange={handleChange}
                color="primary"
                size="sm"
                sx={{
                  margin: "20px 0px",
                }}
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          className="licence-agreements-bottom"
          sx={{ marginBottom: "20px" }}
        >
          <Button
            variant="solid"
            type="submit"
            loading={submitting}
            disabled={submitting || !accepted}
          >
            {submitting ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
