import { graphql } from "../../graphql";

export const GridSettings = graphql(`
    fragment GridSettings on grid_settings {
        adhoc_spreads_rows
        adhoc_spreads_switch
        alternating_row_colours
        broadcast_colour
        cal_current
        cal_switch
        cals
        commodity_group
        commodity_parent_group
        description
        enable_full_month_row_feature
        eod_colour
        extra_header_height
        field_name
        flash_cell_updates
        geographical_region
        global_colour
        grid_scratchpad_switch
        header_column_colour
        hide_broadcast
        hide_eod
        hide_global
        hide_private
        hide_status_row
        hlv_current
        hlv_switch
        hlvs
        hybrid_colour
        increment_map
        indicator_colour
        listen_colour
        local_colour
        main_month_column_width
        months
        package
        qtr_current
        qtr_switch
        qtrs
        quarter_borders
        scratchpad_max_cols
        scratchpad_max_rows
        season_current
        season_switch
        seasons
        sound
        source
        status_map
        subheader_column_colour
        updated_at
        user_id
        eod_date
    }
`);

export const GridSettingsQuery = graphql(`
    query GridSettingsQuery($userId: String!) {
        grid_settings(where: { user_id: { _eq: $userId } }) {
            ...GridSettings
        }
    }
`);

export const GridSettingsSubscription = graphql(`
    subscription GridSettingsSubscription($userId: String!) {
        grid_settings(where: { user_id: { _eq: $userId } }) {
            ...GridSettings
        }
    }
`);

export const GridSettingsUpdate = graphql(`
    mutation GridSettingsUpdate($userId: String!, $values: grid_settings_set_input) {
        update_grid_settings_by_pk(pk_columns: { user_id: $userId }, _set: $values) {
            user_id
        }
    }
`);

export const GridSettingsInsert = graphql(`
    mutation GridSettingsInsert($object: grid_settings_insert_input!) {
        insert_grid_settings_one(object: $object) {
            ...GridSettings
        }
    }
`);
