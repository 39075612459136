import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { readFragment } from "../../graphql";
import { useGraphQLClient } from "../../utils/graphql";
import { useUserId } from "../../context/auth";
import type { AlertFragmentFragment } from "../../__generated__/gql/graphql";
import { logger } from "@artis/logger";
import { useActivePageId } from "../pages";
import {
  AlertDeleteMutation,
  AlertFragment,
  AlertInsertMutation,
  AlertQueryByPageId,
  AlertsUpdateMany,
} from "./alerts";

export const useAlertsByPageId = () => {
  const userId = useUserId();
  const pageId = useActivePageId();
  const hasura = useGraphQLClient();

  return useQuery({
    queryKey: ["alerts", userId, pageId],
    queryFn: async () => {
      const res = await hasura.execute(AlertQueryByPageId, { userId, pageId });
      return readFragment(AlertFragment, res?.data?.alerts ?? []);
    },
  });
};

export const useInsertAlert = () => {
  const userId = useUserId();
  const pageId = useActivePageId();
  const hasura = useGraphQLClient();
  const qry = useQueryClient();

  return useMutation({
    networkMode: "always",
    mutationFn: (alert: AlertFragmentFragment) => {
      return hasura.execute(AlertInsertMutation, { alert });
    },
    onError: (err) => logger.error(err),
    onSettled: () => {
      qry.invalidateQueries({ queryKey: ["alerts", userId, pageId] });
    },
  });
};

export const useUpdateAlert = () => {
  const userId = useUserId();
  const hasura = useGraphQLClient();
  const pageId = useActivePageId();
  const qry = useQueryClient();

  return useMutation({
    networkMode: "always",
    mutationFn: (alert: AlertFragmentFragment) => {
      const update = {
        where: { id: { _eq: alert.id } },
        _set: alert,
      };

      return hasura.execute(AlertsUpdateMany, {
        updates: [update],
      });
    },
    onError: (err) => logger.error(err),
    onSettled: () => {
      qry.invalidateQueries({ queryKey: ["alerts", userId, pageId] });
    },
  });
};

export const useDeleteAlert = () => {
  const userId = useUserId();
  const hasura = useGraphQLClient();
  const qry = useQueryClient();
  const pageId = useActivePageId();

  return useMutation({
    networkMode: "always",
    mutationFn: (id: string) => {
      return hasura.execute(AlertDeleteMutation, { id });
    },
    onMutate: async (id) => {
      qry.setQueryData<AlertFragmentFragment[]>(["alerts", userId], (old) => [
        ...(old ?? []).filter((x) => x.id !== id),
      ]);
    },
    onSettled: () => {
      qry.invalidateQueries({ queryKey: ["alerts", userId, pageId] });
    },
  });
};
