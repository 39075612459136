import { Typography, Link, Stack, Box } from "@mui/joy";
import { InfoModal } from "./InfoModal";
import { CloseButton } from "../components/CloseButton";

export function ContactUsModal({
  open,
  setClose,
}: {
  open: boolean;
  setClose: () => void;
}) {
  return (
    <InfoModal
      open={open}
      onClose={setClose}
      title={"Contact Support"}
      labelBy="contact-title"
      describedBy="contact-description"
      disableMinWidth
      showCloseButton={false}
    >
      <Stack
        sx={{
          pb: 3,
        }}
      >
        <Typography level="body-lg">
          You can contact us on WhatsApp at{" "}
          <Link
            href="https://wa.me/447537143416"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: (theme) => theme.palette.primary[500],
              textDecoration: "none",
              fontWeight: "bold",
              "&:hover": {
                textDecoration: "none",
                color: (theme) => theme.palette.primary[500],
              },
            }}
          >
            +44 7537 143416
          </Link>
        </Typography>
        <Stack direction={"row"} gap={1.4}>
          <Box
            component="img"
            src="/img/Artis_Support_QR.png"
            alt="WhatsApp QR Code"
            sx={{
              marginTop: "5px",
              width: 70,
              height: 70,
              backgroundColor: (theme) => theme.palette.common.white,
            }}
          />
          <Stack pt={2}>
            <Typography level="body-lg">
              or e-mail us at{" "}
              <Link
                href="mailto:support@artis.works"
                sx={{
                  color: (theme) => theme.palette.primary[500],
                  textDecoration: "none",
                  fontWeight: "bold",
                  "&:hover": {
                    textDecoration: "none",
                    color: (theme) => theme.palette.primary[500],
                  },
                }}
              >
                support@artis.works
              </Link>
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
          }}
        >
          <CloseButton onClick={() => setClose()} />
        </Stack>
      </Stack>
    </InfoModal>
  );
}
