import { atom, createStore, useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { logger } from "@artis/logger";

import Loading from "../components/Loading";

/**
 * Global loader component that overlays the entire page
 *
 * We use this instead of suspense because it allows us to
 * render the entire react tree in the background while
 * waiting for the worker to start.
 */
export const Loader = (p: { children: React.ReactNode }) => {
  const loading = useAtomValue($isLoading, { store });
  return (
    <>
      {loading && (
        <div className="fixed inset-0 z-[999]">
          <Loading showLogo />
        </div>
      )}
      {p.children}
    </>
  );
};

const store = createStore();
const $loading = atom<Record<string, boolean>>({});
const $isLoading = selectAtom($loading, (x) =>
  Object.values(x).some((x) => x === true),
);

export const showLoader = (name: string) => {
  store.set($loading, { ...store.get($loading), [name]: true });
  const timeout = setTimeout(() => {
    logger.error(`Loader (${name}) timed out at 20 seconds`);
    store.set($loading, { ...store.get($loading), [name]: false });
  }, 20_000);
  return () => {
    clearTimeout(timeout);
    store.set($loading, { ...store.get($loading), [name]: false });
  };
};
