import { Box, Modal, Typography, Button, Table } from "@mui/joy";
import type { Theme } from "@mui/joy/styles/types";
import type { Pricing } from "../../../src/__generated__/sanity/graphql-request";
import { ArtisLogo } from "../../images/ArtisLogo";
import { useState } from "react";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { logger } from "@artis/logger";

const renderCellContent = (cell: string) => {
  switch (cell) {
    case "<tick>":
      return "✓";
    case "<money>":
      return "$";
    case "<empty>":
      return "";
    default:
      return cell;
  }
};

const renderFullCellContent = (cell: string) => {
  const processedContent = renderCellContent(cell);
  if (typeof processedContent === "string" && /<\d+>/.test(processedContent)) {
    return renderCellWithSuperscripts(processedContent);
  }
  return processedContent;
};

const renderCellWithSuperscripts = (text: string) => {
  const regex = /<(\d+)>/g;
  const parts = text.split(regex);
  return parts.map((part, index) =>
    index % 2 === 1 ? (
      <Box
        component="sup"
        key={`sup-${part}`}
        sx={{
          verticalAlign: "super",
          fontSize: "0.75em",
          marginLeft: "2px",
          marginRight: "2px",
        }}
      >
        {part}
      </Box>
    ) : (
      <span key={`sup-${part}`}>{part}</span>
    ),
  );
};

export const PricingTable = ({ tableData }: { tableData: Pricing }) => {
  const rows = tableData?.body?.rows || [];
  logger.debug("Rendering pricing table", { rows });
  const hasSingleNonEmptyCell = (cells: (string | null | undefined)[]) => {
    return cells.filter((cell) => cell && cell.trim() !== "").length === 1;
  };
  const rowsWithIds = rows.map((row) => ({
    ...row,
    id: uuidv4(),
  }));
  return (
    <Table aria-label="pricing table">
      <thead>
        <tr>
          {rows[0]?.cells?.map((cell, index: number) => (
            <th
              key={cell}
              style={{
                width: index === 0 ? "50%" : "auto",
                textTransform: "uppercase",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {cell ? renderFullCellContent(cell) : ""}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowsWithIds.slice(1).map((row, rowIndex) => {
          if (!row) return null;
          const isSingleNonEmpty = hasSingleNonEmptyCell(row.cells ?? []);

          return (
            <tr key={row.id}>
              {row?.cells?.map((cell, cellIndex: number) => (
                <td
                  key={`row-${rowIndex}-${cell}`}
                  style={{
                    textTransform: isSingleNonEmpty ? "uppercase" : "none",
                    fontWeight: isSingleNonEmpty ? "bold" : "normal",
                    textAlign: cellIndex === 0 ? "left" : "center",
                  }}
                >
                  {cell ? renderFullCellContent(cell) : ""}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export function PricingModal({
  open,
  setClose,
  theme,
  pricingData,
  email,
}: {
  open: boolean;
  setClose: () => void;
  theme: Theme;
  pricingData: Pricing;
  email: string | undefined;
}) {
  const isDark = theme.palette.mode === "dark";
  const subscripts = pricingData?.subscripts?.filter(Boolean) || [];

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Request subscription upgrade");
  const [isLoading, setIsLoading] = useState(false);

  const requestUpgrade = async () => {
    setIsLoading(true);
    setIsButtonDisabled(true);

    try {
      const response = await fetch("/.netlify/functions/send-to-slack", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email || "unknown",
        }),
      });

      const contentType = response.headers.get("content-type");

      let result: string | Record<string, unknown>;

      if (contentType?.includes("application/json")) {
        result = (await response.json()) as string | Record<string, unknown>;
      } else {
        result = await response.text();
      }

      if (response.ok) {
        logger.info("Upgrade request successful", { result });
        setButtonText("We'll be in touch! ");
      } else {
        logger.error("Failed to request upgrade", { result });

        setIsLoading(false);
        toast.error("Failed to request upgrade. Please try again.");

        setIsButtonDisabled(false);
      }
    } catch (error) {
      logger.error("Error requesting upgrade", { error });

      setIsLoading(false);
      toast.error("Failed to request upgrade. Please try again.");
      setIsButtonDisabled(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      disablePortal={true}
      open={open}
      onClose={setClose}
      sx={{
        "&:focus-visible": {
          outline: "none",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "90%",
          maxWidth: "700px",
          bgcolor: "white",
          borderRadius: "sm",
          background: theme.palette.background.surface,
          p: 0,
          paddingBottom: 1,
          maxHeight: "90vh",
          outline: "none",
        }}
      >
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "70vh",
            padding: 3,
          }}
        >
          <Box
            sx={{
              background: "var(--navBackgroundColor)",
              paddingBottom: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "150px",
            }}
          >
            <ArtisLogo />
          </Box>
          <Box sx={{ padding: "20px" }}>
            <PricingTable tableData={pricingData} />
          </Box>
          {subscripts.length > 0 && (
            <Box sx={{ marginTop: "20px", padding: "20px" }}>
              {subscripts.map((subscript: string, index: number) => (
                <Typography
                  level="body-xs"
                  key={subscript}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <sup
                    style={{
                      marginRight: "3px",
                      marginBottom: "5px",
                      fontSize: "7px",
                    }}
                  >
                    {index + 1}
                  </sup>
                  {subscript}
                </Typography>
              ))}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
              mt: 2,
            }}
          >
            <Button
              variant="solid"
              color="primary"
              onClick={requestUpgrade}
              disabled={isButtonDisabled}
            >
              {isLoading ? "Sending..." : buttonText}
            </Button>
            <Button variant="solid" color="neutral" onClick={setClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
