import { atom } from "jotai";
import {
  getManualInstrumentType,
  knownStatusList,
  type TStatus,
} from "./statusLogic";
import { useCallback } from "react";

import { objectEntries } from "../../../utils";
import {
  knownStatusOptions,
  knownStatusRules,
  statusTransitions,
  type TKnownStatusRules,
  type TStatusOption,
} from "./consts";
import { monthRowIds, offsetToRow } from "../periodHelpers";
import {
  type TProduct,
  type TGridSettings,
  useUpdateGridSettings,
  useGridSettingsStatusMap,
  useGlobalPackagesByProductIds,
  useGlobalProductsByProductIds,
} from "../../../data";
import {
  type CalcWorker,
  getCalcWorker,
  type TOptimisticCell,
} from "../../calculations-worker";

export const changeAllHoverAtom = atom<boolean>(false);

export function useUpdateColumnStatus() {
  const statusMap = useGridSettingsStatusMap();
  const update = useUpdateGridSettings();
  return useCallback(
    (toStatus: TStatus, productIds: string[]) => {
      const status_map = { ...statusMap };
      productIds.forEach((productId) => {
        status_map[productId] = toStatus;
      });
      return update.optimistic({ status_map });
    },
    [statusMap, update.optimistic],
  );
}

async function writeStatusInstruments(
  {
    to,
    from,
    curveId,
    gridId,
  }: {
    to: TStatus;
    from: TStatus;
    curveId: string;
    gridId: string;
  },
  getData: CalcWorker["getGridData"],
  updateCell: CalcWorker["optimisticCellEdit"],
) {
  const storageType = getManualInstrumentType(to);
  if (!storageType || !knownStatusList.includes(to)) {
    console.error(`can't write when going to status: ${to}`);
    return;
  }
  const rowIds = monthRowIds.map((month, idx) => ["mth", month, idx] as const);
  const rawCellValues = await getData({
    rowIds,
    columns: [
      {
        eodId: undefined,
        columnId: gridId,
        productId: curveId,
        artisType: "customer_curve",
        hasSharedCell: true,
        selector: "value",
        status: from,
        isPermissioned: true,
      },
    ],
  });
  if (!rawCellValues) return;
  const cells: TOptimisticCell[] = [];
  for (const rowId of rowIds) {
    const rowIdx = rowId[2];
    const cell = rawCellValues[rowIdx];
    const month = offsetToRow[rowIdx];
    const result = cell?.[gridId]?.Ok ?? undefined;
    if (typeof result === "string") {
      console.error(`Result is string: ${result}`);
      continue;
    }
    cells.push({
      result,
      month,
      storageType,
      offset: rowIdx,
      product: curveId,
      field: "value",
      rowId: rowId[1],
    });
  }
  updateCell(cells);
}

export function useWriteStatusInstruments() {
  return useCallback(
    async (params: Parameters<typeof writeStatusInstruments>[0]) => {
      await writeStatusInstruments(
        { ...params },
        getCalcWorker().getGridData,
        getCalcWorker().optimisticCellEdit,
      );
    },
    [],
  );
}

export function checkCanBroadcast(
  permissions: TProduct["packageByPackage"]["permissions"] | null | undefined,
) {
  return permissions?.some(
    (perm) => perm.permission === "broadcast" || perm.permission === "write",
  );
}

export function verifyKnownStatusRules(
  rules: TKnownStatusRules | undefined,
  resolvedRules: Required<TKnownStatusRules>,
) {
  return (
    !rules ||
    objectEntries(rules).every(([rule, bool]) => bool === resolvedRules[rule])
  );
}

const sourceToGlobalPackageIcons: Record<number, string> = {
  33: "OB_favicon.png",
  29: "OB_favicon.png",
  4: "logo-TP.png",
  369: "logo-TP.png",
  5: "logo-ICAP.png",
  368: "logo-ICAP.png",
  6: "logo-PVM.png",
  367: "logo-PVM.png",
  28: "logo-Artis.svg",
  27: "logo-Marex.png",
};

export function useGlobalDependencyOptions(
  products: TProduct[] | null | undefined,
) {
  const dependencies =
    products?.flatMap((product) => product.product_global_dependencies) || [];

  const { data: globalProducts } = useGlobalProductsByProductIds(
    dependencies.map((dep) => dep.global_product),
  );
  const { data: globalDependenciesDetails } = useGlobalPackagesByProductIds(
    dependencies.map((dep) => dep.global_product),
  );
  if (!products || !globalDependenciesDetails) return [];

  const uniqueOptions = new Set<number>();

  return dependencies.reduce<TStatusOption[]>((acc, dep) => {
    const productId = dep.global_product;
    const productDetail = globalProducts?.find((prod) => prod.id === productId);
    const globalPackageId = productDetail?.global_package;
    const globalPackageDetail = globalDependenciesDetails.find(
      (pkg) => pkg.id === globalPackageId,
    );

    const iconPath = globalPackageDetail?.source
      ? `/icons/${sourceToGlobalPackageIcons[globalPackageDetail.source]}`
      : undefined;

    const option = {
      value: productId,
      label: globalPackageDetail?.description || "",
      style: undefined,
      icon: undefined,
      image: false,
      logo: iconPath || undefined,
    } satisfies TStatusOption;

    if (
      globalPackageDetail?.source &&
      !uniqueOptions.has(globalPackageDetail.source)
    ) {
      uniqueOptions.add(globalPackageDetail.source);
      acc.push(option);
    }

    return acc;
  }, []);
}

export function genSelectStatusOptions({
  resolvedRules,
  globalOptions,
  settings,
}: {
  resolvedRules: Required<TKnownStatusRules>;
  globalOptions: ReturnType<typeof useGlobalDependencyOptions>;
  settings: TGridSettings | null | undefined;
}) {
  return [
    ...knownStatusOptions
      // to compare the hard coded rules in statusOptions with the resolved rules
      // belonging to the product in question.
      .filter(({ value }) => {
        let userDisabled = false;
        switch (value) {
          case "eod":
            userDisabled = !!settings?.hide_eod;
            break;
          case "private":
            userDisabled = !!settings?.hide_private;
            break;
          case "broadcast":
            userDisabled = !!settings?.hide_broadcast;
            break;
        }
        return (
          !userDisabled &&
          verifyKnownStatusRules(knownStatusRules[value], resolvedRules)
        );
      }),
    ...(settings?.hide_global ? [] : globalOptions),
  ] satisfies TStatusOption[];
}

export function genStatusTransitionActions({
  from,
  to,
}: {
  from: string | undefined;
  to: string;
}) {
  return statusTransitions.find(
    (entry) => (entry.from === "*" || entry.from === from) && entry.to === to,
  );
}
