import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { datadogRum } from "@datadog/browser-rum";
import { Box } from "@mui/joy";
import { z } from "zod";
import { Layout } from "./layout/Skeleton";
import { rootRoute } from "../baseRoutes";

const rootWebappRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "app",
  component: Layout,
});

const marketSearchSchema = z.object({
  columnSettingsModal: z
    .object({
      columnId: z.string().optional(),
      initialColumnWidth: z.number().optional(),
      initialThousandsSeparator: z.boolean().optional(),
      initialDecimalPlaces: z.number().optional(),
      increment: z.number().optional(),
    })
    .optional(),
});

const userGuideSchema = z.object({
  id: z.string().optional(),
});

const marketHomeRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "market",
  validateSearch: marketSearchSchema,
  onEnter: () => datadogRum.startView({ name: "Market Home" }),
  component: lazyRouteComponent(() => import("./MarketView"), "MarketHome"),
});

const marketPageRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "market/$id",
  validateSearch: marketSearchSchema,
  onEnter: () => datadogRum.startView({ name: "Market Page" }),
  component: lazyRouteComponent(() => import("./MarketView"), "MarketPage"),
});

const managePagesRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "manage-pages",
  onEnter: () => datadogRum.startView({ name: "Manage Pages" }),
  component: () => <Box> manage pages </Box>,
});

const userGuideRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "user-guide",
  validateSearch: userGuideSchema,
  onEnter: () => datadogRum.startView({ name: "User Guide" }),
  component: lazyRouteComponent(() => import("./UserGuide"), "UserGuide"),
});

const tradingChartRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "trading-chart",
  onEnter: () => {
    document.title = "Artis Charts";
    datadogRum.startView({ name: "Trading Chart" });
  },
  onLeave: () => {
    document.title = "Artis";
  },
  component: lazyRouteComponent(
    () => import("./live-charts/ChartsLayout"),
    "ChartsLayout",
  ),
});

const singleTradingChartRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "trading-chart/$productId/$month",
  onEnter: () => {
    document.title = "Artis Charts";
    datadogRum.startView({ name: "Trading Chart" });
  },
  onLeave: () => {
    document.title = "Artis";
  },
  validateSearch: z.object({
    productId: z.string().optional(),
    month: z.string().optional(),
  }),
  component: lazyRouteComponent(
    () => import("./live-charts/ChartsLayout"),
    "SingleChartLayout",
  ),
});

export const webappRoute = rootWebappRoute.addChildren([
  marketHomeRoute,
  marketPageRoute,
  managePagesRoute,
  tradingChartRoute,
  singleTradingChartRoute,
  userGuideRoute,
]);
