import {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  type ReactNode,
} from "react";
import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import posthog from "posthog-js";

import { clearIdbCache } from "../../webapp/calculations-worker";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "../../globals";

// Broadcast channel for logout events
const LogoutBroadcastChannel = new BroadcastChannel("logout");
const LogoutContext = createContext(() => Promise.resolve());
export const LogoutProvider = LogoutContext.Provider;
export const useLogout = () => useContext(LogoutContext);

export function AuthProvider({ children }: { children: ReactNode }) {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      useRefreshTokensFallback={true}
      onRedirectCallback={(state) => {
        posthog.capture("login", {
          $set_once: { first_login: new Date().toISOString() },
        });
        if (state?.returnTo) {
          window.location.href = window.location.origin + state.returnTo;
        }
      }}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "hasura",
      }}
    >
      <ProvideLogout>
        <AuthRedirect>{children}</AuthRedirect>
      </ProvideLogout>
    </Auth0Provider>
  );
}

const AuthRedirect = withAuthenticationRequired(Fragment, {
  loginOptions: { appState: { returnTo: window.location.pathname } },
});

const ProvideLogout = (p: { children: React.ReactNode }) => {
  const { logout } = useAuth0();

  const onLogout = useCallback(async () => {
    const isUmi = window.location.pathname.includes("admin");

    // 1. Track logout in PostHog
    posthog.capture("user logout");
    posthog.reset();

    // 2. Clear synchronous storages
    sessionStorage.clear();
    localStorage.clear();
    clearIdbCache();

    // 3. Clear cookies (synchronous)
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name =
        eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim();
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });

    // 4. Prepare async clearing steps
    //    Using Promise.all so we wait for all tasks to complete
    const clearCaches = (async () => {
      if ("caches" in window) {
        const cacheNames = await caches.keys();
        await Promise.all(
          cacheNames.map((cacheName) => caches.delete(cacheName)),
        );
      }
    })();

    const clearIndexedDB = (async () => {
      if (window.indexedDB && indexedDB.databases) {
        const dbs = await indexedDB.databases();
        for (const db of dbs) {
          if (db.name) {
            indexedDB.deleteDatabase(db.name);
          }
        }
      }
    })();

    const clearServiceWorkers = (async () => {
      if (navigator.serviceWorker) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of registrations) {
          await registration.unregister();
        }
      }
    })();

    // 5. Wait for all asynchronous steps to finish
    await Promise.all([clearCaches, clearIndexedDB, clearServiceWorkers]);

    // 6. Broadcast logout event after everything is cleared
    LogoutBroadcastChannel.postMessage("logout");

    // 7. Finally, call Auth0’s logout
    return logout({
      logoutParams: {
        returnTo: `${window.location.origin}${isUmi ? "/admin/orgs" : ""}`,
      },
    });
  }, [logout]);

  useEffect(() => {
    // Listen for “logout” messages from other tabs and call our onLogout
    LogoutBroadcastChannel.addEventListener("message", onLogout);
    return () => {
      LogoutBroadcastChannel.removeEventListener("message", onLogout);
    };
  }, [onLogout]);

  return (
    <LogoutContext.Provider value={onLogout}>
      {p.children}
    </LogoutContext.Provider>
  );
};
