import { graphql } from "../../graphql";

export const ProductInfoFragment = graphql(`
  fragment ProductInfoFragment on product {
    id
    artis_type
    code
    commodity_group
    description
    eod_product_dep
    geographical_region
    has_shared_cell
    logical_code
    maturity
    name
    package
    product_type
    summary_type
    uom
    packageByPackage {
      package_type
      name
      permissions(limit: 1, where: { folio_user: { _eq: $folioUser } }) {
        permission
        folio_user
      }
      sourceBySource {
        name
        logo
        exchange {
          code
        }
      }
    }
    commodityGroupByCommodityGroup {
      parent
      commodity_group: value
      commodity_parent_group {
        commodity_parent_group: value
      }
    }
    geographicalRegionByGeographicalRegion {
      geographical_region: value
    }
    product_global_dependencies {
      global_product
    }
  }
`);

export const ConfigsQuery = graphql(`
  query ConfigsQuery {
    product_config(
      order_by: { relative_month: asc },
      where: {
          relative_month: { _lte: 60, _gte: 0 }
      }
    ) {
      formula
      product
      offset: relative_month
    }
  }
`);

export const ConfigsSub = graphql(`
  subscription ConfigsSub {
    product_config(
      order_by: { relative_month: asc },
      where: {
          relative_month: { _lte: 60, _gte: 0 }
      }
    ) {
      formula
      product
      offset: relative_month
    }
  }
`);

export const ProductInfoQuery = graphql(`
  query ProductInfo($folioUser: String!) {
    product {
      ...ProductInfoFragment
    }
  }
`);

export const ProductInfoSub = graphql(`
  subscription ProductInfoSub($folioUser: String!) {
    product {
      ...ProductInfoFragment
    }
  }
`);

export const gridProductsByIds = graphql(`
  query GridProductsByIds($ids: [uuid!]!, $folioUser: String!) {
    product(where: { id: { _in: $ids } }) {
      ...ProductInfoFragment
    }
  }
`);

export const globalProductsForLiveCharts = graphql(`
  query GlobalProductsForLiveCharts($folioUser: String!) {
    global_product {
      id
      name
      description
      uom
      geographical_region
      commodity_group
      global_package
      globalPackageByGlobalPackage {
        id
        name
        source
        description
        package_type
        global_permissions(
          limit: 1
          where: { folio_user: { _eq: $folioUser } }
        ) {
          permission
          folio_user
        }
      }
    }
  }
`);

export const globalPackagesByProductsIds = graphql(`
  query GlobalPackagesByProductIds($productIds: [uuid!]!) {
    global_package(where: { global_products: { id: { _in: $productIds } } }) {
      id
      source
      description
    }
  }
`);

export const globalProductsByProductsIds = graphql(`
  query GlobalProductsByProductsIds($productIds: [uuid!]!) {
    global_product(where: { id: { _in: $productIds } }) {
      id
      global_package
    }
  }
`);
