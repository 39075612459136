import type { DocumentType } from "../__generated__/gql";
import type { ProductFragment } from "../admin/products";
import type { TProductConfig } from "../data/cache";

export type TProduct = DocumentType<typeof ProductFragment>;

export const formatProductName = (product: {
  name: string;
  description: string;
  packageName: string;
}) => {
  const { name, description, packageName } = product;
  return `${name}${name !== description ? ` (${description})` : ""}: ${packageName}`;
};

/**
 * Formats a formula string by replacing product IDs with their corresponding names.
 *
 * @param products - The list of products with their IDs and names.
 * @param formulaString - The string containing formula with product IDs.
 *
 * @returns The formula string with product IDs replaced by their corresponding names.
 */
export const formulaRegex = /\[(.*?)\]/g;

export function formatProductFormula(
  products: (Pick<TProduct, "id" | "name" | "description"> & {
    packageByPackage: { name: string };
  })[],
  formulaString?: string | null,
): string | null {
  if (!formulaString || !products) return null;

  const productMap = new Map(products.map((product) => [product.id, product]));

  return formulaString.replace(
    formulaRegex,
    (fullMatch: string, id: string) => {
      const product = productMap.get(id);
      return product
        ? `[${formatProductName({
            name: product.name,
            description: product.description || "",
            packageName: product.packageByPackage.name || "",
          })}]`
        : fullMatch;
    },
  );
}

/**
 * Retrieves and formats formulas from a given product's configs.
 * @param products - The collection of products.
 * @param product - The product from which to extract formulas.
 * @returns A formatted string containing the extracted formulas.
 */
export function getFormulasFromProduct(
  products: TProduct[],
  product: TProduct,
): string {
  const configs = product.product_configs;
  if (!configs) return "";

  const uniqueFormulas = new Set(
    configs.map((config) =>
      config?.formula == null
        ? "MANUAL"
        : formatProductFormula(products, config?.formula) || "",
    ),
  );

  return Array.from(uniqueFormulas).join(", ");
}

type TSortedFormulas = Array<{
  month: string | undefined;
  formula: string | undefined;
}>;

type TSortedFormulasState = {
  result: TSortedFormulas;
  startMonth?: number;
  currentFormula?: string | null;
};

function areFormulaStringsEqual(
  a: string | undefined | null,
  b: string | undefined | null,
): boolean {
  return a?.replace(/\s/g, "") === b?.replace(/\s/g, "");
}

/**
 * Group identical formulas and render with a range of month codes.
 *
 * @param configs - The product configurations.
 * @returns The sorted formulas.
 */
export function sortFormulasByMonth(configs: TProductConfig[] = []) {
  const { length } = configs;
  if (!length) return [];

  const state: TSortedFormulasState = { result: [] };
  let startMonth = configs[0]?.offset;

  for (const [index, config] of configs.entries()) {
    const isLast = index === length - 1;
    const { formula, offset: currentMonth } = config;
    const previousMonth = index > 0 ? configs[index - 1].offset : undefined;

    if (
      index === 0 ||
      !areFormulaStringsEqual(formula, configs[index - 1].formula)
    ) {
      if (index !== 0 && startMonth !== undefined) {
        state.result.push({
          month: `M${startMonth}${startMonth !== previousMonth ? ` - M${previousMonth}` : ""}`,
          formula: state.currentFormula ?? "MANUAL",
        });
      }
      state.currentFormula = formula;
      startMonth = currentMonth;
    }

    if (isLast && startMonth !== undefined) {
      state.result.push({
        month: `M${startMonth}${startMonth !== currentMonth ? ` - M${currentMonth}` : ""}`,
        formula: state.currentFormula ?? "MANUAL",
      });
    }
  }

  return state.result;
}
