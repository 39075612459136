import { createContext, useContext } from "react";
import type { GraphQLClient } from "./index";

const GraphQLContext = createContext<GraphQLClient | null>(null);
export const GraphQLProvider = GraphQLContext.Provider;

export const useGraphQLClient = () => {
  const client = useContext(GraphQLContext);
  if (!client) throw new Error("Missing hasura client provider");
  return client;
};
