import { SideNavIconContainer } from "./SideNavIconContainer";

function Icon({ selected }: { selected: boolean }) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: We have a custom tooltip
    <svg
      className={selected ? "nav-icon selected" : "nav-icon"}
      width="42"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5 18.6266V28.3732C36.5 29.6393 35.3533 30.6666 33.94 30.6666H7.06C5.64667 30.6666 4.5 29.6393 4.5 28.3732V18.6266C4.5 17.3605 5.64667 16.3333 7.06 16.3333H33.94C35.3533 16.3333 36.5 17.3605 36.5 18.6266Z"
        fill="white"
      />
      <path
        className="arrow"
        d="M29.0527 19.1377L32.8217 19.2627L32.6822 22.6385"
        strokeWidth="2"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        className="arrow"
        d="M10.6318 28.0519L18.2901 21.6816L24.1704 26.4592C24.1704 26.4592 30.4232 21.2579 32.8219 19.2627"
        strokeWidth="2"
        strokeMiterlimit={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.55762 13.3333H33.4465"
        stroke="white"
        strokeWidth="2.77778"
        strokeMiterlimit={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5576 9.33325H31.891"
        stroke="white"
        strokeWidth="2.77778"
        strokeMiterlimit={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function HFCIcon({ selected = false }: { selected?: boolean }) {
  return (
    <SideNavIconContainer>
      <Icon selected={selected} />
    </SideNavIconContainer>
  );
}
