import type { GridApi } from "ag-grid-community";
import { atom } from "jotai";
import { unique } from "remeda";

import type { validColumnSettings } from "../column-defs";
import { getPeriodByRowId } from "../modals/ConditionalFormatting/helpers";
import { rowStringToCode, rowIdToRelativeRow } from "..";
import type { TPageFormattingOptions } from "../../../data";
import type { TRangeSelection } from "../useRangeSelectionChange";

export const initialFormattingAndSettingsAtom = atom<{
  formatting: TPageFormattingOptions | null;
  settings: ReturnType<typeof validColumnSettings> | null;
}>({ formatting: null, settings: null });
initialFormattingAndSettingsAtom.debugLabel =
  "initialFormattingAndSettingsAtom";

export type TFormatTypes = "range" | "column" | "period";

export function modalTypeToHighlightType(
  type: string,
): "cell_highlights" | "column_highlights" | "period_highlights" {
  switch (type) {
    case "range":
      return "cell_highlights";
    case "column":
      return "column_highlights";
    case "period":
      return "period_highlights";
    default:
      return "cell_highlights";
  }
}

export function getCellIds({
  api,
  type,
  ranges,
}: {
  api: GridApi | null;
  type: TFormatTypes;
  ranges: TRangeSelection;
}) {
  const cellIds: string[] = [];
  const rowCodes: string[] = [];

  if (type === "range") {
    if (!ranges.length || !ranges[0].length) return { cellIds, rowCodes };

    ranges.map((range) => {
      range.map((cell) => {
        const colId = cell.columnId;
        const period = getPeriodByRowId(api, cell.rowId);

        if (colId && period) {
          const code = rowStringToCode(period);
          if (code && !rowCodes.includes(code)) {
            rowCodes.push(code);
          }

          const id = `${colId}-${rowIdToRelativeRow[period]}`;
          if (!cellIds.includes(id)) {
            cellIds.push(`${colId}-${rowIdToRelativeRow[period]}`);
          }
        }
      });
    });
  }

  if (type === "column") {
    ranges.map((range) => {
      range.map((cell) => {
        const colId = cell.columnId;
        if (colId && !cellIds.includes(colId)) {
          cellIds.push(colId);
        }
      });
    });
  }

  if (type === "period") {
    ranges.map((range) => {
      range.map((cell) => {
        const id = cell.rowId;
        if (!cellIds.includes(id)) {
          cellIds.push(id);
        }
      });
    });
  }

  return { cellIds: unique(cellIds), rowCodes: unique(rowCodes) };
}

export function getColorFromVarName(varName: string) {
  if (varName === "inherit") return "inherit";

  // If the variable is in the format var(--variable-name), get the content between the parentheses.
  const variable = varName.includes("(")
    ? varName?.match(/\(([^)]+)\)/)?.[1]
    : varName;

  return getComputedStyle(document.documentElement).getPropertyValue(
    variable || "",
  );
}

const statuses = ["listen", "hybrid_broadcast", "private", "eod", "broadcast"];
export function getBackgroundForStatus(status: string) {
  if (status.length === 36) {
    return "var(--global-color-bg)";
  }
  if (statuses.includes(status)) {
    return `var(--${status}-color-bg)`;
    // return `var(--${statusClasses[status as keyof typeof statusClasses]})`;
  }
  console.error(`Unknown status: ${status}`);
  return "inherit";
}

export function getForegroundForStatus(status: string) {
  if (status?.length === 36) {
    return "var(--global-color-fg)";
  }
  if (statuses.includes(status)) {
    return `var(--${status}-color-fg)`;
  }
  return "inherit";
}
