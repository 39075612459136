import { extendTheme } from "@mui/joy";
import { useTheme } from "@mui/system";
import type { ColorSystemOptions } from "@mui/joy/styles/extendTheme";
import type { DefaultColorScheme } from "@mui/joy/styles/types";

const defaultTheme = extendTheme();

declare module "@mui/joy/styles" {
  interface PaletteBackgroundOverrides {
    artis: true;
    secondary: true;
    sideBar: true;
    topBar: true;
    bottomBar: true;
  }
}

declare module "@mui/joy/styles" {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    secondary: true;
  }
}

declare module "@mui/joy/styles" {
  interface Palette {
    highContrast: string;
    hover: string;
    secondary: {
      [key: string]: string;
    };
    text: {
      primary: string;
      secondary: string;
      icon: string;
      disabled: string;
    };
  }
}

type TColorSchemes = Partial<Record<DefaultColorScheme, ColorSystemOptions>>;

// all vars in src/styles/artisVariables.css

const secondary = {
  "50": "#dde7fe",
  "100": "#b4cbfe",
  "200": "#b7acfe",
  "300": "#6f9cff",
  "400": "#5087ff",
  "500": "#3675ff", // main
  "600": "#135dff",
  "700": "#0149e8",
  "800": "#0134a3",
  "900": "#012572",
  solidDisabledBg: "var(--webapp-palette-neutral-200)",
  solidDisabledColor: "var(--webapp-palette-neutral-400)",
};

const webappColorSchemes = {
  ...defaultTheme.colorSchemes,
  light: {
    ...defaultTheme.colorSchemes.light,
    palette: {
      ...defaultTheme.colorSchemes.light.palette,
      highContrast: "black",
      focusVisible: "transparent",
      primary: {
        ...defaultTheme.colorSchemes.light.palette.primary,
        "50": "#ffe4db",
        "100": "#fec6b4",
        "200": "#fe9e82",
        "300": "#fe764f",
        "400": "#fc4e1c",
        "500": "#ff4306", // main
        "600": "#ce3502",
        "700": "#b22f04",
        "800": "#872302",
        "900": "#641a01",
      },
      secondary: {
        ...defaultTheme.colorSchemes.light.palette.secondary,
        ...secondary,
      },
      neutral: {
        ...defaultTheme.colorSchemes.light.palette.neutral,
        "50": "#d3d8da",
        "100": "#bcc4c9",
        "200": "#a4afB7",
        "300": "#8d9aa6",
        "400": "#768694",
        "500": "#5f737b",
        "600": "#566974",
        "700": "#4b5c65",
        "800": "#171A1c",
        "900": "#0B0D0E",
      },
      danger: {
        ...defaultTheme.colorSchemes.light.palette.danger,
        "50": "#fcefef",
        "100": "#fae5e5",
        "200": "#f5d1d1",
        "300": "#eda9a9",
        "400": "#e89393",
        "500": "#e48181",
        "600": "#d32f2f", // main
        "700": "#a52323",
        "800": "#891d1d",
        "900": "#611515",
      },
      success: {
        ...defaultTheme.colorSchemes.light.palette.success,
        "50": "#e7f5e8",
        "100": "#dcf0dd",
        "200": "#c0e3c1",
        "300": "#86ca88",
        "400": "#68bd6b",
        "500": "#4cb050", // main
        "600": "#377e39",
        "700": "#2a612c",
        "800": "#235125",
        "900": "#183719",
      },
      warning: {
        ...defaultTheme.colorSchemes.light.palette.warning,
        "50": "#fff3b2",
        "100": "#ffeb7d",
        "200": "#fed700", // main
        "300": "#d9b800",
        "400": "#c6a900",
        "500": "#fed700",
        "600": "#826f00",
        "700": "#655500",
        "800": "#534700",
        "900": "#3a3200",
      },
      background: {
        surface: "var(--webapp-palette-common-white)",
        level1: "var(--webapp-palette-neutral-50)",
        level2: "var(--webapp-palette-neutral-100)",
        level3: "var(--webapp-palette-neutral-200)",
        tooltip: "var(-webapp-palette-neutral-400)",
        topBar: "var(--artis-nav-link-bg-light)",
        bottomBar: "var(--artis-nav-link-bg-light)",
        sideBar: "var(--artis-dark-bg)",
        artis: "var(--artis-light-bg)",
      },
      text: {
        primary: "var(--webapp-palette-common-black)",
        secondary: "var(--webapp-palette-neutral-800)",
        tertiary: "var(--webapp-palette-neutral-700)",
        icon: "var(--webapp-palette-neutral-600)",
        disabled: "var(--webapp-palette-neutral-400)",
      },
    },
  },
  dark: {
    palette: {
      ...defaultTheme.colorSchemes.dark.palette,
      highContrast: "white",
      primary: {
        ...defaultTheme.colorSchemes.dark.palette.primary,
        "50": "#ffe4db",
        "100": "#fec6b4",
        "200": "#fe9e82",
        "300": "#fe764f",
        "400": "#fc4e1c",
        "500": "#ff4306", // main
        "600": "#ce3502",
        "700": "#b22f04",
        "800": "#872302",
        "900": "#641a01",
      },
      secondary: {
        ...defaultTheme.colorSchemes.dark.palette.secondary,
        "50": "#dde7fe",
        "100": "#b4cbfe",
        "200": "#b7acfe",
        "300": "#6f9cff",
        "400": "#5087ff",
        "500": "#3675ff", // main
        "600": "#135dff",
        "700": "#0149e8",
        "800": "#0134a3",
        "900": "#012572",
      },
      neutral: {
        ...defaultTheme.colorSchemes.dark.palette.neutral,
        "50": "#d3d8da", // main
        "100": "#bcc4c9",
        "200": "#a4afB7",
        "300": "#8d9aa6",
        "400": "#768694",
        "500": "#5f737b",
        "600": "#566974",
        "700": "#4b5c65",
        "800": "#171A1c",
        "900": "#0B0D0E",
      },
      danger: {
        ...defaultTheme.colorSchemes.dark.palette.danger,
        "50": "#fcefef",
        "100": "#fae5e5",
        "200": "#f5d1d1",
        "300": "#eda9a9",
        "400": "#e89393",
        "500": "#e48181",
        "600": "#d32f2f", // main
        "700": "#a52323",
        "800": "#891d1d",
        "900": "#611515",
      },
      success: {
        ...defaultTheme.colorSchemes.dark.palette.success,
        "50": "#e7f5e8",
        "100": "#dcf0dd",
        "200": "#c0e3c1",
        "300": "#86ca88",
        "400": "#68bd6b",
        "500": "#4cb050", // main
        "600": "#377e39",
        "700": "#2a612c",
        "800": "#235125",
        "900": "#183719",
      },
      warning: {
        ...defaultTheme.colorSchemes.dark.palette.warning,
        "50": "#fff3b2",
        "100": "#ffeb7d",
        "200": "#fed700", // main
        "300": "#d9b800",
        "400": "#c6a900",
        "500": "#b79c00",
        "600": "#826f00",
        "700": "#655500",
        "800": "#534700",
        "900": "#3a3200",
      },
      background: {
        surface: "var(--webapp-palette-common-black)",
        level1: "var(--webapp-palette-neutral-800)",
        level2: "var(--webapp-palette-neutral-700)",
        level3: "var(--webapp-palette-neutral-600)",
        tooltip: "var(--webapp-palette-neutral-500)",
        topBar: "var(--artis-nav-bg-dark)",
        bottomBar: "var(--artis-nav-bg-dark)",
        sideBar: "black",
        artis: "var(--artis-dark-bg)",
      },
      text: {
        primary: "var(--webapp-palette-neutral-50)",
        secondary: "var(--webapp-palette-neutral-200)",
        tertiary: "var(--webapp-palette-neutral-300)",
        icon: "var(--webapp-palette-neutral-400)",
        disabled: "var(--webapp-palette-neutral-400)",
      },
    },
  },
} satisfies TColorSchemes;

const colorSchemes = {
  light: {
    palette: {
      highContrast: "black",
      focusVisible: "transparent",
      background: {
        level1: "var(--artis-dark-bg)",
        level2: "var(--artis-nav-bg-light)",
        tooltip: "var(--artis-dark-bg)",
        artis: "var(--artis-light-bg)",
      },
      text: {
        primary: "var(--artis-text-primary-light)",
        secondary: "var(--artis-text-secondary-light)",
      },
    },
  },
  dark: {
    palette: {
      highContrast: "white",
      background: {
        level1: "var(--artis-dark-bg)",
        level2: "var(--artis-dark-bg)",
        tooltip: "var(--artis-dark-bg)",
        artis: "var(--artis-dark-bg)",
      },
      text: {
        primary: "var(--artis-text-primary-dark)",
        secondary: "var(--artis-text-secondary-dark)",
      },
    },
  },
} satisfies TColorSchemes;

export const lumiTheme = extendTheme({ colorSchemes, cssVarPrefix: "lumi" });

export const webappTheme = extendTheme({
  cssVarPrefix: "webapp",
  fontFamily: {
    ...defaultTheme.fontFamily,
    body: "var(--default-font)",
    display: "var(--default-font)",
  },
  colorSchemes: webappColorSchemes,
});

export const liveChartsTheme = extendTheme({
  cssVarPrefix: "liveCharts",
  fontFamily: {
    ...defaultTheme.fontFamily,
    body: "var(--default-font)",
    display: "var(--default-font)",
  },
  colorSchemes: webappColorSchemes,
});

export function useWebappTheme() {
  return useTheme(webappTheme);
}
