import { useCallback, useState } from "react";
import { Button } from "@mui/joy";
import { useLogout } from "../../context/auth";

function Icon({ selected }: { selected: boolean }) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: We have a custom tooltip
    <svg
      className={selected ? "nav-icon selected" : "nav-icon"}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="42"
      viewBox="0 0 512 512"
    >
      <path
        d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"
        className="orange"
      />
    </svg>
  );
}

export function SignOutIcon({ selected = false }: { selected?: boolean }) {
  const [loading, setLoading] = useState(false);
  const logout = useLogout();

  const handleLogout = useCallback(async () => {
    setLoading(true);
    try {
      await logout();
    } catch (error) {
      console.error("Error logging out:", error);
      setLoading(false);
    }
  }, [logout]);

  return (
    <Button
      variant="plain"
      onClick={handleLogout}
      loading={loading}
      sx={{
        width: "50px",
        height: "50px",
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      {!loading && <Icon selected={selected} />}
    </Button>
  );
}
