import {
  Box,
  Checkbox,
  Divider,
  Input,
  Typography,
  Stack,
  Button,
} from "@mui/joy";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { useCallback, useMemo, useState, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import { validHexChars } from "../utils";
import type { GridColorSetting } from ".";
import { unique } from "remeda";
import { NO_COLOR } from "../../utils/color";
import { colorPickerZIndex } from "../globals";
import {
  initGridSettings,
  useOptionalActivePageId,
  usePageFormattingOptions,
  useQueryConditionalFormattingRulesGrouped,
} from "../../data";
import { useUserId } from "../../context/auth";

const defaultSwatches = [
  "#C6D9F0",
  "#EBF1DD",
  "#FDEADA",
  "#FCDAD9",
  "#FFFFFF",
  "#0039BB",
  "#00FF00",
  "#4F81BD",
  "#9BBB59",
  "#F79646",
  "#C0504D",
  "#C8C8C8",
  "#41EEFA",
  "#FF0000",
];

function Swatch({
  color,
  onClick,
}: {
  color: string;
  onClick: (hex: string) => void;
}) {
  return (
    <Box
      onClick={() => onClick(color)}
      sx={{
        width: "20px",
        height: "20px",
        backgroundColor: color,
        border: color?.toLowerCase() === "#ffffff" ? "1px inset black" : "none",
        margin: "4px",
        borderRadius: "4px",
        cursor: "pointer",
        ":hover": {
          border: "2px solid black",
          borderStyle: "inset",
        },
      }}
    />
  );
}

type TColorPickerValue = {
  color?: string | null;
  invertTextColor?: boolean;
  boldText?: boolean;
};

export function ColorPicker({
  disabled = false,
  setting,
  show,
  setShow,
  value,
  isLoading,
  onChange,
  showSwatches,
  showTextOptions,
  width,
  height,
  pickerWidth = "216px",
}: {
  disabled?: boolean;
  setting?: GridColorSetting["key"];
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  value: TColorPickerValue;
  isLoading?: boolean;
  onChange: (value: TColorPickerValue) => void;
  showSwatches?: boolean;
  showTextOptions?: boolean;
  width?: number | string;
  height?: number | string;
  pickerWidth?: number | string;
}) {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [hexValue, setHexValue] = useState<string>(value.color || "");
  const [state, setState] = useState<TColorPickerValue>({
    color: value.color || "",
    invertTextColor: value.invertTextColor || false,
    boldText: value.boldText || false,
  });
  useEffect(() => {
    if (!isLoading) {
      setState({
        color: value.color || "",
        invertTextColor: value.invertTextColor || false,
        boldText: value.boldText || false,
      });
      setHexValue(value.color || "");
    }
  }, [value, isLoading]);

  const pageId = useOptionalActivePageId();
  const pageFormatting = usePageFormattingOptions(pageId ?? "");
  const userId = useUserId();
  const conditionalFormatting = useQueryConditionalFormattingRulesGrouped(
    pageId ?? "",
    userId,
  );

  const pageFormattingColors =
    useMemo(() => {
      const pageFormattingColorsCell = pageFormatting.data?.cell_highlights
        ? Object.values(pageFormatting.data?.cell_highlights).map((highlight) =>
            highlight.color?.toUpperCase(),
          )
        : [];

      const pageFormattingColorsColumn = pageFormatting.data?.column_highlights
        ? Object.values(pageFormatting.data?.column_highlights).map(
            (highlight) => highlight.color?.toUpperCase(),
          )
        : [];

      const pageFormattingColorsPeriod = pageFormatting.data?.period_highlights
        ? Object.values(pageFormatting.data?.period_highlights).map(
            (highlight) => highlight.color?.toUpperCase(),
          )
        : [];

      // Return first 7 unique colors from all formatting.
      return unique([
        ...pageFormattingColorsCell,
        ...pageFormattingColorsColumn,
        ...pageFormattingColorsPeriod,
      ])
        .filter(Boolean)
        .slice(0, 7);
    }, [pageFormatting]) || [];

  // If there are less than 7 colors in the page formatting, get the remaining colors from the conditional formatting.
  const conditionalFormattingColors =
    useMemo(() => {
      if (pageFormattingColors.length < 7) {
        const colors = conditionalFormatting.data
          ? Object.values(conditionalFormatting.data)
              .flat()
              .map((rule) => rule.bg_color?.toUpperCase())
          : [];

        const numberToReturn = 7 - pageFormattingColors.length;
        return unique(colors).filter(Boolean).slice(0, numberToReturn);
      }
    }, [conditionalFormatting.data, pageFormattingColors]) || [];

  const background = disabled
    ? { backgroundColor: "#c8c8c8" }
    : !state.color || state.color === NO_COLOR
      ? {
          backgroundPosition: "0px 0px, 10px 10px",
          backgroundSize: "20px 20px",
          backgroundImage:
            "linear-gradient(45deg, #c8c8c8 25%, transparent 25%, transparent 75%, #c8c8c8 75%, #c8c8c8 100%),linear-gradient(45deg, #c8c8c8 25%, white 25%, white 75%, #c8c8c8 75%, #c8c8c8 100%)",
        }
      : { backgroundColor: state.color };

  const handleInputChange = useCallback(
    (newValue: string) => {
      if (!validHexChars(newValue?.split(""))) {
        return;
      }

      const hex =
        newValue?.length > 0 && newValue[0] === "#" ? newValue : `#${newValue}`;

      setHexValue(hex);
      if (state.color !== hex) {
        setState((prev) => ({
          ...prev,
          color: hex,
        }));
      }
    },
    [state],
  );

  const userColors = [
    ...pageFormattingColors,
    ...conditionalFormattingColors,
  ].sort();

  return (
    <Box
      sx={{
        width,
        height,
      }}
    >
      <Box
        onClick={
          disabled
            ? undefined
            : (e) => {
                setAnchor(e.currentTarget);
                setShow(!show);
              }
        }
        sx={{
          width: width ?? 40,
          height: height ?? 24,
          cursor: disabled ? "default" : "pointer",
          borderRadius: "sm",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          ...background,
        }}
      />
      {show && (
        <ClickAwayListener
          onClickAway={() => {
            onChange(value);
            setState(value);
            setShow(false);
          }}
        >
          <BasePopup
            open={show}
            id="color-popup"
            style={{ zIndex: colorPickerZIndex }}
            offset={4}
            anchor={anchor}
            placement="bottom-end"
          >
            <Box
              sx={{
                width: pickerWidth,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                "& .react-colorful": {
                  width: pickerWidth,
                  height: 120,
                  "& .react-colorful__last-control": {
                    borderRadius: "0px !important",
                  },
                },
              }}
            >
              <HexColorPicker
                color={state.color || undefined}
                onChange={(newColor) => {
                  setState((prev) => ({
                    ...prev,
                    color: newColor,
                  }));

                  if (newColor !== hexValue) {
                    setHexValue(newColor);
                  }
                }}
              />
              <Box
                className="color-picker-popup-checkbox"
                sx={(theme) => ({
                  background: theme.palette.background.surface,
                  boxShadow: theme.shadow.md,
                  border: `1px solid ${theme.palette.divider}`,
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                })}
                width={"100%"}
              >
                {showSwatches && (
                  <>
                    <Stack
                      direction={"row"}
                      flexWrap={"wrap"}
                      sx={{
                        padding: 1,
                      }}
                    >
                      {unique([...userColors, ...defaultSwatches]).map(
                        (color) => (
                          <Swatch
                            key={color}
                            color={color}
                            onClick={(hex: string) => handleInputChange(hex)}
                          />
                        ),
                      )}
                    </Stack>
                    <Divider />
                  </>
                )}
                <Box padding={2} pb={0}>
                  <Stack gap={2}>
                    <Stack
                      gap={2}
                      flexDirection={"row"}
                      justifyContent="space-between"
                    >
                      <Typography level="body-sm">No Colour</Typography>
                      <Checkbox
                        checked={state.color === NO_COLOR}
                        color="neutral"
                        onChange={(e) => {
                          const color = setting
                            ? initGridSettings[setting]
                            : "#fff";
                          setState({
                            color: e.target.checked ? NO_COLOR : color,
                            invertTextColor: state.invertTextColor,
                            boldText: state.boldText,
                          });
                          setHexValue(e.target.checked ? "#fff" : color || "");
                        }}
                      />
                    </Stack>
                    {showTextOptions && (
                      <>
                        <Stack
                          gap={2}
                          flexDirection={"row"}
                          justifyContent="space-between"
                        >
                          <Typography level="body-sm">
                            Invert Text Color
                          </Typography>
                          <Checkbox
                            checked={Boolean(state.invertTextColor)}
                            onChange={(e) => {
                              setState(
                                e.target.checked
                                  ? { ...state, invertTextColor: true }
                                  : { ...state, invertTextColor: false },
                              );
                            }}
                          />
                        </Stack>
                        <Stack
                          gap={2}
                          flexDirection={"row"}
                          justifyContent="space-between"
                        >
                          <Typography level="body-sm">Bold Text</Typography>
                          <Checkbox
                            checked={Boolean(state.boldText)}
                            onChange={(e) => {
                              setState(
                                e.target.checked
                                  ? { ...state, boldText: true }
                                  : { ...state, boldText: false },
                              );
                            }}
                          />
                        </Stack>
                      </>
                    )}
                  </Stack>
                </Box>
                <Divider
                  sx={{
                    mt: 2,
                  }}
                />
                <Box display={"flex"} justifyContent={"center"} pt={2} pb={2}>
                  <Input
                    type="text"
                    value={
                      !hexValue || state.color === NO_COLOR ? "" : hexValue
                    }
                    onChange={(e) => handleInputChange(e.target.value)}
                    sx={{
                      width: "80%",
                    }}
                  />
                </Box>
                <Stack sx={{ padding: 1, pt: 0 }}>
                  <Button
                    type="submit"
                    variant="solid"
                    color="primary"
                    size="sm"
                    sx={{
                      width: "100%",
                    }}
                    onClick={() => {
                      onChange(state);
                      setShow(false);
                    }}
                  >
                    Save
                  </Button>
                </Stack>
              </Box>
            </Box>
          </BasePopup>
        </ClickAwayListener>
      )}
    </Box>
  );
}
