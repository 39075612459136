import { Box } from "@mui/joy";
import { HeaderArtisLogo } from "./HeaderArtisLogo";
import { agGridCellAutoPadding, headerHeight } from "../../globals";

export function ColumnMonthTimespread() {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignSelf: "flex-start",
        justifyContent: "flex-end",
        flexDirection: "column",
        backgroundColor: "var(--ag-header-background-color)",
      }}
    >
      <div
        className="flex flex-col gap-2"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderArtisLogo />
        <Box
          sx={{
            width: "100%",
            fontWeight: "700",
            height: headerHeight,
            display: "flex",
            alignItems: "center",
            pl: agGridCellAutoPadding,
            backgroundColor: "var(--headerColumnColour)",
            color: "var(--header-text-colour)",
          }}
        >
          Spread
        </Box>
      </div>
    </Box>
  );
}
