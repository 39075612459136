import { Box } from "@mui/joy";
import { useAllProductsOptions } from "../curve-management";
import { useUserSubscriptionTier } from "../../context/auth";
import { Autocomplete, type TAutocomplete } from "./Autocomplete/Autocomplete";

export type TProductOptionsProps = {
  disableListPermission?: boolean;
  disabledIds?: string[];
};

type TProductSelect = Omit<TAutocomplete, "options"> & TProductOptionsProps;

export function useProductOptions(props: TProductOptionsProps) {
  const { data: productOptions } = useAllProductsOptions({
    disableListPermission: props.disableListPermission,
    disabledIds: props.disabledIds,
  });
  const { liteUser, enhancedNoExchUser } = useUserSubscriptionTier();
  const filteredProducts = (productOptions || []).filter((product) => {
    return product.metadata.packageByPackage?.sourceBySource?.exchange === null;
  });

  const optionsToShow =
    liteUser || enhancedNoExchUser ? filteredProducts : productOptions;
  return optionsToShow;
}

export function ProductSelect(props: TProductSelect) {
  const productOptions = useProductOptions(props);
  return (
    <Box
      data-testid="product-select"
      sx={{
        width: "100%",
        // Disable the blue outline on focus (this overlaps other elements when making the box scrollable when limiting the height).
        ".MuiAutocomplete-root::before": {
          boxShadow: "none !important",
        },
      }}
    >
      <Autocomplete
        {...props}
        autoComplete={true}
        autoFocus={true}
        multiple={!!props.multiple}
        options={productOptions || []}
      />
    </Box>
  );
}
