import { useGridSettings, useUpdateGridSettings } from "../../../data";
import { potentiallyInvisibleColumns } from "../../market-grid/column-defs";
import { useUserSubscriptionTier } from "../../../context/auth";
import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsCurveDetails = (): GetContextMenuSection => {
  const { disabledFeatures } = useUserSubscriptionTier();
  const update = useUpdateGridSettings();
  const settings = useGridSettings();

  return (ctx) => {
    if (!ctx.isProduct || !ctx.isHeaderMenuItem) return [];
    return [
      {
        disabled: disabledFeatures?.curveDetails,
        name: "Curve Details",
        tooltip: disabledFeatures?.curveDetails
          ? "Please upgrade your subscription to access this feature"
          : undefined,
        icon: getIcon("details"),
        subMenu: potentiallyInvisibleColumns.map(
          ({ name, label, defaultVisible }) => {
            const selected = settings.data?.[name] ?? defaultVisible;
            return {
              name: label,
              icon: selected ? getIcon("check") : "",
              action: () => update.optimistic({ [name]: !selected }),
            };
          },
        ),
      },
    ];
  };
};
