import type { ColumnMovedEvent } from "ag-grid-community";
import { generateKeyBetween } from "fractional-indexing";

import {
  getCachedPageProducts,
  useActivePageId,
  useUpdatePageProducts,
} from "../../data";
import { useMemoCallback } from "../../utils/useMemoCallback";
import { useQueryClient } from "@tanstack/react-query";

export const useOnColumnMoved = () => {
  const updatePage = useUpdatePageProducts();
  const qry = useQueryClient();
  const pageId = useActivePageId();

  const onColumnMoved = (params: ColumnMovedEvent) => {
    if (params.source === "gridOptionsChanged" || !params.finished) return;
    const id = params.column?.getColId();
    const gridColumns = params.api.getAllGridColumns();

    if (typeof params.toIndex === "undefined" || !id) return;
    const products = getCachedPageProducts(qry, pageId) ?? [];

    const prevColumn = gridColumns[params.toIndex - 1];
    const prev = products.find((p) => p.id === prevColumn?.getColId());

    const nextColumn = gridColumns[params.toIndex + 1];
    const next = products.find((p) => p.id === nextColumn?.getColId());

    const idx = generateKeyBetween(prev?.idx ?? null, next?.idx ?? null);
    updatePage.optimistic({ id, idx });
    return;
  };

  return useMemoCallback(onColumnMoved);
};
