import {
  Box,
  Button,
  Checkbox,
  Input,
  Stack,
  Table,
  Typography,
  Tooltip,
} from "@mui/joy";
import { ModalButtons } from "../Buttons";
import { GridModalContainer } from "../GridModal";
import {
  Controller,
  useFieldArray,
  useForm,
  useWatch,
  type UseFormReturn,
} from "react-hook-form";
import { alertValidation, emptyAlert, type TAlertForm } from "./hooks";
import { FaTimes, FaTrash } from "react-icons/fa";
import { rowIdToRelativeRow, rowStringToCode } from "../../periodHelpers";
import {
  getNodePeriod,
  useResetLimitSelection,
} from "../ConditionalFormatting/helpers";
import { useAtomValue } from "jotai";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import { parseNumber } from "../../../numbers";
import { gridModalDockviewAtom } from "../modalAtoms";
import { alertSounds } from "../../../globals";
import { captureEvent } from "../../../../context/ph";
import { GridModalSelectionInfo } from "../modalComponents";
import { useGrid } from "../../stores";
import {
  useActivePageId,
  useGridSettingsSelect,
  usePageProducts,
} from "../../../../data";
import { PointerIcon } from "../../../icons";
import { useThemeMode } from "../../../../context/theme";
import { alertTableStyle, AlertHeader } from "./components";
import { logger } from "@artis/logger";
import type { AlertFragmentFragment } from "../../../../__generated__/gql/graphql";
import { nanoid } from "nanoid";
import { useInsertAlert } from "../../../../data/alerts/hooks";

export function AlertRow({
  form,
  alert,
  index,
  remove,
}: {
  form: UseFormReturn<{ alerts: TAlertForm[] }>;
  alert: TAlertForm;
  index: number;
  remove: (index: number) => void;
}) {
  const [gridApi] = useGrid();
  const resetLimitSelection = useResetLimitSelection();

  const { register, control } = form;

  const output = useWatch({
    control,
    name: "alerts",
  });

  const mode = useThemeMode();
  const disabled = output[index]?.status !== "Active";

  const items = {
    limit: (
      <td
        key="limit"
        style={{
          flexDirection: "column",
          paddingLeft: "30px",
          gap: "16px",
        }}
      >
        <Stack flexDirection={"row"} gap={1}>
          {output[index]?.limitRef?.name ? (
            <Stack
              flexDirection={"row"}
              gap={1}
              overflow="hidden"
              width="100%"
              justifyContent={"space-between"}
            >
              <Controller
                name={`alerts.${index}.limitRef`}
                control={control}
                render={({ field }) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30px",
                      border: (theme) =>
                        `1px solid ${theme.palette.neutral.outlinedBorder}`,
                      padding: "4px 8px",
                      paddingRight: "0",
                      borderRadius: "sm",
                      width: "fit-content",
                      gap: "8px",
                      backgroundColor: (theme) =>
                        theme.palette.background.surface,
                      maxWidth: "106px",
                    }}
                  >
                    <Tooltip
                      title={`${output[index]?.limitRef?.name}[${output[index]?.limitRef?.period}]`}
                      placement="top-start"
                      arrow
                    >
                      <Typography
                        fontSize="xs"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {output[index]?.limitRef?.name}[
                        {output[index]?.limitRef?.period}]
                      </Typography>
                    </Tooltip>

                    <Button
                      size="sm"
                      variant="plain"
                      color="neutral"
                      onClick={() => {
                        field.onChange(undefined);
                      }}
                      sx={{
                        minWidth: "auto",
                      }}
                    >
                      <FaTimes />
                    </Button>
                  </Box>
                )}
              />
            </Stack>
          ) : (
            <>
              <Input
                {...register(`alerts.${index}.limit`)}
                type="number"
                size="sm"
                sx={{
                  height: "30px",
                  gap: "10px",
                  width: "49px",
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                }}
              />
              <Controller
                name={`alerts.${index}.limitRef`}
                control={control}
                render={({ field }) => (
                  <Button
                    size="sm"
                    variant="soft"
                    color="neutral"
                    sx={{
                      display: "flex",
                      minWidth: "49px",
                      height: "30px",
                      padding: "8px 16px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    onClick={() => {
                      if (
                        gridApi?.getGridOption("rowClass") ===
                        "ag-cell-selection"
                      ) {
                        resetLimitSelection();
                        return;
                      }

                      gridApi?.setGridOption("onCellClicked", (e) => {
                        resetLimitSelection();

                        const columnId = e.column.getColId();
                        const name = e.colDef.headerName;
                        const period = getNodePeriod(e.node);
                        const rowId = period
                          ? rowIdToRelativeRow[period]
                          : e.node.id;

                        field.onChange({
                          columnId,
                          rowId,
                          name,
                          period,
                        });
                      });

                      document.querySelectorAll(".ag-row").forEach((cell) => {
                        cell.classList.add("ag-cell-selection");
                      });
                    }}
                  >
                    <PointerIcon mode={mode} />
                  </Button>
                )}
              />
            </>
          )}
        </Stack>
      </td>
    ),
    note: (
      <td
        key="note"
        style={{
          width: "150px",
        }}
      >
        <Input
          {...register(`alerts.${index}.note`)}
          size="sm"
          disabled={disabled}
        />
      </td>
    ),
    actions: (
      <td
        key="actions"
        style={{
          width: "100px",
          paddingLeft: "20px",
        }}
      >
        <Stack gap={1} flexDirection={"row"} justifyContent={"left"}>
          <Button
            sx={{
              display: "flex",
              height: "30px",
              minWidth: "49px",
              padding: "4px 8px",
              justifyContent: "center",
            }}
            size="sm"
            variant="soft"
            color="neutral"
            onClick={() => remove(index)}
            disabled={disabled || output.length === 1}
          >
            <FaTrash />
          </Button>
        </Stack>
      </td>
    ),
    status: (
      <td key="status">
        <Typography
          fontSize="xs"
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          overflow="hidden"
          title={output[index]?.status}
        >
          {output[index]?.status}
        </Typography>
      </td>
    ),
    recurring: (
      <td key="recurring" style={{ width: "60px" }}>
        <Controller
          name={`alerts.${index}.recurring`}
          control={control}
          disabled={disabled}
          render={({ field }) => (
            <Checkbox
              disabled={disabled}
              checked={output[index].recurring}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
              size="lg"
              sx={{
                float: "left",
                paddingBottom: "3.5px",
                "& .MuiCheckbox-checkbox": {
                  width: "25px",
                  height: "25px",
                },
                "& svg": {
                  fontSize: "25px",
                },
              }}
            />
          )}
        />
      </td>
    ),

    sound: (
      <td key="sound" style={{ width: "60px" }}>
        <Controller
          name={`alerts.${index}.sound`}
          control={control}
          disabled={disabled}
          render={({ field }) => (
            <Checkbox
              disabled={disabled}
              checked={output[index].sound}
              onChange={(e) => {
                field.onChange(e.target.checked);

                if (e.target.checked) {
                  if (sessionStorage.getItem("soundPlayed") === "true") return;
                  new Audio(alertSounds.huthut).play();
                  sessionStorage.setItem("soundPlayed", "true");
                }
              }}
              size="lg"
              sx={{
                float: "left",
                paddingBottom: "3.5px",
                "& .MuiCheckbox-checkbox": {
                  width: "25px",
                  height: "25px",
                },
                "& svg": {
                  fontSize: "25px",
                },
              }}
            />
          )}
        />
      </td>
    ),
  };

  const tableItems = [
    items.limit,
    items.note,
    items.actions,
    items.recurring,
    items.sound,
  ];

  return <tr key={alert._id}>{tableItems}</tr>;
}

export function CreateAlert() {
  const pageId = useActivePageId();

  const insertAlert = useInsertAlert();

  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const rangeSelection = dockviewValue?.alerts?.selectedRange || [];

  const [gridApi] = useGrid();
  const resetLimitSelection = useResetLimitSelection();

  const sound = useGridSettingsSelect((x) => x?.sound)?.data;

  const form = useForm<{
    alerts: TAlertForm[];
  }>({
    defaultValues: {
      alerts: [emptyAlert(Boolean(sound))],
    },
    resolver: zodResolver(alertValidation),
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "alerts",
  });

  const formData = useWatch({
    control: form.control,
    name: "alerts",
  });

  const auth = useAuth0();
  const userId = auth.user?.sub;

  const selectedCell = rangeSelection?.[0]?.[0];
  const rowNode = gridApi?.getRowNode(selectedCell?.rowId);
  const selectedPeriod = getNodePeriod(rowNode);
  const selectedColumnId = selectedCell?.columnId;

  const cell = rowNode
    ? {
        value: gridApi?.getCellValue({
          rowNode,
          colKey: selectedColumnId,
        }),
        period: selectedPeriod,
        rowId: selectedPeriod
          ? rowIdToRelativeRow[selectedPeriod]
          : rowNode?.id,
        columnId: selectedColumnId,
      }
    : undefined;

  const currentPageProducts = usePageProducts(useActivePageId());
  const product = currentPageProducts.data?.find(
    (product) => product.product_id === selectedCell?.productId,
  );
  const productId = product?.product_id;

  const canSave = formData.every((rule) => rule.limit || rule.limitRef);
  const { data: pageProducts } = usePageProducts(pageId);

  async function handleSave() {
    toast.promise(
      new Promise((resolve) => {
        resetLimitSelection();

        if (!cell) throw new Error("Cell is not defined");
        if (!pageId) throw new Error("pageId is not defined");
        if (!userId) throw new Error("userId is not defined");
        if (!gridApi) throw new Error("gridApi is not defined");
        if (!formData.length) throw new Error("No alerts defined");

        const formattedAlerts = formData
          .flatMap((alert) => {
            const productRefDetails = pageProducts?.find(
              (item) => item.id === alert?.limitRef?.columnId,
            );
            const limit = alert?.limitRef
              ? `${alert.limitRef.columnId}:${productRefDetails?.product_id}:${productRefDetails?.column_field_selector}:${alert.limitRef.rowId}`
              : parseNumber(alert.limit);
            if (!limit) return;

            const limitRefRowNode = alert?.limitRef
              ? gridApi.getRowNode(rowStringToCode(alert.limitRef?.period))
              : undefined;

            const limitRefCellValue =
              limitRefRowNode && alert.limitRef?.columnId
                ? gridApi.getCellValue({
                    rowNode: limitRefRowNode,
                    colKey: alert.limitRef?.columnId,
                  })
                : undefined;

            if (!product) {
              logger.log("product not found for alert", {
                alert,
                currentPageProducts,
              });
              throw new Error("Product not found for alert");
            }

            const fieldSelector = product.column_field_selector ?? undefined;
            return {
              id: alert.id || nanoid(),
              _id: alert._id,
              limit: limit.toString(),
              // Decides whether the alert will use <= or >= to determine when to trigger.
              value_below_limit: limitRefCellValue
                ? cell.value < limitRefCellValue
                : cell.value < limit,
              note: alert.note,
              status: "Active",
              recurring: alert.recurring ?? false,
              sound: alert?.sound ?? false,
              triggered_at: null,
              column_id: cell.columnId,
              row_id: cell.rowId || "",
              user_id: userId,
              page_id: pageId,
              product_id: productId || alert.productId,
              field_selector: fieldSelector,
            } satisfies AlertFragmentFragment;
          })
          .filter(Boolean);

        const inserts = formattedAlerts.map((alert) => {
          captureEvent("created_alert", {
            pageId,
            productId: alert.product_id,
          });
          return insertAlert.mutate(alert);
        });

        resolve(Promise.all(inserts));
      }),
      {
        loading: "Creating Alert(s)...",
        success: "Alert(s) Created!",
        error: "Error Creating Alert(s)",
      },
    );
  }

  return (
    <GridModalContainer
      panel="alerts"
      padding={0}
      body={
        <>
          <Box
            sx={{
              padding: "15px 10px",
            }}
          >
            <Typography textColor="text.primary" sx={{ paddingBottom: "2px" }}>
              Selected Column and Cell
            </Typography>
            <GridModalSelectionInfo selectedRange={rangeSelection} />
          </Box>
          <form onSubmit={form.handleSubmit(handleSave)}>
            <Box pt={2}>
              <Stack gap={2} mb={4}>
                <Box
                  sx={(theme) => ({
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    borderTop: `1px solid ${theme.palette.divider}`,
                    maxHeight: "210px",
                    overflowY: "auto",
                  })}
                >
                  <Table sx={alertTableStyle} borderAxis="x">
                    <AlertHeader type="create" />
                    <tbody>
                      {fields.map((alert, index) => (
                        <AlertRow
                          key={`${alert.columnId}-${alert.rowId}-${alert.productId}`}
                          form={form}
                          alert={alert}
                          index={index}
                          remove={remove}
                        />
                      ))}
                    </tbody>
                  </Table>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    onClick={() => append(emptyAlert())}
                    sx={{
                      cursor: "pointer",
                      display: "inline",
                      pl: 2,
                      marginLeft: "15px",
                    }}
                  >
                    + Add Alert
                  </Button>
                </Box>
              </Stack>
            </Box>
          </form>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel={"alerts"}
          onCancel={() => {
            gridApi?.setGridOption("onCellClicked", undefined);
            document.querySelectorAll(".ag-cell-selection").forEach((cell) => {
              cell.classList.remove("ag-cell-selection");
            });
          }}
          onSave={() => {
            handleSave();
          }}
          saveDisabled={!canSave}
        />
      }
    />
  );
}
