import { pino } from "pino";
import posthog from "posthog-js";
// -----------------------------------------------------------------------------
// Constants
// -----------------------------------------------------------------------------
const levels = {
    emerg: 80,
    alert: 70,
    crit: 60,
    error: 50,
    warn: 40,
    notice: 30,
    info: 20,
    debug: 10,
};
const COLOR = {
    GREEN: "\x1b[32m",
    RED: "\x1b[31m",
    WHITE: "\x1b[37m",
    YELLOW: "\x1b[33m",
    CYAN: "\x1b[36m",
};
const LEVEL_COLORS = {
    EMERG: COLOR.RED,
    ALERT: COLOR.RED,
    CRIT: COLOR.RED,
    ERROR: COLOR.RED,
    WARN: COLOR.YELLOW,
    NOTICE: COLOR.GREEN,
    INFO: COLOR.GREEN,
    DEBUG: COLOR.GREEN,
};
// -----------------------------------------------------------------------------
// Configuration
// -----------------------------------------------------------------------------
export const isProd = typeof process === "undefined"
    ? !import.meta.env.PROD
    : process.env.NODE_ENV === "production";
export const minLevel = isProd ? "error" : "info";
export const LOGTAIL_TOKEN = "8PSqmzGT2uNCR4xSbhz9jh6H";
export const LOGTAIL_ENDPOINT = "/betterstack";
let logtail = null;
let ddLogs = null;
let currentUserId;
export const setLogTailClient = (client) => {
    logtail = client;
};
export const setDatadogLogsClient = (client) => {
    ddLogs = client;
};
let env;
export function setCurrentUserId(userId) {
    currentUserId = userId;
}
export function setEnv(e) {
    env = e;
}
// Helper function to send logs to external services
const sendExternalLogs = (level, message, context) => {
    if (!isProd || level === "debug")
        return;
    const logMessage = message
        ? typeof message === "string"
            ? message
            : message.message || String(message)
        : "No message provided";
    const logContext = {
        ...(message instanceof Error ? { error: message } : {}),
        ...(context || {}),
        userId: currentUserId,
        env,
    };
    // Map our log levels to standard levels
    const getStandardLevel = (level) => {
        switch (level) {
            case "error":
            case "emerg":
            case "alert":
            case "crit":
                return "error";
            case "warn":
                return "warn";
            case "notice":
            case "info":
                return "info";
            case "debug":
                return "debug";
            default:
                return "info";
        }
    };
    const standardLevel = getStandardLevel(level);
    try {
        // Send to Logtail
        if (logtail) {
            logtail[standardLevel](logMessage, logContext);
        }
        // Send to Datadog (browser only)
        if (ddLogs?.logger) {
            console.log({
                level: standardLevel,
                logMessage,
                logContext,
            });
            ddLogs.logger[standardLevel](logMessage, logContext);
        }
    }
    catch (error) {
        console.error("Failed to send external logs:", error);
    }
};
// -----------------------------------------------------------------------------
// Base Logger Setup
// -----------------------------------------------------------------------------
const formatLogMessage = (logObj) => {
    const { level, msg, group = "app", time } = logObj;
    const levelString = String(level).toLowerCase();
    const levelUppercased = levelString.toUpperCase();
    const levelColor = LEVEL_COLORS[levelUppercased] || COLOR.WHITE;
    return `[${time}] ${levelColor}${levelUppercased} ${COLOR.CYAN}[${group}] ${msg} ${COLOR.WHITE}`;
};
const baseLogger = pino({
    level: minLevel,
    customLevels: levels,
    formatters: {
        level: (label) => ({ level: label }),
        bindings: () => ({}),
    },
    timestamp: () => new Date().toISOString(),
    browser: {
        write: (o) => {
            if (!o || typeof o !== "object")
                return;
            const obj = o;
            try {
                const logString = formatLogMessage(obj);
                const level = String(obj.level || "info");
                switch (level) {
                    case "debug":
                        console.debug(logString);
                        break;
                    case "error":
                        console.error(logString);
                        break;
                    case "warn":
                        console.warn(logString);
                        break;
                    case "info":
                    case "notice":
                        console.info(logString);
                        break;
                    default:
                        console.log(logString);
                }
                if (logtail) {
                    logtail.log(logString);
                }
            }
            catch (error) {
                console.error("Error in logger write:", error);
            }
        },
    },
});
// -----------------------------------------------------------------------------
// Event Logging
// -----------------------------------------------------------------------------
function captureEvent(eventName, info) {
    if (typeof posthog === "undefined" || !posthog.capture) {
        console.debug("Analytics tracking is disabled or blocked");
        return;
    }
    try {
        posthog.capture(eventName, info);
        logtail?.info(`Event: ${eventName}`, info);
    }
    catch (error) {
        console.error("Error capturing event", { error, eventName });
    }
}
// -----------------------------------------------------------------------------
// Logger Implementation
// -----------------------------------------------------------------------------
function createLogger(options = {}) {
    const logger = baseLogger.child({
        group: options.group || "app",
        ...options.context,
    });
    // Helper function to handle common logging logic
    const logWithLevel = (level, message, context) => {
        try {
            const logMessage = message
                ? typeof message === "string"
                    ? message
                    : message.message || String(message)
                : "No message provided";
            const logContext = {
                ...(message instanceof Error ? { error: message } : {}),
                ...(context || {}),
            };
            sendExternalLogs(level, message, logContext);
            const pinoContext = { msg: logMessage, ...logContext };
            logger?.[level]?.(pinoContext);
            if (level === "error") {
                console.error(message, logContext);
            }
        }
        catch (error) {
            console.error("Error in logWithLevel:", error);
        }
    };
    return {
        emerg: (message, context) => logWithLevel("emerg", message, context),
        alert: (message, context) => logWithLevel("alert", message, context),
        crit: (message, context) => logWithLevel("crit", message, context),
        error: (message, context) => logWithLevel("error", message, context),
        warn: (message, context) => logWithLevel("warn", message, context),
        notice: (message, context) => logWithLevel("notice", message, context),
        info: (message, context) => logWithLevel("info", message, context),
        debug: (message, context) => logWithLevel("debug", message, context),
        log: (message, context) => logWithLevel("info", message, context),
        child: (childOptions) => createLogger({
            ...options,
            ...childOptions,
            context: { ...options.context, ...childOptions.context },
        }),
        captureEvent,
    };
}
export const eventLogger = { captureEvent };
const logger = createLogger();
export { logger };
