function Icon({
  selected,
  disabled,
}: { selected: boolean; disabled: boolean }) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: We have a custom tooltip
    <svg
      className={selected ? "nav-icon selected" : "nav-icon"}
      style={{
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.5 : 1,
      }}
      width="42"
      height="42"
      viewBox="0 0 38 38"
      fill={disabled ? "gray" : "white"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="background"
        opacity="0.15"
        width="42"
        height="42"
        rx="2"
        fill={disabled ? "gray" : "white"}
      />
      <path
        d="M31.8875 21.539C32.142 22.0292 31.951 22.6328 31.4609 22.8874C30.9708 23.1419 30.3671 22.9509 30.1125 22.4608L31.8875 21.539ZM20 21.9999L19.0889 21.5876L19.0966 21.5707L19.1049 21.5541L20 21.9999ZM7.60688 22.4497L7.15706 21.5566L8.94329 20.657L9.39312 21.5501L7.60688 22.4497ZM30.1125 22.4608C29.0342 20.3844 28.1134 18.8797 27.2883 17.9005C26.4422 16.8965 25.8539 16.6321 25.4631 16.6242C25.0877 16.6167 24.5168 16.8413 23.6861 17.8255C22.8722 18.7897 21.9615 20.3045 20.8951 22.4457L19.1049 21.5541C20.1887 19.3777 21.1849 17.688 22.1578 16.5354C23.1139 15.4028 24.2095 14.5985 25.5034 14.6246C26.782 14.6504 27.8659 15.4823 28.8177 16.6118C29.7905 17.7662 30.7934 19.4324 31.8875 21.539L30.1125 22.4608ZM20.9111 22.4122C19.9137 24.6162 18.9234 26.3335 17.9011 27.5106C16.8836 28.6821 15.7123 29.4534 14.3641 29.4348C13.0316 29.4164 11.8519 28.6286 10.8036 27.4676C9.74417 26.2944 8.6899 24.6 7.60688 22.4497L9.39312 21.5501C10.4451 23.6387 11.4047 25.1491 12.2879 26.1272C13.1823 27.1176 13.8741 27.4278 14.3917 27.435C14.8936 27.4419 15.5511 27.1662 16.3911 26.1991C17.2262 25.2376 18.1219 23.7247 19.0889 21.5876L20.9111 22.4122Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 10H16V13.5H19.5V16.5H16V20H13V16.5H9.5V13.5H13V10Z"
        className="orange"
      />
    </svg>
  );
}

export function CreateCurvesIcon({
  selected = false,
  disabled = false,
}: { selected?: boolean; disabled?: boolean }) {
  return <Icon selected={selected} disabled={disabled} />;
}
