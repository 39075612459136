import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { logger } from "@artis/logger";
import { nanoid } from "nanoid";
import posthog from "posthog-js";
import { useParams } from "@tanstack/react-router";
import { useCallback, useEffect, useRef } from "react";
import { generateKeyBetween } from "fractional-indexing";

import type { PageFragmentFragment } from "../../__generated__/gql/graphql";
import { type GraphQLClient, useGraphQLClient } from "../../utils/graphql";
import { getSyncBrotli } from "../../utils/encoding";
import { useSuspenseFn } from "../../utils/useSuspenseFn";
import { useUserId } from "../../context/auth";
import { readFragment } from "../../graphql";
import {
  PagesQuery,
  PageFragment,
  PageUpdateMutation,
  PageInsertMutation,
  PageDeleteMutation,
  PagesSubscription,
} from "./queries";

// Avoid updating the current pages data from the server if there are uncommited optimistic updates.
// Since the subscription exists separately from the update hook, we need to track this at a higher scope.
const PENDING_MUTATIONS: Set<string> = new Set();

export type TPage = PageFragmentFragment;

export const pagesQueryOptions = (hasura: GraphQLClient, userId: string) =>
  queryOptions({
    queryKey: ["pages", userId],
    queryFn: async () => {
      const res = await hasura.execute(PagesQuery, { userId });
      return readFragment(PageFragment, res?.data?.pages ?? []).sort((a, b) =>
        a.idx < b.idx ? -1 : 1,
      );
    },
  });

export const useSyncPages = () => {
  const qry = useQueryClient();
  const userId = useUserId();
  const hasura = useGraphQLClient();
  useEffect(() => {
    return hasura.subscribe(
      PagesSubscription,
      { userId },
      {
        next: (res) => {
          if (PENDING_MUTATIONS.size > 0) return;
          const data = readFragment(PageFragment, res.data?.pages);
          qry.setQueryData(["pages", userId], data);
        },
      },
    );
  }, [qry, hasura, userId]);
};

export const usePages = () =>
  useQuery(pagesQueryOptions(useGraphQLClient(), useUserId()));

export const usePage = (pageId: string) =>
  useQuery({
    ...pagesQueryOptions(useGraphQLClient(), useUserId()),
    select: (data) => data.find((p) => p.id === pageId),
  });

export const useUpdatePage = () => {
  const userId = useUserId();
  const qry = useQueryClient();
  const hasura = useGraphQLClient();

  // const dirty = useRef<Set<string>>(new Set());
  const timeout = useRef<Record<string, NodeJS.Timeout>>({});
  const updates = useRef<Record<string, Partial<TPage>>>({});

  const mutation = useMutation({
    networkMode: "always",
    mutationFn: (id: string) => {
      PENDING_MUTATIONS.delete(id);
      const values = { ...updates.current[id] };
      delete updates.current[id];
      return hasura.execute(PageUpdateMutation, { pageId: id, values: values });
    },
    onError: (err) => logger.error(err),
    onSettled: () => {
      if (PENDING_MUTATIONS.size > 0) return;
      qry.invalidateQueries({ queryKey: ["pages", userId] });
    },
  });

  const optimistic = useCallback(
    async (values: Partial<TPage> & { id: string }) => {
      qry.cancelQueries({ queryKey: ["pages", userId] });
      PENDING_MUTATIONS.add(values.id);
      updates.current[values.id] = { ...updates.current[values.id], ...values };
      qry.setQueryData<TPage[]>(["pages", userId], (old) =>
        old?.map((x) => (x.id === values.id ? { ...x, ...values } : x)),
      );
      if (timeout.current[values.id]) clearTimeout(timeout.current[values.id]);
      timeout.current[values.id] = setTimeout(
        () => mutation.mutate(values.id),
        300,
      );
    },
    [qry, userId, mutation.mutate],
  );

  return Object.assign(mutation, { optimistic });
};

export const useAppendNewPage = () => {
  const qry = useQueryClient();
  const userId = useUserId();
  const pages = usePages();
  const hasura = useGraphQLClient();

  return useMutation({
    networkMode: "always",
    mutationFn: ({ name }: { name: string }) => {
      const last = pages.data?.[pages.data.length - 1]?.idx ?? null;
      const idx = generateKeyBetween(last ?? null, null);
      return hasura.execute(PageInsertMutation, {
        page: { id: nanoid(), name, idx, user_id: userId },
      });
    },
    onSettled: () => qry.invalidateQueries({ queryKey: ["pages", userId] }),
    onSuccess: (p) => {
      posthog.capture("page_created", {
        initial_page: false,
        page_name: p?.data?.insert_pages_one?.name,
        idx: p?.data?.insert_pages_one?.idx,
      });
    },
    onError: (e) => {
      logger.error(e);
    },
  });
};

export const useDeletePage = () => {
  const qry = useQueryClient();
  const hasura = useGraphQLClient();
  return useMutation({
    networkMode: "always",
    mutationFn: (id: string) => {
      return hasura.execute(PageDeleteMutation, { id });
    },
    onSettled: () => qry.invalidateQueries({ queryKey: ["pages"] }),
  });
};

export const useActivePageId = () => useParams({ from: "/app/market/$id" }).id;
export const useOptionalActivePageId = () => useParams({ strict: false }).id;

export const usePageFormattingOptions = (pageId: string) => {
  const brotli = useSuspenseFn("brotli", getSyncBrotli);

  return useQuery({
    ...pagesQueryOptions(useGraphQLClient(), useUserId()),
    select: (data): TPageFormattingOptions => {
      const page = data.find((p) => p.id === pageId);

      const formatting: TPageFormattingOptions = {
        cell_highlights: {},
        column_highlights: {},
        period_highlights: {},
      };
      if (page?.cell_highlights)
        formatting.cell_highlights = brotli.decompress(
          page.cell_highlights,
          {},
        );
      if (page?.column_highlights)
        formatting.column_highlights = brotli.decompress(
          page.column_highlights,
          {},
        );
      if (page?.period_highlights)
        formatting.period_highlights = brotli.decompress(
          page.period_highlights,
          {},
        );

      return formatting;
    },
  });
};

export const useUpdatePageFormatting = () => {
  const updatePage = useUpdatePage();
  const brotli = useSuspenseFn("brotli", getSyncBrotli);

  return useCallback(
    (x: Partial<TPageFormattingOptions> & { id: string }) => {
      const next: Partial<TPage> = {};
      if (x.cell_highlights)
        next.cell_highlights = brotli.compress(x.cell_highlights);
      if (x.column_highlights)
        next.column_highlights = brotli.compress(x.column_highlights);
      if (x.period_highlights)
        next.period_highlights = brotli.compress(x.period_highlights);

      return updatePage.optimistic({ id: x.id, ...next });
    },
    [updatePage.optimistic, brotli],
  );
};

export type TPageFormattingOptions = {
  cell_highlights: TPageFormatting;
  column_highlights: TPageFormatting;
  period_highlights: TPageFormatting;
};

export type TPageFormatting = Record<
  string,
  {
    color: string | null;
    boldText: boolean;
    invertTextColor: boolean;
  }
>;
