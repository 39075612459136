import { Button } from "@mui/joy";

import type { TRangeSelection } from "../../useRangeSelectionChange";
import { modalCancelActions } from "../modalAtoms";

export const FormatContainer = (p: {
  children: React.ReactNode;
  selection: TRangeSelection;
  onClose: () => void;
}) => {
  return (
    <div className="h-full grid [grid-template-rows:1fr_max-content] p-5">
      <div className="overflow-auto">{p.children}</div>
      <div className="flex gap-4 justify-end">
        <Button
          size="sm"
          variant="solid"
          color="neutral"
          type="button"
          sx={{ minWidth: "70px" }}
          onClick={(e) => {
            modalCancelActions.cancel();
            p.onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="solid"
          size="sm"
          sx={{
            width: "70px",
            background: (theme) => theme.palette.secondary[500],
          }}
          onClick={p.onClose}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
