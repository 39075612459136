import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import dayjs from "dayjs";
import { graphql } from "../../../graphql";

if (import.meta.env.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export const EodEntriesSub = graphql(`
  subscription eodEntries($eval_date: date!, $date: date!) {
    eod_entry(
      where: { evaluation_date: { _eq: $eval_date }, month: { _gte: $date } }
    ) {
      evaluation_date
      month
      product
      value
    }
  }
`);

export const LatestEodDateQuery = graphql(`
  query latestEODDate {
    eod_entry(
      order_by: { evaluation_date: desc }
      distinct_on: evaluation_date
      limit: 2
    ) {
      evaluation_date
    }
  }
`);

export const GridSettingsQuerySub = graphql(`
  subscription GridSettingsEodSub($userId: String!) {
      grid_settings(where: { user_id: { _eq: $userId } }) {
          eod_date
          status_map
      }
  }
`);

export const currentDate = new Date();
export const currentDay = currentDate.toISOString().split("T")[0];
export const currentMonth = `${currentDate.getFullYear()}-${
  currentDate.getMonth() + 1
}-01`;

export const SharedInstsQuery = graphql(`
  query sharedInstrumentsQuery($current_month: date!) {
    shared_instrument(where: { month: { _gte: $current_month } }) {
      product
      edited_at
      edited_by
      month
      value
      folio_user {
        id
        username
        firstname
        lastname
      }
    }
  }
`);

export const SharedInstsSub = graphql(`
  subscription sharedInstruments(
    $current_date: timestamptz
    $current_month: date!
  ) {
    shared_instrument_stream(
      cursor: { initial_value: { edited_at: $current_date }, ordering: ASC }
      where: { month: { _gte: $current_month } }
      batch_size: 10000
    ) {
      product
      edited_at
      edited_by
      month
      value
      folio_user {
        id
        username
        firstname
        lastname
      }
    }
  }
`);

export function subtractFourYears() {
  // Subtract 1460 days (4 years) from the current date
  const fourYearsAgo = dayjs().subtract(1460, "day");
  // Return the instant in ISO 8601 string format
  return fourYearsAgo.toISOString();
}

export const LocalInstsSub = graphql(`
  subscription localInstruments(
    $latest_date: timestamptz!
    $current_month: date!
  ) {
    local_instrument(
      where: {
        edited_at: { _gte: $latest_date }
        month: { _gte: $current_month }
      }
    ) {
      product
      storage_type
      folio_user
      edited_at
      month
      value
    }
  }
`);

export const MarketUserPermissionsFragment = graphql(`
  fragment MarketUserPermissionsFragment on permission {
    id
    folio_user
    permission
    package
  }
`);

export const MarketUserPermissionsSub = graphql(`
  subscription marketUserPermissions($folio_user: String!) {
    permission(where: { folio_user: { _eq: $folio_user } }) {
      ...MarketUserPermissionsFragment
    }
  }
`);

export const LogoutRequestCheck = graphql(`
  subscription CheckForLogout(
    $user: String!
    $context: String!
    $since: timestamptz!
  ) {
    logout_request(
      where: {
        _and: [
          { folio_user: { _eq: $user } }
          { context: { _in: ["all", "refresh", $context] } }
          { logout_requested_at: { _gt: $since } }
        ]
      }
    ) {
      logout_requested_at
    }
  }
`);

export const RequestLogoutMutation = graphql(`
  mutation RequestLogout($user: String!, $context: String!) {
    insert_logout_request_one(
      object: { folio_user: $user, context: $context }
    ) {
      context
    }
  }
`);

export const GlobalInstFragment = graphql(`
  fragment GlobalInstFragment on global_instrument {
    product: global_product
    id
    month
    value
    edited_at
  }
`);

export const GlobalInstsQuery = graphql(`
  query globalInstrumentsQuery($current_month: date!) {
    global_instrument(where: { month: { _gte: $current_month } }) {
      ...GlobalInstFragment
    }
  }
`);

export const GlobalInstsSub = graphql(`
  subscription globalInstruments(
    $current_date: timestamptz
    $current_month: date!
  ) {
    global_instrument_stream(
      cursor: { initial_value: { edited_at: $current_date }, ordering: ASC }
      where: { month: { _gte: $current_month } }
      batch_size: 10000
    ) {
      ...GlobalInstFragment
    }
  }
`);

// Order by idx ascending
export const PageProductsSub = graphql(`
  subscription pageProducts($userId: String!) {
    page_products(where: { user_id: { _eq: $userId } }, order_by: { idx: asc }) {
      id
      column_field_selector
      column_type
      column_width
      decimal_places
      idx
      page_id
      product_id
      thousands_separator
      user_id
    }
  }
`);
