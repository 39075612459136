import {
  AutocompleteListbox,
  AutocompleteOption,
  Box,
  Button,
  Chip,
  ChipDelete,
  type AutocompleteOptionProps,
  Typography,
  Stack,
} from "@mui/joy";
import {
  type ComponentProps,
  type Dispatch,
  type HTMLAttributes,
  type SetStateAction,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { GroupedVirtuoso } from "react-virtuoso";
import * as R from "remeda";
import {
  autocompleteZIndex,
  virtualizedListAutocompleteZIndex,
} from "../../globals";
import {
  Autocomplete as AutocompleteJoy,
  type AutocompleteRenderGetTagProps,
} from "@mui/joy";
import { Popper } from "@mui/base/Popper";
import type { Product_Artis_Type_Enum } from "../../../__generated__/gql/graphql";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import type { FilterOptionsState } from "@mui/base/useAutocomplete";
import type { SxProps } from "@mui/joy/styles/types";
import { FaPlus } from "react-icons/fa";
import { objectKeys } from "../../../utils";
import type { TProduct } from "../../../data";

export type TOption = {
  label: string;
  value: string;
  groupValue: string;
  disabled?: boolean;
  // I don't care that this isn't generic, nothing else will use this
  metadata?: TProduct | undefined;
};

type TJoyListbox = {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  anchorEl: any;
  open: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  modifiers: any;
};

type TVirtualizedList = TJoyListbox & HTMLAttributes<HTMLDivElement>;

export type TAutocomplete = {
  options: TOption[];
  listHeight?: number;
  disabled?: boolean;
  autoComplete?: boolean;
  autoFocus?: boolean;
  singleState: [
    TOption | undefined,
    Dispatch<SetStateAction<TOption | undefined>>,
  ];
  multipleState: [TOption[], Dispatch<SetStateAction<TOption[]>>];
  multiple: boolean;
  onSelectValue?: <T extends TOption>(value: T) => unknown;
  onSelectValues?: <T extends TOption[]>(value: T) => unknown;
  defaultValue?: TOption | TOption[];
  sx?: SxProps;
  placeholder?: string;
  disableFilter?: boolean;
  onOpen?: () => void;
  value?: string;
};

type TAutocompleteContext =
  | ({
      selectedValues: TOption[];
      setSelectedValues: Dispatch<SetStateAction<TOption[]>>;
    } & TAutocomplete)
  | null;

const AutocompleteContext = createContext<TAutocompleteContext>(null);

type TChildren = [AutocompleteOptionProps, TOption, string][];

function HighlightedText({
  parts,
}: {
  parts: {
    text: string;
    highlight: boolean;
  }[];
}) {
  return (
    <>
      {parts.map((part, index) => {
        return (
          <Typography
            // biome-ignore lint/suspicious/noArrayIndexKey: not sure there is a better key
            key={index}
            {...(part.highlight && {
              variant: "soft",
              color: "primary",
              px: "2px",
            })}
          >
            {part.text}
          </Typography>
        );
      })}
    </>
  );
}

const VirtualizedList = forwardRef<HTMLDivElement, TVirtualizedList>(
  function VirtualizedList(props, ref) {
    const { children, anchorEl, open, modifiers, ...other } = props;
    const { listHeight, setSelectedValues, multiple } =
      useContext(AutocompleteContext) || {};

    const options = (children as [TChildren])[0];
    const inputValue = options?.[0]?.[2] || "";

    if (!options) throw new Error("options is not defined");
    if (options.length === 0) return null;

    const typeMap = R.groupBy(
      options,
      ([_, option]) => option.metadata?.artis_type || "customer_curve",
    );

    const types = objectKeys(typeMap);
    const groupedTypes = types.reduce(
      (acc, type: Product_Artis_Type_Enum) => {
        if (type in typeMap) {
          acc[type] =
            Object.entries(
              R.groupBy(
                typeMap[type] || [],
                ([_, option]) => option.groupValue,
              ) || {},
            )
              .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
              .map(([, value]) => value) || [];
        }
        return acc;
      },
      { sourced: [], canned: [], customer_curve: [], eod: [] } as {
        sourced: Array<Array<(typeof options)[number]>>;
        canned: Array<Array<(typeof options)[number]>>;
        customer_curve: Array<Array<(typeof options)[number]>>;
        eod: Array<Array<(typeof options)[number]>>;
      },
    );

    const groupedAndSorted = [
      ...(groupedTypes.sourced || []),
      ...(groupedTypes.canned || []),
      ...(groupedTypes.customer_curve || []),
      ...(groupedTypes.eod || []),
    ];

    const sortedOptions = groupedAndSorted.flat();

    return (
      <Popper
        disablePortal
        style={{ zIndex: virtualizedListAutocompleteZIndex }}
        ref={ref}
        anchorEl={anchorEl}
        open={open}
        modifiers={modifiers}
      >
        <AutocompleteListbox
          {...other}
          component="div"
          color="neutral"
          ref={ref}
          sx={{
            "& ul": {
              padding: 0,
              margin: 0,
              flexShrink: 0,
            },
          }}
        >
          <GroupedVirtuoso
            style={{ height: listHeight || 200 }}
            groupCounts={groupedAndSorted.map((group) => group.length)}
            groupContent={(index) => {
              const group = groupedAndSorted[index] || [];
              const label = group[0][1].groupValue;

              const isGroupDisabled = group.every(
                ([, option]) => option.disabled,
              );

              const matches = match(label, inputValue);
              const parts = parse(label, matches);

              return (
                <Box
                  sx={{
                    display: "flex",
                    textTransform: "uppercase",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 1,
                    background: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.neutral[800]
                        : theme.palette.neutral[100],
                  }}
                >
                  <Typography level="inherit">
                    <HighlightedText parts={parts} />
                  </Typography>
                  {multiple && (
                    <Button
                      size="sm"
                      variant="solid"
                      color="neutral"
                      sx={{
                        backgroundColor: "transparent",
                        color: "inherit",
                        "&:hover": {
                          color: "inherit",
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? theme.palette.neutral[900]
                              : theme.palette.neutral[200],
                        },
                      }}
                      disabled={isGroupDisabled}
                      onClick={() => {
                        setSelectedValues?.((prevValues) => {
                          const newProducts = group
                            .map(([, option]) => option)
                            .filter(
                              (option) =>
                                !prevValues.includes(option) &&
                                !option.disabled,
                            );
                          return [...prevValues, ...newProducts];
                        });
                      }}
                    >
                      Add All
                    </Button>
                  )}
                </Box>
              );
            }}
            itemContent={(index) => {
              const [attrs, option] = sortedOptions[index] || [];
              const { key, ...props } = attrs;
              const matches = match(option.label, inputValue);
              const parts = parse(option.label, matches);
              return (
                <AutocompleteOption
                  key={key}
                  {...props}
                  component="li"
                  color="neutral"
                >
                  <Typography level="inherit">
                    <HighlightedText parts={parts} />
                  </Typography>
                </AutocompleteOption>
              );
            }}
          />
        </AutocompleteListbox>
      </Popper>
    );
  },
);

type TTag = {
  values: TOption[];
  getTagProps: AutocompleteRenderGetTagProps;
};

function Tags({ values, getTagProps }: TTag) {
  const { setSelectedValues } = useContext(AutocompleteContext) || {};

  const groupedOptions = values.reduce(
    (groups, option, idx) => {
      const key = option.groupValue;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push({ ...option, idx });
      return groups;
    },
    {} as Record<string, (TOption & { idx: number })[]>,
  );

  const chipSx = { minWidth: 0, maxWidth: "250px" } as const;

  return (
    <>
      {Object.entries(groupedOptions).map(([_, options]) => {
        const onAdd = (option: TOption) => {
          setSelectedValues?.((prevValues) => {
            return [...prevValues, option];
          });
        };

        return options.map((option) => {
          const { onClick, key, ...tagProps } = getTagProps({
            index: option.idx,
          });

          return (
            <Chip
              key={key}
              variant="soft"
              color="neutral"
              endDecorator={
                <Stack flexDirection={"row"} gap={1}>
                  <ChipDelete onDelete={onClick} />
                  {option.metadata?.artis_type === "canned" ||
                    (option.metadata?.artis_type === "sourced" && (
                      <Stack
                        onClick={() => onAdd(option)}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                          pointerEvents: "all",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "transparent",
                            svg: {
                              fill: "var(--webapp-palette-neutral-600)",
                            },
                          },
                        }}
                      >
                        <FaPlus color="var(--webapp-palette-neutral-500)" />
                      </Stack>
                    ))}
                </Stack>
              }
              sx={chipSx}
              {...tagProps}
            >
              {option.label}
            </Chip>
          );
        });
      })}
    </>
  );
}
const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const filterOptions = (
  options: unknown[],
  { inputValue }: FilterOptionsState<unknown>,
) => {
  const searchTerms = inputValue
    .trim()
    .toLowerCase()
    .split(/\s+/)
    .map(escapeRegExp);

  return (options as TOption[]).filter((option) => {
    const combinedFields = [
      option.label.toLowerCase(),
      option.groupValue.toLowerCase(),
    ].join(" ");

    return searchTerms.every((term) => {
      const specialCharRegex = /[^a-z0-9\s]/i;
      let regex: RegExp;

      if (specialCharRegex.test(term)) {
        regex = new RegExp(`(^|\\W)${term}`, "i");
        return regex.test(combinedFields);
      }
      regex = new RegExp(`(^|\\W)${term}`, "i");
      return regex.test(combinedFields);
    });
  });
};

type PartialAutocompleteProps = Partial<ComponentProps<typeof AutocompleteJoy>>;

export function Autocomplete(props: TAutocomplete) {
  const {
    multiple,
    options,
    autoComplete = false,
    autoFocus = false,
    singleState,
    multipleState,
    disabled,
    onOpen,
    onSelectValues,
    onSelectValue,
    sx,
    placeholder,
    disableFilter,
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = singleState;
  const [selectedValues, setSelectedValues] = multipleState;

  const tagsCallback = useCallback(
    (values: unknown[], getTagProps: AutocompleteRenderGetTagProps) => {
      return <Tags values={values as TOption[]} getTagProps={getTagProps} />;
    },
    [],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: We don't want to re-run this effect when input value changes
  useEffect(() => {
    if (!props.value || props.value === inputValue) return;
    setSelectedValue(options.find((x) => x.value === props.value));
  }, [props.value]);

  const sharedProps = {
    size: "sm",
    slots: {
      listbox: VirtualizedList,
    },
    placeholder: placeholder || "Search...",
    options: options,
    disabled,
    filterOptions: !disableFilter ? filterOptions : undefined,
    isOptionEqualToValue: (option, value) => {
      return (option as TOption)?.value === (value as TOption)?.value;
    },
    getOptionDisabled: (option) => !!(option as TOption).disabled,
    disableListWrap: true,
    renderOption: (props, option, { inputValue }) =>
      [props, option, inputValue] as React.ReactNode,
    renderGroup: (params) => params as unknown as React.ReactNode,
  } as const satisfies PartialAutocompleteProps;

  const multiProps = {
    multiple: true,
    inputValue: inputValue,
    placeholder: placeholder || "Search...",
    onOpen: () => {
      if (onOpen) onOpen();
    },
    onInputChange: (_event, newInputValue, reason) => {
      if (reason !== "reset") setInputValue(newInputValue);
    },
    onChange: (_event, newValue) => {
      setSelectedValues(newValue as TOption[]);
      onSelectValues?.(newValue as TOption[]);
    },
    disableCloseOnSelect: true,
    renderTags: tagsCallback,
  } as const satisfies PartialAutocompleteProps;

  // we use this to manage the onOpen / onClose behavior
  // when the user opens the dropdown, we want to clear the selected value
  // to show all options.
  // when the options are closed, we want to either restore the original value
  // or keep the selected value if the user has selected a new one.
  const tempState = useRef<TOption | null>(null);

  const singleProps = {
    multiple: false,
    onOpen: () => {
      if (onOpen) onOpen();
      setSelectedValue(undefined);
    },
    onClose: () => {
      const tempStateVal = tempState.current;
      if (tempStateVal === undefined || tempStateVal === null)
        setSelectedValue(props.defaultValue as TOption);
    },
    onChange: (_event, newValue) => {
      tempState.current = newValue as TOption;
      setSelectedValue(newValue as TOption);
      onSelectValue?.(newValue as TOption);
    },
    disableClearable: true,
  } as const satisfies PartialAutocompleteProps;

  const conditionalProps = multiple ? multiProps : singleProps;

  return (
    <AutocompleteContext.Provider
      value={{ ...props, selectedValues, setSelectedValues }}
    >
      <Box sx={{ position: "relative" }}>
        <AutocompleteJoy
          {...sharedProps}
          {...conditionalProps}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          limitTags={2}
          slotProps={{
            popupIndicator: { sx: { alignSelf: "self-end", mb: ".18rem" } },
            clearIndicator: { sx: { alignSelf: "self-end", mb: ".18rem" } },
            wrapper: {
              sx: { height: "auto", maxHeight: "20rem", overflowY: "auto" },
            },
            input: { sx: { height: "max-content" } },
            listbox: { sx: { zIndex: autocompleteZIndex } },
          }}
          sx={sx}
          // @ts-expect-error - read below
          // Unfortunately, Autocomplete doesn't accept a 'null' value
          // for the 'value' prop in singleProps. It is needed because passing
          // from `undefined` to some value, when the user selects an option,
          // causes the input to go from uncontrolled to controlled.
          // `null` is used to prevent this, when a defaultValue cannot be provided.
          value={multiple ? selectedValues : selectedValue || null}
        />
      </Box>
    </AutocompleteContext.Provider>
  );
}
