import type { MenuItemDef } from "ag-grid-community";

import { useActivePageId, usePage, useUpdatePage } from "../../../data";
import { useUserSubscriptionTier } from "../../../context/auth";
import { getIcon } from "./icons";

export const useGetMenuItemsHeaderSize = () => {
  const { disabledFeatures } = useUserSubscriptionTier();
  const pageId = useActivePageId();
  const page = usePage(pageId);
  const extraHeaderHeightEnabled = !!page.data?.extra_header_height;
  const update = useUpdatePage();

  return (props: {
    isProduct: boolean;
    isHeaderMenuItem: boolean;
  }): MenuItemDef[] => {
    if (!props.isProduct || !props.isHeaderMenuItem) return [];
    return [
      {
        disabled: disabledFeatures?.headerSize,
        name: "Header Size",
        tooltip: disabledFeatures?.headerSize
          ? "Please upgrade your subscription to access this feature"
          : undefined,
        icon: getIcon("header"),
        subMenu: [
          {
            name: "Compact",
            icon: !extraHeaderHeightEnabled ? getIcon("check") : "",
            action: () =>
              update.optimistic({
                id: pageId,
                extra_header_height: false,
              }),
          },
          {
            name: "Full",
            icon: extraHeaderHeightEnabled ? getIcon("check") : "",
            action: () =>
              update.optimistic({
                id: pageId,
                extra_header_height: true,
              }),
          },
        ],
      },
    ];
  };
};
