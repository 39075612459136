import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import type { TEnv } from "./globals";

type SearchParams = {
  env?: TEnv;
};

export const rootRoute = createRootRouteWithContext()({
  component: () => <Outlet />,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    // these are auth0 params that we don't want to keep in the url
    search.code = undefined;
    search.state = undefined;
    return {
      ...search,
    };
  },
});
