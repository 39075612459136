import { monthCodeToShortMonthAndYear } from "../periodHelpers";
import { useUserSubscriptionTier } from "../../../context/auth";
import type { GetContextMenuSection } from "./helpers";
import { getIcon } from "./icons";

export const useGetMenuItemsCharts = (): GetContextMenuSection => {
  const { disabledFeatures } = useUserSubscriptionTier();

  return (ctx) => {
    if (!ctx.isProduct) return [];
    return [
      {
        name: "Plot Historical Chart",
        disabled: disabledFeatures?.plotLiveChart,
        tooltip: disabledFeatures?.plotLiveChart
          ? "Please upgrade your subscription to access this feature"
          : undefined,
        icon: getIcon("barChart"),
        action: () => {
          const cell = ctx.rangeSelection?.[0];
          if (!cell) return;

          const productId = cell?.productId;
          const rowId = cell?.rowId;
          if (!productId || !rowId) return;

          const month = monthCodeToShortMonthAndYear(rowId);

          window.open(
            `/app/trading-chart/${productId}/${month}`,
            "singleChart", // Prevent users from bypassing chart limit.
            "location=yes,scrollbars=yes,status=yes,width=750,height=500",
          );
        },
      },
    ];
  };
};
