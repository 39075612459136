import { useEffect, useRef, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { atom, useAtom } from "jotai";

import { useSessionState } from "../../context/auth";
import { logger } from "@artis/logger";

export function useScrollableTabs(pagesLength: number) {
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const checkScroll = useCallback(() => {
    const container = tabContainerRef.current;
    if (container) {
      const currentScroll = Math.round(container.scrollLeft);
      const maxScroll = Math.round(
        container.scrollWidth - container.clientWidth,
      );

      setCanScrollLeft(currentScroll > 0);
      setCanScrollRight(currentScroll < maxScroll - 2);
    }
  }, []);

  useEffect(() => {
    const tabContainer = tabContainerRef.current;
    if (!tabContainer) return;

    const resizeObserver = new ResizeObserver(() => {
      checkScroll();
    });
    resizeObserver.observe(tabContainer);

    let scrollTimeout: NodeJS.Timeout;
    const handleScroll = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(checkScroll, 50);
      checkScroll();
    };

    tabContainer.addEventListener("scroll", handleScroll);

    checkScroll();
    if (pagesLength) {
      handleScroll();
    }

    return () => {
      clearTimeout(scrollTimeout);
      resizeObserver.disconnect();
      tabContainer.removeEventListener("scroll", handleScroll);
    };
  }, [checkScroll, pagesLength]);

  const scrollToNextTab = useCallback(() => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollBy({
      left: 100,
      behavior: "smooth",
    });
    setTimeout(checkScroll, 300);
  }, [checkScroll]);

  const scrollToPreviousTab = useCallback(() => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollBy({
      left: -100,
      behavior: "smooth",
    });
    setTimeout(checkScroll, 300);
  }, [checkScroll]);

  const scrollToBeginning = useCallback(() => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollTo({
      left: 0,
      behavior: "smooth",
    });
    setTimeout(checkScroll, 300);
  }, [checkScroll]);

  const scrollToEnd = useCallback(() => {
    if (!tabContainerRef.current) return;
    tabContainerRef.current.scrollTo({
      left: tabContainerRef.current.scrollWidth,
      behavior: "smooth",
    });
    setTimeout(checkScroll, 300);
  }, [checkScroll]);

  return {
    tabContainerRef,
    canScrollLeft,
    canScrollRight,
    scrollToNextTab,
    scrollToPreviousTab,
    scrollToBeginning,
    scrollToEnd,
  };
}

// Prevent multiple refresh timeouts from being set.
export const refreshTimeoutSet = atom<boolean>(false);
refreshTimeoutSet.debugLabel = "refreshTimeoutAtom";

export function useRefreshTimeout({
  hour = 1,
  minute = 0,
  offset = 10,
}: {
  hour?: number;
  minute?: number;
  offset?: number;
}) {
  const [timeoutSet, setTimeoutSet] = useAtom(refreshTimeoutSet);
  const { isLoading: tokenLoading } = useAuth0();
  const session = useSessionState();

  useEffect(() => {
    const previousRefresh = Number(
      localStorage.getItem("previousRefresh") || 0,
    );

    if (timeoutSet) {
      logger.log("timeout already set");
      return;
    }

    if (session.data?.access_token && !tokenLoading) {
      const today = new Date();

      // 1 AM based on the user's local time.
      const baseRefreshDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        hour,
        minute,
        0,
      );

      const isPreviousRefreshToday =
        new Date(previousRefresh).getDate() === today.getDate();

      // Determine if we need to use tomorrow's date instead
      const shouldUseTomorrowDate =
        baseRefreshDate.getTime() < today.getTime() || isPreviousRefreshToday;

      // Calculate the final refresh date without mutation
      const refreshDate = shouldUseTomorrowDate
        ? new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() + 1,
            hour,
            minute,
            0,
          )
        : baseRefreshDate;

      // 1 AM based on GMT, adjusted for the user's local time.
      const timeDiff = today.getTimezoneOffset() / 60;
      const livePricesResetDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1,
        hour - timeDiff,
        minute,
        0,
      );

      const offsetInMs = offset * 60 * 1000;
      const randomOffset =
        Math.floor(Math.random() * (offsetInMs * 2)) - offsetInMs;

      const timeUntilRefresh =
        refreshDate.getTime() - today.getTime() + randomOffset;

      const timeUntilLivePricesReset =
        livePricesResetDate.getTime() - today.getTime() + randomOffset;

      const timeoutRefresh = timeUntilRefresh < 0 ? 0 : timeUntilRefresh;

      const partsRefresh = new Date(timeUntilRefresh)
        .toISOString()
        .substring(11, 19)
        .split(":");

      const partsLivePricesReset = new Date(timeUntilLivePricesReset)
        .toISOString()
        .substring(11, 19)
        .split(":");

      logger.debug("Time until refresh:", {
        partsRefresh,
        offset,
        refreshDate,
      });

      logger.debug("Time until live prices reset:", {
        partsLivePricesReset,
        offset,
        livePricesResetDate,
      });

      setTimeout(() => {
        // If the previous refresh time is within the last offset minutes, don't refresh.
        if (
          previousRefresh &&
          Date.now() - previousRefresh < offset * 60 * 1000
        ) {
          console.log("previous refresh within 5 minutes");
          return;
        }

        logger.log("doing scheduled page reload");
        localStorage.setItem("previousRefresh", Date.now().toString());
        window.location.reload();
      }, timeoutRefresh);

      setTimeoutSet(true);
    }
  }, [
    session.data?.access_token,
    tokenLoading,
    hour,
    minute,
    offset,
    timeoutSet,
    setTimeoutSet,
  ]);
}
