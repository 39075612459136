import { Box } from "@mui/joy";

import { ArtisSmallLogo } from "../../icons/ArtisSmallLogo";
import { useThemeMode } from "../../../context/theme";
import { agGridCellAutoPadding } from "../../globals";

export function HeaderArtisLogo() {
  const mode = useThemeMode();

  return (
    <Box sx={{ width: 63, pl: agGridCellAutoPadding }}>
      <ArtisSmallLogo mode={mode === "dark" ? "dark" : "light"} />
    </Box>
  );
}
