import type { CellClassParams, CellStyle } from "ag-grid-community";

import {
  type TPageFormattingOptions,
  useActivePageId,
  usePageFormattingOptions,
  type TPageFormatting,
  type TProduct,
  type TConditionalFormattingRulesGrouped,
  useQueryConditionalFormattingRulesGrouped,
} from "../../../data";
import { getNodePeriod } from "../modals/ConditionalFormatting/helpers";
import type { TConditionalFormattingRules } from "../../../triplit";
import { isListOnlyPermissions, parseLimitRef } from "../../utils";
import { useMemoCallback } from "../../../utils/useMemoCallback";
import { rowIdToRelativeRow } from "../relativeRowHelpers";
import type { TColumnHeaderProps } from "../components";
import { calculateTextColor } from "../../../utils/color";
import type { TData } from "../../calculations-worker";
import { rowStringToCode } from "../periodHelpers";
import { parseNumber } from "../../numbers";
import { getBackgroundForStatus, getForegroundForStatus } from "./helpers";
import { useUserId } from "../../../context/auth";

export type ProcessCellStyleFn = (
  params: CellClassParams,
  product?: TProduct,
) => CellStyle | undefined;

export const useProcessCellStyle = (): ProcessCellStyleFn => {
  const pageId = useActivePageId();
  const userId = useUserId();
  const formatting = usePageFormattingOptions(pageId);
  const conditional = useQueryConditionalFormattingRulesGrouped(pageId, userId);

  const process: ProcessCellStyleFn = (params, product) => {
    const isNotMonthColumn = params.colDef.field !== "period";
    const isNotSpreadColumn = params.colDef.field !== "month-timespread";
    const perms = product?.packageByPackage.permissions;
    const isListOnly = isListOnlyPermissions(perms);
    const noPermissions = !perms;
    const headerParams = params.column?.getUserProvidedColDef()
      ?.headerComponentParams as TColumnHeaderProps | null;
    const status = headerParams?.status || "listen";
    const sharedCell =
      params?.data?.rowType === "mth" &&
      headerParams?.sharedCellOffsets?.includes(params.rowIndex?.toString());

    const statusBackgroundColor = sharedCell
      ? getBackgroundForStatus(status)
      : "inherit";

    const statusForegroundColor = sharedCell
      ? getForegroundForStatus(status)
      : "inherit";

    const period = getNodePeriod(params.node);

    const highlight = getHighlight(
      formatting.data,
      params.column.getColId(),
      period,
    );

    const rule = getConditionalFormatting(params, conditional.data);

    if (
      isNotMonthColumn &&
      isNotSpreadColumn &&
      (isListOnly || noPermissions)
    ) {
      return {
        background: "var(--disabled-column)",
        color: "inherit",
        fontWeight: "normal",
      };
    }

    if (rule) {
      const bg = rule.bgColor || highlight?.color || "inherit";
      const textColor = calculateTextColor(bg, rule.invertTextColor);
      return {
        background: bg,
        color: textColor,
        fontWeight: rule.boldText ? "bold" : "normal",
      };
    }

    return {
      color: highlight?.color
        ? calculateTextColor(highlight?.color, !!highlight?.invertTextColor)
        : statusForegroundColor,
      background: highlight?.color ?? statusBackgroundColor,
      fontWeight: highlight?.boldText ? "bold" : "normal",
    };
  };

  return useMemoCallback(process);
};

function getHighlight(
  pageFormatting: TPageFormattingOptions | undefined,
  colId: string,
  period: string,
) {
  if (!pageFormatting || !period) return null;

  const { cell_highlights, column_highlights, period_highlights } =
    pageFormatting;

  // this is not addressing aggregates
  // it happens to work for quarters and halves because Q and H are valid month codes
  // but fails at Win 24. This is a bug.
  const rowId = rowIdToRelativeRow[period];
  if (!rowId) {
    console.error(`No rowId found for period: ${period}`);
    return null;
  }

  return mergeRules(
    period_highlights[rowStringToCode(period)],
    column_highlights[colId],
    cell_highlights[`${colId}-${rowId}`],
  );
}

type TConditionalFormattingRule = {
  bgColor?: string | null;
  invertTextColor: boolean;
  boldText: boolean;
  stopIfTrue: boolean;
  limit: string;
  rule: string;
};

function getConditionalFormatting(
  params: CellClassParams<TData>,
  conditionalFormattingRules: TConditionalFormattingRulesGrouped | undefined,
) {
  if (!conditionalFormattingRules) return;

  const colId = params.column.getColId();
  const period = getNodePeriod(params.node);

  if (!period) return;

  const rowId = rowIdToRelativeRow[period];

  if (!(`${colId}-${rowId}` in conditionalFormattingRules)) return;

  const rules = conditionalFormattingRules[`${colId}-${rowId}`];

  if (!rules.length) return;

  const cellValue = params.value;

  if (typeof cellValue !== "number") return;

  const accumulatedFormatting: Partial<TConditionalFormattingRule> = {};

  for (const currentRule of rules) {
    if (ruleMet(params, currentRule, cellValue)) {
      if (currentRule.bg_color) {
        accumulatedFormatting.bgColor = currentRule.bg_color;
      }

      if (currentRule.invert_text_color !== undefined) {
        accumulatedFormatting.invertTextColor = currentRule.invert_text_color;
      }

      if (currentRule.bold_text !== undefined) {
        accumulatedFormatting.boldText = currentRule.bold_text;
      }

      accumulatedFormatting.limit = currentRule.limit;
      accumulatedFormatting.rule = currentRule.rule;

      if (currentRule.stop_if_true) {
        break;
      }
    }
  }

  if (Object.keys(accumulatedFormatting).length > 0) {
    return accumulatedFormatting as TConditionalFormattingRules;
  }

  return;
}

function ruleMet(
  params: CellClassParams<TData>,
  rule: TConditionalFormattingRulesGrouped[string][0],
  cellValue: number,
  acc?: TConditionalFormattingRulesGrouped[string][0],
) {
  const limitIsRef = rule.limit.includes(":");

  const limitNumber = limitIsRef
    ? getCellValueByRef(params, rule.limit)
    : parseNumber(rule.limit);

  if (limitNumber !== 0 && !limitNumber) return acc || undefined;

  return conditionalRuleMet(rule.rule, cellValue, limitNumber);
}

function conditionalRuleMet(rule: string, source: number, target: number) {
  switch (rule) {
    case "equals":
      return source === target;
    case "isNotEqual":
      return source !== target;
    case "lessThan":
      return source < target;
    case "moreThan":
      return source > target;
    case "lessThanOrEqual":
      return source <= target;
    case "moreThanOrEqual":
      return source >= target;
  }
}

function getCellValueByRef(params: CellClassParams<TData>, ref: string) {
  const { columnId, rowId } = parseLimitRef(ref);

  if (!columnId || !rowId) return;

  const rowNode = params.api.getRowNode(rowId);
  if (!rowNode) return;

  const value = params.api.getCellValue({ rowNode, colKey: columnId });

  return value;
}

const mergeRules = (
  ...rules: (TPageFormatting[keyof TPageFormatting] | null | undefined)[]
) =>
  rules.reduce<TPageFormatting[keyof TPageFormatting] | null>((acc, b) => {
    if (!b) return acc;
    if (!acc) return b;
    return {
      color: b.color || acc.color,
      boldText: b.boldText || acc.boldText,
      invertTextColor: b.invertTextColor || acc.invertTextColor,
    };
  }, null);
