import { Button, Stack, Typography, Box, Select, Option } from "@mui/joy";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import {
  halfLookups,
  monthLookups,
  quarterLookups,
  seasonLookups,
  yearLookups,
} from "..";
import { useState, useMemo, useEffect } from "react";
import { useColorScheme } from "@mui/joy";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { formulaEditorZIndex } from "../../globals";
import { useGrid } from "../stores";
import {
  usePage,
  useAdhocSpreads,
  useActivePageId,
  type TAdhocPeriod,
  useUpdateAdhocSpreads,
  type TAdhocSpreadFormatted,
} from "../../../data";

export type TTimeSpreadsModalProps = {
  rowId?: string;
};

const monthValues = Object.values(monthLookups);
const quarterValues = Object.values(quarterLookups);
const halfValues = Object.values(halfLookups);
const calValues = Object.values(yearLookups);
const seasonValues = Object.values(seasonLookups);

export const useTimeSpreadsModal = () => {
  const [state, setState] = useState<Omit<Props, "setClose" | "open"> | null>(
    null,
  );
  const modal = useMemo(() => {
    if (!state) return null;
    return (
      <TimeSpreadsModalWithState
        open
        {...state}
        setClose={() => setState(null)}
      />
    );
  }, [state]);

  return [modal, setState] as const;
};

type Props = {
  rowId: string;
  open: boolean;
  anchor: HTMLElement | null;
  setClose: () => void;
};

export function TimeSpreadsModalWithState({
  rowId,
  open,
  anchor,
  setClose,
}: Props) {
  const { mode } = useColorScheme();
  const pageId = useActivePageId();
  const page = usePage(pageId);
  const [gridApi] = useGrid();
  const spreads = useAdhocSpreads(pageId);
  const update = useUpdateAdhocSpreads();

  const allPeriods = useMemo(
    () => [
      ...monthValues.map((v) => ({
        value: v,
        label: v,
        type: "months" as const,
      })),
      ...quarterValues.map((v) => ({
        value: v,
        label: v,
        type: "quarters" as const,
      })),
      ...halfValues.map((v) => ({
        value: v,
        label: v,
        type: "halves" as const,
      })),
      ...calValues.map((v) => ({ value: v, label: v, type: "cals" as const })),
      ...seasonValues.map((v) => ({
        value: v,
        label: v,
        type: "seasons" as const,
      })),
    ],
    [],
  );

  const [from, setFrom] = useState<TAdhocPeriod>({
    periodType: "months",
    periodValue: allPeriods[0].value,
  });

  const [to, setTo] = useState<TAdhocPeriod>({
    periodType: "months",
    periodValue: allPeriods[0].value,
  });

  useEffect(() => {
    const adhocSpread = spreads.data?.[rowId];
    if (open) {
      setFrom({
        periodType: adhocSpread?.from?.periodType ?? "months",
        periodValue: adhocSpread?.from?.periodValue ?? allPeriods[0].value,
      });
      setTo({
        periodType: adhocSpread?.to?.periodType ?? "months",
        periodValue: adhocSpread?.to?.periodValue ?? allPeriods[0].value,
      });
    }
  }, [open, rowId, spreads.data?.[rowId], allPeriods]);

  const refreshCell = () => {
    if (gridApi) {
      const rowNode = gridApi.getRowNode(rowId);
      if (rowNode) {
        gridApi.clearFocusedCell();
        gridApi.refreshCells({
          force: true,
          rowNodes: [rowNode],
          suppressFlash: true,
        });
      }
    }
  };
  const handleDelete = () => {
    if (!page.data) return;
    const adhoc_spreads = { ...spreads.data };
    delete adhoc_spreads[rowId];
    update({ id: pageId, adhoc_spreads });
    refreshCell();
    setClose?.();
  };

  const onSave = () => {
    if (!page.data) return;
    const adhoc_spreads: TAdhocSpreadFormatted = {
      ...spreads.data,
      [rowId]: { rowId, from, to },
    };

    update({ id: pageId, adhoc_spreads });

    setClose?.();
  };

  return (
    <ClickAwayListener onClickAway={(event) => setClose?.()}>
      <BasePopup
        open={open}
        anchor={anchor}
        placement="right"
        offset={{ mainAxis: 8, crossAxis: -90 }}
        style={{
          width: 300,
          zIndex: formulaEditorZIndex,
        }}
      >
        <Box
          sx={{
            borderRadius: "sm",
            overflow: "hidden",
            width: "330px",
            background: (theme) =>
              mode === "dark"
                ? "var(--artis-dark-mode-light-grey)"
                : theme.palette.background.surface,
            borderWidth: 1,
            paddingTop: "10px !important",
            borderColor: (theme) =>
              mode === "light"
                ? theme.palette.neutral[200]
                : theme.palette.neutral[700],
            p: 2,
          }}
        >
          <Stack spacing={2}>
            <Typography level="h4" sx={{ mb: 1 }}>
              Custom Time Spread
            </Typography>

            <Stack direction="row" spacing={2}>
              <Stack spacing={0} sx={{ flex: 1 }}>
                <Typography>From:</Typography>
                <Select
                  value={from.periodValue}
                  onChange={(_, newValue) => {
                    if (!newValue) return;
                    const selected = allPeriods.find(
                      (p) => p.value === newValue,
                    );
                    if (selected) {
                      console.log("selected", selected);
                      setFrom({
                        periodType: selected.type,
                        periodValue: selected.value,
                      });
                    }
                  }}
                  slotProps={{
                    listbox: {
                      sx: {
                        maxHeight: "300px",
                      },
                    },
                  }}
                  sx={{ width: "130px" }}
                >
                  {allPeriods.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Stack>

              <Stack spacing={0} sx={{ flex: 1 }}>
                <Typography>To:</Typography>
                <Select
                  value={to.periodValue}
                  onChange={(_, newValue) => {
                    if (!newValue) return;
                    const selected = allPeriods.find(
                      (p) => p.value === newValue,
                    );
                    if (selected) {
                      setTo({
                        periodType: selected.type,
                        periodValue: selected.value,
                      });
                    }
                  }}
                  slotProps={{
                    listbox: {
                      sx: {
                        maxHeight: "300px",
                      },
                    },
                  }}
                  sx={{ width: "130px" }}
                >
                  {allPeriods.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                size="sm"
                variant="solid"
                color="danger"
                sx={{ width: "70px" }}
                onClick={handleDelete}
              >
                Delete
              </Button>
              <Button
                size="sm"
                variant="solid"
                color="neutral"
                sx={{ width: "70px" }}
                onClick={setClose}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                variant="solid"
                onClick={onSave}
                sx={{ width: "70px" }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </BasePopup>
    </ClickAwayListener>
  );
}
