import { useUpdateColumnFromCellByValueAndOperation } from "./useUpdateColumnFromCellByValueAndOperation";
import { useUpdateCellByValueAndOperation } from "./useUpdateCellByValueAndOperation";
import { copyWithHeadersAndMonths } from "../copyRangeSelectionHelpers";
import { getCellIds } from "../cell-style";

import {
  useActivePageId,
  useOptionalActivePageId,
  usePage,
  usePageFormattingOptions,
  useUpdatePageFormatting,
} from "../../../data";
import type { TGridKeyboardShortcut } from "./utils";
import { getProcessedSelectedCellsByRange } from "../../../tableUtils";
import type { GridApi } from "ag-grid-community";

export const useKeyboardShortcuts = () => {
  const pageId = useOptionalActivePageId();
  const page = usePage(pageId ?? "");
  const updateColumnFromCellByValueAndOperation =
    useUpdateColumnFromCellByValueAndOperation();
  const updateCellsByValueAndOperation = useUpdateCellByValueAndOperation();
  const toggleBold = useBoldToggle();

  return [
    {
      name: "Increment Column",
      description: "Increment the selected column(s)",
      shortcuts: [
        "ctrl+alt+ArrowUp",
        "cmd+alt+ArrowUp",
        "ctrl+shift+i",
        "cmd+shift+i",
      ],
      action: ({ params }) =>
        updateColumnFromCellByValueAndOperation({ params, operation: "+" }),
    },
    {
      name: "Decrement Column",
      description: "Decrement the selected column(s)",
      shortcuts: [
        "ctrl+alt+ArrowDown",
        "cmd+alt+ArrowDown",
        "ctrl+shift+d",
        "cmd+shift+d",
      ],
      action: ({ params }) =>
        updateColumnFromCellByValueAndOperation({ params, operation: "-" }),
    },
    {
      name: "Increment Cell",
      description: "Increment the selected cell(s)",
      shortcuts: ["ctrl+ArrowUp", "cmd+ArrowUp", "ctrl+i", "cmd+i"],
      action: ({ params }) => {
        updateCellsByValueAndOperation({ params, operation: "+" });
      },
    },
    {
      name: "Decrement Cell",
      description: "Decrement the selected cell(s)",
      shortcuts: ["ctrl+ArrowDown", "cmd+ArrowDown", "ctrl+d", "cmd+d"],
      action: ({ params }) => {
        updateCellsByValueAndOperation({ params, operation: "-" });
      },
    },
    {
      name: "Copy with Headers and Months",
      description: "Copy the selected range with headers and months",
      shortcuts: ["ctrl+shift+c", "cmd+shift+c"],
      action: ({ params }) => copyWithHeadersAndMonths(params),
    },
    {
      name: "Make Cell Bold",
      description: "Format the selected cell to be bold",
      shortcuts: ["ctrl+b", "cmd+b"],
      action: ({ params }) => {
        if (!page?.data) return;
        toggleBold(params);
      },
    },
  ] satisfies TGridKeyboardShortcut[];
};

const useBoldToggle = () => {
  const pageId = useActivePageId();
  const updateFormatting = useUpdatePageFormatting();
  const pageFormatting = usePageFormattingOptions(pageId);

  return ({ api }: { api: GridApi }) => {
    const ranges = getProcessedSelectedCellsByRange({ api });
    const { cellIds } = getCellIds({ api, ranges, type: "range" });
    const cell_highlights = pageFormatting.data?.cell_highlights ?? {};

    for (const cellId of cellIds) {
      const highlight = cell_highlights[cellId] || {};
      const bold = highlight.boldText || false;
      cell_highlights[cellId] = {
        ...cell_highlights[cellId],
        boldText: !bold,
      };
    }

    updateFormatting({ id: pageId, cell_highlights });
  };
};
