import { Button } from "@mui/joy";
import { getAlertDetails } from "./hooks";
import toast, { type Toast } from "react-hot-toast";
import { alertSounds } from "../../../globals";
import { nanoid } from "nanoid";
import type { useApi } from "../../../../admin/hooks/useApi";
import { vapidKeys } from "../../../../globals";
import { v4 as uuidv4 } from "uuid";

import { logger } from "@artis/logger";
import type { QueryClient } from "@tanstack/react-query";
import { productsInfoQueryOptions } from "../../../../data/products";
import type { AlertFragmentFragment } from "../../../../__generated__/gql/graphql";
import { alertExists, updateAlert } from "../../../../data/alerts/alerts";
import {
  type GraphQLClient,
  useGraphQLClient,
} from "../../../../utils/graphql";
import { client } from "../../../../triplit";
import { useDeleteAlert, useInsertAlert } from "../../../../data/alerts/hooks";
import type { TGridSettings } from "../../../../data";
import { atom } from "jotai";
import { store } from "../../../modal";
import { AlertToast } from "../../../ui";
import { parseLimitRef } from "../../../utils";
import {
  monthCodeToShortMonthAndYear,
  rowIdToRelativeRow,
} from "../../periodHelpers";
import {
  alertConditionMet,
  getAlertColumns,
  getAlertLimit,
  parseRowId,
} from "../../../../data/alerts/utils";
import { stores } from "../../../calculations-worker/stores";
import { getProductConfigs, getProductInfo } from "../../../../data/cache";
import { parseNumber } from "../../../numbers";
import { getCalcWorker } from "../../../calculations-worker";
import { isNullish } from "remeda";

function limitToRowId(alert: AlertFragmentFragment) {
  const limit = alert.limit;

  if (limit.includes(":")) {
    const id = parseLimitRef(limit).rowId;
    if (id) {
      const month = monthCodeToShortMonthAndYear(id);
      return parseRowId(rowIdToRelativeRow[month]);
    }
  }
}

async function conditionMet(alert: AlertFragmentFragment) {
  const limitRowId = limitToRowId(alert);
  const alertRowId = parseRowId(alert.row_id);

  const [productInfo, configs] = await Promise.all([
    getProductInfo(),
    getProductConfigs(),
  ]);

  const statusMap = stores.settings.statusMap;
  const eodEvalDate = stores.settings.getEodDate();

  const { columns, limitColumns } = getAlertColumns(
    [alert],
    productInfo,
    configs,
    statusMap,
  );

  const worker = getCalcWorker();
  const alertsGridData = await worker.getGridData({
    columns,
    rowIds: [alertRowId].filter(Boolean),
    eodEvalDate,
  });

  const limitGridData = await worker.getGridData({
    columns: limitColumns,
    rowIds: [limitRowId].filter(Boolean),
    eodEvalDate,
  });

  const cellValue = alertsGridData[0][alert.column_id]?.Ok ?? null;

  if (cellValue !== null) {
    const limit = getAlertLimit(
      limitGridData,
      alert.limit,
      [limitRowId].filter(Boolean),
    );

    if (isNullish(limit)) {
      logger.warn(
        "No limit found for alert - should probably look into this as it shouldn't happen",
        { alert },
      );
      return;
    }

    const conditionMet = alertConditionMet(
      parseNumber(cellValue),
      limit,
      alert.value_below_limit,
    );

    return conditionMet;
  }
}

function AlertToastMessage({
  t,
  alert,
  productName,
  limitName,
}: {
  t: Toast;
  alert: AlertFragmentFragment;
  productName: string;
  limitName?: string | null;
}) {
  const hasuraClient = useGraphQLClient();
  const insertAlert = useInsertAlert();
  const deleteAlert = useDeleteAlert();

  const { formattedMessage } = getAlertDetails(alert, productName, limitName);
  const duplicateAlert: AlertFragmentFragment = {
    id: nanoid(),
    _id: uuidv4(),
    column_id: alert.column_id,
    row_id: alert.row_id,
    limit: alert.limit,
    note: alert.note,
    recurring: alert.recurring,
    sound: alert.sound,
    value_below_limit: alert.value_below_limit,
    status: "Paused",
    triggered_at: null,
    product_id: alert.product_id,
    field_selector: alert.field_selector,
    user_id: alert.user_id,
    page_id: alert.page_id,
  };

  const message = (
    <>
      <p className="py-2">{formattedMessage}</p>
      {!alert.recurring ? (
        <Button
          color="neutral"
          sx={{ width: "90px" }}
          onClick={async () => {
            const alertConditionMet = await conditionMet(alert);
            toast.dismiss(alert.id);
            deleteAlert.mutate(alert.id);
            insertAlert.mutate({
              ...duplicateAlert,
              status: alertConditionMet ? "Paused" : "Active",
            });
          }}
        >
          Re-set alert
        </Button>
      ) : null}
    </>
  );

  return (
    <AlertToast
      id={alert.id}
      title={`${alert.note || "Alert"} triggered`}
      hidden={!t.visible || t.dismissed}
      message={message}
      onDismiss={async () => {
        toast.dismiss(alert.id);

        const existing = await alertExists(hasuraClient, alert.id);

        deleteAlert.mutate(alert.id);

        if (alert.recurring && existing?.id) {
          const alertConditionMet = await conditionMet(alert);
          insertAlert.mutate({
            ...duplicateAlert,
            status: alertConditionMet ? "Paused" : "Active",
          });
        }
      }}
    />
  );
}

// To prevent multiple alerts from playing at the same time.
function playAlertSound() {
  if (document.getElementById("alert-sound")) return;
  const audio = document.createElement("audio");
  audio.id = "alert-sound";
  audio.src = alertSounds.huthut;
  document.body.appendChild(audio);
  audio.play();
  audio.addEventListener("ended", () => {
    document.getElementById("alert-sound")?.remove();
  });
}

const notificationSentAtom = atom<string[]>([]);

export async function sendNotification(
  alert: AlertFragmentFragment,
  initial: boolean,
  hasuraClient: GraphQLClient,
  queryClient: QueryClient,
  makeApiRequest: ReturnType<typeof useApi>,
) {
  const productInfo = await queryClient.fetchQuery(productsInfoQueryOptions());
  const productName = productInfo?.[alert.product_id]?.name || "";
  const limitId = alert.limit.includes(":") ? alert.limit.split(":")[0] : null;
  const limitName = limitId ? productInfo?.[limitId]?.name || "" : null;

  logger.info("Alert triggered", {
    alert,
    productName,
    limitName,
  });

  if (alert.status !== "Triggered" || !alert.triggered_at) {
    await updateAlert(hasuraClient, {
      [alert.id]: {
        status: "Triggered",
        triggered_at: alert.triggered_at || new Date().toISOString(),
      },
    });
  }

  toast.custom(
    (t) => (
      <AlertToastMessage
        t={t}
        alert={alert}
        productName={productName || ""}
        limitName={limitName}
      />
    ),
    {
      id: alert.id,
      position: "bottom-right",
      duration: Number.POSITIVE_INFINITY,
    },
  );

  const currentSent = store.get(notificationSentAtom);
  if (currentSent.includes(alert.id)) {
    return;
  }

  const sound = Boolean(
    queryClient.getQueryData<TGridSettings>(["grid_settings", alert.user_id])
      ?.sound,
  );
  const isSoundPlaying = !!document.getElementById("alert-sound");
  const isShowing = document.getElementById(`alert-toast-${alert.id}`);

  if ((sound || alert.sound) && !isSoundPlaying) {
    if (initial && isShowing) return;
    playAlertSound();
  }

  store.set(notificationSentAtom, [...currentSent, alert.id]);

  setTimeout(() => {
    const updatedSent = store.get(notificationSentAtom);
    store.set(
      notificationSentAtom,
      updatedSent.filter((id) => id !== alert.id),
    );
  }, 3000);

  if (!Notification) {
    console.warn("Desktop notifications not available.");
    return;
  }

  if (Notification.permission !== "granted") {
    console.warn("Notification permission not granted.");
    return;
  }

  const pushSubscriptions = await client.fetchById(
    "pushSubscriptions",
    alert.user_id,
  );

  if (pushSubscriptions && !alert.push_notification_sent) {
    const body = {
      publicKey: vapidKeys.publicKey,
      privateKey: vapidKeys.privateKey,
      subscriptions: pushSubscriptions.subscriptions,
      title: `${alert.note || "Alert"} triggered`,
      body: getAlertDetails(alert, productName, limitName).formattedMessage,
    };

    logger.info("Attempting to send push notification", { body });

    await updateAlert(hasuraClient, {
      [alert.id]: { push_notification_sent: true },
    });

    makeApiRequest("/api/send-notification", "POST", JSON.stringify(body));
  }

  if (!alert.desktop_notification_sent) {
    await updateAlert(hasuraClient, {
      [alert.id]: { desktop_notification_sent: true },
    });

    const notification = new Notification(
      `${alert.note || "Alert"} triggered`,
      {
        body: getAlertDetails(alert, productName, limitName).formattedMessage,
      },
    );

    logger.info("Alert desktop notification sent", {
      alert,
      productName,
      limitName,
    });

    notification.onclick = () => {
      window.focus();
      notification.close();
      updateAlert(hasuraClient, {
        [alert.id]: { status: "Triggered", triggered_at: null },
      });
    };

    return notification;
  }
}
