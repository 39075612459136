import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { z } from "zod";

import { pagesQueryOptions, useUpdatePage } from "./page";
import { useGraphQLClient } from "../../utils/graphql";
import { useUserId } from "../../context/auth";

export const useAdhocSpreads = (pageId: string) => {
  const hasura = useGraphQLClient();
  const userId = useUserId();

  return useQuery({
    ...pagesQueryOptions(hasura, userId),
    select: (x) => {
      const page = x.find((y) => y.id === pageId);
      const adhoc_spreads = z
        .array(SpreadSchema)
        .safeParse(page?.adhoc_spreads ? JSON.parse(page?.adhoc_spreads) : []);
      if (adhoc_spreads.success === false) return {};
      return Object.fromEntries(
        adhoc_spreads.data.map((x) => [x.rowId, x]),
      ) as TAdhocSpreadFormatted;
    },
  });
};

export const useUpdateAdhocSpreads = () => {
  const updatePage = useUpdatePage();
  const update = useCallback(
    (data: { id: string; adhoc_spreads: TAdhocSpreadFormatted }) => {
      return updatePage.optimistic({
        ...data,
        adhoc_spreads: JSON.stringify(
          Object.entries(data.adhoc_spreads).map(([_, period]) => period),
        ),
      });
    },
    [updatePage.optimistic],
  );

  return update;
};

const PeriodSchema = z.object({
  periodType: z
    .enum(["months", "quarters", "halves", "cals", "seasons"])
    .optional()
    .nullable(),
  periodValue: z.string().nullable().optional(),
});

const SpreadSchema = z.object({
  rowId: z.string(),
  from: PeriodSchema.optional().nullable(),
  to: PeriodSchema.optional().nullable(),
});

export type TAdhocSpread = z.infer<typeof SpreadSchema>;
export type TAdhocSpreads = TAdhocSpread[];
export type TAdhocPeriod = z.infer<typeof PeriodSchema>;
export type TAdhocSpreadFormatted = Record<string, TAdhocSpread>;
